import React, { useEffect, useState } from 'react';
import Authenticated from '@/Layouts/Authenticated';
import { Head, Link, useForm, usePage } from '@inertiajs/inertia-react';
import Button from '@/Components/Button';
import Input from '@/Components/Input';
import EmptyState from '@/Components/EmptyState';
import Label from '@/Components/Label';
import { Inertia } from '@inertiajs/inertia';
import UserCard from '@/Components/UserCard';

function Home({auth}) {
    const { data, setData, post, errors } = useForm({ phone: '' });

    const onHandleChange = (event) => {
        setData(event.target.name, event.target.value);
    };

    const submit = (e) => {
        e.preventDefault();
        post(route('updatephonenumber'));
    };

    const cancelInviteRequest = async (id) => {
        try {
            Inertia.post(route('cancel-friend-request', id));
        } catch (error) {
            console.error('Error canceling friend request:', error);
        }
    };
    const { users, loading, page, sort, verification_image } = usePage().props;
    // console.log(verification_image);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [currentPage, setCurrentPage] = useState(Number(page));
    const [loadedUsers, setLoadedUsers] = useState(users);
    const [prevSort, setPrevSort] = useState(sort);

    const loadMoreUsers = () => {
        if (isLoadingMore || loading) return;
        setIsLoadingMore(true);
        Inertia.get(`/home?page=${currentPage + 1}&sort=${sort}`, {
            onSuccess: (res) => {
                setCurrentPage((prevPage) => prevPage + 1);
                setLoadedUsers((prevUsers) => [...prevUsers, ...res.props.users.data]);
                setIsLoadingMore(false);
            },
            onError: () => {
                setIsLoadingMore(false);
            },
        });
    };
    
    useEffect(() => {
        if (sort !== prevSort) {
            setCurrentPage(1);
            setLoadedUsers(users);
            setPrevSort(sort);
        }
    }, [sort, users]);
    
    return  <Authenticated
                auth={auth}
                errors={errors}
                header={<h2 className="text-xl font-semibold leading-tight text-gray-800 dark:text-slate-50 ">Matches</h2>}
                btnName="Preferences"
                svg={<svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 mr-0 text-center fill-current sm:mr-2" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z" />
                    </svg>}
                href={route('auth.user.preferences')}
            >
                <Head title="Home | Suitable" />
                <div className="py-2">
                    <div className="max-w-3xl mx-auto sm:px-6 lg:px-8">
                        {auth.user.account_status == 0 && (
                            <div className="p-4 mb-4 border border-red-400 rounded-md shadow-sm bg-red-50/50 dark:bg-slate-800"> 
                                <h2 className="mb-4 text-xl font-bold text-slate-900 dark:text-slate-50">Profile Verification Required</h2>
                                <div className="flex items-start space-x-2 ">
                                    <div className="flex-none p-5 text-lg font-bold text-red-400 border border-red-400 rounded-lg shadow-md dark:text-red-400">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="text-red-400 h-7 w-7" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clipRule="evenodd" />
                                        </svg>
                                    </div>
                                    <div className="relative flex-auto min-w-0">
                                        <div className="p-2 pt-0">
                                            <p className="text-sm font-medium leading-5 tracking-wide text-gray-900 dark:text-gray-200">
                                                {auth.user.username}, your profile is currently pending verification, which may take up to 48 hours.
                                                {!verification_image && (
                                                    <>
                                                        To complete the process, please <Link href={route('verifyAge')} className="text-sm font-semibold text-red-500 underline ease-in-out dark:text-red-400 hover:border-b-2 hover:border-red-600 hover:text-red-600">Verify </Link> your account.
                                                    </>
                                                )}
                                                <br/> We recommend you to set up your 
                                                <Link href={route('auth.user.preferences')} className="ml-1 mr-1 text-sm font-semibold text-red-500 underline ease-in-out hover:no-underline dark:text-red-400 hover:border-b-2 hover:border-red-600 hover:text-red-600">Preferences</Link> while you wait to activate account.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            )
                        }

                        {auth.user.account_status == 1 && auth.user.phone == null && (
                            <div className="p-4 mb-4 border rounded-md shadow-sm border-slate-500/50 dark:border-slate-500 bg-slate-50/50 dark:bg-slate-800"> 
                                <h2 className="text-lg font-semibold text-slate-900 dark:text-slate-50">Please add your phone number.</h2>
                                <p className="mb-4 text-sm font-medium leading-5 tracking-wide text-gray-600 dark:text-gray-400">
                                    We only ask for phone number to contact our customer, this will not show in your profile.
                                </p>
                                <div className="flex items-start space-x-2 ">
                                    <div className="flex-none p-5 text-lg font-bold text-red-400 border border-red-400 rounded-lg shadow-md dark:text-red-400">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="text-red-400 h-7 w-7">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
                                        </svg>
                                    </div>
                                    <div className="relative flex-auto min-w-0">
                                        <div className="p-2 pt-0">
                                            {/* <ValidationErrors errors={errors} /> */}
                                            <form onSubmit={submit}>
                                                <Label htmlFor="phone" value={"Phone Number"} className="sr-only"/>
                                                <Input
                                                    type="tel"
                                                    id="phone"
                                                    name="phone"
                                                    className={`appearance-none bg-slate-100 text-slate-900 dark:bg-slate-600 dark:text-white ${errors.phone && 'border border-red-400'}`}
                                                    handleChange={onHandleChange}
                                                    value={data.phone}
                                                    placeholder="Enter your phone number"
                                                />
                                                {errors.phone && (
                                                    <p className="text-sm text-red-600 dark:text-red-400">{errors.phone}</p>
                                                )}
                                                <Button className='mt-2' type="submit">Add</Button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {auth.user.account_status === 2 && (
                            <div className="p-4 mb-4 border border-red-400 rounded-md shadow-sm bg-red-50/50 dark:bg-slate-800"> 
                                <h2 className="mb-4 text-lg font-semibold text-slate-800 dark:text-slate-100">Attention!</h2>
                                <div className="flex items-start space-x-2 ">
                                    <div className="flex-none p-5 text-lg font-bold text-red-400 border border-red-400 rounded-lg shadow-md dark:text-red-400">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="text-red-400 h-7 w-7" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clipRule="evenodd" />
                                        </svg>
                                    </div>
                                    <div className="relative flex-auto min-w-0">
                                        <div className="p-2 pt-0">
                                            <p className="text-sm font-semibold leading-5 tracking-wide text-gray-600 dark:text-gray-400">
                                                {auth.user.username}, your profile approval status has been declined! Please check your email for further instructions. You can send or receive invitation once your account is activated again.
                                                You can read our <Link href={route('faq')} className="text-sm font-medium text-red-400 underline ease-in-out hover:no-underline dark:text-red-300 hover:border-b-2 hover:border-red-500 hover:text-red-500">Faq</Link> to get a guidline on using our platform. 
                                                If you have any question, please <Link href={route('contact-support')} className=" underline hover:no-underline text-red-400 dark:text-red-300 text-sm font-medium hover:border-b-2 hover:border-red-500 hover:pb-0.5 hover:text-red-600 ease-in-out">Contact us</Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            )
                        }

                        {users.map(user => (
                            <UserCard
                                key={user.id}
                                user={user}
                                auth={auth}
                                cancelInviteRequest={cancelInviteRequest}
                            />
                        ))}

                        {users.length >= 10 && (
                            <div className="flex items-center justify-center mb-2"> 
                                <button onClick={loadMoreUsers} disabled={isLoadingMore || loading} className="px-4 py-3 font-semibold text-white bg-purple-700 border border-purple-700 rounded-lg hover:bg-purple-600 hover:border-purple-900 hover:text-white dark:hover:bg-purple-600 dark:hover:text-white focus:outline-none focus:border-0">
                                    {isLoadingMore ? 
                                    (
                                        <div className="text-center">
                                            <div role="status">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="inline w-6 h-6 text-gray-100 animate-spin dark:text-gray-50 fill-purple-600">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                                                </svg>
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                    )
                                    :
                                    <div className="inline-flex items-center justify-center">
                                        Load more
                                        <span
                                            className="ml-1 font-semibold transition-transform duration-300 ease-in text-md group-hover:translate-x-1"
                                            aria-hidden="true"
                                        >
                                            →
                                        </span>
                                    </div>
                                    }
                                </button>
                            </div>
                        )}
                        
                    </div>
                </div>
                {users.length === 0 && (
                <div className="">
                    <EmptyState bgimage="bg-empty-background" title="Looks like no one's here." subtitle="Please wait or come back later!" btnName="Read our faqs" linktext="Have any questions?" href={route('faq')} />
                        {/* <p className="text-2xl font-semibold text-slate-800 dark:text-slate-50">Looks like you've reached to the end. Please come back later!</p>  */}
                </div>
                )}
            </Authenticated>;
    }

export default Home;



// ----------------------------------------------------------------
// MAIN 

// <div key={id} className="flex-none mb-4 space-y-2 bg-white shadow-md sm:flex dark:bg-slate-800 sm:rounded-lg">
//     <div className="blur-[2px] overflow-hidden relative sm:min-h-full w-full sm:w-[19rem] sm:mb-0 mb-3">
//         {!profile_image ? (
//                 <img src="assets/images/man.svg" alt={`Man photo`}  className="blur-[2px] w-full sm:w-[19rem] h-auto sm:min-h-full inset-0 object-cover aspect-square sm:rounded-l-lg" />
//             ): 
//                 <img src={`http://localhost:3000/${profile_image}`} alt={`${username}'s Profile photo`} onerror="this.onerror=null;this.src='https://picsum.photos/200';"  className="blur-[2px] w-full sm:w-[19rem] h-auto sm:min-h-full inset-0 object-cover aspect-square sm:rounded-l-lg" />
//             }
//     </div>
//     <div className="flex-auto p-4 sm:ml-3 justify-evenly">
//         <div className="flex items-center sm:mt-2">
//             <div className="flex flex-col ">
//                 <div className="flex flex-wrap">
//                     <div className="flex items-center">
//                         <h1 className="text-lg font-semibold text-slate-900 dark:text-slate-50">
//                             {username}, {age}
//                         </h1>
//                         <div className="w-2 h-2 m-1 ml-2 bg-green-600 rounded-full"></div>
//                     </div>
//                     <h1 className="flex-auto text-lg font-semibold text-slate-900">
//                         {/* {age} */}
//                     </h1>
                    
//                     <div className="text-lg font-semibold text-slate-500">
//                         <Dropdown>
//                             <Dropdown.Trigger>
//                                 <span className="inline-flex rounded-md">
//                                     <button
//                                         type="button"
//                                         className="inline-flex items-center px-2 py-1 text-sm font-medium leading-4 text-gray-500 transition duration-300 ease-in-out rounded focus:bg-slate-200 dark:focus:bg-slate-200 dark:focus:text-slate-800 focus:border-slate-400 dark:text-gray-100 focus:outline-none"
//                                     >
//                                         <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-slate-700 dark:text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//                                                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z" />
//                                         </svg>
//                                     </button>
//                                 </span>
//                             </Dropdown.Trigger>

//                             <Dropdown.Content className="w-10" >
//                                 <Dropdown.Link href={route('block-user', id)} method="post" as="button">
//                                     Block
//                                 </Dropdown.Link>
//                                 <Dropdown.Link href={route('auth.user.profile')} method="get" as="button">
//                                     Report
//                                 </Dropdown.Link>
//                             </Dropdown.Content>
//                         </Dropdown>
//                     </div>
//                     <div className="pl-3 text-lg font-semibold text-slate-500">
//                         {auth.user.account_status == 1 &&
//                             <Link method="post" href={route('pass-user', id)} preserveScroll>
//                                 <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
//                                     <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
//                                 </svg>
//                             </Link>
//                         }
//                     </div>
//                     <div className="flex-none w-full mt-2 text-slate-700">
//                         <span className="text-sm font-medium divide-y-2 text-slate-600 dark:text-slate-200">{ethnic_origin} From {country} </span> - <span className="text-slate-600 dark:text-slate-200">{recidency_status}</span>
//                     </div>
//                 </div>
                
//                 <div className='pb-6 mb-6 border-b border-slate-200'>
//                     <p className="text-sm text-slate-600 dark:text-slate-300">{highest_education != '' ? highest_education : ''  } - {current_profession} - {height}</p>
//                     <p className="text-sm text-slate-600 dark:text-slate-300">Practicing Muslim - {sect}</p>
//                 </div>
//                 <div className="flex-auto my-1 text-gray-700 dark:text-gray-400">
//                 </div>
//             </div>
//         </div>

//         <div className="flex mt-3 space-x-4 text-sm font-medium">
//             <div className="flex flex-auto space-x-4">
//                 <Link href={route('user-profile', username)} className="inline-flex items-center h-10 px-6 py-2 font-bold transition duration-300 ease-in-out bg-black rounded-md dark:bg-slate-100 text-slate-50 dark:text-slate-500 hover:bg-slate-800 hover:text-slate-50 dark:hover:bg-slate-50 border-1 border-slate-800 dark:border-slate-50 focus:ring-2 dark:ring-slate-400 focus:outline-none">
//                     <span className="text-xs sm:text-sm">View</span>
//                 </Link>
                
//                 {auth.user.account_status == 0 ? ( 
//                     <button disabled className="inline-flex items-center h-10 px-6 py-2 font-bold transition duration-300 ease-in-out bg-transparent rounded-md disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none text-slate-800 dark:text-slate-500 dark:bg-slate-800 hover:bg-slate-800 hover:text-slate-50 dark:hover:bg-slate-50 border-1 border-slate-200 bg-slate-200 dark:border-slate-50 focus:ring-2 dark:ring-slate-400 focus:outline-none">
//                         <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//                             <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
//                         </svg>
//                         <span className="text-xs sm:text-sm">Invite</span>
//                     </button>
//                 ) : ( (!isInvited ? (
//                     <Link preserveScroll href={route('invite.user', id)} method="post" as="button"  className="inline-flex items-center h-10 px-6 py-2 font-bold transition duration-300 ease-in-out bg-transparent border-2 rounded-md text-slate-800 dark:text-slate-300 dark:bg-slate-800 hover:bg-slate-800 hover:text-slate-50 dark:hover:bg-slate-50 dark:hover:text-slate-500 border-slate-200 bg-slate-200 dark:border-slate-50 focus:ring-2 dark:ring-slate-400 focus:outline-none">
//                         <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 mr-2 rotate-90" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//                             <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
//                         </svg>
//                         <span className="text-xs sm:text-sm">Invite</span>
//                     </Link>
//                 ) :
//                     <Link preserveScroll href={route('uninvite.user', id)} method="post" as="button"  className="inline-flex items-center h-10 px-6 py-2 font-bold text-indigo-500 transition duration-300 ease-in-out bg-transparent border-2 rounded-md dark:text-indigo-500 dark:bg-slate-800 hover:bg-slate-100 hover:text-indigo-600 dark:hover:bg-slate-50 dark:hover:text-slate-500 border-slate-200 bg-slate-200 dark:border-slate-50 focus:ring-2 dark:ring-slate-400 focus:outline-none">
//                         <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//                             <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
//                         </svg>
//                         <span className="text-xs sm:text-sm">Invited</span>
//                     </Link> )
//                 )}
//             {/* 
//                 {isFollowing ? ( 
//                     <Link preserveScroll href={route('user.unfollow', id)} method="post" as="button" className="inline-flex items-center h-10 px-6 py-2 font-bold transition duration-300 ease-in-out bg-black rounded-md dark:bg-slate-100 text-slate-50 dark:text-slate-500 hover:bg-slate-800 hover:text-slate-50 dark:hover:bg-slate-50 border-1 border-slate-800 dark:border-slate-50 focus:ring-2 dark:ring-slate-400 focus:outline-none">
//                         <span className="text-xs sm:text-sm">Unfollow</span>
//                     </Link> 
//                 ) : (
//                     <Link preserveScroll href={route('user.follow', id)} method="post" as="button"className="inline-flex items-center h-10 px-6 py-2 font-bold transition duration-300 ease-in-out bg-black rounded-md dark:bg-slate-100 text-slate-50 dark:text-slate-500 hover:bg-slate-800 hover:text-slate-50 dark:hover:bg-slate-50 border-1 border-slate-800 dark:border-slate-50 focus:ring-2 dark:ring-slate-400 focus:outline-none">
//                         <span className="text-xs sm:text-sm">Follow</span>
//                     </Link> 
//                 )}
//             */}
//             </div>

//             {/* Save button */}

//             {!isSaved ? ( 
//                 <Link preserveScroll method="post" href={route('save.user', id)} className="flex items-center justify-center flex-none border rounded-md w-9 h-9 text-slate-300 border-slate-200 dark:border-slate-500 hover:bg-slate-800 hover:text-slate-50 dark:hover:bg-slate-50 dark:text-slate-600" type="button" aria-label="Like">
//                     <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
//                         <path strokeLinecap="round" strokeLinejoin="round" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
//                     </svg>
//                 </Link> )
//             : (
//                 <Link preserveScroll method="post" href={route('unsave.user', id)} className="flex items-center justify-center flex-none border rounded-md w-9 h-9 text-slate-300 border-slate-200 dark:border-slate-500 hover:bg-slate-800 hover:text-slate-50 dark:hover:bg-slate-50 dark:text-slate-600" type="button" aria-label="Like">
//                     <svg className="text-purple-400" width="20" height="20" fill="currentColor" aria-hidden="true">
//                         <path fillRule="evenodd" clipRule="evenodd" d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z" />
//                     </svg>
//                 </Link>
//             )}
//         </div>
//         <p className="pt-4 text-sm text-yellow-700">
//             {auth.user.account_status == 0 ? 'You can send invitation to this user once your account is activated!' : (!isAccepted ? `You can view ${username}'s profile once ${gender == 'Male' ? 'he' : 'she'} accepts your request.` : `You can view ${username}'s full profile`) }
            
//         </p>
// </div>
// </div>