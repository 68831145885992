import { Link, useForm } from '@inertiajs/inertia-react';
import React, { useEffect, useState } from 'react'

import LogoPurple from '../../../public/assets/images/logo-purple.svg';
import LogoLight from '../../../public/assets/images/logo-light.svg';

import Instagram from '../../../public/assets/images/instagram-logo.svg';
import Linkedin from '../../../public/assets/images/linkedin-logo.svg';

import { ThemeContext } from '@/context/ThemeContext';
import Label from '@/Components/Label';
import InputSelect from '@/Components/InputSelect';
import ValidationErrors from '@/Components/ValidationErrors';
import FlashMessages from '@/Components/FlashMessages';
import Footer from '@/Layouts/Footer';
import LoadingButton from '@/Components/LoadingButton';
import { CheckCircleIcon, PhoneIcon } from '@heroicons/react/outline';
import Input from '@/Components/Input';



function Contact(props) {
    const { theme, setTheme } = React.useContext(ThemeContext);

    const { data, setData, post, processing, errors, reset } = useForm({
        name: '',
        email: '',
        query_type: '',
        contact_number: '',
        message: '',
    });
    const [selectedOption, setSelectedOption] = useState('');
    const [submitted, setSubmitted] = useState(false);

    const onHandleChange = (event) => {
        const { name, value } = event.target;
        setData(name, value);
        if (name === 'query_type') {
          setSelectedOption(value);
        }
      };
    
    const submit = (e) => {
        e.preventDefault();
        post('/contact-support/store', {
          onSuccess: () => {
            reset();
            setSubmitted(true);
          },
        });
    };


    const queryTypeOptions = [
        { value: "Customer service", label: "Customer service" },
        { value: "Existing query", label: "Existing query" },
        { value: "Report a problem", label: "Report a problem" },
        { value: "Feedback", label: "Feedback" },
        { value: "Suggest a feature", label: "Suggest a feature" },
        { value: "Other", label: "Other" },
    ];
  
    return (
        <>
            <div className="w-full bg-white dark:bg-slate-900 h-100">
                <nav className="bg-white px-5 sm:px-20 py-2  dark:bg-slate-800 border-b border-gray-100 dark:border-slate-500 sticky top-0 z-40 backdrop-blur flex-none transition-colors duration-500 lg:z-50 lg:border-b lg:border-slate-900/10 dark:border-slate-50/[0.06] bg-white/95 supports-backdrop-blur:bg-white/60 dark:bg-transparent">
                    <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                        <div className="flex flex-wrap items-center justify-between">
                            <Link as="a" href="/" className="flex items-center justify-center">
                                <img className="block w-full h-7 dark:hidden" src={LogoPurple} alt="shape" />
                                <img className="hidden w-full h-7 dark:block" src={LogoLight} alt="shape" />
                            </Link>
                            <div className="flex space-x-3">
                                {theme === 'dark' ? (
                                    <button id="theme-toggle" type="button" onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')} className={`${theme === 'light' ? 'hidden' : ''}text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5`}>
                                        <svg id="theme-toggle-dark-icon" xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z" /></svg>
                                    </button>

                                        ) : (
                                        <button id="theme-toggle" type="button" onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')} className={`${theme === 'dark' ? 'hidden' : ''}text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5`}>
                                            <svg id="theme-toggle-light-icon" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z" fillRule="evenodd" clipRule="evenodd"></path></svg>
                                        </button>
                                    )}

                                    {props.auth.user ? (
                                            <Link href={route('home')} className="font-semibold main-btn gradient-btn">
                                                {props.auth.user.username}
                                            </Link>
                                        ) : (
                                            <Link href={route('login')} className="font-semibold main-btn gradient-btn">
                                                Get started
                                            </Link>
                                    )}
                                </div>
                        </div>
                    </div>
                </nav>
            
                <div className="bg-gray-100 rounded-md dark:bg-slate-900">
                    <div className="px-6 py-16 mx-auto max-w-7xl sm:py-24 lg:px-8">
                        <div className="relative rounded-md shadow-xl dark:border-slate-700 dark:border">
                            <div className="grid grid-cols-1 lg:grid-cols-3">
                            {/* Contact information */}
                            <div className="relative px-6 py-10 overflow-hidden bg-indigo-700 sm:px-10 xl:p-12">
                                <div className="absolute inset-0 pointer-events-none sm:hidden" aria-hidden="true">
                                <svg
                                    className="absolute inset-0 w-full h-full"
                                    width={343}
                                    height={388}
                                    viewBox="0 0 343 388"
                                    fill="none"
                                    preserveAspectRatio="xMidYMid slice"
                                >
                                    <path
                                    d="M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z"
                                    fill="url(#linear1)"
                                    fillOpacity=".1"
                                    />
                                    <defs>
                                    <linearGradient
                                        id="linear1"
                                        x1="254.553"
                                        y1="107.554"
                                        x2="961.66"
                                        y2="814.66"
                                        gradientUnits="userSpaceOnUse"
                                    >
                                        <stop stopColor="#fff" />
                                        <stop offset={1} stopColor="#fff" stopOpacity={0} />
                                    </linearGradient>
                                    </defs>
                                </svg>
                                </div>
                                <div
                                className="absolute top-0 bottom-0 right-0 hidden w-1/2 pointer-events-none sm:block lg:hidden"
                                aria-hidden="true"
                                >
                                <svg
                                    className="absolute inset-0 w-full h-full"
                                    width={359}
                                    height={339}
                                    viewBox="0 0 359 339"
                                    fill="none"
                                    preserveAspectRatio="xMidYMid slice"
                                >
                                    <path
                                    d="M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z"
                                    fill="url(#linear2)"
                                    fillOpacity=".1"
                                    />
                                    <defs>
                                    <linearGradient
                                        id="linear2"
                                        x1="192.553"
                                        y1="28.553"
                                        x2="899.66"
                                        y2="735.66"
                                        gradientUnits="userSpaceOnUse"
                                    >
                                        <stop stopColor="#fff" />
                                        <stop offset={1} stopColor="#fff" stopOpacity={0} />
                                    </linearGradient>
                                    </defs>
                                </svg>
                                </div>
                                <div
                                className="absolute top-0 bottom-0 right-0 hidden w-1/2 pointer-events-none lg:block"
                                aria-hidden="true"
                                >
                                <svg
                                    className="absolute inset-0 w-full h-full"
                                    width={160}
                                    height={678}
                                    viewBox="0 0 160 678"
                                    fill="none"
                                    preserveAspectRatio="xMidYMid slice"
                                >
                                    <path
                                    d="M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z"
                                    fill="url(#linear3)"
                                    fillOpacity=".1"
                                    />
                                    <defs>
                                    <linearGradient
                                        id="linear3"
                                        x1="192.553"
                                        y1="325.553"
                                        x2="899.66"
                                        y2="1032.66"
                                        gradientUnits="userSpaceOnUse"
                                    >
                                        <stop stopColor="#fff" />
                                        <stop offset={1} stopColor="#fff" stopOpacity={0} />
                                    </linearGradient>
                                    </defs>
                                </svg>
                                </div>
                                <h2 className="text-3xl font-bold tracking-tight text-white">Get in touch</h2>

                                <p className="max-w-3xl mt-6 text-base text-indigo-50">
                                    We are open to any suggestions or questions you have. Send us a message and we will get back to you as soon as possible.
                                </p>
                                <dl className="mt-8 space-y-6">
                                <dt>
                                    <span className="sr-only">Email</span>
                                </dt>
                                <dd className="flex text-base text-indigo-50">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="flex-shrink-0 w-6 h-6 text-indigo-200">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
                                    </svg>

                                    <span className="ml-3">hello@suitable.one</span>
                                </dd>
                                </dl>
                                <ul role="list" className="flex mt-8 space-x-12">
                                <li>
                                    <a className="text-indigo-200 hover:text-indigo-100" href="#">
                                    <span className="sr-only">Facebook</span>
                                    <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                                        <path
                                        fillRule="evenodd"
                                        d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                                        clipRule="evenodd"
                                        />
                                    </svg>
                                    </a>
                                </li>
                                <li>
                                    <a className="text-indigo-200 hover:text-indigo-100" href="#">
                                        <span className="sr-only">Instagram</span>
                                        <img src={Instagram} className='w-7 h-7' />
                                    </a>
                                </li>
                                <li>
                                    <a className="text-indigo-200 hover:text-indigo-100" href="#">
                                        <span className="sr-only">Linkedin</span>
                                        <img src={Linkedin} className='w-6 h-6' />
                                        {/* <svg className='w-6 h-6 text-white' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/></svg> */}
                                    </a>
                                </li>
                                </ul>
                            </div>

                            {/* Contact form */}
                            <div className="px-6 py-10 sm:px-10 lg:col-span-2 xl:p-12">
                                <h3 className="text-2xl font-semibold text-gray-900 dark:text-slate-200 dark:text-white">{submitted ? "Thank You!" : "Send us a message"}</h3>
                                
                                {submitted ? (
                                    <div className="flex-row items-start justify-start mt-2">
                                        <div className="flex items-center justify-start gap-2 mt-2">
                                            <div className="flex-shrink-0">
                                                <CheckCircleIcon className="text-green-400 h-9 w-9" aria-hidden="true" />
                                            </div>
                                            <p className="text-gray-700 dark:text-gray-300">
                                               We received your message. We'll get back to you as soon as possible.
                                            </p>
                                        </div>
                                        <button
                                            onClick={() => setSubmitted(false)}
                                            className="inline-flex items-center justify-center px-6 py-3 mt-6 text-base font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        >
                                            Send Another Message
                                        </button>
                                    </div>
                                ) : (

                                    <form onSubmit={submit} className="grid grid-cols-1 mt-6 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                                        <div>
                                            <label htmlFor="name" className="block text-sm font-medium text-gray-900 dark:text-slate-200">
                                                First name
                                            </label>
                                            <div className="mt-1">
                                            <Input
                                                type="text"
                                                value={data.name} 
                                                name={`name`} 
                                                id="name"
                                                handleChange={onHandleChange} 
                                                className="block w-full px-4 py-3 text-gray-900 border-gray-300 rounded-md shadow-sm dark:text-slate-200 focus:border-indigo-500 focus:ring-indigo-500"
                                            />
                                            {errors.name && (
                                                <p className="text-sm text-red-600 dark:text-red-400">{errors.name}</p>
                                            )}
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor="email" className="block text-sm font-medium text-gray-900 dark:text-slate-200">
                                                Email
                                            </label>
                                            <div className="mt-1">
                                                <Input
                                                    id="email"
                                                    value={data.email} 
                                                    type="email" 
                                                    name='email'
                                                    handleChange={onHandleChange} 
                                                    className="block w-full px-4 py-3 text-gray-900 border-gray-300 rounded-md shadow-sm dark:text-slate-200 focus:border-indigo-500 focus:ring-indigo-500"
                                                />
                                                {errors.email && (
                                                    <p className="text-sm text-red-600 dark:text-red-400">{errors.email}</p>
                                                )}
                                            </div>
                                        </div>
                                        
                                        <div>
                                            <div className="flex justify-between">
                                                <label htmlFor="phone" className="block text-sm font-medium text-gray-900 dark:text-slate-200">
                                                    Phone
                                                </label>
                                                <span id="phone-optional" className="text-sm text-gray-500 dark:text-gray-300">
                                                    Optional
                                                </span>
                                            </div>
                                            <div className="mt-1">
                                                <Input
                                                    value={data.contact_number} 
                                                    type="tel" 
                                                    name='contact_number'
                                                    id="phone"
                                                    className="block w-full px-4 py-3 text-gray-900 border-gray-300 rounded-md shadow-sm dark:text-slate-200 focus:border-indigo-500 focus:ring-indigo-500"
                                                    handleChange={onHandleChange} 
                                                    aria-describedby="phone-optional"
                                                />
                                                {errors.contact_number && (
                                                    <p className="text-sm text-red-600 dark:text-red-400">{errors.contact_number}</p>
                                                )}
                                            </div>
                                        </div>
                                        <div >
                                            <label htmlFor="subject" className="block text-sm font-medium text-gray-900 dark:text-slate-200">
                                            Subject
                                            </label>
                                            <div className="mt-1">
                                            <select
                                                value={selectedOption}
                                                onChange={onHandleChange}
                                                name="query_type"
                                                className="block w-full px-4 py-3 text-gray-900 border-gray-300 rounded-md shadow-sm dark:text-slate-200 focus:border-indigo-500 focus:ring-indigo-500"
                                            >
                                                <option value="" disabled>Select query type</option>
                                                {queryTypeOptions.map((option) => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                                ))}
                                            </select>
                                            {errors.query_type && (
                                                <p className="text-sm text-red-600 dark:text-red-400">{errors.query_type}</p>
                                            )}
                                            </div>
                                        </div>
                                        <div className="sm:col-span-2">
                                            <div className="flex justify-between">
                                            <label htmlFor="message" className="block text-sm font-medium text-gray-900 dark:text-slate-200">
                                                Message
                                            </label>
                                            <span id="message-max" className="text-sm text-gray-500">
                                                Max. 500 characters
                                            </span>
                                            </div>
                                            <div className="mt-1">
                                                <textarea
                                                    id="message"
                                                    name="message"
                                                    rows={4}
                                                    value={data.message}
                                                    onChange={onHandleChange}
                                                    className="block w-full px-4 py-3 text-gray-900 border-gray-300 rounded-md shadow-sm dark:text-slate-200 focus:border-indigo-500 focus:ring-indigo-500"
                                                    aria-describedby="message-max"
                                                />
                                                {errors.message && (
                                                    <p className="text-sm text-red-600 dark:text-red-400">{errors.message}</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="sm:col-span-2 sm:flex sm:justify-end">
                                            <button
                                            type="submit"
                                            className="inline-flex items-center justify-center w-full px-6 py-3 mt-2 text-base font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                                            >
                                            Submit
                                            </button>
                                        </div>
                                    </form>
                                )}

                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
  )
}

export default Contact
{/* <div className="flex items-center justify-center w-full my-12">
                  <div className="px-8 py-12 rounded shadow bg-slate-50 dark:bg-slate-800 lg:px-28">
                      <p className="text-xl font-bold leading-7 text-center text-gray-700 md:text-4xl dark:text-slate-100">Contact us</p>
                      <p className="text-sm font-medium leading-7 text-center text-gray-500 md:text-lg dark:text-slate-400">We are open to any suggestions or questions you have. Send us a message and we will get back to you as soon as possible.</p>
                      <ValidationErrors errors={errors} />
                      <FlashMessages/>
                      <form className="px-2 sm:px-12 animate-fade-in" onSubmit={submit} autoComplete="off">
                          <div className="items-center mt-12 md:flex">
                              <div className="flex flex-col md:w-full">
                                  <label className="text-base font-semibold leading-none text-gray-800 dark:text-slate-100">Name</label>
                                  <Input value={data.name} name={`name`} type="text" className="p-3 mt-4 text-base leading-none text-gray-900 placeholder-gray-100 bg-gray-100 border border-gray-200 rounded dark:text-slate-200 focus:oultine-none focus:border-purple-700" placeholder="Jhon doe" handleChange={onHandleChange} />
                              </div>
                              <div className="flex flex-col mt-4 md:w-full md:ml-6 md:mt-0">
                                  <label className="text-base font-semibold leading-none text-gray-800 dark:text-slate-100">Email</label>
                                  <Input value={data.email} type="email" name='email' className="p-3 mt-4 text-base leading-none text-gray-900 placeholder-gray-100 bg-gray-100 border border-gray-200 rounded dark:text-slate-200 focus:oultine-none focus:border-purple-700" placeholder="john@doe.com" handleChange={onHandleChange} />
                              </div>
                          </div>
                          
                          <div className="items-center mt-12 md:flex">
                              <div className="flex flex-col md:w-full">
                                  <label className="text-base font-semibold leading-none text-gray-800 dark:text-slate-100">Contact number</label>
                                  <Input value={data.contact_number} type="number" name='contact_number' className="p-3 mt-4 text-base leading-none text-gray-900 placeholder-gray-100 bg-gray-100 border border-gray-200 rounded dark:text-slate-200 focus:oultine-none focus:border-purple-700" placeholder="Contact number" handleChange={onHandleChange} />
                              </div>
                              <div className="flex flex-col mt-4 md:w-full md:ml-6 md:mt-0">
                                  <label className="text-base font-semibold leading-none text-gray-800 dark:text-slate-100">Query type</label>
                                  <InputSelect 
                                      value={data.query_type} 
                                      handleChange={onHandleChange} 
                                      options={queryTypeOptions} 
                                      className={`!text-md !leading-none text-gray-900 dark:text-slate-200 !p-3 h-11 focus:oultine-none focus:border-purple-700 mt-4 bg-gray-100 border !rounded-sm border-gray-200 !placeholder-gray-100`} 
                                      placeholder='Select a query type'
                                      name={`query_type`}
                                  />
                              </div>
                          </div>
                          <div>
                              <div className="flex flex-col w-full mt-8">
                                  <label className="text-base font-semibold leading-none text-gray-800 dark:text-slate-100">Message</label>
                                  <textarea role="textbox" type="name" name="message" className="p-3 mt-4 text-base leading-none text-gray-900 placeholder-gray-100 bg-gray-100 border border-gray-200 rounded resize-none dark:text-slate-200 h-36 focus:oultine-none focus:border-purple-700" value={data.message} handleChange={onHandleChange} />
                              </div>
                          </div>
                          <p className="mt-4 text-xs leading-3 text-gray-600 dark:text-slate-100">By clicking submit you agree to our terms of service, privacy policy and how we use data as stated</p>
                          <div className="flex items-center justify-center w-full">
                              <LoadingButton
                                  loading={processing}
                                  type="submit"
                                  className="px-10 py-4 text-base font-semibold leading-none text-white bg-purple-700 rounded mt-9 hover:bg-purple-600 focus:ring-2 focus:ring-offset-2 focus:ring-purple-700 focus:outline-none"
                                  >
                                  Submit
                              </LoadingButton>
                          </div>
                      </form>
                  </div>
              </div> */}