import React, { useState, useEffect } from 'react';
import { Inertia } from '@inertiajs/inertia';
import { Link } from '@inertiajs/inertia-react';
import { debounce } from 'lodash';
import axios from 'axios';
import { SearchIcon } from '@heroicons/react/outline';

const UserSearch = () => {
    const [query, setQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (query.length > 2) {
            setLoading(true);
            debounceFetchUsers(query);
        } else {
            setSuggestions([]);
            setLoading(false);
        }
    }, [query]);

    const debounceFetchUsers = debounce((query) => {
        axios
            .get(route('admin.searchUsers', { query }))
            .then((response) => {
                setSuggestions(response.data);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    }, 300);


    return (
        <div className="relative mt-4">
            <form className="relative flex flex-1 border-b border-slate-200 dark:border-slate-600 focus-within:border-indigo-400">
                <label htmlFor="search-field" className="sr-only">
                    Search
                </label>
                
                <SearchIcon
                    className="absolute inset-y-0 w-5 h-5 text-indigo-600 pointer-events-none top-1 dark:text-indigo-400 left-3 "
                    aria-hidden="true"
                />
                <input
                    id="search-field"
                    className="block w-full h-full py-2 pl-10 pr-0 text-gray-900 border-0 dark:text-white placeholder:text-gray-600 dark:placeholder:text-gray-300 ring-0 focus:border-b-2 focus:ring-0 sm:text-sm"
                    placeholder="Search users..."
                    type="search"
                    name="search"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                />
            </form>

            {loading && <div className="absolute right-2 top-2">Loading...</div>}
            {suggestions.length === 0 && query.length > 2 && !loading && (
                <div className="w-full mt-2 bg-white border rounded-lg shadow-lg">
                    <div className="px-4 py-2 text-gray-500">No users found.</div>
                </div>
            )}
            {suggestions.length > 0 && (
                <div className="w-full mt-2 bg-white border rounded-lg shadow-lg">
                    <ul role="list" className="flex-grow px-4 py-2 overflow-x-hidden overflow-y-auto divide-y divide-gray-100 overscroll-contain">
                        {suggestions.map((user) => (
                            <li key={user.email}>
                                <Link href={route('admin.userProfile', user.username)} as="a" className="flex px-2 py-2 gap-x-4 hover:bg-gray-100">
                                    <img
                                        className="flex-none w-8 h-8 p-1 border-2 border-indigo-400 rounded-full bg-gray-50"
                                        src={user.profile_image}
                                        alt=""
                                    />
                                    <div className="min-w-0">
                                        <p className="text-sm font-semibold leading-6 text-gray-900">
                                            {user.username}, {user.age}
                                        </p>
                                        <p className="text-sm font-medium leading-5 text-gray-500">
                                            {user.email}, {user.phone}
                                        </p>
                                    </div>
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default UserSearch;
