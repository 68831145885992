import Button from '@/Components/Button'
import Dropdown from '@/Components/Dropdown'
import EmptyState from '@/Components/EmptyState'
import MatchedSideNav from '@/Components/MatchedSideNav'
import Authenticated from '@/Layouts/Authenticated'
import { Link, usePage } from '@inertiajs/inertia-react'
import React from 'react'
import Img from '../../../../public/images/user-avatar.png'
import { Inertia } from '@inertiajs/inertia'
import DropdownOptions from '@/Components/DropdownOptions'
import { Menu } from '@headlessui/react'
import { LockClosedIcon, ShieldCheckIcon, UserRemoveIcon, XIcon } from '@heroicons/react/outline'

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

function PeopleWhoLikedMe({auth}) {
        const { inviters } = usePage().props;
        const sendInviteRequest = (id) => {
            try {
                Inertia.post(route('send-friend-request'), { user_id: id });
            } catch (error) {
            //   toast.error('Error sending friend request. Please try again.');
            }
          };
        
          const cancelInviteRequest = async (id) => {
            try {
                Inertia.post(route('cancel-friend-request', id));
              // Assuming 'cancel-friend-request' is the route to handle canceling friend requests
            //   toast.success('Friend request canceled successfully!');
            } catch (error) {
            //   toast.error('Error canceling friend request. Please try again.');
            }
          };
        return (
            <Authenticated 
                auth={auth}
                // errors={errors}
                header={<h2 className="text-xl font-semibold leading-tight text-gray-800 dark:text-slate-50 ">People who liked me </h2>}
                btnName="Back"
                svg={<svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2"><path strokeLinecap="round" strokeLinejoin="round" d="M10 19l-7-7m0 0l7-7m-7 7h18" /></svg>}
                href={route('home')}
            >

            <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
                <div className="flex-row sm:flex sm:space-x-2">
                    <div className="sm:w-[250px] h-min ">
                        
                        <MatchedSideNav/>
                        
                    </div>
                    
                    <div className="sm:w-2/3">
                        <div className="py-0">
                            
                            <div className="max-w-3xl mx-auto sm:px-6 lg:px-8">
                                {inviters.map(({ 
                                    id, 
                                    username, 
                                    age, 
                                    height, 
                                    country, 
                                    ethnic_origin, 
                                    profile_image, 
                                    current_profession, 
                                    isSaved, 
                                    isInvited, 
                                    amiInvited, 
                                    isRejectedInvitation,
                                    amIBeingRejected,
                                    hasBeenAccepted,
                                    hasAcceptedMyInvitation,
                                    amIBeingAccepted,
                                    is_verified
                                }) => (
                                    <div className="p-6 mb-2 rounded-lg shadow-md bg-slate-50 dark:bg-slate-800 dark:highlight-white/5" key={username}>
                                        <div className="flex flex-col-reverse">
                                            <Link preserveScroll href={route('user-profile', username)} as="a" className="flex items-center space-x-4">
                                                <img
                                                    src={profile_image}
                                                    alt={`${username}'s Profile photo`}
                                                    className={`flex-none w-12 h-12 rounded-full object-cover`}
                                                    loading="lazy"
                                                />
                                                <div className="flex-auto">
                                                    <div className="flex items-center space-x-1">
                                                        <p className='text-lg font-bold text-slate-900 dark:text-slate-50'>{username}, {`${age}`}</p>
                                                        {is_verified === 1 && 
                                                            (<div className="relative text-indigo-600 dark:text-indigo-400">
                                                                <ShieldCheckIcon className='w-5 h-5' />
                                                            </div>)
                                                        }
                                                    </div>
                                                    <div className="text-sm font-medium text-slate-600 dark:text-slate-200">
                                                        {ethnic_origin} From {country} 
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="pl-8">
                                            <div className="flex-none w-full pl-8 text-slate-700">
                                                <p className="text-sm text-slate-600 dark:text-slate-300">{current_profession} - {height}</p>
                                            </div>
                                            <div className='pt-2 pl-8'>
                                                <div className="flex mt-2 space-x-4 text-sm font-medium">
                                                    <div className="flex flex-auto space-x-0 sm:space-x-4">
                                                        <Link href={route('user-profile', username)} as='a' className="items-center hidden h-10 px-6 py-2 font-bold text-purple-600 transition duration-300 ease-in-out bg-transparent bg-purple-100 border border-purple-400 rounded-md dark:text-purple-400 dark:bg-slate-800 hover:bg-purple-600 hover:text-white dark:hover:bg-purple-400 dark:hover:text-slate-50 dark:border-purple-400 focus:ring-2 dark:ring-slate-400 sm:inline-flex focus:outline-none">
                                                            <span className="mr-1 text-xs font-semibold sm:text-sm">View</span>
                                                            
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                                                            </svg>
                                                        </Link>

                                                        {auth.user.account_status === 0 || auth.user.account_status === 2 ? ( 
                                                            <button 
                                                                disabled 
                                                                className="inline-flex items-center h-10 px-6 py-2 font-bold bg-transparent border rounded-md disabled:bg-slate-100/20 disabled:text-slate-600 disabled:shadow-none text-slate-800 dark:text-slate-300 dark:bg-slate-700 border-slate-300 bg-slate-200 dark:border-slate-500 focus:ring-2 dark:ring-slate-400 "
                                                            >
                                                                {/* <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
                                                                </svg> */}
                                                                <LockClosedIcon className="w-5 h-5 mr-2" />

                                                                <span className="text-xs font-medium sm:text-sm">Invite</span>
                                                            </button>
                                                        ) : (
                                                            <>
                                                                {!isInvited && !amiInvited &&(
                                                                    // User can send an invitation
                                                                    <Link preserveScroll href={route('invite.user', id)} method="post" as="button"  className="inline-flex items-center h-10 px-6 py-2 font-bold transition duration-300 ease-in-out border rounded-md bg-indigo-50 text-slate-800 dark:text-slate-300 dark:bg-slate-800 hover:bg-slate-800 hover:text-slate-50 dark:hover:bg-slate-50 dark:hover:text-slate-500 border-slate-200 dark:border-slate-50 focus:ring-2 dark:ring-slate-400 focus:outline-none">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 mr-2 rotate-90" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
                                                                        </svg>
                                                                        <span className="text-xs sm:text-sm">Invite</span>
                                                                    </Link>
                                                                )}
                                                                {isInvited && !amiInvited && !hasAcceptedMyInvitation &&  !amIBeingRejected && (
                                                                    // User can cancel the invitation
                                                                    <button
                                                                        onClick={() => cancelInviteRequest(id)}
                                                                        className="inline-flex items-center h-10 px-6 py-2 font-bold text-indigo-500 transition duration-300 ease-in-out bg-transparent border rounded-md dark:text-slate-50 dark:bg-slate-800 hover:bg-slate-100 hover:text-indigo-600 dark:hover:bg-slate-50 dark:hover:text-slate-500 border-slate-200 bg-slate-200 dark:border-slate-50 focus:ring-2 dark:ring-slate-400 focus:outline-none"
                                                                    >
                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
                                                                        </svg>
                                                                        <span className="text-xs font-medium sm:text-sm">Cancel</span>
                                                                    </button>
                                                                )}
                                                            
                                                                {amiInvited && !hasBeenAccepted && !isRejectedInvitation && (
                                                                    <>
                                                                        <div className="flex items-center justify-center space-x-1">
                                                                            <Link preserveScroll href={route('accept.user', id)} method="post" as="button" className="inline-flex items-center h-10 px-6 py-2 mr-2 font-bold text-purple-800 transition duration-300 ease-in-out bg-transparent border rounded-md dark:text-slate-300 dark:bg-slate-800 hover:bg-slate-800 hover:text-slate-50 dark:hover:bg-slate-50 dark:hover:text-slate-500 border-slate-200 bg-slate-200 dark:border-slate-50 focus:ring-2 dark:ring-slate-400 focus:outline-none">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 mr-2">
                                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z" />
                                                                                </svg>
                                                                                <span className="text-xs sm:text-sm">Accept</span>
                                                                            </Link>
                                                                            <Link preserveScroll href={route('reject.user', id)} method="post" as="button" className="inline-flex items-center h-10 px-6 py-2 font-bold text-red-400 transition duration-300 ease-in-out rounded-md bg-red-50 dark:bg-red-100 dark:text-red-500 hover:bg-red-200 hover:text-red-500 dark:hover:bg-slate-50 dark:hover:text-red-500 dark:border-slate-50 focus:ring-2 dark:ring-slate-400 focus:outline-none">
                                                                                <span className="text-xs sm:text-sm">Reject</span>
                                                                            </Link>
                                                                        </div>
                                                                    </>
                                                                )}
                                                                
                                                                {amIBeingAccepted && (
                                                                    // User can chat
                                                                    <Link
                                                                        href={route('chatuser', username)}
                                                                        className="inline-flex items-center justify-center h-10 px-6 py-2 font-bold text-indigo-500 transition duration-300 ease-in-out bg-transparent border rounded-md dark:text-slate-50 dark:bg-slate-800 hover:bg-blue-50 hover:text-indigo-600 dark:hover:bg-blue-50 dark:hover:text-blue-500 border-slate-200 bg-slate-200 dark:border-slate-50 focus:ring-2 dark:ring-slate-400 focus:outline-none"
                                                                    >
                                                                        <svg className='w-4 h-4 mr-2' width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M19.4313 13.0007C19.4313 13.5312 19.2206 14.0399 18.8455 14.4149C18.4704 14.79 17.9617 15.0007 17.4313 15.0007H5.4313L1.4313 19.0007V3.00073C1.4313 2.4703 1.64202 1.96159 2.01709 1.58652C2.39216 1.21145 2.90087 1.00073 3.4313 1.00073H17.4313C17.9617 1.00073 18.4704 1.21145 18.8455 1.58652C19.2206 1.96159 19.4313 2.4703 19.4313 3.00073V13.0007Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                                        </svg>
                                                                        <span className="text-xs font-medium sm:text-sm">Chat</span>
                                                                    </Link>
                                                                )}
                                                                {hasAcceptedMyInvitation && (
                                                                    // User can chat
                                                                    <Link
                                                                        href={route('chatuser', username)}
                                                                        className="inline-flex items-center justify-center h-10 px-6 py-2 font-bold text-indigo-500 transition duration-300 ease-in-out bg-transparent border rounded-md dark:text-slate-50 dark:bg-slate-800 hover:bg-blue-50 hover:text-indigo-600 dark:hover:bg-blue-50 dark:hover:text-blue-500 border-slate-200 bg-slate-200 dark:border-slate-50 focus:ring-2 dark:ring-slate-400 focus:outline-none"
                                                                    >
                                                                        <svg className='w-4 h-4 mr-2' width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M19.4313 13.0007C19.4313 13.5312 19.2206 14.0399 18.8455 14.4149C18.4704 14.79 17.9617 15.0007 17.4313 15.0007H5.4313L1.4313 19.0007V3.00073C1.4313 2.4703 1.64202 1.96159 2.01709 1.58652C2.39216 1.21145 2.90087 1.00073 3.4313 1.00073H17.4313C17.9617 1.00073 18.4704 1.21145 18.8455 1.58652C19.2206 1.96159 19.4313 2.4703 19.4313 3.00073V13.0007Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                                        </svg>
                                                                        <span className="text-xs font-medium sm:text-sm">Chat</span>
                                                                    </Link>
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                    
                                                    {/* Save button */}

                                                    {!isSaved ? ( 
                                                        <Link preserveScroll method="post" href={route('save.user', id)} className="flex items-center justify-center flex-none w-8 h-8 transition duration-300 ease-in-out border rounded-md text-slate-400 border-slate-400 dark:border-slate-400 hover:bg-red-400 hover:text-slate-50 dark:hover:bg-slate-50 dark:text-slate-600" as="button" aria-label="Like">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                                                            </svg>
                                                        </Link> )
                                                    : (
                                                        <Link preserveScroll method="post" href={route('unsave.user', id)} className="flex items-center justify-center flex-none w-8 h-8 transition duration-300 ease-in-out border rounded-md text-slate-400 border-slate-400 dark:border-slate-500 hover:bg-red-400 hover:text-slate-50 dark:hover:bg-slate-50 dark:text-slate-600" as="button" aria-label="Like">
                                                            <svg className="text-red-400 hover:text-white dark:hover:text-red-400" width="20" height="20" fill="currentColor" aria-hidden="true">
                                                                <path fillRule="evenodd" clipRule="evenodd" d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z" />
                                                            </svg>
                                                        </Link>
                                                    )}

                                                    {/* Pass button */}
                                                    {!amIBeingAccepted && !hasAcceptedMyInvitation &&
                                                        <Link preserveScroll method="post" href={route('pass-user', id)} className="flex items-center justify-center flex-none w-8 h-8 transition duration-300 ease-in-out border rounded-md text-slate-400 border-slate-400 dark:border-slate-400 hover:bg-red-400 hover:text-white dark:hover:bg-red-400 dark:hover:text-white dark:text-slate-500" as="button" aria-label="Like">
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                                            </svg>
                                                        </Link>
                                                    }
                                                    {amIBeingAccepted && (
                                                        <div>
                                                            <div className='flex flex-col col-span-1 mt-1 text-center'>
                                                                <Dropdown>
                                                                    <Dropdown.Trigger>
                                                                        <span className="inline-flex rounded-md dark:text-slate-300">
                                                                            <button className="dark:text-slate-300">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="w-5 h-5 text-gray-700 bi bi-three-dots-vertical dark:text-slate-300" viewBox="0 0 16 16">
                                                                                    <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                                                                </svg>
                                                                            </button>
                                                                        </span>
                                                                    </Dropdown.Trigger>

                                                                    <Dropdown.Content >
                                                                        <Dropdown.Link className="px-2 py-2" href={route('pass-user', id)} method="post" as="button">
                                                                            <div className="inline-flex items-center w-full gap-3 px-2 py-2 text-sm leading-5 text-left text-gray-500 transition-all duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus:bg-gray-100">
                                                                                <span>
                                                                                    <XIcon className="w-6 h-6" />
                                                                                </span>
                                                                                <span>Hide from search</span>
                                                                            </div>
                                                                        </Dropdown.Link>
                                                                        <Dropdown.Link className="px-2 py-2" href={route('block-user', id)} method="post" as="button">
                                                                            <div className="inline-flex items-center w-full gap-3 px-4 py-2 text-sm leading-5 text-left text-gray-500 transition-all duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus:bg-gray-100">
                                                                                <span>
                                                                                    <UserRemoveIcon className="w-6 h-6" />
                                                                                </span>
                                                                                <span>Block</span>
                                                                            </div>
                                                                        </Dropdown.Link>
                                                                    </Dropdown.Content>
                                                                </Dropdown>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {hasAcceptedMyInvitation && (
                                                        <div>
                                                            <div className='flex flex-col col-span-1 mt-1 text-center'>
                                                                <Dropdown>
                                                                    <Dropdown.Trigger>
                                                                        <span className="inline-flex rounded-md dark:text-slate-300">
                                                                            <button className="dark:text-slate-300">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="w-5 h-5 text-gray-700 bi bi-three-dots-vertical dark:text-slate-300" viewBox="0 0 16 16">
                                                                                    <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                                                                </svg>
                                                                            </button>
                                                                        </span>
                                                                    </Dropdown.Trigger>

                                                                    <Dropdown.Content >
                                                                        <Dropdown.Link className="px-2 py-2" href={route('pass-user', id)} method="post" as="button">
                                                                            <div className="inline-flex items-center w-full gap-3 px-2 py-2 text-sm leading-5 text-left text-gray-500 transition-all duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus:bg-gray-100">
                                                                                <span>
                                                                                    <XIcon className="w-6 h-6" />
                                                                                </span>
                                                                                <span>Hide from search</span>
                                                                            </div>
                                                                        </Dropdown.Link>
                                                                        <Dropdown.Link className="px-2 py-2" href={route('block-user', id)} method="post" as="button">
                                                                            <div className="inline-flex items-center w-full gap-3 px-4 py-2 text-sm leading-5 text-left text-gray-500 transition-all duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus:bg-gray-100">
                                                                                <span>
                                                                                    <UserRemoveIcon className="w-6 h-6" />
                                                                                </span>
                                                                                <span>Block</span>
                                                                            </div>
                                                                        </Dropdown.Link>
                                                                    </Dropdown.Content>
                                                                </Dropdown>
                                                            </div> 
                                                        </div>
                                                    )}
                                                </div>
                                                {/* {auth.user.account_status === 0 || auth.user.account_status === 2 &&
                                                    <p className="pt-2 text-sm text-yellow-700 dark:text-yellow-500">
                                                        You can send or receive invitation once your account is activated!
                                                    </p>
                                                } */}
                                                {isRejectedInvitation &&(
                                                    <p className="pt-2 text-sm text-yellow-600">
                                                        You have rejected this user!
                                                    </p>
                                                )}
                                                
                                            </div>
                                        </div>
                                    </div>
                                ))}

                                {inviters.length === 0 && (

                                    <div className="sm:px-6 lg:px-8">
                                        <EmptyState bgimage="bg-empty-background" title="Looks like its empty in here." subtitle="Please browse for more users..." btnName="Read our faqs" linktext="Have any questions?" href={route('faq')} />
                                            {/* <p className="text-2xl font-semibold text-slate-800 dark:text-slate-50">Looks like you've reached to the end. Please come back later!</p>  */}
                                    </div>
                                )}
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div> 

        </Authenticated>);
}

export default PeopleWhoLikedMe

{/* <article className="flex items-start p-6 mb-2 space-x-6 rounded-md shadow-lg bg-slate-100">
    <img src={profile_image || Img} alt="" width="60" height="88" className="flex-none rounded-md bg-slate-100" />
    <div className="relative flex-auto min-w-0">
        <h2 className="pr-20 font-semibold truncate text-slate-900">{username}, {age}</h2>
        <dl className="flex flex-wrap mt-2 text-sm font-medium leading-6">
            <div className="absolute top-0 right-0 flex items-center space-x-1">
                <dt className="text-sky-500">
                    <span className="sr-only">More button</span>
                    <div className="text-lg font-semibold text-slate-500">
                        
            </div>
            <div>
                <dt className="sr-only">Profile info</dt>
                <span className="text-sm font-medium divide-y-2 text-slate-600 dark:text-slate-200">{ethnic_origin} From {country} </span> - <span className="text-slate-600 dark:text-slate-200">{recidency_status}</span>
            </div>
            
        </dl>
        <dl>
            <div className="flex-row">
                <dt className="sr-only">Profile info</dt>
                <p className="text-sm text-slate-600 dark:text-slate-300">Practicing Muslim - {sect}</p>
            </div>
        </dl>
        <dl className="mt-4 border-t border-slate-200"> 
            <div className="flex items-start justify-start mt-4 mb-3">
                {amiInvited ?
                    <div>
                        <p>{username} wants to view your profile</p>
                        <div className="flex items-center justify-start mt-1 space-x-2">
                            
                            <Link preserveScroll href={route('accept.user', id)} method="post" as="button"  className="inline-flex items-center h-10 px-6 py-2 font-bold text-purple-800 transition duration-150 ease-in-out bg-transparent border rounded-md dark:text-slate-300 dark:bg-slate-800 hover:bg-slate-800 hover:text-slate-50 dark:hover:bg-slate-50 dark:hover:text-slate-500 border-slate-200 bg-slate-200 dark:border-slate-50 focus:ring-2 dark:ring-slate-400 focus:outline-none">
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 mr-2 rotate-90" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
                                </svg>
                                <span className="text-xs sm:text-sm">Accept</span>
                            </Link>
                             {isAccepted ? (
                                <Link preserveScroll href={route('accept.user', id)} method="post" as="button"  className="inline-flex items-center px-2 py-1 font-bold text-indigo-600 transition duration-150 ease-in-out rounded-md dark:text-slate-300 hover:bg-slate-300 hover:text-slate-700 dark:hover:bg-slate-50 dark:hover:text-slate-500 bg-slate-200 focus:ring-2 dark:ring-slate-400 focus:outline-none">
                                    <span className="text-xs sm:text-sm">Accept</span>
                                </Link> 
                            ):(
                                <Link preserveScroll href={route('accept.user', id)} method="post" as="button"  className="inline-flex items-center px-2 py-1 font-bold transition duration-150 ease-in-out bg-indigo-500 rounded-md text-slate-50 dark:text-slate-600 hover:bg-slate-300 hover:text-slate-800 dark:hover:bg-slate-50 dark:hover:text-slate-800 focus:ring-2 dark:ring-slate-400 focus:outline-none">
                                    <span className="text-xs sm:text-sm">Accepted</span>
                                </Link> 
                            )} 
                            <Link preserveScroll href={route('reject.user', id)} method="post" as="button"  className="inline-flex items-center px-2 py-1 font-bold text-red-400 transition duration-150 ease-in-out rounded-md dark:text-slate-300 dark:bg-slate-500 hover:bg-red-400 hover:text-slate-50 dark:hover:bg-red-400 dark:hover:text-slate-50 focus:ring-2 dark:ring-slate-400 focus:outline-none">
                                <span className="text-xs sm:text-sm">Remove</span>
                            </Link> 
                        </div>
                    </div> : '' 
                }
            </div>
        </dl>
    </div>
</article> */}

                                        //     <div key={id} className="flex-none mb-4 space-y-2 bg-white shadow-md sm:flex dark:bg-slate-800 sm:rounded-lg">
                                        //     <div className="blur-[2px] overflow-hidden relative sm:min-h-full w-full sm:w-[19rem] sm:mb-0 mb-3">
                                        //         {!profile_image ? (
                                        //                 <img src="assets/images/man.svg" alt={`Man photo`}  className="blur-[2px] w-full sm:w-[19rem] h-auto sm:min-h-full inset-0 object-cover aspect-square sm:rounded-l-lg" />
                                        //             ): 
                                        //                 <img src={`http://localhost:3000/${profile_image}`} alt={`${firstname}'s Profile photo`} onerror="this.onerror=null;this.src='https://picsum.photos/200';"  className="blur-[2px] w-full sm:w-[19rem] h-auto sm:min-h-full inset-0 object-cover aspect-square sm:rounded-l-lg" />
                                        //             }
                                        //     </div>
                                        //     <div className="flex-auto p-4 sm:ml-3 justify-evenly">
                                        //         <div className="flex items-center sm:mt-2">
                                        //             <div className="flex flex-col ">
                                        //                 <div className="flex flex-wrap">
                                        //                     <div className="flex items-center">
                                        //                         <h1 className="text-lg font-semibold text-slate-900 dark:text-slate-50">
                                        //                             {firstname} {lastname}, {age}
                                        //                         </h1>
                                        //                         <div className="w-2 h-2 m-1 ml-2 bg-green-600 rounded-full"></div>
                                        //                     </div>
                                        //                     <h1 className="flex-auto text-lg font-semibold text-slate-900">
                                        //                         {/* {age} */}
                                        //                     </h1>
                                                            
                                                            // <div className="text-lg font-semibold text-slate-500">
                                                                
                                                            //     <Dropdown>
                                                            //         <Dropdown.Trigger>
                                                            //             <span className="inline-flex rounded-md">
                                                            //                 <button
                                                            //                     as="button"
                                                            //                     className="inline-flex items-center px-2 py-1 text-sm font-medium leading-4 text-gray-500 transition duration-150 ease-in-out rounded focus:bg-slate-200 dark:focus:bg-slate-200 dark:focus:text-slate-800 focus:border-slate-400 dark:text-gray-100 focus:outline-none"
                                                            //                 >
                                                            //                     <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-slate-700 dark:text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            //                             <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z" />
                                                            //                     </svg>
                                                            //                 </button>
                                                            //             </span>
                                                            //         </Dropdown.Trigger>

                                                            //         <Dropdown.Content className="w-10" >
                                                            //             <Dropdown.Link href={route('user-profile', username)} method="get" as="button">
                                                            //                 Demo view user
                                                            //             </Dropdown.Link>
                                                            //             <Dropdown.Link href={route('block-user', id)} method="post" as="button">
                                                            //                 Block
                                                            //             </Dropdown.Link>
                                                            //             <Dropdown.Link href={route('auth.user.profile')} method="get" as="button">
                                                            //                 Report
                                                            //             </Dropdown.Link>
                                                            //         </Dropdown.Content>
                                                            //     </Dropdown>
                                                            // </div>
                                        //                     <div className="pl-3 text-lg font-semibold text-slate-500">
                                        //                         {auth.user.account_status == 1 &&
                                        //                             <Link method="post" href={route('pass-user', id)} preserveScroll>
                                        //                                 <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                        //                                     <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                        //                                 </svg>
                                        //                             </Link>
                                        //                         }
                                        //                     </div>
                                        //                     <div className="flex-none w-full mt-2 text-slate-700">
                                        //                         <span className="text-sm font-medium divide-y-2 text-slate-600 dark:text-slate-200">{ethnic_origin} From {country} </span> - <span className="text-slate-600 dark:text-slate-200">{recidency_status}</span>
                                        //                     </div>
                                        //                 </div>
                                                        
                                        //                 <div className='pb-6 mb-6 border-b border-slate-200'>
                                                            
                                        //                     <div className="flex items-center justify-center mt-4 mb-3">
                                        //                         {amiInvited ?
                                        //                             <div>
                                        //                                 <p>{firstname} wants to view your profile</p>
                                        //                                 <div className="flex items-center justify-center mt-1 space-x-2">
                                        //                                     {isAccepted ? (
                                        //                                         <Link preserveScroll href={route('accept.user', id)} method="post" as="button"  className="inline-flex items-center px-2 py-1 font-bold text-indigo-600 transition duration-150 ease-in-out rounded-md dark:text-slate-300 hover:bg-slate-300 hover:text-slate-700 dark:hover:bg-slate-50 dark:hover:text-slate-500 bg-slate-200 focus:ring-2 dark:ring-slate-400 focus:outline-none">
                                        //                                             <span className="text-xs sm:text-sm">Accept</span>
                                        //                                         </Link> 
                                        //                                     ):(
                                        //                                         <Link preserveScroll href={route('accept.user', id)} method="post" as="button"  className="inline-flex items-center px-2 py-1 font-bold transition duration-150 ease-in-out bg-indigo-500 rounded-md text-slate-50 dark:text-slate-600 hover:bg-slate-300 hover:text-slate-800 dark:hover:bg-slate-50 dark:hover:text-slate-800 focus:ring-2 dark:ring-slate-400 focus:outline-none">
                                        //                                             <span className="text-xs sm:text-sm">Accepted</span>
                                        //                                         </Link> 
                                        //                                     )}
                                        //                                     <Link preserveScroll href={route('reject.user', id)} method="post" as="button"  className="inline-flex items-center px-2 py-1 font-bold text-red-400 transition duration-150 ease-in-out rounded-md dark:text-slate-300 dark:bg-slate-500 hover:bg-red-400 hover:text-slate-50 dark:hover:bg-red-400 dark:hover:text-slate-50 focus:ring-2 dark:ring-slate-400 focus:outline-none">
                                        //                                         <span className="text-xs sm:text-sm">Remove</span>
                                        //                                     </Link> 
                                        //                                 </div>
                                        //                             </div> : '' 
                                        //                         }
                                        //                     </div>
                                        //                 </div>
                                        //                 <div className="flex-auto my-1 text-gray-700 dark:text-gray-400">
                                        //                 </div>
                                        //             </div>
                                        //         </div>

                                        //         <div className="flex mt-3 space-x-4 text-sm font-medium">
                                        //             <div className="flex flex-auto space-x-4">
                                        //                 {isAccepted ? <Link href={route('user-profile', username)} className="inline-flex items-center h-10 px-6 py-2 font-bold transition duration-150 ease-in-out bg-black rounded-md dark:bg-slate-100 text-slate-50 dark:text-slate-500 hover:bg-slate-800 hover:text-slate-50 dark:hover:bg-slate-50 border-1 border-slate-800 dark:border-slate-50 focus:ring-2 dark:ring-slate-400 focus:outline-none">
                                        //                     <span className="text-xs sm:text-sm">View</span>
                                        //                 </Link> : (
                                        //                     <button disabled className="inline-flex items-center h-10 px-6 py-2 font-bold transition duration-150 ease-in-out bg-black rounded-md disabled:bg-slate-400 disabled:text-slate-200 disabled:border-slate-200 disabled:shadow-none dark:bg-slate-100 text-slate-50 dark:text-slate-500 hover:bg-slate-800 hover:text-slate-50 dark:hover:bg-slate-50 border-1 border-slate-800 dark:border-slate-50 focus:ring-2 dark:ring-slate-400 focus:outline-none">
                                                                
                                        //                         <span className="text-xs sm:text-sm">View</span>
                                        //                     </button>
                                        //                 ) }
                                                        
                                        //                 {auth.user.account_status == 0 ? ( 
                                        //                     <button disabled className="inline-flex items-center h-10 px-6 py-2 font-bold transition duration-150 ease-in-out bg-transparent rounded-md disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none text-slate-800 dark:text-slate-500 dark:bg-slate-800 hover:bg-slate-800 hover:text-slate-50 dark:hover:bg-slate-50 border-1 border-slate-200 bg-slate-200 dark:border-slate-50 focus:ring-2 dark:ring-slate-400 focus:outline-none">
                                        //                         <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        //                             <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
                                        //                         </svg>
                                        //                         <span className="text-xs sm:text-sm">Invite</span>
                                        //                     </button>
                                        //                 ) : ( (!isInvited ? (
                                        //                     <Link preserveScroll href={route('invite.user', id)} method="post" as="button"  className="inline-flex items-center h-10 px-6 py-2 font-bold transition duration-150 ease-in-out bg-transparent border-2 rounded-md text-slate-800 dark:text-slate-300 dark:bg-slate-800 hover:bg-slate-800 hover:text-slate-50 dark:hover:bg-slate-50 dark:hover:text-slate-500 border-slate-200 bg-slate-200 dark:border-slate-50 focus:ring-2 dark:ring-slate-400 focus:outline-none">
                                        //                         <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 mr-2 rotate-90" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        //                             <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
                                        //                         </svg>
                                        //                         <span className="text-xs sm:text-sm">Invite</span>
                                        //                     </Link>
                                        //                 ) :
                                        //                     <Link preserveScroll href={route('uninvite.user', id)} method="post" as="button"  className="inline-flex items-center h-10 px-6 py-2 font-bold transition duration-150 ease-in-out bg-transparent border-2 rounded-md text-slate-800 dark:text-slate-300 dark:bg-slate-800 hover:bg-slate-800 hover:text-slate-50 dark:hover:bg-slate-50 dark:hover:text-slate-500 border-slate-200 bg-slate-200 dark:border-slate-50 focus:ring-2 dark:ring-slate-400 focus:outline-none">
                                        //                         <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        //                             <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
                                        //                         </svg>
                                        //                         <span className="text-xs sm:text-sm">Invited</span>
                                        //                     </Link> )
                                        //                 )}
                                        //             {/* 
                                        //                 {isFollowing ? ( 
                                        //                     <Link preserveScroll href={route('user.unfollow', id)} method="post" as="button" className="inline-flex items-center h-10 px-6 py-2 font-bold transition duration-150 ease-in-out bg-black rounded-md dark:bg-slate-100 text-slate-50 dark:text-slate-500 hover:bg-slate-800 hover:text-slate-50 dark:hover:bg-slate-50 border-1 border-slate-800 dark:border-slate-50 focus:ring-2 dark:ring-slate-400 focus:outline-none">
                                        //                         <span className="text-xs sm:text-sm">Unfollow</span>
                                        //                     </Link> 
                                        //                 ) : (
                                        //                     <Link preserveScroll href={route('user.follow', id)} method="post" as="button"className="inline-flex items-center h-10 px-6 py-2 font-bold transition duration-150 ease-in-out bg-black rounded-md dark:bg-slate-100 text-slate-50 dark:text-slate-500 hover:bg-slate-800 hover:text-slate-50 dark:hover:bg-slate-50 border-1 border-slate-800 dark:border-slate-50 focus:ring-2 dark:ring-slate-400 focus:outline-none">
                                        //                         <span className="text-xs sm:text-sm">Follow</span>
                                        //                     </Link> 
                                        //                 )}
                                        //             */}
                                        //             </div>

                                        //             {/* Save button */}

                                        //             {!isSaved ? ( 
                                        //                 <Link preserveScroll method="post" href={route('save.user', id)} className="flex items-center justify-center flex-none border rounded-md w-9 h-9 text-slate-300 border-slate-200 dark:border-slate-500 hover:bg-slate-800 hover:text-slate-50 dark:hover:bg-slate-50 dark:text-slate-600" as="button" aria-label="Like">
                                        //                     <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                        //                         <path strokeLinecap="round" strokeLinejoin="round" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                                        //                     </svg>
                                        //                 </Link> )
                                        //             : (
                                        //                 <Link preserveScroll method="post" href={route('unsave.user', id)} className="flex items-center justify-center flex-none border rounded-md w-9 h-9 text-slate-300 border-slate-200 dark:border-slate-500 hover:bg-slate-800 hover:text-slate-50 dark:hover:bg-slate-50 dark:text-slate-600" as="button" aria-label="Like">
                                        //                     <svg className="text-red-400" width="20" height="20" fill="currentColor" aria-hidden="true">
                                        //                         <path fillRule="evenodd" clipRule="evenodd" d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z" />
                                        //                     </svg>
                                        //                 </Link>
                                        //             )}
                                        //         </div>
                                                
                                        //         {inviters.length === 0 && (
                                        //             <p className="text-2xl font-semibold text-slate-800 dark:text-slate-50">You haven't been invited by any user</p> 
                                        //         )}
                                        //         <p className="pt-4 text-sm text-yellow-700">
                                        //             {auth.user.account_status == 0 ? 'You can send invitation to this user once your account is activated!' : (!isAccepted ? `You can view ${firstname}'s profile once ${gender == 'Male' ? 'he' : 'she'} accepts your request.` : `You can view ${firstname}'s full profile`) }
                                        //         </p>
                                        //     </div>
                                        // </div>