import Authenticated from '@/Layouts/AdminLayouts/Authenticated'
import { HomeIcon, ShieldExclamationIcon } from '@heroicons/react/outline'
import { Link } from '@inertiajs/inertia-react'
import React from 'react'

function Logs(props) {
  return (
    <Authenticated auth={props.auth} errors={props.errors}>
        <div className="relative block w-full p-12 text-center border-2 border-gray-300 border-dashed rounded-lg hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            <div className="text-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-12 h-12 mx-auto text-gray-400 dark:text-gray-200">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
                </svg>

                <h3 className="mt-2 text-sm font-semibold text-gray-900 dark:text-gray-100">Access restricted!</h3>
                <p className="mt-1 text-sm text-gray-500 dark:text-gray-300">Sorry, you don't have permission to access this page!</p>
                <div className="mt-6">
                    <Link
                        href={route('admin.dashboard')}
                        as='a'
                        className="inline-flex items-center px-4 py-3 text-sm font-semibold text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        <HomeIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                        Dashboard
                    </Link>
                </div>
            </div>
        </div>
    </Authenticated>
  )
}

export default Logs