import Avatar from '@/Components/Avatar';
import ChatBox from '@/Components/Chat/ChatBox'
import ChatList from '@/Components/Chat/ChatList'
import Dropdown from '@/Components/Dropdown';
import Authenticated from '@/Layouts/Authenticated'
import { Link, usePage } from '@inertiajs/inertia-react';
import moment from 'moment';
import { UserRemoveIcon } from '@heroicons/react/outline';
import React, { useEffect, useRef, useState } from 'react'
import { Inertia } from '@inertiajs/inertia';
import EmojiPicker from 'emoji-picker-react';
import Pusher from 'pusher-js';
import Echo from 'laravel-echo';

function Chat({type, onTypeChange, auth}) {
  const { acceptedusers, selectedConversation, conversations, loadMessages, isBlocked } = usePage().props;
  const [activeType, setActiveType] = useState(type || 'all');

    const formatMessageTime = (created_at) => {
        const now = moment();
        const messageTime = moment(created_at);

        if (now.isSame(messageTime, 'day')) {
            // Show time if it's today
            return messageTime.format('HH:mm');
        } else if (now.subtract(1, 'days').isSame(messageTime, 'day')) {
            // Show yesterday's time if it's yesterday
            return 'Yesterday ' + messageTime.format('LT');
        } else {
            // Show date and time for any other day
            return messageTime.format('MMM D, YYYY LT');
        }
    };

    const handleClick = (newType) => {
        setActiveType(newType);
        if (typeof onTypeChange === 'function') {
            onTypeChange(newType);
        }
    };

return (
    <Authenticated auth={auth}>
        <div className="fixed h-full flex bg-white dark:bg-slate-800 lg:shadow-sm overflow-hidden inset-0 top-32 lg:top-16 lg:inset-x-2 m-auto lg:h-[90%] rounded-t-lg">
            <div className="hidden lg:block relative w-full md:w-[320px] xl:w-[400px] overflow-y-auto shrink-0 h-full">
                <div className='flex flex-col h-full overflow-hidden transition-all'>
                    <header className="sticky top-0 z-10 w-full px-3 py-2 bg-white dark:bg-slate-800">
                        <div className="flex items-center justify-between pb-2 border-b border-slate-400 dark:border-slate-600">
                            <div className="flex items-center gap-2">
                                <h5 className="text-xl font-bold text-slate-700 dark:text-slate-50">Chats</h5>
                            </div>
                            <button className='text-black dark:text-slate-50'>
                                <svg className="w-6 h-6 font-bold"  xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                    <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"/>
                                </svg>
                            </button>
                        </div>

                        <div className="flex items-center gap-3 p-4 pl-0 overflow-x-scroll">
                            <button
                                onClick={() => handleClick('all')}
                                className={`inline-flex justify-center items-center rounded-full gap-x-1 text-xs font-medium px-3 lg:px-5 py-1 lg:py-2.5 border dark:text-slate-300 ${
                                    activeType === 'all' ? 'bg-blue-100 border-0 text-black dark:text-slate-700' : ''
                                }`}
                            >
                                All conversations
                            </button>
                            <button
                                onClick={() => handleClick('accepted-users')}
                                    className={`inline-flex justify-center items-center rounded-full gap-x-1 text-xs font-medium px-3 lg:px-5 py-1 lg:py-2.5 border dark:text-slate-300 ${
                                    activeType === 'accepted-users' ? 'bg-blue-100 border-0 text-black dark:text-slate-700' : ''
                                }`}
                            >
                                Mutual Matches
                            </button>
                        </div>
                    </header>

                    <main className="relative h-full overflow-hidden overflow-y-scroll grow" style={{contain:'content'}}>
                        {activeType === 'all' && (
                            <ul className="grid w-full p-2 spacey-y-2">
                                {conversations.map((conversation) => {
                                    return (
                                        <li key={conversation.id} className={`py-3 mb-2 ${conversation.id === selectedConversation.id && 'bg-gray-100 dark:bg-gray-700/70'} hover:bg-gray-50 rounded-2xl dark:hover:bg-gray-700/70 transition-colors duration-150 flex gap-4 relative w-full cursor-pointer px-2 `}>
                                            <Link as='a' className="shrink-0 " href={route('chatuser', conversation.receiver_username)}>
                                                <Avatar src={conversation.receiver_profile_image} className="w-12 h-12" />
                                            </Link>
                                            <aside className="grid w-full grid-cols-12">
                                                <Link as='a' href={route('chatuser',  conversation.receiver_username)} className="relative w-full col-span-11 p-1 overflow-hidden leading-5 truncate border-b border-gray-300 dark:border-slate-600 flex-nowrap">
                                                    <div className="flex items-center justify-between w-full">
                                                        <h6 className="font-medium tracking-wider text-gray-500 truncate dark:text-gray-50">
                                                            {conversation.receiver_username}
                                                        </h6>
                                                        <small className="mt-1 text-gray-700 dark:text-slate-300">
                                                            {/* {moment(conversation.time).fromNow()} */}
                                                            {formatMessageTime(conversation.time)}
                                                        </small>
                                                    </div>

                                                    <div className="flex items-center gap-x-2">
                                                        {conversation.sender_id === auth.user.id ? ( conversation.is_read ? (
                                                            <span className='text-slate-300 dark:text-slate-100'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check2-all" viewBox="0 0 16 16">
                                                                    <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l7-7zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0z"/>
                                                                    <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708z"/>
                                                                </svg>
                                                            </span>
                                                            ) : (
                                                                <span className="text-slate-300 dark:text-slate-100">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check2" viewBox="0 0 16 16">
                                                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                                                                    </svg>
                                                                </span>
                                                            )) : ''
                                                        }
                                                        <p className="text-sm font-medium truncate grow dark:text-slate-300">
                                                            {conversation.last_message}
                                                        </p>
                                                        {conversation.unread_count !== 0 &&(
                                                            <span className="p-px px-2 text-xs font-bold text-white bg-blue-500 rounded-full shrink-0">
                                                                {conversation.unread_count}
                                                            </span>
                                                        )}
                                                    </div>
                                                </Link>
                                                <div className='flex flex-col col-span-1 my-auto text-center'>
                                                    <Dropdown>
                                                        <Dropdown.Trigger>
                                                            <span className="inline-flex rounded-md dark:text-slate-300">
                                                                <button className="dark:text-slate-300">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="w-5 h-5 text-gray-700 bi bi-three-dots-vertical dark:text-slate-300" viewBox="0 0 16 16">
                                                                        <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                                                    </svg>
                                                                </button>
                                                            </span>
                                                        </Dropdown.Trigger>

                                                        <Dropdown.Content >
                                                            <Dropdown.Link href={ !isBlocked ? route('user-profile', conversation.receiver_username) : "#"} method="get" as="button">
                                                                <div className="inline-flex items-center w-full gap-3 px-4 py-2 text-sm leading-5 text-left text-gray-500 transition-all duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus:bg-gray-100">
                                                                    <span>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-circle" viewBox="0 0 16 16">
                                                                            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                                                                            <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
                                                                        </svg>
                                                                    </span>
                                                                    <span>View Profile</span>
                                                                </div>
                                                            </Dropdown.Link>
                                                            {!isBlocked &&
                                                                <Dropdown.Link  href={route('block-user', conversation.receiver_id)} method="post" as="button">
                                                                        <div className="inline-flex items-center w-full gap-3 px-4 py-2 text-sm leading-5 text-left text-gray-500 transition-all duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus:bg-gray-100">
                                                                            <span>
                                                                                <UserRemoveIcon className="w-6 h-6" />
                                                                            </span>
                                                                            <span>Block</span>
                                                                        </div>
                                                                </Dropdown.Link>
                                                            }
                                                        </Dropdown.Content>
                                                    </Dropdown>
                                                </div>
                                            </aside>
                                        </li>
                                    )
                                })}
                            </ul>
                        )}
                        {activeType === 'accepted-users' && (
                            <ul className="grid w-full p-2 spacey-y-2">
                            {acceptedusers.map(({
                                id,
                                username,
                                profile_image,
                                }) => (
                                    <li key={id} className="py-3 mb-1 hover:bg-gray-50 rounded-2xl dark:hover:bg-gray-700/70 transition-colors duration-150 flex gap-4 relative w-full cursor-pointer px-2 {{$conversation->id==$selectedConversation?->id ? 'bg-gray-100/70':''}}">
                                        <Link as='a' className="shrink-0 " href={route('chatuser', username)}>
                                            <Avatar src={profile_image} className="w-12 h-12" />
                                        </Link>

                                        <aside className="grid w-full grid-cols-12">
                                            <Link as='a' href={route('chatuser', username)} className="relative w-full col-span-11 p-1 overflow-hidden leading-5 truncate border-b border-gray-300 dark:border-slate-600 flex-nowrap">
                                                <div className="flex items-center justify-between w-full">
                                                    <h6 className="font-medium tracking-wider text-gray-500 truncate dark:text-gray-50">
                                                        {username}
                                                    </h6>
                                                </div>

                                                <div className="flex items-center gap-x-2">
                                                    <p className="text-sm font-medium truncate grow dark:text-slate-300">
                                                        {/* Start conversation */} .....
                                                    </p>
                                                </div>

                                            </Link>
                                            <div className='flex flex-col col-span-1 my-auto text-center'>
                                                <Dropdown>
                                                    <Dropdown.Trigger>
                                                        <span className="inline-flex rounded-md dark:text-slate-300">
                                                            <button className="dark:text-slate-300">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="w-5 h-5 text-gray-700 bi bi-three-dots-vertical dark:text-slate-300" viewBox="0 0 16 16">
                                                                    <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                                                </svg>
                                                            </button>
                                                        </span>
                                                    </Dropdown.Trigger>

                                                    <Dropdown.Content >
                                                        <Dropdown.Link href={ !isBlocked ? route('user-profile', username) : "#"} method="get" as="button">
                                                            <div className="inline-flex items-center w-full gap-3 px-4 py-2 text-sm leading-5 text-left text-gray-500 transition-all duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus:bg-gray-100">
                                                                <span>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-circle" viewBox="0 0 16 16">
                                                                        <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                                                                        <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
                                                                    </svg>
                                                                </span>
                                                                <span>View Profile</span>
                                                            </div>
                                                        </Dropdown.Link>
                                                    </Dropdown.Content>
                                                </Dropdown>
                                            </div>
                                        </aside>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </main>
                </div>
            </div>
            <div className="relative grid w-full h-full overflow-y-auto border-l dark:border-slate-600" style={{contain:"content"}}>
                <ChatBox isBlocked={isBlocked} selectedConversation={selectedConversation} conversations={conversations} loadMessages={loadMessages} auth={auth}/>
                {/* <div className="chat-container">
                    <div className="messages-list">
                        {messages.map((msg) => (
                            <div key={msg.id} className={`message ${msg.sender_id === selectedConversation.auth_id ? 'sent' : 'received'}`}>
                                <p>{msg.body}</p>
                            </div>
                        ))}
                        <div ref={messagesEndRef} />
                    </div>
                    <form onSubmit={sendMessage} className="send-message-form">
                        <input
                            type="text"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            placeholder="Type your message..."
                            className="message-input"
                        />
                        <button type="submit" className="send-button">Send</button>
                    </form>
                </div> */}
            </div>
        </div>
    </Authenticated>
  )
}

export default Chat
