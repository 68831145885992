import React from 'react'
import UserSideNavMenuItem from './UserSideNavMenuItem'

function MutualSideNav() {
  return (
    <div>
        <nav id="nav" className="leading-6 hidden md:block fixed z-50 bg-gray-50 dark:bg-slate-800 p-2 sm:p-6 rounded-md shadow-sm ">
                <ul>
                    <UserSideNavMenuItem 
                        href={route('mutualMatches')}
                        svg={<svg className='h-6 w-6' width="51" height="45" viewBox="0 0 51 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M46.2216 4.80151C45.0244 3.59632 43.6028 2.64028 42.0382 1.988C40.4735 1.33573 38.7965 1 37.1029 1C35.4093 1 33.7323 1.33573 32.1676 1.988C30.603 2.64028 29.1815 3.59632 27.9842 4.80151L25.4994 7.30154L23.0146 4.80151C20.5961 2.36825 17.316 1.00126 13.8958 1.00126C10.4757 1.00126 7.19554 2.36825 4.7771 4.80151C2.35867 7.23477 1 10.535 1 13.9761C1 17.4173 2.35867 20.7175 4.7771 23.1507L7.2619 25.6508L25.4994 44L43.7368 25.6508L46.2216 23.1507C47.4195 21.9461 48.3697 20.5158 49.018 18.9416C49.6663 17.3674 50 15.6801 50 13.9761C50 12.2721 49.6663 10.5848 49.018 9.01062C48.3697 7.43641 47.4195 6.00614 46.2216 4.80151Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                <path d="M31 15L25 22" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                                <path d="M32 14L26.75 29L24.5 21.5L17 19.25L32 14Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                            </svg>
                            }
                        active={route().current('mutualMatches')}
                    >
                        Matched Profiles
                    </UserSideNavMenuItem>
                
                    <UserSideNavMenuItem 
                        href={route('rejectedMatches')}
                        svg={<svg width="51" height="45" className='h-6 w-6' viewBox="0 0 51 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M46.2216 4.80151C45.0244 3.59632 43.6028 2.64028 42.0382 1.988C40.4735 1.33573 38.7965 1 37.1029 1C35.4093 1 33.7323 1.33573 32.1676 1.988C30.603 2.64028 29.1815 3.59632 27.9842 4.80151L25.4994 7.30154L23.0146 4.80151C20.5961 2.36825 17.316 1.00126 13.8958 1.00126C10.4757 1.00126 7.19554 2.36825 4.7771 4.80151C2.35867 7.23477 1 10.535 1 13.9761C1 17.4173 2.35867 20.7175 4.7771 23.1507L7.2619 25.6508L25.4994 44L43.7368 25.6508L46.2216 23.1507C47.4195 21.9461 48.3697 20.5158 49.018 18.9416C49.6663 17.3674 50 15.6801 50 13.9761C50 12.2721 49.6663 10.5848 49.018 9.01062C48.3697 7.43641 47.4195 6.00614 46.2216 4.80151Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                            <path d="M29.5 16L21 25" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                            <path d="M21 16L29.5 25" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                        </svg>
                        }
                        active={route().current('rejectedMatches')}
                    >
                        Rejected Profiles
                    </UserSideNavMenuItem>
                </ul>
            </nav>

            <div className="sm:hidden block relative z-10 max-w-3xl mx-auto">
                <div className="flex overflow-auto mb-6 -mx-4 sm:-mx-6">
                    <div className="flex-none min-w-full px-4 sm:px-6">
                        <ul className="border-b border-slate-200 space-x-6 flex whitespace-nowrap dark:border-slate-200/5">
                            <li>
                                <UserSideNavMenuItem 
                                    href={route('mutualMatches')}
                                    svg={<svg className='h-5 w-5' width="51" height="45" viewBox="0 0 51 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M46.2216 4.80151C45.0244 3.59632 43.6028 2.64028 42.0382 1.988C40.4735 1.33573 38.7965 1 37.1029 1C35.4093 1 33.7323 1.33573 32.1676 1.988C30.603 2.64028 29.1815 3.59632 27.9842 4.80151L25.4994 7.30154L23.0146 4.80151C20.5961 2.36825 17.316 1.00126 13.8958 1.00126C10.4757 1.00126 7.19554 2.36825 4.7771 4.80151C2.35867 7.23477 1 10.535 1 13.9761C1 17.4173 2.35867 20.7175 4.7771 23.1507L7.2619 25.6508L25.4994 44L43.7368 25.6508L46.2216 23.1507C47.4195 21.9461 48.3697 20.5158 49.018 18.9416C49.6663 17.3674 50 15.6801 50 13.9761C50 12.2721 49.6663 10.5848 49.018 9.01062C48.3697 7.43641 47.4195 6.00614 46.2216 4.80151Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                                            <path d="M31 15L25 22" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                                            <path d="M32 14L26.75 29L24.5 21.5L17 19.25L32 14Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                                        </svg>
                                        }
                                    active={route().current('mutualMatches')}
                                >
                                    Matched Profiles
                                </UserSideNavMenuItem>
                            </li>
                            <li>
                                <UserSideNavMenuItem 
                                    href={route('rejectedMatches')}
                                    svg={<svg width="51" height="45" className='h-5 w-5' viewBox="0 0 51 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M46.2216 4.80151C45.0244 3.59632 43.6028 2.64028 42.0382 1.988C40.4735 1.33573 38.7965 1 37.1029 1C35.4093 1 33.7323 1.33573 32.1676 1.988C30.603 2.64028 29.1815 3.59632 27.9842 4.80151L25.4994 7.30154L23.0146 4.80151C20.5961 2.36825 17.316 1.00126 13.8958 1.00126C10.4757 1.00126 7.19554 2.36825 4.7771 4.80151C2.35867 7.23477 1 10.535 1 13.9761C1 17.4173 2.35867 20.7175 4.7771 23.1507L7.2619 25.6508L25.4994 44L43.7368 25.6508L46.2216 23.1507C47.4195 21.9461 48.3697 20.5158 49.018 18.9416C49.6663 17.3674 50 15.6801 50 13.9761C50 12.2721 49.6663 10.5848 49.018 9.01062C48.3697 7.43641 47.4195 6.00614 46.2216 4.80151Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                                        <path d="M29.5 16L21 25" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                                        <path d="M21 16L29.5 25" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                                    </svg>
                                    }
                                    active={route().current('rejectedMatches')}
                                >
                                    Rejected Profiles
                                </UserSideNavMenuItem>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default MutualSideNav