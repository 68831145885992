import React, { Fragment, useState } from 'react'
import { Dialog, Disclosure, Menu, Transition } from '@headlessui/react'
import {
  BellIcon,
  ChatIcon,
  ChartPieIcon,
  ChevronRightIcon,
  DocumentDuplicateIcon,
  HomeIcon,
  UsersIcon,
  CurrencyPoundIcon,
  CreditCardIcon,
  ChipIcon
} from '@heroicons/react/outline'
import { ChevronDownIcon, UserIcon } from '@heroicons/react/solid'
import FlashMessages from '@/Components/FlashMessages'
import { Link, usePage } from '@inertiajs/inertia-react'

import LogoPurple from '../../../../public/assets/images/favicon.svg'
import { method } from 'lodash'
import { ThemeContext } from '@/context/ThemeContext'
import Img from '../../../../public/assets/images/admin-avatar.png';
import UserSearch from '@/Components/UserSearch'
import { current } from 'tailwindcss/colors'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

export default function Authenticated({ auth, header, href, btnName, children }) {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const { url } = usePage();
  const { theme, setTheme } = React.useContext(ThemeContext);
  const { unreadContactsCount } = usePage().props;
  console.log(unreadContactsCount);

  const userNavigation = [
    { name: 'Your profile', href: route('admin.profile'), as: 'a' },
    { name: 'Sign out', href: route('admin.logout'), method: "post", as: 'button' },
  ]
  const navigations = [
    //   { name: 'Dashboard', href: '#', icon: HomeIcon, current: true },
      { name: 'Dashboard', href: route('admin.dashboard'), icon: HomeIcon, current: url.startsWith(new URL(route('admin.dashboard')).pathname),
    },

      {
        name: 'Users',
        href: '#',
        icon: UsersIcon,
        current: url.startsWith(new URL(route('admin.activeUsers')).pathname) ||  url.startsWith(new URL(route('admin.inactivatedUsersByAdmin')).pathname) || url.startsWith(new URL(route('admin.users')).pathname) || url.startsWith(new URL(route('admin.suspendedUsers')).pathname) || url.startsWith(new URL(route('admin.deactivatedAccounts')).pathname) || url.startsWith(new URL(route('admin.verifiedUsers')).pathname) || url.startsWith(new URL(route('admin.unverifiedUsers')).pathname),
        isOpen: url.startsWith(new URL(route('admin.activeUsers')).pathname) || url.startsWith(new URL(route('admin.inactivatedUsersByAdmin')).pathname) || url.startsWith(new URL(route('admin.users')).pathname) || url.startsWith(new URL(route('admin.suspendedUsers')).pathname) || url.startsWith(new URL(route('admin.deactivatedAccounts')).pathname) || url.startsWith(new URL(route('admin.verifiedUsers')).pathname) || url.startsWith(new URL(route('admin.unverifiedUsers')).pathname),
        children: [
            { name: 'Active users', href: route('admin.activeUsers'), current: url.startsWith(new URL(route('admin.activeUsers')).pathname)},
            { name: 'Declined users', href: route('admin.inactivatedUsersByAdmin'), current: url.startsWith(new URL(route('admin.inactivatedUsersByAdmin')).pathname)},
            { name: 'Inactive Users', href: route('admin.users'), current: url.startsWith(new URL(route('admin.users')).pathname)},
            { name: 'Verified Users', href: route('admin.verifiedUsers'), current: url.startsWith(new URL(route('admin.verifiedUsers')).pathname) },
            { name: 'Unverified Users', href: route('admin.unverifiedUsers'), current: url.startsWith(new URL(route('admin.unverifiedUsers')).pathname) },
            { name: 'Suspended users', href: route('admin.suspendedUsers'), current: url.startsWith(new URL(route('admin.suspendedUsers')).pathname) },
            { name: 'Deactivated accounts', href: route('admin.deactivatedAccounts'), current: url.startsWith(new URL(route('admin.deactivatedAccounts')).pathname) },
            { name: 'Deleted accounts', href: route('admin.deletedAccounts'), current: url.startsWith(new URL(route('admin.deletedAccounts')).pathname) },
        ],
      },

      { name: 'Contacts', href: route('admin.contacts'), icon: ChatIcon, current: url.startsWith(new URL(route('admin.contacts')).pathname), count: unreadContactsCount,},
      { name: 'Faq', href: route('admin.faqs'), icon: DocumentDuplicateIcon, current: url.startsWith(new URL(route('admin.faqs')).pathname) },

      {
        name: 'Subscription Plans',
        icon: CreditCardIcon,
        current: url.startsWith(new URL(route('admin.plans')).pathname) || url.startsWith(new URL(route('admin.plan.create')).pathname),
        isOpen: url.startsWith(new URL(route('admin.plans')).pathname) || url.startsWith(new URL(route('admin.plan.create')).pathname),
        children: [
          { name: 'Plans', href: route('admin.plans') },
          { name: 'Create plan', href: route('admin.plan.create') }
        ],
      },
      {
        name: 'Admin',
        icon: UserIcon,
        current: url.startsWith(new URL(route('admin.moderator')).pathname) || url.startsWith(new URL(route('admin.moderator.create')).pathname),
        isOpen: url.startsWith(new URL(route('admin.moderator')).pathname) || url.startsWith(new URL(route('admin.moderator.create')).pathname),
        children: [
          { name: 'Manage admins', href: route('admin.moderator') },
          { name: 'Add new', href: route('admin.moderator.create') }
        ],
      },

      { name: 'Log', href: route('admin.logs'), icon: ChipIcon, current: url.startsWith(new URL(route('admin.logs')).pathname) },

    ]

  return (
    <>
      <div className="h-full bg-white dark:bg-gray-900">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-slate-100 dark:bg-gray-900" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex flex-1 w-full max-w-xs mr-16">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 flex justify-center w-16 pt-5 left-full">
                      <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                        <span className="sr-only">Close sidebar</span>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-white">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                        </svg>

                      </button>
                    </div>
                  </Transition.Child>
                    <div className="flex flex-col px-6 overflow-y-auto bg-white border-r border-gray-500 grow gap-y-5 dark:bg-slate-900">
                        <div className="flex items-center h-16 shrink-0">
                            <img
                                className="w-auto h-8"
                                src={LogoPurple}
                                alt="Your Company"
                            />
                        </div>
                        <nav className="flex flex-col flex-1">
                            <ul role="list" className="flex flex-col flex-1 gap-y-7">
                            <li>
                                <ul role="list" className="-mx-2 space-y-1.5">
                                {navigations.map((item) => (
                                    <li key={item.name}>
                                    {!item.children ? (
                                        <Link
                                            as='a'
                                            href={item.href}
                                            className={classNames(
                                                item.current ? 'bg-gray-200/50 dark:bg-gray-700 text-gray-900 dark:text-gray-100' : 'text-gray-500 dark:text-gray-400 hover:bg-gray-200/50 dark:hover:bg-gray-700 hover:text-gray-900 dark:hover:text-gray-100',
                                                'group flex gap-x-4 rounded-md p-2 text-sm leading-6 font-semibold'
                                            )}
                                        >
                                            <item.icon
                                                className={classNames(
                                                    item.current ? 'text-gray-900 dark:text-gray-100' : 'hover:text-gray-900 dark:hover:text-white',
                                                    'group w-6 h-6 shrink-0'
                                                )}
                                                // className="w-6 h-6 text-gray-800 shrink-0 dark:text-gray-300 hover:text-gray-900 dark:hover:text-gray-100" 
                                                aria-hidden="true" />
                                            {item.name}
                                        </Link>
                                    ) : (
                                        <Disclosure as="div" defaultOpen={item.isOpen}>
                                        {({ open }) => (
                                            <>
                                            <Disclosure.Button
                                                className={classNames(
                                                item.current ? 'bg-gray-200/50 dark:bg-gray-700 text-gray-900 dark:text-gray-100' : 'text-gray-500 dark:text-gray-400 hover:bg-gray-200/50 dark:hover:bg-gray-700 hover:text-gray-900 dark:hover:text-gray-100',
                                                'flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold'
                                                )}
                                            >
                                                <item.icon 
                                                    className={classNames(
                                                        item.current ? 'text-gray-900 dark:text-gray-100' : 'hover:text-gray-900 dark:hover:text-white',
                                                        'group w-6 h-6 shrink-0'
                                                    )}
                                                    // className="w-6 h-6 text-gray-800 shrink-0 dark:text-gray-100" 
                                                    aria-hidden="true" 
                                                />
                                                {item.name}
                                                <ChevronRightIcon
                                                className={classNames(
                                                    open ? 'rotate-90 text-gray-800 dark:text-gray-100' : 'text-gray-400',
                                                    'ml-auto h-5 w-5 shrink-0'
                                                )}
                                                aria-hidden="true"
                                                />
                                            </Disclosure.Button>
                                            <Disclosure.Panel as="ul" className="px-2 mt-1">
                                                {item.children.map((subItem) => (
                                                <li key={subItem.name}>
                                                    {/* 44px */}
                                                    <Disclosure.Button
                                                        as="a"
                                                        href={subItem.href}
                                                        className={classNames(
                                                            subItem.current ? 'text-gray-800 dark:text-gray-300 font-semibold dark:text-white text-slate-900' : 'hover:bg-gray-200/50 dark:hover:bg-gray-700 hover:text-gray-900 dark:hover:text-gray-100',
                                                            'block rounded-md py-2 pr-2 pl-9 text-sm leading-6 dark:text-gray-300'
                                                        )}
                                                    >
                                                    {subItem.name}
                                                    </Disclosure.Button>
                                                </li>
                                                ))}
                                            </Disclosure.Panel>
                                            </>
                                        )}
                                        </Disclosure>
                                    )}
                                    </li>
                                ))}
                                </ul>
                            </li>
                            <li className="mt-auto -mx-6">
                                <a
                                href="#"
                                className="flex items-center px-6 py-3 text-sm font-semibold leading-6 gap-x-4 text-slate-900 dark:text-gray-100 hover:bg-gray-50/10"
                                >
                                <img
                                    className="w-8 h-8 rounded-full bg-gray-50/10"
                                    src={auth.user?.profile_image || Img}
                                    alt=""
                                />
                                <span className="sr-only">Your profile</span>
                                <span aria-hidden="true">{auth.user?.firstname}</span>
                                </a>
                            </li>
                            </ul>
                        </nav>
                    </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex flex-col px-6 overflow-y-auto bg-white border-r grow gap-y-5 border-gray-600/30 dark:bg-slate-900">
                <div className="flex items-center h-6 mt-4 mb-4 shrink-0">
                    <img
                        className="w-auto h-6"
                        src={LogoPurple}
                        alt="Suitable"
                    />
                </div>
                <nav className="flex flex-col flex-1">
                    <ul role="list" className="flex flex-col flex-1 gap-y-7">
                    <li>
                        <ul role="list" className="-mx-2 space-y-1.5">
                        {navigations.map((item) => (
                            <li key={item.name}>
                            {!item.children ? (
                                <Link
                                    as='a'
                                    href={item.href}
                                    className={classNames(
                                        item.current ? 'bg-gray-200/50 dark:bg-gray-700 text-gray-900 dark:text-gray-100' : 'text-gray-500 dark:text-gray-400 hover:bg-gray-200/50 dark:hover:bg-gray-700 hover:text-gray-900 dark:hover:text-gray-100',
                                        'group flex gap-x-4 rounded-md p-2 text-sm leading-6 font-semibold'
                                    )}
                                >
                                    <item.icon
                                         className={classNames(
                                            item.current ? 'text-gray-900 dark:text-gray-100' : 'hover:text-gray-900 dark:hover:text-white',
                                            'group w-6 h-6 shrink-0'
                                        )}
                                        // className="w-6 h-6 text-gray-800 shrink-0 dark:text-gray-300 hover:text-gray-900 dark:hover:text-gray-100" 
                                        aria-hidden="true" />
                                    {item.name}
                                    {item.count > 0 && (
                                        <span className="inline-flex items-center justify-center px-2 ml-auto text-xs font-medium leading-5 text-red-600 bg-red-100 rounded-full">
                                            {item.count}
                                        </span>
                                    )}
                                </Link>
                            ) : (
                                <Disclosure as="div" defaultOpen={item.isOpen}>
                                {({ open }) => (
                                    <>
                                    <Disclosure.Button
                                        className={classNames(
                                        item.current ? 'bg-gray-200/50 dark:bg-gray-700 text-gray-900 dark:text-gray-100' : 'text-gray-500 dark:text-gray-400 hover:bg-gray-200/50 dark:hover:bg-gray-700 hover:text-gray-900 dark:hover:text-gray-100',
                                        'flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold'
                                        )}
                                    >
                                        <item.icon 
                                            className={classNames(
                                                item.current ? 'text-gray-900 dark:text-gray-100' : 'hover:text-gray-900 dark:hover:text-white',
                                                'group w-6 h-6 shrink-0'
                                            )}
                                            // className="w-6 h-6 text-gray-800 shrink-0 dark:text-gray-100" 
                                            aria-hidden="true" 
                                        />
                                        {item.name}
                                        
                                        <ChevronRightIcon
                                        className={classNames(
                                            open ? 'rotate-90 text-gray-800 dark:text-gray-100' : 'text-gray-400',
                                            'ml-auto h-5 w-5 shrink-0'
                                        )}
                                        aria-hidden="true"
                                        />
                                    </Disclosure.Button>
                                    <Disclosure.Panel as="ul" className="px-2 mt-1">
                                        {item.children.map((subItem) => (
                                        <li key={subItem.name}>
                                            {/* 44px */}
                                            <Disclosure.Button
                                                as="a"
                                                href={subItem.href}
                                                className={classNames(
                                                    subItem.current ? 'text-gray-800 dark:text-gray-300 font-semibold dark:text-white text-slate-900' : 'hover:bg-gray-200/50 dark:hover:bg-gray-700 hover:text-gray-900 dark:hover:text-gray-100',
                                                    'block rounded-md py-2 pr-2 pl-9 text-sm leading-6 dark:text-gray-300'
                                                )}
                                            >
                                            {subItem.name}
                                            </Disclosure.Button>
                                        </li>
                                        ))}
                                    </Disclosure.Panel>
                                    </>
                                )}
                                </Disclosure>
                            )}
                            </li>
                        ))}
                        </ul>
                    </li>
                    <li className="mt-auto -mx-6">
                        <a
                        href="#"
                        className="flex items-center px-6 py-3 text-sm font-semibold leading-6 gap-x-4 text-slate-900 dark:text-gray-100 hover:bg-gray-50/10"
                        >
                        <img
                            className="w-8 h-8 rounded-full bg-gray-50/10"
                            src={auth.user?.profile_image || Img}
                            alt=""
                        />
                        <span className="sr-only">Your profile</span>
                        <span aria-hidden="true">{auth.user?.firstname}</span>
                        </a>
                    </li>
                    </ul>
                </nav>
            </div>
        </div>

        <div className="lg:pl-72">
          <div className="sticky top-0 z-40 flex items-center h-16 px-4 bg-white border-b border-gray-300 shadow-sm shrink-0 gap-x-4 dark:border-gray-500 dark:bg-slate-900 sm:gap-x-6 sm:px-6 lg:px-8">
            <button type="button" className="-m-2.5 p-2.5 text-gray-900 dark:text-gray-100 lg:hidden" onClick={() => setSidebarOpen(true)}>
                <span className="sr-only">Open sidebar</span>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 9h16.5m-16.5 6.75h16.5" />
                </svg>
            </button>
            {/* Separator */}
            {/* <div className="w-px h-6 bg-gray-500 dark:bg-gray-800/10 lg:hidden" aria-hidden="true" /> */}

            <div className="flex self-stretch flex-1 gap-x-4 lg:gap-x-6">
              <div className="relative flex flex-1" action="#" method="GET">
                    <UserSearch />
              </div>
              <div className="flex items-center gap-x-4 lg:gap-x-6">
                <button type="button" className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500">
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="w-6 h-6" aria-hidden="true" />
                </button>

                {theme === 'dark' ? (
                    <button id="theme-toggle" type="button" onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')} className={`${theme === 'light' ? 'hidden' : ''}text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5`}>
                        <svg id="theme-toggle-dark-icon" xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z" /></svg>
                        {/* Night */}
                    </button>

                        ) : (
                        <button id="theme-toggle" type="button" onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')} className={`${theme === 'dark' ? 'hidden' : ''}text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5`}>

                            <svg id="theme-toggle-light-icon" className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z" fillRule="evenodd" clipRule="evenodd"></path></svg>
                            {/* Day */}
                        </button>
                )}

                {/* Separator */}
                <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-600" aria-hidden="true" />

                {/* Profile dropdown */}
                <Menu as="div" className="relative">
                  <Menu.Button className="-m-1.5 flex items-center p-1.5">
                    <span className="sr-only">Open user menu</span>
                    <img
                      className="w-8 h-8 rounded-full bg-gray-50 dark:bg-gray-800"
                      src={auth.user?.profile_image || Img}
                      alt=""
                    />
                    <span className="hidden lg:flex lg:items-center">
                      <span className="ml-4 text-sm font-semibold leading-6 text-gray-900 dark:text-gray-50" aria-hidden="true">
                        {auth.user?.firstname}
                      </span>
                      <ChevronDownIcon className="w-5 h-5 ml-2 text-gray-400" aria-hidden="true" />
                    </span>
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-gray-50 dark:bg-gray-800 py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <Link
                              href={item.href}
                              className={classNames(
                                active ? 'bg-gray-50 dark:bg-gray-800' : '',
                                'block px-3 py-1 text-sm leading-6 text-gray-700 dark:text-gray-200 hover:text-gray-900 dark:hover:text-gray-50'
                              )}
                              method={item.method}
                            >
                              {item.name}
                            </Link>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <main className="h-full py-4">
            <div className="px-4">
                <div className="w-full px-2 py-2 overflow-hidden overflow-y-auto md:p-6">
                    <div className="w-full h-full">
                        {header && (
                            <div>
                                <div className="container flex flex-col items-start justify-between px-2 mx-auto md:flex-row md:items-center">
                                    <div>
                                        <h4 className="text-xl font-bold leading-tight text-gray-800 dark:text-gray-100">{header}</h4>
                                    </div>
                                    <div className="mt-6 md:mt-0">
                                        <Link href={href} className="px-8 py-2 text-sm text-white transition duration-150 ease-in-out bg-indigo-700 rounded focus:outline-none hover:bg-indigo-600">{btnName}</Link>
                                    </div>
                                </div>
                            </div>
                        )}
                        <FlashMessages />
                        {children}
                    </div>
                </div>
            </div>
          </main>
        </div>
      </div>
    </>
  )
}
