import { Inertia } from '@inertiajs/inertia';
import Authenticated from '@/Layouts/Authenticated';
import { useForm, usePage } from '@inertiajs/inertia-react';
import React from 'react';
import moment from 'moment';

function UserAdminMessages(props) {
    const { data, setData, post, processing, errors } = useForm({
        respond: '',
    });

    const { interactions } = usePage().props;

    const handleResponseSubmit = (e, interactionId) => {
        e.preventDefault();
        Inertia.post(`/interactions/${interactionId}/respond`, data, {
            preserveScroll: true,
            onSuccess: () => {
                setData('respond', ''); // Clear the respond input after successful submission
            },
        });
    };

    return (
        <Authenticated auth={props.auth} errors={props.errors}>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-4 sm:py-6 md:py-8">
                <div className="overflow-y-scroll bg-white p-6 rounded-lg border border-[#e5e7eb] w-full h-[525px] shadow-lg">
                    <div className="flex flex-col space-y-1.5 pb-6">
                        <h2 className="font-semibold text-lg tracking-tight">Instant contact with admin</h2>
                    </div>

                    <div className="grid gap-4 h-[380px] overflow-y-auto">
                        {interactions?.map((interaction) => (
                            <div key={interaction.id} className="grid grid-cols-[auto,1fr] gap-3 my-4 text-gray-600 text-sm">
                                <div className="relative flex shrink-0 overflow-hidden rounded-full w-8 h-8">
                                    {interaction.initiated_by === 'admin' ? (
                                        <div className="rounded-full bg-gray-100 border p-1">
                                            <svg stroke="none" fill="black" strokeWidth="1.5" viewBox="0 0 24 24" aria-hidden="true" height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                    d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z">
                                                </path>
                                            </svg>
                                        </div>
                                    ) : (
                                        <img src={props.auth.user.profile_image} className="h-8 w-8 rounded-full" alt="User Profile" />
                                    )}
                                </div>
                                <div>
                                    <p className="leading-relaxed mb-4">
                                        <span className="block font-bold text-gray-700">
                                            {interaction.initiated_by === 'admin' ? interaction.admin.firstname : props.auth.user.username}
                                        </span>
                                        <span className="block text-xs text-gray-500">{moment(interaction.created_at).format('LLL')}</span>
                                        <pre className='text-balance mt-3'>{interaction.message}</pre>
                                    </p>

                                    {interaction.respond !== null &&
                                        <div className="flex gap-3 my-4 text-gray-600 text-sm flex-1 mt-4">
                                            <div className="rounded-full h-8 w-8">
                                                <img
                                                    className={`flex-none h-8 w-8 rounded-full object-cover`}
                                                    src={props.auth.user.profile_image}
                                                />
                                            </div>
                                            <p className="leading-relaxed">
                                                <span className="block font-bold text-gray-700">You Responded</span>
                                                <span className="block text-xs text-gray-500">{moment(interaction.updated_at).format('LLL')}</span>
                                                <pre className='text-balance mt-3'>{interaction.respond}</pre>
                                                {interaction.user_read_at && (
                                                    <span className="block text-xs text-gray-500 mt-1">Read at: {moment(interaction.user_read_at).format('LLL')}</span>
                                                )}
                                                {interaction.admin_read_at && (
                                                    <span className="block text-xs text-gray-500 mt-1">Admin read at: {moment(interaction.admin_read_at).format('LLL')}</span>
                                                )}
                                            </p>
                                        </div>
                                    }
                                    {!interaction.respond && (
                                        <form onSubmit={(e) => handleResponseSubmit(e, interaction.id)} className="fixed bottom-0 mt-2 pr-6">
                                            <textarea
                                                name="respond"
                                                id="respond"
                                                rows={3}
                                                value={data.respond}
                                                onChange={(e) => setData('respond', e.target.value)}
                                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            />
                                            {errors.respond && <div className="text-red-500">{errors.respond}</div>}

                                            <button
                                                type="submit"
                                                className="inline-flex items-center justify-center rounded-md text-sm font-medium text-[#f9fafb] disabled:pointer-events-none disabled:opacity-50 bg-black hover:bg-[#111827E6] h-10 px-4 py-2 mt-2"
                                                disabled={processing}
                                            >
                                                Send Response
                                            </button>
                                        </form>
                                    )}
                                </div>
                            </div>
                        ))}

                        {interactions?.length === 0 && (
                            <div className="grid grid-cols-[auto,1fr] gap-3 my-4 text-gray-600 text-sm">
                                <div className="relative flex shrink-0 overflow-hidden rounded-full w-8 h-8">
                                    <div className="rounded-full bg-gray-100 border p-1">
                                        <svg stroke="none" fill="black" strokeWidth="1.5" viewBox="0 0 24 24" aria-hidden="true" height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z">
                                            </path>
                                        </svg>
                                    </div>
                                </div>
                                <div>
                                    <p className="leading-relaxed">
                                        <span className="block font-bold text-gray-700">{props.auth.user.firstname}</span>
                                        Say Hi to {props.auth.user.username}
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Authenticated>
    );
}

export default UserAdminMessages;
