import { Head, InertiaLink, Link, useForm, usePage } from '@inertiajs/inertia-react';
import React from 'react'
import { ThemeContext } from '@/context/ThemeContext';
import Guest from '@/Layouts/Guest';

import LogoPurple from '../../../../../public/assets/images/logo-purple.svg';
import LogoLight from '../../../../../public/assets/images/logo-light.svg';
import { Inertia } from '@inertiajs/inertia';
// import { loadStripe } from '@stripe/stripe-js';

function Subscription() {
    const { plans } = usePage().props;
    const { theme, setTheme } = React.useContext(ThemeContext);
    // const { stripeKey } = usePage().props;
    // const stripePromise = loadStripe(stripeKey);

    // const handleSubscription = async (priceId, plan) => {
    //     const stripe = await stripePromise;

    //     // Call your backend to create the Checkout Session
    //     const response = await fetch('/create-subscription-session', {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    //         },
    //         body: JSON.stringify({
    //             stripe_price_id: priceId,
    //             plan: plan,
    //         }),
    //     });

    //     const session = await response.json();

    //     // Redirect to Stripe Checkout
    //     const { error } = await stripe.redirectToCheckout({
    //         sessionId: session.id,
    //     });

    //     if (error) {
    //         console.error(error);
    //     }
    // };


    return (
        <div>
            <Head title="Choose a subscription plan" />
            <div className="pt-5 bg-white dark:bg-gray-900" id="pricing">
                <div className="px-6 pb-20 mx-auto mt-4 max-w-7xl lg:px-8">
                    <div className="max-w-4xl mx-auto text-center">
                        <div className="flex items-center justify-center">
                            <Link href="/">
                                <img className="block w-full h-7 dark:hidden" src={LogoPurple} alt="shape" />
                                <img className="hidden w-full h-7 dark:block" src={LogoLight} alt="shape" />
                            </Link>
                        </div>
                        <div className="mt-6 mb-6">
                            <p className="mt-2 text-2xl font-bold tracking-tight text-gray-800 dark:text-white sm:text-4xl">Choose a plan to enjoy all premium features of suitable.</p>
                            <p className="max-w-2xl mx-auto mt-2 text-xl leading-8 text-center text-gray-500 dark:text-slate-300">Get everything you need.</p>
                        </div>
                    </div>
                    <div className="p-4 mt-4">
                        <div className="grid max-w-6xl grid-cols-1 gap-4 mx-auto md:grid-cols-2 lg:grid-cols-3 lg:gap-8 ">
                            {plans && plans.map(({id, plan_name, slug, stripe_price_id, price, abbreviation, highlight}) =>
                                <div key={id} className={`dark:bg-white/5 bg-slate-100  ${highlight === 1 ? 'ring-2 ring-purple-500' : 'ring-1 ring-white/10'} rounded-3xl p-8 xl:p-10`}>
                                    <div className="flex items-baseline justify-between gap-x-4">
                                        <h2 id="product2" className="text-xl font-semibold leading-8 text-slate-800 dark:text-white">{plan_name}</h2>
                                        {highlight === 1 &&
                                        <p className="rounded-full bg-purple-500 px-2.5 py-1 text-xs font-semibold leading-5 text-white">Save £40</p>}
                                    </div>
                                    <h4 className="pb-6 font-medium text-md text-slate-700 dark:text-purple-50">7 days free trial</h4>
                                    <p className="flex items-baseline mt-6 gap-x-1">
                                        <span className="text-4xl font-bold tracking-tight text-slate-700 dark:text-white">£ {price} </span>
                                        <span className="text-sm font-semibold leading-6 text-gray-600 dark:text-slate-400">{abbreviation}</span>
                                    </p>

                                    <Link as='a' href={ route('createSubscription', slug) } className={`mb-3 ${highlight === 1 ? 'bg-purple-500 text-white shadow-sm hover:bg-purple-400 focus-visible:outline-purple-500 mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2' : 'bg-slate-700 text-slate-50 dark:bg-slate-200 dark:text-slate-500 hover:bg-slate-900 hover:text-slate-50 focus-visible:outline-white mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2'} `}>Start your free week</Link>

                                    {/* <button
                                        onClick={() => handleSubscription(stripe_price_id, slug)}
                                        className={`mb-3 ${highlight === 1 ? 'bg-purple-500 text-white shadow-sm hover:bg-purple-400 focus-visible:outline-purple-500 mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 w-full' : 'w-full bg-slate-700 text-slate-50 dark:bg-slate-200 dark:text-slate-500 hover:bg-slate-900 hover:text-slate-50 focus-visible:outline-white mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2'}`}>
                                        Start your free week
                                    </button> */}

                                    <ul className="flex flex-col mb-6">
                                        <li className="flex items-center mb-2.5">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 mr-4 dark:text-slate-50" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                                            </svg>
                                            <p className="text-base font-normal text-gray-800 dark:text-purple-50">Unlimited profile browsing</p>
                                        </li>
                                        <li className="flex items-center mb-2.5">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 mr-4 dark:text-slate-50" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                                            </svg>
                                            <p className="text-base font-normal text-gray-800 dark:text-purple-50">Unlimited profile request</p>
                                        </li>
                                        <li className="flex items-center mb-2.5">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 mr-4 dark:text-slate-50" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                                            </svg>
                                            <p className="text-base font-normal text-gray-800 dark:text-purple-50">{plan_name === 'Yearly' ? '£200 per year' : '£20 per month '} to continue after trial ends</p>
                                        </li>
                                        <li className="flex items-center mb-2.5">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 mr-4 text-slate-800 dark:text-slate-50" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                                            </svg>
                                            <p className="text-base font-normal text-gray-800 dark:text-slate-200">Unlimited chat</p>
                                        </li>
                                        <li className="flex items-center mb-2.5">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 mr-4 text-slate-800 dark:text-slate-50" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                                            </svg>
                                            <p className="text-base font-normal text-gray-800 dark:text-slate-200">24/7 Special admin support</p>
                                        </li>
                                        <li className="flex items-center mb-2.5">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 mr-4 text-slate-800 dark:text-white" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                                            </svg>
                                            <p className="text-base font-normal text-gray-800 dark:text-slate-200">Cancel Anytime</p>
                                        </li>
                                    </ul>
                                    <p className="relative pl-3 text-base text-slate-700">
                                        <span className="font-semibold text-md text-slate-500 dark:text-slate-200">You will not be charge today!</span>
                                    </p>
                                </div>
                            )}
                            </div>
                    </div>
                </div>
            </div>
            <div className="fixed bottom-0 left-0 right-0 z-40 hidden px-4 py-3 text-xs text-center shadow-sm md:flex md:items-center md:justify-center bg-gray-50 dark:shadow-none dark:bg-gray-800 text-slate-800 dark:text-slate-50">
                <div className="flex items-center justify-center">

                    <Link href="www.suitable.com">&copy; Suitable</Link>
                    <div className="w-1 h-1 ml-3 rounded-full bg-slate-800 dark:bg-slate-50"></div>
                    <Link className="ml-3 text-gray-700 dark:text-slate-50 hover:text-gray-900 dark:hover:text-slate-200 hover:underline" href="/">Home</Link>
                </div>
                <div className="ml-2">
                    {theme === 'dark' ? (
                                <button id="theme-toggle" type="button" onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')} className={`${theme === 'light' ? 'hidden' : ''}text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5`}>
                                    <svg id="theme-toggle-dark-icon" xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z" /></svg>
                                </button>
                                    ) : (
                                    <button id="theme-toggle" type="button" onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')} className={`${theme === 'dark' ? 'hidden' : ''}text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5`}>
                                        <svg id="theme-toggle-light-icon" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z" fillRule="evenodd" clipRule="evenodd"></path></svg>
                                    </button>
                                )}
                </div>
            </div>
        </div>
    )
}

export default Subscription
