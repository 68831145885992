import EmptyStateMessage from '@/Components/Admin/EmptyStateMessage';
import Pagination from '@/Components/Admin/Pagination';
import Authenticated from '@/Layouts/AdminLayouts/Authenticated'
import { InertiaLink, Link, usePage } from '@inertiajs/inertia-react';
import React from 'react'

function Index({auth, errors}) {

  const { contacts } = usePage().props;
  const {data} = contacts;

  return (
    <Authenticated
          auth={auth}
          errors={errors}
          header={`Contact us`}
          btnName = {`Dashboard`}
          href = {route('admin.dashboard')}
        >
        <div className='h-full'>
            <div className="py-2 mt-4 rounded-md">
            <table className="w-full mt-6 text-left whitespace-nowrap">
                  <colgroup>
                      <col className="w-full sm:w-4/12" />
                      <col className="lg:w-4/12" />
                      <col className="lg:w-2/12" />
                      <col className="lg:w-1/12" />
                      <col className="lg:w-1/12" />
                  </colgroup>
                  <thead className="text-sm leading-6 text-gray-600 border-b border-slate-300 dark:border-white/10 dark:text-white">
                  <tr>
                    <th scope="col" className="text-gray-600 dark:text-white">#</th>
                      <th scope="col" className="py-2 pl-4 pr-8 font-semibold sm:pl-6 lg:pl-8">
                          Name
                      </th>
                      <th scope="col" className="hidden py-2 pl-0 pr-8 font-semibold sm:table-cell">
                          Contact info
                      </th>
                      <th scope="col" className="hidden py-2 pl-0 pr-8 font-semibold md:table-cell lg:pr-20">
                          Query Type
                      </th>
                      <th scope="col" className="py-2 pl-0 pr-4 font-semibold text-right sm:pr-8 sm:text-left">
                          Message
                      </th>
                      <th scope="col" className="hidden py-2 pl-0 pr-4 font-semibold text-right sm:table-cell sm:pr-6 lg:pr-8">
                          Existing user?
                      </th>
                      <th>
                        
                      </th>
                  </tr>
                  </thead>
                  <tbody className="divide-y divide-slate-300 dark:divide-white/5">
                  {data.length !== 0 && data.map(({ id, name, email,
                                                                            contact_number,
                                                                            query_type,
                                                                            message,
                                                                            existing_user,}) => 
                    (
                      <tr key={id}>
                        <td className="py-4 text-gray-600 dark:text-white">{id}</td>
                      <td className="py-4 pl-4 pr-8 sm:pl-6 lg:pl-8">
                          <div className="flex items-center ">
                            <div className="text-sm font-medium leading-6 text-gray-600 truncate dark:text-white">{name}</div>
                          </div>
                      </td>
                      <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                          <div className="flex-row gap-x-3">
                            <div className="font-mono text-sm leading-6 text-slate-800 dark:text-gray-100">{email}</div>
                            <div className="font-mono text-sm leading-6 text-slate-800 dark:text-gray-100">{contact_number}</div>
                          </div>
                      </td>
                      <td className="py-4 pl-4 pr-8 sm:pl-6 lg:pl-8">
                          <div className="flex items-center ">
                            <div className="text-sm font-medium leading-6 text-gray-600 truncate dark:text-white">{query_type}</div>
                          </div>
                      </td>
                      <td className="py-4 pl-4 pr-8 sm:pl-6 lg:pl-8">
                          <div className="flex items-center ">
                            <div className="text-sm font-medium leading-6 text-gray-600 truncate text-wrap line-clamp-2 dark:text-white">{message}</div>
                          </div>
                      </td>
                      <td className="py-4 pl-0 pr-4 text-sm leading-6 sm:pr-8 lg:pr-20">
                          <div className="flex items-center justify-end gap-x-2 sm:justify-start">
                              <div className="text-gray-400 sm:hidden">
                                  {existing_user === 1 ? 'Yes' : 'No'}
                              </div>
                              <div className={existing_user === 1 ? 'text-green-400 bg-green-400/10 flex-none rounded-full p-1' : 'flex-none rounded-full p-1 text-rose-400 bg-rose-400/10'}>
                                  <div className="h-1.5 w-1.5 rounded-full bg-current" />
                              </div>
                              <div className="hidden text-gray-600 dark:text-white sm:block">{existing_user === 1 ? 'Yes' : 'No'}</div>
                          </div>
                      </td>
                      
                      <td className="hidden py-4 pl-0 pr-4 text-sm leading-6 text-right text-gray-400 sm:table-cell sm:pr-6 lg:pr-8">
                          <Link className="flex items-center justify-center gap-2 text-sm font-semibold hover:underline" href={route('admin.contactrespond', id)} as="a">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                              </svg>
                              <span>View</span>
                          </Link>
                      </td>
                      </tr>
                  ))}
                 
                  {data.length === 0 && (
                      <tr> 
                          <td colSpan={6} className="w-full">
                            <EmptyStateMessage
                                title="No messages!"
                                description="No contact messages found!"
                                buttonText="Dashboard"
                                buttonUrl={route('admin.dashboard')}
                                icon={
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-full h-full text-gray-700 dark:text-white">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.182 16.318A4.486 4.486 0 0 0 12.016 15a4.486 4.486 0 0 0-3.198 1.318M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75Zm-.375 0h.008v.015h-.008V9.75Zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75Zm-.375 0h.008v.015h-.008V9.75Z" />
                                    </svg>
                                }
                            />
                            </td>
                        </tr>
                        )
                    }
                  </tbody>
              </table>
            </div>
            <Pagination links={data.links} />
        </div>
        
      </Authenticated>
  )
}

export default Index