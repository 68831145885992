import React, { useEffect, useState } from 'react';
import Button from '@/Components/Button';
import Guest from '@/Layouts/Guest';
import Input from '@/Components/Input';
import Label from '@/Components/Label';
import ValidationErrors from '@/Components/ValidationErrors';
import { Head, Link, useForm } from '@inertiajs/inertia-react';
import InputSelect from '@/Components/InputSelect';
import RadioButton from '@/Components/RadioButton';

function SetUpProfileStepOne() {    
    const { data, setData, post, processing, errors, reset } = useForm({
        date_of_birth: '',
        gender: '',
        height: '',
        marital_status: '',
        have_children: '',
        siblings: '',
        ethnic_origin: '',
    });

    

    const heightOptions = [
        { value: '122cm - 4\'', label: '122cm - 4\'', cm: 122 },
        { value: '124cm - 4\' 1"', label: '124cm - 4\' 1"', cm: 124 },
        { value: '127cm - 4\' 2"', label: '127cm - 4\' 2"', cm: 127 },
        { value: '130cm - 4\' 3"', label: '130cm - 4\' 3"', cm: 130 },
        { value: '132cm - 4\' 4"', label: '132cm - 4\' 4"', cm: 132 },
        { value: '135cm - 4\' 5"', label: '135cm - 4\' 5"', cm: 135 },
        { value: '137cm - 4\' 6"', label: '137cm - 4\' 6"', cm: 137 },
        { value: '140cm - 4\' 7"', label: '140cm - 4\' 7"', cm: 140 },
        { value: '142cm - 4\' 8"', label: '142cm - 4\' 8"', cm: 142 },
        { value: '145cm - 4\' 9"', label: '145cm - 4\' 9"', cm: 145 },
        { value: '147cm - 4\' 10"', label: '147cm - 4\' 10"', cm: 147 },
        { value: '150cm - 4\' 11"', label: '150cm - 4\' 11"', cm: 150 },
        
        { value: '152cm - 5\'', label: '152cm - 5\'', cm: 152 },
        { value: '155cm - 5\' 1"', label: '155cm - 5\' 1"', cm: 155 },
        { value: '157cm - 5\' 2"', label: '157cm - 5\' 2"', cm: 157 },
        { value: '160cm - 5\' 3"', label: '160cm - 5\' 3"', cm: 160 },
        { value: '163cm - 5\' 4"', label: '163cm - 5\' 4"', cm: 163 },
        { value: '165cm - 5\' 5"', label: '165cm - 5\' 5"', cm: 165 },
        { value: '168cm - 5\' 6"', label: '168cm - 5\' 6"', cm: 168 },
        { value: '170cm - 5\' 7"', label: '170cm - 5\' 7"', cm: 170 },
        { value: '173cm - 5\' 8"', label: '173cm - 5\' 8"', cm: 173 },
        { value: '175cm - 5\' 9"', label: '175cm - 5\' 9"', cm: 175 },
        { value: '178cm - 5\' 10"', label: '178cm - 5\' 10"', cm: 178 },
        { value: '180cm - 5\' 11"', label: '180cm - 5\' 11"', cm: 180 },

        { value: '183cm - 6\'', label: '183cm - 6\'', cm: 183 },
        { value: '185cm - 6\' 1"', label: '185cm - 6\' 1"', cm: 185 },
        { value: '188cm - 6\' 2"', label: '188cm - 6\' 2"', cm: 188 },
        { value: '191cm - 6\' 3"', label: '191cm - 6\' 3"', cm: 191 },
        { value: '193cm - 6\' 4"', label: '193cm - 6\' 4"', cm: 193 },
        { value: '196cm - 6\' 5"', label: '196cm - 6\' 5"', cm: 196 },
        { value: '198cm - 6\' 6"', label: '198cm - 6\' 6"', cm: 198 },
        { value: '201cm - 6\' 7"', label: '201cm - 6\' 7"', cm: 201 },
        { value: '203cm - 6\' 8"', label: '203cm - 6\' 8"', cm: 203 },
        { value: '206cm - 6\' 9"', label: '206cm - 6\' 9"', cm: 206 },
        { value: '208cm - 6\' 10"', label: '208cm - 6\' 10"', cm: 208 },
        { value: '211cm - 6\' 11"', label: '211cm - 6\' 11"', cm: 211 },

        { value: '213cm - 7\'', label: '213cm - 7\'', cm: 213 },
        { value: '216cm - 7\' 1"', label: '216cm - 7\' 1"', cm: 216 },
        { value: '218cm - 7\' 2"', label: '218cm - 7\' 2"', cm: 218 },
        { value: '221cm - 7\' 3"', label: '221cm - 7\' 3"', cm: 221 },
        { value: '224cm - 7\' 4"', label: '224cm - 7\' 4"', cm: 224 },
        { value: '226cm - 7\' 5"', label: '226cm - 7\' 5"', cm: 226 },
        { value: '229cm - 7\' 6"', label: '229cm - 7\' 6"', cm: 229 },
        { value: '231cm - 7\' 7"', label: '231cm - 7\' 7"', cm: 231 },
        { value: '234cm - 7\' 8"', label: '234cm - 7\' 8"', cm: 234 },
        { value: '236cm - 7\' 9"', label: '236cm - 7\' 9"', cm: 236 },
    ];
    
    const maritalStatusOptions = [
        { value: "Single", label: "Single" },
        { value: "Divorced", label: "Divorced" },
        { value: "Widowed", label: "Widowed" },
    ];

    const childrenOptions = [
        { value: "Yes", label: "Yes" },
        { value: "No", label: "No" },
    ];

    const ethnicOriginOptions = [
        { value: "Afghan", label: "Afghan"},
        { value: "African American", label: "African American"},
        { value: "Arab", label: "Arab"},
        { value: "Asian", label: "Asian"},
        { value: "Bangladeshi", label: "Bangladeshi"},
        { value: "Berber", label: "Berber"},
        { value: "Caribbean", label: "Caribbean"},
        { value: "Central African", label: "Central AfricanBerber"},
        { value: "East African", label: "East African"},
        { value: "Egyptian", label: "Egyptian"},
        { value: "European", label: "European"},
        { value: "Far East Asian", label: "Far East Asian"},
        { value: "Indian", label: "Indian"},
        { value: "Indonesian", label: "Indonesian"},
        { value: "Iraqi", label: "Iraqi"},
        { value: "Kurdish", label: "Kurdish"},
        { value: "Latino", label: "Latino"},
        { value: "Malay", label: "Malay"},
        { value: "Mixed Heritage", label: "Mixed Heritage"},
        { value: "Nigerian", label: "Nigerian"},
        { value: "North African", label: "North African"},
        { value: "North American", label: "North American"},
        { value: "Other", label: "Other"},
        { value: "Pakistani", label: "Pakistani"},
        { value: "Persian", label: "Persian"},
        { value: "Somali", label: "Somali"},
        { value: "South African", label: "South African"},
        { value: "Sudanese", label: "Sudanese"},
        { value: "Turkish", label: "Turkish"},
        { value: "West African", label: "West African"},        
    ];

    
    // Sort the array alphabetically by label
    ethnicOriginOptions.sort((a, b) => {
        const labelA = a.label.toUpperCase();
        const labelB = b.label.toUpperCase();
        if (labelA < labelB) {
            return -1;
        }
        if (labelA > labelB) {
            return 1;
        }
        return 0;
      });

    let nums = [];
    let min = 1, max = 20;
    for(let i = min; i <= max; i++){
        nums.push(i);
    }

    const onHandleChange = (e) => {
        setData(e.target.name, e.target.type === 'hidden' ? e.target.defaultValue : e.target.value);
        // setData({ ...data, [e.target.name]: e.target.value });
    };

    const submit = (e) => {
        e.preventDefault();
        post('set-up-profile-step-1');
    };

    return <Guest
                bgimage="bg-location-background"
            >
                <Head title="Suitable | Set up profile" />
                <div className="text-center">
                    <div className="flex mb-2 justify-between items-center">
                        <h2 className="mt-6 text-lg font-semibold text-gray-900 dark:text-slate-50">
                            Your personal information
                        </h2>
                        <p className="mt-6 text-md sm:text-lg font-bold text-purple-800 dark:text-purple-50">20%</p>
                    </div>
                    <div className="h-2 w-full bg-slate-400  dark:bg-slate-600 relative rounded-full">
                        <div className="h-2 w-[100px] bg-slate-800  dark:bg-slate-50 absolute z-50 rounded-full"></div>
                    </div>
                </div>

                <ValidationErrors errors={errors} />

                <form onSubmit={submit}>

                    <div className="mt-4 space-y-1">
                        <Label forInput="date_of_birth" value="Date of Birth" />

                        <Input
                            type="date"
                            name="date_of_birth"
                            value={data.date_of_birth}
                            className="mt-1 block w-full"
                            handleChange={onHandleChange}
                        />
                    </div>

                    <div className="mt-4 space-y-1">
                        <Label forInput="gender" value="Gender" />
                        <div className="flex items-baseline mb-2 pb-2 space-x-2">
                            <RadioButton name="gender" value={ `Male` } btnName="Male" handleChange={onHandleChange} />
                            <RadioButton name="gender" value={`Female`} btnName="Female" handleChange={onHandleChange} />
                        </div>
                    </div>

                    <div className="mt-4 space-y-1">
                        <Label forInput="height" value="Height" />
                        <InputSelect 
                            defaultValue={data.height} 
                            onChange={onHandleChange} 
                            options={heightOptions} 
                            className={`block w-full sm:text-sm text-slate-500 dark:text-slate-200`} 
                            placeholder='Select your height'
                            name={`height`}
                        />
                    </div>

                    <div className="mt-4 space-y-1">
                        <Label forInput="marital_status" value="Marital Status" />
                        <InputSelect 
                            defaultValue={data.marital_status} 
                            onChange={onHandleChange} 
                            options={maritalStatusOptions} 
                            className={`block w-full sm:text-sm text-slate-500 dark:text-slate-200`} 
                            placeholder='Select your Marital Status'
                            name={`marital_status`}
                        />
                    </div>

                    <div className="mt-4 space-y-1">
                        <Label forInput="have_children" value="Children?" />
                        <InputSelect 
                            defaultValue={data.have_children} 
                            onChange={onHandleChange} 
                            options={childrenOptions} 
                            className={`block w-full sm:text-sm text-slate-500 dark:text-slate-200`} 
                            placeholder='Do you have any children?'
                            name={`have_children`}
                        />
                    </div>

                    <div className="mt-4 space-y-1">
                        <Label forInput="siblings" value="Number of siblings" />
                        <select 
                            className={
                                `mt-1 py-3 px-4 border-gray-300 rounded-xl shadow-sm text-slate-500 dark:text-slate-200`
                            }
                            onChange={onHandleChange}
                            name="siblings" 
                            value={data.siblings} 
                        >
                            <option className="text-slate-500 dark:text-slate-200" value="">Select how many</option>
                            <option className="text-slate-500 dark:text-slate-200" value="0">I am only child</option>
                            {
                                nums.map((num) => <option className="text-slate-500 dark:text-slate-200" value={num} key={num}>{num}</option>)
                            }
                        </select>
                    </div>
                    
                    <div className="mt-4 space-y-1">
                        <Label forInput="ethnic-origin" value="Ethnic origin" />
                        <InputSelect 
                            defaultValue={data.ethnic_origin} 
                            onChange={onHandleChange} 
                            options={ethnicOriginOptions} 
                            className={`block w-full sm:text-sm text-slate-500 dark:text-slate-200`} 
                            placeholder='Select your ethnic origin'
                            name={`ethnic_origin`}
                        />
                    </div>

                    <div className="flex items-center justify-end mt-4 mb-4">
                        <Button className=" font-semibold py-2 px-4 rounded inline-flex items-center" processing={processing}>
                            <span>Next</span>
                            <svg className="fill-current w-4 h-4 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                            </svg>
                        </Button>
                    </div>
                </form>
        </Guest>;
}

export default SetUpProfileStepOne;
