import React, { useEffect, useRef, useState } from 'react';
import { Inertia } from '@inertiajs/inertia';
import moment from 'moment';
import { Link } from '@inertiajs/inertia-react';
import EmojiPicker from 'emoji-picker-react';
import Echo from 'laravel-echo';
import Avatar from '../Avatar';


function ChatBox({ selectedConversation, loadMessages, conversations, auth, isBlocked }) {
    const { username, id, profile_image, receiver_id, auth_id } = selectedConversation;
    const [body, setBody] = useState('');
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [messages, setMessages] = useState(loadMessages);

    const messagesEndRef = useRef(null);

    useEffect(() => {
        const echo = new Echo({
            broadcaster: 'pusher',
            key: process.env.MIX_PUSHER_APP_KEY,
            cluster: process.env.MIX_PUSHER_APP_CLUSTER,
            forceTLS: true,
            encrypted: true,
            authEndpoint: '/broadcasting/auth',
            auth: {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            },
        });

        echo.private(`chat.${selectedConversation.id}`)
            .listen('MessageSent', (e) => {
                setMessages((prevMessages) => {
                    if (prevMessages.some(msg => msg.id === e.message.id)) {
                        return prevMessages;
                    }
                    return [...prevMessages, e.message];
                });
                messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
            });

        return () => {
            echo.leave(`chat.${selectedConversation.id}`);
        };
    }, [selectedConversation.id]);

    const sendMessage = () => {
        if (isBlocked) {
            alert('You cannot send a message because you are blocked or you have blocked this user.');
            return;
        }
        try {
            Inertia.post(route('send.message'), {
                body: body,
                conversation_id: selectedConversation.id,
                sender_id: auth_id,
                receiver_id: receiver_id,
            }, {
                onSuccess: () => setBody(''),  // Clear input after successful message send
            });
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };

    const handleEmoji = () => {
        setShowEmojiPicker((prevShowEmojiPicker) => !prevShowEmojiPicker);
    };

    const handleEmojiSelect = (emoji) => {
        const emojiString = emoji.emoji || emoji.unicode;
        setBody((prevBody) => prevBody + emojiString);
        setShowEmojiPicker(false);
    };

    const formatMessageTime = (created_at) => {
        const now = moment();
        const messageTime = moment(created_at);

        if (now.isSame(messageTime, 'day')) {
            return messageTime.format('HH:mm');
        } else if (now.subtract(1, 'days').isSame(messageTime, 'day')) {
            return 'Yesterday ' + messageTime.format('LT');
        } else {
            return messageTime.format('MMM D, YYYY LT');
        }
    };

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);


    return (
        <div className="w-full overflow-hidden">
        <div className="flex flex-col h-full overflow-y-scroll grow">
            <header className="sticky inset-x-0 top-0 z-10 flex w-full pt-1 pb-1 bg-white border-b dark:bg-slate-800 dark:border-slate-600">
                <div className="flex items-center w-full gap-2 px-2 sm:px-3 md:px-4 lg:px-5">
                    <Link href={route('chat')} className="shrink-0 lg:hidden text-slate-900 dark:text-slate-50">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                        </svg>
                    </Link>
                    <Link href={route('user-profile', username)} className="shrink-0">
                        <Avatar src={profile_image} className="w-12 h-12 p-0.5 border border-purple-300 dark:border-purple-800" />
                    </Link>
                    <Link href={route('user-profile', username)} className="font-bold truncate dark:text-white">{username}</Link>
                </div>
            </header>
    
            <main className="flex flex-col gap-3 p-2.5 overflow-y-auto flex-grow overscroll-contain overflow-x-hidden w-full my-auto">
                {messages.length === 0 && (
                    <div className="flex items-center justify-center">
                        <div>
                            <h2 className="text-xl font-medium text-slate-600 dark:text-slate-100">Start conversation</h2>
                            <p className="text-sm font-medium text-slate-600 dark:text-slate-100">Keep it halal &amp; brief!</p>
                        </div>
                    </div>
                )}
                {messages.map((message, key) => {
                    const previousMessage = key > 0 ? messages[key - 1] : null;
    
                    return (
                        <div
                            id={`conversation-${message.id}`}
                            key={key}
                            className={`max-w-[85%] md:max-w-[78%] flex w-auto gap-2 relative mt-2 ${
                                message.sender_id === auth_id ? 'ml-auto' : ''
                            }`}
                        >
                            <div
                                className={`shrink-0 ${
                                    previousMessage?.sender_id === message.sender_id ? 'invisible' : ''
                                } ${message.sender_id === auth_id ? 'hidden' : ''}`}
                            >
                                <Avatar src={profile_image} className="w-10 h-10" />
                            </div>
                            <div
                                className={`flex flex-wrap text-[15px] rounded-xl py-2 px-4 flex-col bg-[#f6f6f8fb] ${
                                    message.sender_id === auth_id
                                        ? 'rounded-br-none bg-indigo-200 py-2 px-4 shadow text-slate-800 '
                                        : 'rounded-bl-none border border-gray-200/40 text-black shadow'
                                }`}
                            >
                                <p
                                    className={`whitespace-normal truncate text-sm md:text-base tracking-wide lg:tracking-normal ${
                                        message.sender_id === auth_id && 'text-slate-800'
                                    }`}
                                >
                                    {message.body}
                                </p>
                                <div className="flex gap-1 ml-auto">
                                    <small
                                        className={`text-xs ml-5 ${
                                            message.sender_id === auth_id ? 'text-gray-500' : 'text-gray-500'
                                        }`}
                                    >
                                        {formatMessageTime(message.created_at)}
                                    </small>
                                    {message.sender_id === auth.user.id && (
                                        <div>
                                            {message.read_at !== null ? (
                                                <span className="text-gray-500">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        className="bi bi-check2-all"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l7-7zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0z" />
                                                        <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708z" />
                                                    </svg>
                                                </span>
                                            ) : (
                                                <span className="text-gray-500">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        className="bi bi-check2"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                                    </svg>
                                                </span>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    );
                })}
                <div ref={messagesEndRef} />
            </main>
    
            <footer className="inset-x-0 z-10 bg-white shrink-0 dark:bg-slate-800 mb-14 sm:mb-0">
                {!isBlocked && (
                    <form onSubmit={(e) => { e.preventDefault(); sendMessage(); }}>
                        <div className="flex flex-row items-center w-full h-16 px-4 rounded-b-none shadow-lg rounded-xl bg-white/80 dark:bg-slate-700/50">
                            <div className="flex-grow">
                                <div className="relative w-full">
                                    <input
                                        type="text"
                                        autoFocus
                                        autoComplete="off"
                                        placeholder="Type your message"
                                        maxLength={1700}
                                        name="body"
                                        value={body}
                                        onChange={(e) => setBody(e.target.value)}
                                        className="flex w-full h-10 pl-4 border rounded-xl focus:outline-none dark:text-slate-700 dark:bg-slate-100 focus:border-indigo-300"
                                    />
                                    <button
                                        type="button"
                                        onClick={handleEmoji}
                                        className="absolute top-0 right-0 flex items-center justify-center w-12 h-full text-gray-400 hover:text-gray-600"
                                    >
                                        <svg
                                            className="w-6 h-6"
                                            fill="none"
                                            stroke="currentColor"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                            ></path>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <div className="ml-2">
                                <button
                                    type="submit"
                                    className="flex items-center justify-center flex-shrink-0 px-4 py-2 text-white bg-indigo-500 hover:bg-indigo-600 rounded-xl"
                                >
                                    <span>Send</span>
                                    <span className="ml-2">
                                        <svg
                                            className="w-4 h-4 -mt-px transform rotate-45"
                                            fill="none"
                                            stroke="currentColor"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                                            ></path>
                                        </svg>
                                    </span>
                                </button>
                            </div>
                        </div>
                        {showEmojiPicker && (
                            <div className="flex items-center justify-center max-w-5xl">
                                <EmojiPicker
                                    onEmojiClick={handleEmojiSelect}
                                    autoFocusSearch={false}
                                    searchDisabled={true}
                                    height={300}
                                    width={"100%"}
                                    showPreview={false}
                                    skinTonesDisabled={true}
                                    pickerStyle={{ position: 'absolute', bottom: '20px', right: '20px' }}
                                />
                            </div>
                        )}
                    </form>
                )}
                {isBlocked && (
                    <div className="flex items-center justify-center p-4 mt-auto text-center bg-white border-t dark:bg-slate-900/50 border-gray-200/50">
                        <p className="text-center text-red-600 dark:text-red-500">
                            You cannot send or receive messages from this user!
                        </p>
                    </div>
                )}
            </footer>
        </div>
    </div>
    
    );
}

export default ChatBox;



// import React, { useEffect, useRef, useState } from 'react'
// import { error } from 'laravel-mix/src/Log'
// import { Inertia } from '@inertiajs/inertia';
// import moment from 'moment';
// import { Link } from '@inertiajs/inertia-react';
// import EmojiPicker from 'emoji-picker-react';
// import Pusher from 'pusher-js';
// import Echo from 'laravel-echo';
// import Avatar from '../Avatar';

// function ChatBox({selectedConversation, loadMessages, conversations, auth, isBlocked}) {
//     const { username, id, profile_image, receiver_id, auth_id } = selectedConversation;
//     const [body, setBody] = useState('');
//     const [showEmojiPicker, setShowEmojiPicker] = useState(false);
//     const [selectedEmoji, setSelectedEmoji] = useState('');
//     const [messages, setMessages] = useState(loadMessages);


//     const sendMessage = () => {
//         if (isBlocked) {
//             alert('You cannot send a message because you are blocked or you have blocked this user.');
//             return;
//         }
//         try {
//             Inertia.post(route('send.message'), {
//                 body: body,
//                 conversation_id: selectedConversation.id,
//                 sender_id: auth_id,
//                 receiver_id: receiver_id, // Assuming getReceiver() is a method on the conversation model
//             });
//             setBody('');
//             messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });

//         } catch (error) {
//             console.error('Error sending message:', error);
//         }
//     };

//     const handleEmoji = () => {
//         setShowEmojiPicker((prevShowEmojiPicker) => !prevShowEmojiPicker);
//     };

//     const handleEmojiSelect = (emoji) => {
//         const emojiString = emoji.emoji || emoji.unicode;
//         setSelectedEmoji(emojiString);
//         setBody((prevBody) => prevBody + emojiString);
//         setShowEmojiPicker(true);
//     };

//     const formatMessageTime = (created_at) => {
//         const now = moment();
//         const messageTime = moment(created_at);

//         if (now.isSame(messageTime, 'day')) {
//             // Show time if it's today
//             return messageTime.format('HH:mm');
//         } else if (now.subtract(1, 'days').isSame(messageTime, 'day')) {
//             // Show yesterday's time if it's yesterday
//             return 'Yesterday ' + messageTime.format('LT');
//         } else {
//             // Show date and time for any other day
//             return messageTime.format('MMM D, YYYY LT');
//         }
//     };

//     const messagesEndRef = useRef(null);

//     useEffect(() => {
//         const echo = new Echo({
//             broadcaster: 'pusher',
//             key: process.env.MIX_PUSHER_APP_KEY,
//             cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//             forceTLS: true,
//             encrypted: true,
//             authEndpoint: '/broadcasting/auth',
//             auth: {
//                 headers: {
//                     Authorization: `Bearer ${localStorage.getItem('token')}`,
//                 },
//             },
//         });

//         echo.private(`chat.${selectedConversation.id}`)
//             .listen('MessageSent', (e) => {
//                 setMessages((messages) => [...messages, e.message]);
//                 // if (e.receiver_id === auth.user.id) {
//                 //     // Update read status for the received message
//                 //     const messageId = e.message.id;
//                 //     updateReadStatus(messageId);
//                 // }
//             });

//         return () => {
//             echo.leave(`chat.${selectedConversation.id}`);
//         };
//     }, [selectedConversation.id]);

//     const messageList = messages.map((message, key) => {
//         const previousMessage = key > 0 ? messages[key - 1] : null;

//         return (
//             <div
//                 id={"conversation-"+message.id}
//                 key={key}
//                 className={`max-w-[85%] md:max-w-[78%] flex w-auto gap-2 relative mt-2 ${
//                 message.sender_id === auth_id ? 'ml-auto' : ''
//                 }`}
//             >
//                 <div
//                     className={`shrink-0 ${
//                         previousMessage?.sender_id === message.sender_id ? 'invisible' : ''
//                     } ${message.sender_id === auth_id ? 'hidden' : ''}`}
//                     >
//                     <Avatar src={profile_image} className="w-10 h-10"/>
//                 </div>
//                 <div
//                     className={`flex flex-wrap text-[15px] rounded-xl py-2 px-4 flex-col bg-[#f6f6f8fb] ${
//                         message.sender_id === auth_id
//                         ? 'rounded-br-none bg-indigo-200 py-2 px-4 shadow text-slate-800 '
//                         : 'rounded-bl-none border border-gray-200/40 text-black shadow'
//                     }`}
//                 >
//                     <p className={`whitespace-normal truncate text-sm md:text-base tracking-wide lg:tracking-normal ${message.sender_id === auth_id && 'text-slate-800'}`}>
//                         {message.body}
//                     </p>
//                     <div className="flex gap-1 ml-auto">
//                         <small className={`text-xs ml-5 ${message.sender_id === auth_id ? 'text-gray-500' : 'text-gray-500'}`}>
//                             {formatMessageTime(message.created_at)}
//                         </small>
//                         {message.sender_id === auth.user.id && (
//                         <div>
//                             {
//                                 message.read_at !== null ? (
//                                     <span
//                                         className="text-gray-500"
//                                     >
//                                         <svg
//                                             xmlns="http://www.w3.org/2000/svg"
//                                             width="16"
//                                             height="16"
//                                             fill="currentColor"
//                                             className="bi bi-check2-all"
//                                             viewBox="0 0 16 16"
//                                         >
//                                             <path
//                                             d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l7-7zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0z"
//                                             />
//                                             <path
//                                             d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708z"
//                                             />
//                                         </svg>
//                                     </span>
//                                 ) : (
//                                     <span className="text-gray-500">
//                                         <svg
//                                             xmlns="http://www.w3.org/2000/svg"
//                                             width="16"
//                                             height="16"
//                                             fill="currentColor"
//                                             className="bi bi-check2"
//                                             viewBox="0 0 16 16"
//                                         >
//                                             <path
//                                             d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"
//                                             />
//                                         </svg>
//                                     </span>
//                                 )
//                             }
//                         </div>
//                         )}
//                     </div>
//                 </div>
//             </div>
//         );
//     });


//     useEffect(() => {
//         messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
//     }, [messages]);

//     const updateReadStatus = async (messageId) => {
//         try {
//             const response = await axios.post(`/api/messages/${messageId}/mark-as-read`);
//             // Handle success
//             console.log(response.data); // Log success message or update state as needed
//         } catch (error) {
//             // Handle error
//             console.error('Error updating read status:', error);
//         }
//     };


//   return (
//     <div className="w-full overflow-hidden">
//         <div className="flex flex-col h-full overflow-y-scroll grow">
//             <header className="w-full sticky inset-x-0 flex pb-[5px] pt-[5px] top-0 z-10 bg-white dark:bg-slate-800 border-b dark:border-slate-600">
//                 <div className="flex items-center w-full gap-2 px-2 lg:px-4 md:gap-5">
//                     <Link href={route('chat')} className="shrink-0 lg:hidden text-slate-900 dark:text-slate-50">
//                         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5">
//                             <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
//                         </svg>
//                     </Link>
//                     <Link href={route('user-profile',username)} className="shrink-0">
//                         <Avatar src={profile_image} className='h-9 w-9' />
//                     </Link>
//                     <Link href={route('user-profile', username)} className="font-bold truncate dark:text-white">{username}</Link>
//                 </div>
//             </header>

//             <main className="flex flex-col gap-3 p-2.5 overflow-y-auto flex-grow overscroll-contain overflow-x-hidden w-full my-auto">
//                 {messages.length === 0 && (
//                     <div className="flex items-center justify-center">
//                         <div>
//                             <h2 className="text-xl font-medium text-slate-600 dark:text-slate-100">Start conversion</h2>
//                             <p className="text-sm font-medium text-slate-600 dark:text-slate-100">Keep it halal &amp; brief!</p>
//                         </div>
//                     </div>
//                 )}
//                 {messageList}
//                 <div ref={messagesEndRef} />
//             </main>

//             <footer className="inset-x-0 z-10 bg-white shrink-0 dark:bg-slate-800 mb-14 sm:mb-0">
//             {!isBlocked && (

//                 <form onSubmit={(e) => { e.preventDefault(); sendMessage(); }}>
//                     <div className="flex flex-row items-center w-full h-16 px-4 rounded-b-none shadow-lg rounded-xl bg-white/80 dark:bg-slate-700/50">
//                         <div className="flex-grow">
//                             <div className="relative w-full">
//                                 <input
//                                     type='text'
//                                     autoFocus
//                                     autoComplete='off'
//                                     placeholder='Type your message'
//                                     maxLength={1700}
//                                     name='body'
//                                     value={body}
//                                     onChange={(e) => setBody(e.target.value)}
//                                     className="flex w-full h-10 pl-4 border rounded-xl focus:outline-none dark:text-slate-700 dark:bg-slate-100 focus:border-indigo-300"
//                                 />
//                                 <button
//                                     type='button'
//                                     onClick={handleEmoji}
//                                     className="absolute top-0 right-0 flex items-center justify-center w-12 h-full text-gray-400 hover:text-gray-600"
//                                 >
//                                 <svg
//                                     className="w-6 h-6"
//                                     fill="none"
//                                     stroke="currentColor"
//                                     viewBox="0 0 24 24"
//                                     xmlns="http://www.w3.org/2000/svg"
//                                 >
//                                     <path
//                                     strokeLinecap="round"
//                                     strokeLinejoin="round"
//                                     strokeWidth="2"
//                                     d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
//                                     ></path>
//                                 </svg>
//                                 </button>
//                             </div>
//                         </div>
//                         <div className="ml-2">
//                             <button
//                                 type='submit'
//                                 className="flex items-center justify-center flex-shrink-0 px-4 py-2 text-white bg-indigo-500 hover:bg-indigo-600 rounded-xl"
//                             >
//                                 <span>Send</span>
//                                 <span className="ml-2">
//                                 <svg
//                                     className="w-4 h-4 -mt-px transform rotate-45"
//                                     fill="none"
//                                     stroke="currentColor"
//                                     viewBox="0 0 24 24"
//                                     xmlns="http://www.w3.org/2000/svg"
//                                 >
//                                     <path
//                                     strokeLinecap="round"
//                                     strokeLinejoin="round"
//                                     strokeWidth="2"
//                                     d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
//                                     ></path>
//                                 </svg>
//                                 </span>
//                             </button>
//                         </div>
//                     </div>
//                     {showEmojiPicker && (
//                         <div className="flex items-center justify-center max-w-5xl" >
//                             <EmojiPicker
//                                 onEmojiClick={handleEmojiSelect}
//                                 autoFocusSearch={false}
//                                 searchDisabled={true}
//                                 height={300}
//                                 width={"100%"}
//                                 showPreview={false}
//                                 skinTonesDisabled={true}
//                                 pickerStyle={{ position: 'absolute', bottom: '20px', right: '20px' }}
//                             />
//                         </div>
//                     )}
//                 </form>
//             )}
//             {isBlocked && (
//                 <div className="flex items-center justify-center p-4 mt-auto text-center bg-white border-t dark:bg-slate-900/50 border-gray-200/50">
//                     <p className="text-center text-red-600 dark:text-red-500">
//                         You cannot send or receive messages from this user!
//                     </p>
//                 </div>
//             )}
//             </footer>
//         </div>
//     </div>
//   )
// }

// export default ChatBox
