import ResponsiveSidenav from '@/Components/ResponsiveSidenav'
import UserSideNav from '@/Components/UserSideNav'
import Authenticated from '@/Layouts/Authenticated'
import React, { useEffect, useState } from 'react'
import { Head, InertiaLink, Link, useForm, usePage } from '@inertiajs/inertia-react';
import Button from '@/Components/Button';
import Input from '@/Components/Input';
import Label from '@/Components/Label';
import ValidationErrors from '@/Components/ValidationErrors';
import PopUpModal from '@/Components/PopUpModal';
import { Inertia } from '@inertiajs/inertia';
import { RadioGroup, Field, Switch } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/outline';
const plans = [
    { name: 'Startup', ram: '12GB', cpus: '6 CPUs', disk: '256GB SSD disk' },
    { name: 'Business', ram: '16GB', cpus: '8 CPUs', disk: '512GB SSD disk' },
    { name: 'Enterprise', ram: '32GB', cpus: '12 CPUs', disk: '1TB SSD disk' },
  ];

function Settings(props) {
    const [values, setValues] = useState({ photo: '', blur: false });

    const [isChecked, setIsChecked] = useState(props.auth.user.blur_profile_image === 1);

    const { blockedusers } = usePage().props;

    const handleCheckboxChange = (e) => {
        const isChecked = e?.target?.checked ?? !isChecked;
        setIsChecked(isChecked);
        setValues((prevValues) => ({ ...prevValues, blur: !prevValues.blur }));
        const formData = new FormData();

        formData.append('blur_profile_image', values.blur ? 1 : 0);

        Inertia.post('settings/blur-image', formData, {
          forceFormData: true,
        });
      };

    const { data, setData, post, processing, errors, reset } = useForm({
        old_password:'',
        password: '',
        new_password_confirmation: '',
    });

    useEffect(() => {
        return () => {
            reset('password', 'password_confirmation', 'old_password');
        };
    }, []);

    const onHandleChange = (event) => {
        setData(event.target.name, event.target.value);
    };

    const submit = (e) => {
        e.preventDefault();
        post(route('auth.user.settings.reset.password'));
    };

    const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [action, setAction] = useState(null);

    const handleOpenModal = (actionType) => {
        // Set the action (e.g., 'delete' or 'deactivate') and open the modal
        setAction(actionType);
        setConfirmationModalOpen(true);
    };

    const handleConfirmAction = () => {
        // Handle the action based on the current value of 'action'
        if (action === 'delete') {
            // Perform delete account logic
            Inertia.post('/settings/delete');
        } else if (action === 'deactivate') {
            // Perform deactivate account logic
            Inertia.post('/settings/deactivate');
        }

        // Close the confirmation modal
        setConfirmationModalOpen(false);
    };

    const handleCancelAction = () => {
        // Close the confirmation modal without performing any action
        setConfirmationModalOpen(false);
    };

    const [selected, setSelected] = useState(plans[0])

  return (
    <Authenticated
        auth={props.auth}
        errors={props.errors}
        header={<h2 className="text-xl font-semibold leading-tight text-gray-800 dark:text-slate-50 ">Settings</h2>}
        btnName="Back"
        svg={<svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2"><path strokeLinecap="round" strokeLinejoin="round" d="M10 19l-7-7m0 0l7-7m-7 7h18" /></svg>}
        href={route('home')}
    >

    <ResponsiveSidenav />
        <div className="px-4 mx-auto max-w-7xl sm:px-6 md:px-8">
            <div className="flex-row sm:flex sm:space-x-2">
                <div className="hidden sm:block sm:w-[250px] h-min ">
                    <UserSideNav/>
                </div>


                <div className="rounded-lg shadow-sm sm:w-2/3 bg-gray-50 dark:bg-slate-800 ">
                    <div className="px-4 py-5 bg-white border-b border-gray-200 rounded-t-lg dark:border-gray-700 dark:bg-slate-800/50 sm:px-6">
                        <h3 className="text-base font-semibold leading-6 text-gray-900 dark:text-white">Settings</h3>
                        <p className="pt-1 text-sm text-gray-500 dark:text-gray-400">Update your account settings.</p>
                    </div>
                    {/* Settings forms */}
                    <div className="px-6 divide-y dark:divide-white/5 divide-slate-200">
                        <div className="grid grid-cols-1 px-4 py-16 max-w-7xl gap-x-8 gap-y-10 sm:px-6 md:grid-cols-3 lg:px-8">
                            <div>
                                <h2 className="text-base font-semibold leading-7 text-slate-800 dark:text-white">Security and Blocking</h2>
                                <p className="mt-1 text-sm leading-6 text-gray-500 dark:text-gray-400">
                                    See all blocked users list.
                                </p>
                            </div>

                            <div className="md:col-span-2">
                                <div className="">
                                    {blockedusers.map(({ id, username, age, country, ethnic_origin, profile_image }) => (
                                        <div className="" key={id}>
                                            <div className="relative flex flex-col-reverse p-6 mb-2 rounded-lg shadow-md bg-slate-50 dark:bg-slate-800 dark:bg-slate-900/20">
                                                <div className="flex items-center space-x-4">
                                                    <img src={profile_image} alt={`${username}'s Profile photo`} className="flex-none object-cover rounded-full w-14 h-14" loading="lazy"/>
                                                    <div className="flex-auto">
                                                        <div className="text-base font-semibold text-slate-900 dark:text-slate-300">
                                                            {username}, {age}
                                                        </div>
                                                        <div className="mt-0.5 text-sm font-medium text-slate-600 dark:text-slate-200">{ethnic_origin} From {country}</div>
                                                    </div>
                                                    <div className="text-slate-700 dark:text-slate-300">
                                                        <div className="mt-2">
                                                            <Link className="inline-flex items-center px-3 py-2 text-sm font-medium transition duration-150 ease-in-out border rounded-md border-slate-500 text-slate-500 dark:text-slate-200 hover:bg-slate-800 hover:text-slate-50 dark:hover:text-slate-700 dark:hover:bg-slate-50 dark:border-slate-50 focus:ring-2 dark:ring-slate-400 focus:outline-none" href={route('unblock-user', id)} method="post" as="button">
                                                                Unblock
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                ))}

                                </div>
                                {blockedusers.length === 0 && (
                                    <p className="text-lg font-semibold text-slate-800 dark:text-slate-50">No blocked users</p>
                                )}

                                {blockedusers.length !== 0 && (
                                    <div className="flex mt-4">
                                        <Link
                                            href={route('block-users')} method="get"
                                            className="px-3 py-2 text-sm font-semibold text-white bg-indigo-500 rounded-md shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                                        >
                                            View all
                                        </Link>
                                    </div>
                                )}
                            </div>
                        </div>


                        {/* Pravacy */}
                        <div className="grid grid-cols-1 px-4 py-16 max-w-7xl gap-x-8 gap-y-10 sm:px-6 md:grid-cols-3 lg:px-8">
                            <div>
                                <h2 className="text-base font-semibold leading-7 text-slate-800 dark:text-white">Privacy</h2>
                                <p className="mt-1 text-sm leading-6 text-gray-500 dark:text-gray-400">
                                    Activate blur photos will make all of your photos will be blurred to the public except matched profiles.
                                </p>
                            </div>

                            <div className="md:col-span-2">
                                <form>
                                    <div className="flex items-center justify-between py-4">
                                        <div className="flex flex-col">
                                            <Label as="p" className="text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                                {values.blur ? 'Unblur' : 'Blur'} Photos
                                            </Label>

                                            <p className="block font-sans text-sm antialiased font-normal leading-normal text-gray-700 dark:text-slate-300">
                                                {values.blur
                                                ? 'Unblur photos to make it visible to public.'
                                                : 'Blur photos to make it visible only to invited users.'}
                                            </p>
                                        </div>
                                        <Switch
                                            checked={isChecked}
                                            onChange={handleCheckboxChange}
                                            className={`${isChecked ? 'bg-indigo-400' : 'bg-gray-200'}
                                                'relative ml-4 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                                            `}
                                        >
                                            <span
                                                aria-hidden="true"
                                                className={`${isChecked ? 'translate-x-5' : 'translate-x-0'}
                                                'inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                                `}
                                            />
                                        </Switch>

                                    </div>
                                </form>
                            </div>
                        </div>


                        {/* Password forms */}
                        <div className="grid grid-cols-1 px-4 py-16 max-w-7xl gap-x-8 gap-y-10 sm:px-6 md:grid-cols-3 lg:px-8">
                            <div>
                                <h2 className="text-base font-semibold leading-7 text-slate-800 dark:text-white">Change password</h2>
                                <p className="mt-1 text-sm leading-6 text-gray-500 dark:text-gray-400">
                                    Update your password associated with your account.
                                </p>
                            </div>

                            <form onSubmit={submit} className="md:col-span-2">
                                <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                                    <div className="col-span-full">
                                        <ValidationErrors errors={errors} />

                                        <label htmlFor="current-password" className="block text-sm font-medium leading-6 text-slate-800 dark:text-white">
                                            Current password
                                        </label>
                                        <div className="mt-2">
                                            <Input
                                                type="password"
                                                name="old_password"
                                                value={data.old_password}
                                                autoComplete="old_password"
                                                isFocused={false}
                                                handleChange={onHandleChange}
                                                className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset dark:ring-white/10  ring-slate-900/10 focus:ring-2 focus:ring-inset focus:ring-slate-500 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-span-full">
                                        <label htmlFor="new-password" className="block text-sm font-medium leading-6 text-slate-800 dark:text-white">
                                            New password
                                        </label>
                                        <div className="mt-2">
                                            <Input
                                                type="password"
                                                name="new_password"
                                                value={data.new_password}
                                                autoComplete="new_password"
                                                isFocused={false}
                                                handleChange={onHandleChange}
                                                className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset dark:ring-white/10 ring-slate-900/10 focus:ring-2 focus:ring-inset focus:ring-slate-500 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-span-full">
                                        <label htmlFor="confirm-password" className="block text-sm font-medium leading-6 dark:text-white text-slate-800">
                                            Confirm new password
                                        </label>
                                        <div className="mt-2">
                                            <Input
                                                type="password"
                                                name="new_password_confirmation"
                                                value={data.new_password_confirmation}
                                                autoComplete="new-password-confirmation"
                                                handleChange={onHandleChange}
                                                className="block w-full rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset dark:ring-white/10 ring-slate-900/10 focus:ring-2 focus:ring-inset focus:ring-slate-500 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="flex mt-8">
                                    <button
                                        processing={processing}
                                        type="submit"
                                        className="px-3 py-2 text-sm font-semibold text-white bg-indigo-500 rounded-md shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                                    >
                                        Change password
                                    </button>
                                </div>
                            </form>
                        </div>

                        <div className="grid grid-cols-1 px-4 py-16 max-w-7xl gap-x-8 gap-y-10 sm:px-6 md:grid-cols-3 lg:px-8">
                            <div>
                            <h2 className="text-base font-semibold leading-7 text-gray-800 dark:text-white">Subscription</h2>
                            <p className="mt-1 text-sm leading-6 text-slate-600 dark:text-gray-400">
                                Manage your subscription and billing from here.
                            </p>
                            </div>

                            <div className="md:col-span-2">
                                <a href="/billing-portal" target='_blank' className="px-4 py-2.5 text-sm font-semibold text-white rounded-md shadow-sm bg-slate-500 hover:bg-slate-400">
                                    Go to billing portal
                                </a>
                            </div>
                        </div>

                        <div className="grid grid-cols-1 px-4 py-16 max-w-7xl gap-x-8 gap-y-10 sm:px-6 md:grid-cols-3 lg:px-8">
                            <div>
                                <h2 className="text-base font-semibold leading-7 dark:text-white text-slate-800">Deactivate account</h2>
                                <p className="mt-1 text-sm leading-6 text-gray-500 dark:text-gray-400">
                                    Need a break? Your information will be here when you come back. If you want to learn more about our deactivation policy <Link href={route('faq')} className="text-sm font-medium text-gray-400 underline ease-in-out hover:no-underline dark:text-gray-100 hover:border-b-2 hover:border-slate-500 hover:text-slate-500 dark:hover:text-white">Read Faq</Link>
                                </p>
                            </div>

                            <div className="flex items-start md:col-span-2">
                                <button
                                    onClick={() => handleOpenModal('deactivate')}
                                    className="px-3 py-2 text-sm font-semibold text-white bg-indigo-500 rounded-md shadow-sm hover:bg-indigo-400"
                                >
                                    Deactivate account
                                </button>
                                <PopUpModal
                                    isOpen={isConfirmationModalOpen}
                                    onCancel={handleCancelAction}
                                    onConfirm={handleConfirmAction}
                                    title={action === 'delete' ? 'Delete Account' : 'Deactivate Account'}
                                    message={
                                        action === 'delete'
                                        ? 'Are you sure you want to delete your account? This action cannot be undone.'
                                        : 'Are you sure you want to deactivate your account? This action cannot be undone.'
                                    }
                                    buttonName={
                                        action === 'delete' ? 'Delete' : 'Deactivate'
                                    }
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-1 px-4 py-16 max-w-7xl gap-x-8 gap-y-10 sm:px-6 md:grid-cols-3 lg:px-8">
                            <div>
                                <h2 className="text-base font-semibold leading-7 dark:text-white text-slate-800">Delete account</h2>
                                <p className="mt-1 text-sm leading-6 text-gray-500 dark:text-gray-400">
                                    No longer want to use our service? You can delete your account here. This action is not reversible.
                                    All information related to this account will be deleted permanently. If you want to learn more about our deletion policy <Link href={route('faq')} className="text-sm font-medium text-gray-400 underline ease-in-out hover:no-underline dark:text-gray-100 hover:border-b-2 hover:border-slate-500 hover:text-slate-500 dark:hover:text-white">Read Faq</Link>
                                </p>
                            </div>

                            <div className="flex items-start md:col-span-2">
                                <button
                                    onClick={() => handleOpenModal('delete')}
                                    className="px-3 py-2 text-sm font-semibold text-white bg-red-500 rounded-md shadow-sm hover:bg-red-400"
                                >
                                    Yes, delete my account
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

                {/* Plan */}
                {/* <div className="w-full px-4 py-16">
                    <div className="w-full max-w-md mx-auto">
                        <RadioGroup value={selected} onChange={setSelected}>
                        <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
                        <div className="space-y-2">
                            {plans.map((plan) => (
                            <RadioGroup.Option
                                key={plan.name}
                                value={plan}
                                className={({ active, checked }) =>
                                `${
                                    active
                                    ? 'ring-2 ring-white/60 ring-offset-2 ring-offset-sky-300'
                                    : ''
                                }
                                ${checked ? 'bg-sky-900/75 text-white' : 'bg-white'}
                                    relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none`
                                }
                            >
                                {({ active, checked }) => (
                                <>
                                    <div className="flex items-center justify-between w-full">
                                    <div className="flex items-center">
                                        <div className="text-sm">
                                        <RadioGroup.Label
                                            as="p"
                                            className={`font-medium  ${
                                            checked ? 'text-white' : 'text-gray-900'
                                            }`}
                                        >
                                            {plan.name}
                                        </RadioGroup.Label>
                                        <RadioGroup.Description
                                            as="span"
                                            className={`inline ${
                                            checked ? 'text-sky-100' : 'text-gray-500'
                                            }`}
                                        >
                                            <span>
                                            {plan.ram}/{plan.cpus}
                                            </span>{' '}
                                            <span aria-hidden="true">&middot;</span>{' '}
                                            <span>{plan.disk}</span>
                                        </RadioGroup.Description>
                                        </div>
                                    </div>
                                    {checked && (
                                        <div className="text-white shrink-0">
                                        <CheckCircleIcon className="w-6 h-6" />
                                        </div>
                                    )}
                                    </div>
                                </>
                                )}
                            </RadioGroup.Option>
                            ))}
                        </div>
                        </RadioGroup>
                    </div>
                </div> */}

    </Authenticated>
  )
}

export default Settings
