import React, { useEffect, useState } from 'react';
import Button from '@/Components/Button';
import Checkbox from '@/Components/Checkbox';
import Guest from '@/Layouts/Guest';
import Input from '@/Components/Input';
import Label from '@/Components/Label';
import ValidationErrors from '@/Components/ValidationErrors';
import { Head, Link, useForm } from '@inertiajs/inertia-react';

import LogoPurple from '../../../../public/assets/images/logo-purple.svg';
import LogoLight from '../../../../public/assets/images/logo-light.svg';



export default function Login({ status, canResetPassword }) {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const { data, setData, post, processing, errors, reset } = useForm({
        email: '',
        password: '',
        remember: '',
    });

    useEffect(() => {
        return () => {
            reset('password');
        };
    }, []);

    const onHandleChange = (event) => {
        setData(event.target.name, event.target.type === 'checkbox' ? event.target.checked : event.target.value);
    };

    const submit = (e) => {
        e.preventDefault();
        post(route('login'));
    };


    function togglePasswordVisibility() {
      setIsPasswordVisible((prevState) => !prevState);
    }

    return (
        <Guest
            bgimage = "bg-login-background" title = "Bismillah...."  subtitle = "Start Your soulmate search with the world's most reliable match making site!" linktext = "Need Help ?" href = "/register" btnName="Contact Support"
        >
            <Head title="Log in" />

            {status && <div className="mb-4 text-sm font-medium text-green-600">{status}</div>}
            <div className="mb-2 ">
                <Link href="/" className="">
                    <img className="block w-full h-7 dark:hidden" src={LogoPurple} alt="shape" />
                    <img className="hidden w-full h-7 dark:block" src={LogoLight} alt="shape" />
                </Link>
            </div>
            {/* <ValidationErrors errors={errors} /> */}

            <form className="px-2" onSubmit={submit}>
                <div className="pb-3 mt-4 mb-6 ">
                    <h2 className="mt-8 text-xl font-bold leading-9 tracking-tight text-gray-900 dark:text-white">
                        Welcome Back
                    </h2>
                    <p className="text-sm leading-6 text-gray-500 dark:text-slate-300">Enter your login credentials</p>
                </div>
                <div className='space-y-1'>
                    <Label forInput="email" value="Email"/>
                    <Input
                        type="text"
                        name="email"
                        value={data.email}
                        className={`block w-full mt-1 ${errors.email && 'border border-red-400'}`}
                        autoComplete="email"
                        isFocused={true}
                        handleChange={onHandleChange}
                    />
                    {errors.email && (
                        <p className="text-sm text-red-600 dark:text-red-400">{errors.email}</p>
                    )}
                </div>

                <div className='mt-4 space-y-2'>
                    <Label forInput="password" value="Password" />
                    <div className="relative w-full">
                        <Input
                            type={isPasswordVisible ? "text" : "password"}
                            name="password"
                            value={data.password}
                            className="block w-full mt-1"
                            autoComplete="current-password"
                            handleChange={onHandleChange}
                            required
                            // className="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none dark:placeholder-gray-200 dark:text-slate-200 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                            placeholder="Password"
                        />
                        <button
                            type='button'
                            className="absolute inset-y-0 right-0 flex items-center justify-center px-4 text-gray-600"
                            onClick={togglePasswordVisibility}
                        >
                            {isPasswordVisible ? (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-5 h-5"
                            >
                                <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                                />
                            </svg>
                            ) : (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-5 h-5"
                            >
                                <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                />
                                <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                            </svg>
                            )}
                        </button>
                    </div>
                    {errors.password && (
                        <p className="text-xs text-red-600 dark:text-red-400">{errors.password}</p>
                    )}
                </div>

                <div className="flex items-center justify-between mt-4">
                    <label className="flex items-center">
                        <Checkbox name="remember" value={data.remember} handleChange={onHandleChange} />

                        <span className="ml-2 text-sm text-gray-600 dark:text-slate-300">Remember me</span>
                    </label>

                    {canResetPassword && (
                        <Link
                            href={route('password.request')}
                            className="text-sm font-semibold text-indigo-600 dark:text-indigo-400 hover:text-indigo-500"
                        >
                            Forgot password
                        </Link>
                    )}
                </div>

                <div className="mt-4">
                    <button className="w-full px-3 py-2 font-semibold text-white bg-purple-700 border border-purple-700 rounded-lg hover:bg-purple-600 hover:border-purple-900 hover:text-white dark:hover:bg-purple-600 dark:hover:text-white focus:outline-none focus:border-0" processing={processing}>
                        Log in
                    </button>
                </div>
                <p className="mt-4 text-sm text-center text-gray-600 dark:text-slate-300 max-w">
                    Don't have an account ? {` `}
                    <Link href="/register" className="font-bold text-purple-700 no-underline transition duration-300 ease-in cursor-pointer dark:text-slate-100 dark:hover:text-slate-50 hover:text-purple-900 hover:underline">Sign up</Link>
                </p>
            </form>
            
        </Guest>
    );
}
