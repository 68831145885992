import { useEffect } from 'react';
import { Head, usePage } from '@inertiajs/inertia-react';

function Checkout() {
  const { sessionId, stripeKey } = usePage().props;

  const checkout = () => {
    console.log(sessionId);
    return window.Stripe(stripeKey).redirectToCheckout({
      sessionId: sessionId,
    });
  };

  useEffect(() => {
    // Automatically call the checkout function after the component mounts
    checkout();
  }, []); // The empty dependency array ensures that this effect runs once after the initial render

  return (
    <div>
      <Head title="Checkout" />
      {/* Your component JSX... */}
    </div>
  );
}

export default Checkout;