import Button from '@/Components/Button';
import Input from '@/Components/Input';
import Label from '@/Components/Label';
import ResponsiveSidenav from '@/Components/ResponsiveSidenav';
import UserSideNav from '@/Components/UserSideNav'
import UserSideNavMenuItem from '@/Components/UserSideNavMenuItem';
import Authenticated from '@/Layouts/Authenticated'
import { Inertia } from '@inertiajs/inertia';
import React, { useRef, useState } from 'react'
import InputSelect from '@/Components/InputSelect';
import RadioButton from '@/Components/RadioButton';
import { Link, useForm, usePage } from '@inertiajs/inertia-react';
import ValidationErrors from '@/Components/ValidationErrors';
import { heightOptions, } from '../../../utils/data';
import DropdownOptions from '@/Components/DropdownOptions';
import { Menu } from '@headlessui/react'
import Avatar from '@/Components/Avatar';
import { CameraIcon } from '@heroicons/react/outline';

function Profile({auth, errors}) {
    const {user, interests, userImages} = usePage().props;
    const baseUrl = 'http://suitable.one/';

    const { data, setData, post, processing, reset } = useForm({
        username: user.username || '',
        email: user.email || '',

        age: user.age || '',
        date_of_birth: user.date_of_birth || '',
        // date_of_birth: user.date_of_birth ? new Date(user.date_of_birth) : null,

        ethnic_origin: user.ethnic_origin || '',
        relocate: user.relocate || '',
        country: user.country || '',
        recidency_status: user.recidency_status || '',
        city: user.city || '',
        area: user.area || '',

        back_home_country: user.back_home_country || '',
        back_home_city: user.back_home_city || '',
        back_home_area: user.back_home_area || '',

        height: user.height || '',
        marital_status: user.marital_status || '',
        have_children: user.have_children || '',
        siblings: user.siblings || '',

        highest_education: user.highest_education || '',
        university: user.university || '',
        course_name: user.course_name || '',
        college: user.college || '',
        college_course_name: user.college_course_name || '',
        current_profession: user.current_profession || '',
        company_name: user.company_name || '',

        religious_history: user.religious_history || '',
        prayer_frequency: user.prayer_frequency || '',
        sect: user.sect || '',
        read_quran: user.read_quran || '',
        eat_halal: user.eat_halal || '',
        drink_alchohol: user.drink_alchohol || '',
        bio: user.bio || '',
        verification_image: user.verification_image || '',
    });


    const photoRef = useRef();

    const [link, setLink] = useState(false);
    const [values, setValues] = useState({photo: '',});
    const [images, setImages] = useState([]);
    const showFileInput = userImages.length < 3;

    const [selectedFiles, setSelectedFiles] = useState([]);
    const canUploadMoreImages = selectedFiles.length <= 4;
    const [message, setMessage] = useState('');


    const handleProfileImageChange = (e) => {
        const newImages = [...e.target.files];
        setImages(newImages);

        if (newImages) {
            const filesArray = Array.from(e.target.files).map((file) =>
                URL.createObjectURL(file)
            );

            setSelectedFiles((prevImages) => prevImages.concat(filesArray));
            Array.from(e.target.files).map(
                (file) => URL.revokeObjectURL(file) // avoid memory leak
            );
        }

        e.preventDefault();
        const formData = new FormData();

        newImages.forEach((image) => {
            formData.append('images[]', image);
        });

        Inertia.post('updateprofileimage', formData, {
            forceFormData: true,
          });
        setLink(true);
    };

    const handleImageChange = (e) => {
        const newImages = [...e.target.files];
        setImages(newImages);

        if (newImages) {
            const filesArray = Array.from(e.target.files).map((file) =>
                URL.createObjectURL(file)
            );

            setSelectedFiles((prevImages) => prevImages.concat(filesArray));
            Array.from(e.target.files).map(
                (file) => URL.revokeObjectURL(file) // avoid memory leak
            );
        }

        e.preventDefault();
        const formData = new FormData();

        newImages.forEach((image) => {
            formData.append('images[]', image);
        });

        Inertia.post('upload', formData, {
            forceFormData: true,
          });
        setLink(true);
    };

    const handleChecked = (e) => {
        let id = e.target.value;
        if (e.target.checked) {
            setData("interests", [...data.interests, id]);
        } else {
            setData(
                "interest",
                data.interests.filter((item) => {
                    return item !== id;
                })
            );
        }
    };

    const renderPhotos = (source) => {
        if (source.length === 0) {
            return null;
        }

        return source.map((photo) => {
            return <div key={photo} className="relative rounded-lg shadow-sm bg-gray-50">
                                <div onClick={() => setSelectedFiles(selectedFiles.filter((e) => e !== photo))} className="flex justify-center items-baseline absolute z-50 right-0 left-[4rem] -top-[1rem] text-sm border bg-slate-700 shadow-md cursor-pointer hover:bg-slate-900 border-slate-800 text-slate-50 text-center h-6 w-6 rounded-full">x</div>
                                <img  alt="" src={photo} className="object-cover w-20 h-20 rounded-lg aspect-square" />
                    </div>;
        });
    };

    // const onHandleChange = e => {
    //     const { name, value } = e.target;

    //     // Update the form data
    //     setData(name, value);
    //     setData(name, e.target.type === 'date' ? value : value);

    //     // Calculate age based on date of birth
    //     if (name === 'date_of_birth') {
    //         const dob = new Date(value);
    //         const today = new Date();
    //         const age = today.getFullYear() - dob.getFullYear();

    //         // Update the age in the form data
    //         setData('age', isNaN(age) ? '' : age);
    //     }
    // }


    const onHandleChange = (e) => {
        setData(e.target.name, e.target.value);
    };


    let form = useRef(null);
    const handleSubmit = (event) => {
        event.preventDefault();
        const form_data = new FormData(form.current);
        let payload = {};
        form_data.forEach(function (value, key) {
            payload[key] = value;
        });
    };

    const countries = [{
        "name": "Andorra",
        "code": "Andorra"
      }, {
        "name": "Austria",
        "code": "Austria"
      }, {
        "name": "Australia",
        "code": "Australia"
      }, {
        "name": "Belgium",
        "code": "Belgium"
      }, {
        "name": "Canada",
        "code": "Canada"
      }, {
        "name": "Switzerland",
        "code": "Switzerland"
      }, {
        "name": "Gibraltar",
        "code": "Gibraltar"
      }, {
        "name": "Czech Republic",
        "code": "Czech Republic"
      }, {
        "name": "Germany",
        "code": "Germany"
      }, {
        "name": "Denmark",
        "code": "Denmark"
      }, {
        "name": "Estonia",
        "code": "Estonia"
      }, {
        "name": "Spain",
        "code": "Spain"
      }, {
        "name": "Finland",
        "code": "Finland"
      }, {
        "name": "Faroe Islands",
        "code": "Faroe Islands"
      }, {
        "name": "France",
        "code": "France"
      }, {
        "name": "United Kingdom",
        "code": "United Kingdom"
      }, {
        "name": "Guernsey",
        "code": "Guernsey"
      }, {
        "name": "Greece",
        "code": "Greece"
      }, {
        "name": "Qatar",
        "code": "Qatar"
      }, {
        "name": "Hungary",
        "code": "Hungary"
      }, {
        "name": "Ireland",
        "code": "Ireland"
      }, {
        "name": "Kuwait",
        "code": "Kuwait"
      }, {
        "name": "Iceland",
        "code": "Iceland"
      }, {
        "name": "Italy",
        "code": "Italy"
      }, {
        "name": "Saudi Arabia",
        "code": "Saudi Arabia"
      }, {
        "name": "Liechtenstein",
        "code": "Liechtenstein"
      }, {
        "name": "Lithuania",
        "code": "Lithuania"
      }, {
        "name": "Luxembourg",
        "code": "Luxembourg"
      }, {
        "name": "United States",
        "code": "United States"
      }, {
        "name": "Monaco",
        "code": "Monaco"
      }, {
        "name": "New Zealand ",
        "code": "New Zealand "
      }, {
        "name": "Malta",
        "code": "Malta"
      }, {
        "name": "Netherlands",
        "code": "Netherlands"
      }, {
        "name": "Norway",
        "code": "Norway"
      }, {
        "name": "Oman",
        "code": "Oman"
      }, {
        "name": "Portugal",
        "code": "Portugal"
      }, {
        "name": "United Arab Emirates ",
        "code": "United Arab Emirates "
      }, {
        "name": "Sweden",
        "code": "Sweden"
      }];

    const sortedcs = countries.sort((a,b) => a.name.localeCompare(b.name));

    const backhomecountries = [
        {name: 'Afghanistan', code: 'AF'},
        {name: 'Åland Islands', code: 'AX'},
        {name: 'Albania', code: 'AL'},
        {name: 'Algeria', code: 'DZ'},
        {name: 'American Samoa', code: 'AS'},
        {name: 'AndorrA', code: 'AD'},
        {name: 'Angola', code: 'AO'},
        {name: 'Anguilla', code: 'AI'},
        {name: 'Antarctica', code: 'AQ'},
        {name: 'Antigua and Barbuda', code: 'AG'},
        {name: 'Argentina', code: 'AR'},
        {name: 'Armenia', code: 'AM'},
        {name: 'Aruba', code: 'AW'},
        {name: 'Australia', code: 'AU'},
        {name: 'Austria', code: 'AT'},
        {name: 'Azerbaijan', code: 'AZ'},
        {name: 'Bahamas', code: 'BS'},
        {name: 'Bahrain', code: 'BH'},
        {name: 'Bangladesh', code: 'BD'},
        {name: 'Barbados', code: 'BB'},
        {name: 'Belarus', code: 'BY'},
        {name: 'Belgium', code: 'BE'},
        {name: 'Belize', code: 'BZ'},
        {name: 'Benin', code: 'BJ'},
        {name: 'Bermuda', code: 'BM'},
        {name: 'Bhutan', code: 'BT'},
        {name: 'Bolivia', code: 'BO'},
        {name: 'Bosnia and Herzegovina', code: 'BA'},
        {name: 'Botswana', code: 'BW'},
        {name: 'Bouvet Island', code: 'BV'},
        {name: 'Brazil', code: 'BR'},
        {name: 'British Indian Ocean Territory', code: 'IO'},
        {name: 'Brunei Darussalam', code: 'BN'},
        {name: 'Bulgaria', code: 'BG'},
        {name: 'Burkina Faso', code: 'BF'},
        {name: 'Burundi', code: 'BI'},
        {name: 'Cambodia', code: 'KH'},
        {name: 'Cameroon', code: 'CM'},
        {name: 'Canada', code: 'CA'},
        {name: 'Cape Verde', code: 'CV'},
        {name: 'Cayman Islands', code: 'KY'},
        {name: 'Central African Republic', code: 'CF'},
        {name: 'Chad', code: 'TD'},
        {name: 'Chile', code: 'CL'},
        {name: 'China', code: 'CN'},
        {name: 'Christmas Island', code: 'CX'},
        {name: 'Cocos (Keeling) Islands', code: 'CC'},
        {name: 'Colombia', code: 'CO'},
        {name: 'Comoros', code: 'KM'},
        {name: 'Congo', code: 'CG'},
        {name: 'Congo, The Democratic Republic of the', code: 'CD'},
        {name: 'Cook Islands', code: 'CK'},
        {name: 'Costa Rica', code: 'CR'},
        {name: 'Cote D\'Ivoire', code: 'CI'},
        {name: 'Croatia', code: 'HR'},
        {name: 'Cuba', code: 'CU'},
        {name: 'Cyprus', code: 'CY'},
        {name: 'Czech Republic', code: 'CZ'},
        {name: 'Denmark', code: 'DK'},
        {name: 'Djibouti', code: 'DJ'},
        {name: 'Dominica', code: 'DM'},
        {name: 'Dominican Republic', code: 'DO'},
        {name: 'Ecuador', code: 'EC'},
        {name: 'Egypt', code: 'EG'},
        {name: 'El Salvador', code: 'SV'},
        {name: 'Equatorial Guinea', code: 'GQ'},
        {name: 'Eritrea', code: 'ER'},
        {name: 'Estonia', code: 'EE'},
        {name: 'Ethiopia', code: 'ET'},
        {name: 'Falkland Islands (Malvinas)', code: 'FK'},
        {name: 'Faroe Islands', code: 'FO'},
        {name: 'Fiji', code: 'FJ'},
        {name: 'Finland', code: 'FI'},
        {name: 'France', code: 'FR'},
        {name: 'French Guiana', code: 'GF'},
        {name: 'French Polynesia', code: 'PF'},
        {name: 'French Southern Territories', code: 'TF'},
        {name: 'Gabon', code: 'GA'},
        {name: 'Gambia', code: 'GM'},
        {name: 'Georgia', code: 'GE'},
        {name: 'Germany', code: 'DE'},
        {name: 'Ghana', code: 'GH'},
        {name: 'Gibraltar', code: 'GI'},
        {name: 'Greece', code: 'GR'},
        {name: 'Greenland', code: 'GL'},
        {name: 'Grenada', code: 'GD'},
        {name: 'Guadeloupe', code: 'GP'},
        {name: 'Guam', code: 'GU'},
        {name: 'Guatemala', code: 'GT'},
        {name: 'Guernsey', code: 'GG'},
        {name: 'Guinea', code: 'GN'},
        {name: 'Guinea-Bissau', code: 'GW'},
        {name: 'Guyana', code: 'GY'},
        {name: 'Haiti', code: 'HT'},
        {name: 'Heard Island and Mcdonald Islands', code: 'HM'},
        {name: 'Holy See (Vatican City State)', code: 'VA'},
        {name: 'Honduras', code: 'HN'},
        {name: 'Hong Kong', code: 'HK'},
        {name: 'Hungary', code: 'HU'},
        {name: 'Iceland', code: 'IS'},
        {name: 'India', code: 'IN'},
        {name: 'Indonesia', code: 'ID'},
        {name: 'Iran, Islamic Republic Of', code: 'IR'},
        {name: 'Iraq', code: 'IQ'},
        {name: 'Ireland', code: 'IE'},
        {name: 'Isle of Man', code: 'IM'},
        {name: 'Israel', code: 'IL'},
        {name: 'Italy', code: 'IT'},
        {name: 'Jamaica', code: 'JM'},
        {name: 'Japan', code: 'JP'},
        {name: 'Jersey', code: 'JE'},
        {name: 'Jordan', code: 'JO'},
        {name: 'Kazakhstan', code: 'KZ'},
        {name: 'Kenya', code: 'KE'},
        {name: 'Kiribati', code: 'KI'},
        {name: 'Korea, Democratic People\'S Republic of', code: 'KP'},
        {name: 'Korea, Republic of', code: 'KR'},
        {name: 'Kuwait', code: 'KW'},
        {name: 'Kyrgyzstan', code: 'KG'},
        {name: 'Lao People\'S Democratic Republic', code: 'LA'},
        {name: 'Latvia', code: 'LV'},
        {name: 'Lebanon', code: 'LB'},
        {name: 'Lesotho', code: 'LS'},
        {name: 'Liberia', code: 'LR'},
        {name: 'Libyan Arab Jamahiriya', code: 'LY'},
        {name: 'Liechtenstein', code: 'LI'},
        {name: 'Lithuania', code: 'LT'},
        {name: 'Luxembourg', code: 'LU'},
        {name: 'Macao', code: 'MO'},
        {name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK'},
        {name: 'Madagascar', code: 'MG'},
        {name: 'Malawi', code: 'MW'},
        {name: 'Malaysia', code: 'MY'},
        {name: 'Maldives', code: 'MV'},
        {name: 'Mali', code: 'ML'},
        {name: 'Malta', code: 'MT'},
        {name: 'Marshall Islands', code: 'MH'},
        {name: 'Martinique', code: 'MQ'},
        {name: 'Mauritania', code: 'MR'},
        {name: 'Mauritius', code: 'MU'},
        {name: 'Mayotte', code: 'YT'},
        {name: 'Mexico', code: 'MX'},
        {name: 'Micronesia, Federated States of', code: 'FM'},
        {name: 'Moldova, Republic of', code: 'MD'},
        {name: 'Monaco', code: 'MC'},
        {name: 'Mongolia', code: 'MN'},
        {name: 'Montserrat', code: 'MS'},
        {name: 'Morocco', code: 'MA'},
        {name: 'Mozambique', code: 'MZ'},
        {name: 'Myanmar', code: 'MM'},
        {name: 'Namibia', code: 'NA'},
        {name: 'Nauru', code: 'NR'},
        {name: 'Nepal', code: 'NP'},
        {name: 'Netherlands', code: 'NL'},
        {name: 'Netherlands Antilles', code: 'AN'},
        {name: 'New Caledonia', code: 'NC'},
        {name: 'New Zealand', code: 'NZ'},
        {name: 'Nicaragua', code: 'NI'},
        {name: 'Niger', code: 'NE'},
        {name: 'Nigeria', code: 'NG'},
        {name: 'Niue', code: 'NU'},
        {name: 'Norfolk Island', code: 'NF'},
        {name: 'Northern Mariana Islands', code: 'MP'},
        {name: 'Norway', code: 'NO'},
        {name: 'Oman', code: 'OM'},
        {name: 'Pakistan', code: 'PK'},
        {name: 'Palau', code: 'PW'},
        {name: 'Palestinian Territory, Occupied', code: 'PS'},
        {name: 'Panama', code: 'PA'},
        {name: 'Papua New Guinea', code: 'PG'},
        {name: 'Paraguay', code: 'PY'},
        {name: 'Peru', code: 'PE'},
        {name: 'Philippines', code: 'PH'},
        {name: 'Pitcairn', code: 'PN'},
        {name: 'Poland', code: 'PL'},
        {name: 'Portugal', code: 'PT'},
        {name: 'Puerto Rico', code: 'PR'},
        {name: 'Qatar', code: 'QA'},
        {name: 'Reunion', code: 'RE'},
        {name: 'Romania', code: 'RO'},
        {name: 'Russian Federation', code: 'RU'},
        {name: 'RWANDA', code: 'RW'},
        {name: 'Saint Helena', code: 'SH'},
        {name: 'Saint Kitts and Nevis', code: 'KN'},
        {name: 'Saint Lucia', code: 'LC'},
        {name: 'Saint Pierre and Miquelon', code: 'PM'},
        {name: 'Saint Vincent and the Grenadines', code: 'VC'},
        {name: 'Samoa', code: 'WS'},
        {name: 'San Marino', code: 'SM'},
        {name: 'Sao Tome and Principe', code: 'ST'},
        {name: 'Saudi Arabia', code: 'SA'},
        {name: 'Senegal', code: 'SN'},
        {name: 'Serbia and Montenegro', code: 'CS'},
        {name: 'Seychelles', code: 'SC'},
        {name: 'Sierra Leone', code: 'SL'},
        {name: 'Singapore', code: 'SG'},
        {name: 'Slovakia', code: 'SK'},
        {name: 'Slovenia', code: 'SI'},
        {name: 'Solomon Islands', code: 'SB'},
        {name: 'Somalia', code: 'SO'},
        {name: 'South Africa', code: 'ZA'},
        {name: 'South Georgia and the South Sandwich Islands', code: 'GS'},
        {name: 'Spain', code: 'ES'},
        {name: 'Sri Lanka', code: 'LK'},
        {name: 'Sudan', code: 'SD'},
        {name: 'Suriname', code: 'SR'},
        {name: 'Svalbard and Jan Mayen', code: 'SJ'},
        {name: 'Swaziland', code: 'SZ'},
        {name: 'Sweden', code: 'SE'},
        {name: 'Switzerland', code: 'CH'},
        {name: 'Syrian Arab Republic', code: 'SY'},
        {name: 'Taiwan, Province of China', code: 'TW'},
        {name: 'Tajikistan', code: 'TJ'},
        {name: 'Tanzania, United Republic of', code: 'TZ'},
        {name: 'Thailand', code: 'TH'},
        {name: 'Timor-Leste', code: 'TL'},
        {name: 'Togo', code: 'TG'},
        {name: 'Tokelau', code: 'TK'},
        {name: 'Tonga', code: 'TO'},
        {name: 'Trinidad and Tobago', code: 'TT'},
        {name: 'Tunisia', code: 'TN'},
        {name: 'Turkey', code: 'TR'},
        {name: 'Turkmenistan', code: 'TM'},
        {name: 'Turks and Caicos Islands', code: 'TC'},
        {name: 'Tuvalu', code: 'TV'},
        {name: 'Uganda', code: 'UG'},
        {name: 'Ukraine', code: 'UA'},
        {name: 'United Arab Emirates', code: 'AE'},
        {name: 'United Kingdom', code: 'GB'},
        {name: 'United States', code: 'US'},
        {name: 'United States Minor Outlying Islands', code: 'UM'},
        {name: 'Uruguay', code: 'UY'},
        {name: 'Uzbekistan', code: 'UZ'},
        {name: 'Vanuatu', code: 'VU'},
        {name: 'Venezuela', code: 'VE'},
        {name: 'Viet Nam', code: 'VN'},
        {name: 'Virgin Islands, British', code: 'VG'},
        {name: 'Virgin Islands, U.S.', code: 'VI'},
        {name: 'Wallis and Futuna', code: 'WF'},
        {name: 'Western Sahara', code: 'EH'},
        {name: 'Yemen', code: 'YE'},
        {name: 'Zambia', code: 'ZM'},
        {name: 'Zimbabwe', code: 'ZW'}
    ];

    const residencystatusOptions = [
        { value: "Student visa", label: "Student visa"},
        { value: "Work permit", label: "Work permit"},
        { value: "Citizen", label: "Citizen"},
        { value: "Just Visiting", label: "Just Visiting"},
        { value: "Other", label: "Other"},
    ];

    const relocateOptions = [
        { value: "Yes", label: "Yes" },
        { value: "No", label: "No" },
    ];

    const ethnicOriginOptions = [
        { value: "Afghan", label: "Afghan"},
        { value: "African American", label: "African American"},
        { value: "Arab", label: "Arab"},
        { value: "Asian", label: "Asian"},
        { value: "Bangladeshi", label: "Bangladeshi"},
        { value: "Berber", label: "Berber"},
        { value: "Caribbean", label: "Caribbean"},
        { value: "Central African", label: "Central AfricanBerber"},
        { value: "East African", label: "East African"},
        { value: "Egyptian", label: "Egyptian"},
        { value: "European", label: "European"},
        { value: "Far East Asian", label: "Far East Asian"},
        { value: "Indian", label: "Indian"},
        { value: "Indonesian", label: "Indonesian"},
        { value: "Iraqi", label: "Iraqi"},
        { value: "Kurdish", label: "Kurdish"},
        { value: "Latino", label: "Latino"},
        { value: "Malay", label: "Malay"},
        { value: "Mixed Heritage", label: "Mixed Heritage"},
        { value: "Nigerian", label: "Nigerian"},
        { value: "North African", label: "North African"},
        { value: "North American", label: "North American"},
        { value: "Other", label: "Other"},
        { value: "Pakistani", label: "Pakistani"},
        { value: "Persian", label: "Persian"},
        { value: "Somali", label: "Somali"},
        { value: "South African", label: "South African"},
        { value: "Sudanese", label: "Sudanese"},
        { value: "Turkish", label: "Turkish"},
        { value: "West African", label: "West African"},
    ];


    // Sort the ethnicOriginOptions array alphabetically by label
    ethnicOriginOptions.sort((a, b) => {
        const labelA = a.label.toUpperCase();
        const labelB = b.label.toUpperCase();
        if (labelA < labelB) {
            return -1;
        }
        if (labelA > labelB) {
            return 1;
        }
        return 0;
    });


    const heightOptions = [
        { value: '122cm - 4\'', label: '122cm - 4\'', cm: 122 },
        { value: '124cm - 4\' 1"', label: '124cm - 4\' 1"', cm: 124 },
        { value: '127cm - 4\' 2"', label: '127cm - 4\' 2"', cm: 127 },
        { value: '130cm - 4\' 3"', label: '130cm - 4\' 3"', cm: 130 },
        { value: '132cm - 4\' 4"', label: '132cm - 4\' 4"', cm: 132 },
        { value: '135cm - 4\' 5"', label: '135cm - 4\' 5"', cm: 135 },
        { value: '137cm - 4\' 6"', label: '137cm - 4\' 6"', cm: 137 },
        { value: '140cm - 4\' 7"', label: '140cm - 4\' 7"', cm: 140 },
        { value: '142cm - 4\' 8"', label: '142cm - 4\' 8"', cm: 142 },
        { value: '145cm - 4\' 9"', label: '145cm - 4\' 9"', cm: 145 },
        { value: '147cm - 4\' 10"', label: '147cm - 4\' 10"', cm: 147 },
        { value: '150cm - 4\' 11"', label: '150cm - 4\' 11"', cm: 150 },

        { value: '152cm - 5\'', label: '152cm - 5\'', cm: 152 },
        { value: '155cm - 5\' 1"', label: '155cm - 5\' 1"', cm: 155 },
        { value: '157cm - 5\' 2"', label: '157cm - 5\' 2"', cm: 157 },
        { value: '160cm - 5\' 3"', label: '160cm - 5\' 3"', cm: 160 },
        { value: '163cm - 5\' 4"', label: '163cm - 5\' 4"', cm: 163 },
        { value: '165cm - 5\' 5"', label: '165cm - 5\' 5"', cm: 165 },
        { value: '168cm - 5\' 6"', label: '168cm - 5\' 6"', cm: 168 },
        { value: '170cm - 5\' 7"', label: '170cm - 5\' 7"', cm: 170 },
        { value: '173cm - 5\' 8"', label: '173cm - 5\' 8"', cm: 173 },
        { value: '175cm - 5\' 9"', label: '175cm - 5\' 9"', cm: 175 },
        { value: '178cm - 5\' 10"', label: '178cm - 5\' 10"', cm: 178 },
        { value: '180cm - 5\' 11"', label: '180cm - 5\' 11"', cm: 180 },

        { value: '183cm - 6\'', label: '183cm - 6\'', cm: 183 },
        { value: '185cm - 6\' 1"', label: '185cm - 6\' 1"', cm: 185 },
        { value: '188cm - 6\' 2"', label: '188cm - 6\' 2"', cm: 188 },
        { value: '191cm - 6\' 3"', label: '191cm - 6\' 3"', cm: 191 },
        { value: '193cm - 6\' 4"', label: '193cm - 6\' 4"', cm: 193 },
        { value: '196cm - 6\' 5"', label: '196cm - 6\' 5"', cm: 196 },
        { value: '198cm - 6\' 6"', label: '198cm - 6\' 6"', cm: 198 },
        { value: '201cm - 6\' 7"', label: '201cm - 6\' 7"', cm: 201 },
        { value: '203cm - 6\' 8"', label: '203cm - 6\' 8"', cm: 203 },
        { value: '206cm - 6\' 9"', label: '206cm - 6\' 9"', cm: 206 },
        { value: '208cm - 6\' 10"', label: '208cm - 6\' 10"', cm: 208 },
        { value: '211cm - 6\' 11"', label: '211cm - 6\' 11"', cm: 211 },

        { value: '213cm - 7\'', label: '213cm - 7\'', cm: 213 },
        { value: '216cm - 7\' 1"', label: '216cm - 7\' 1"', cm: 216 },
        { value: '218cm - 7\' 2"', label: '218cm - 7\' 2"', cm: 218 },
        { value: '221cm - 7\' 3"', label: '221cm - 7\' 3"', cm: 221 },
        { value: '224cm - 7\' 4"', label: '224cm - 7\' 4"', cm: 224 },
        { value: '226cm - 7\' 5"', label: '226cm - 7\' 5"', cm: 226 },
        { value: '229cm - 7\' 6"', label: '229cm - 7\' 6"', cm: 229 },
        { value: '231cm - 7\' 7"', label: '231cm - 7\' 7"', cm: 231 },
        { value: '234cm - 7\' 8"', label: '234cm - 7\' 8"', cm: 234 },
        { value: '236cm - 7\' 9"', label: '236cm - 7\' 9"', cm: 236 },
    ];


    const maritalStatusOptions = [
        { value: "Single", label: "Single" },
        { value: "Divorced", label: "Divorced" },
        { value: "Widowed", label: "Widowed" },
    ];


    const childrenOptions = [
        { value: "Yes", label: "Yes" },
        { value: "No", label: "No" },
    ];

    // Siblings
    let nums = [];
    let min = 1, max = 20;
    for(let i = min; i <= max; i++){
        nums.push(i);
    }

    let minOffset = 0, maxOffset = 60;
    let thisYear = (new Date()).getFullYear();
    let allYears = [];
    for(let x = 0; x <= maxOffset; x++) {
        allYears.push(thisYear - x)
    }

    const [add, setAdd] = useState(false);

    const toggle = () => {
        setAdd(!add);
    }

    // Religious
    const regiousHistoryOptions = [
        { value: "From birth", label: "From birth" },
        { value: "Revert", label: "Revert" },
        { value: "Other", label: "Other" },
    ];

    const readQuranOptions = [
        { value: "Yes, I have completed", label: "Yes, I have completed" },
        { value: "Yes, but I’m still learning", label: "Yes, but I’m still learning" },
        { value: "Still learning Arabic", label: "Still learning Arabic" },
        { value: "No, I can’t read ", label: "No, I can’t read " },
    ];

    const sectOptions = [
        { value: "Sunni", label: "Sunni" },
        { value: "Shia", label: "Shia" },
        { value: "Ahmadi", label: "Ahmadi" },
        { value: "Ibadi", label: "Ibadi" },
        { value: "Ismaili", label: "Ismaili" },
        { value: "Other", label: "Other" },
    ];

    const prayerFrequencyOptions = [
        { value: "Never Missed", label: "Never Missed" },
        { value: "Sometimes Miss", label: "Sometimes Miss" },
        { value: "Occasionally Pray", label: "Occasionally Pray" },
    ];

    const drinkAlchoholOptions = [
        { value: "Yes", label: "Yes, doesn’t matter to me" },
        { value: "No", label: "No" },
        { value: "Occasionally", label: "Occasionally" },
    ];

    const eatHalalOptions = [
        { value: "Yes", label: "Yes, always" },
        { value: "No, doesn’t matter to me", label: "No, doesn’t matter to me" },
        { value: "Sometimes", label: "Sometimes" },
    ];

    const handleDelete = (imageId) => {
        Inertia.delete(`/image/delete/${imageId}`);
    };

    const submit = (e) => {
        e.preventDefault();
        post('profile/store');
    };

    const submitPhotos = (e) => {
        e.preventDefault();
        const formData = new FormData();

        images.forEach((image) => {
            formData.append('images[]', image);
        });

        post('/upload', formData);
    }

    const getRandomGradient = () => {
        const baseColors = ['#6327D8', '#F77171'];
        const gradients = [];

        for (let i = 0; i < 20; i++) {
          const opacity = Math.random() * 0.5 + 0.5; // Adjust the opacity range as needed
          const color1 = `${baseColors[0]}${Math.round(opacity * 255).toString(16)}`;
          const color2 = `${baseColors[1]}${Math.round(opacity * 255).toString(16)}`;

          const gradient = `linear-gradient(45deg, ${color1}, ${color2})`;
          gradients.push(gradient);
        }

        const randomIndex = Math.floor(Math.random() * gradients.length);
        return gradients[randomIndex];
    };


    return (
        <Authenticated
        auth={auth}
        errors={errors}
        header={<h2 className="text-xl font-semibold leading-tight text-gray-800 dark:text-slate-50 ">Profile</h2>}
        btnName="Back"
        svg={<svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2"><path strokeLinecap="round" strokeLinejoin="round" d="M10 19l-7-7m0 0l7-7m-7 7h18" /></svg>}
        href={route('home')}
        >

    <ResponsiveSidenav />

    <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="flex-row sm:flex sm:space-x-2">
            <div className="hidden sm:block sm:w-[240px] h-min">
               <UserSideNav/>
            </div>

            <div className="sm:hidden block sm:w-[250px] h-min">
                <div className="mb-4 rounded-lg">
                    <div className="dark:bg-slate-800/50 shadow-sm rounded-md relative mx-auto flex h-auto w-full flex-col items-center bg-white bg-cover bg-clip-border p-[16px] dark:text-slate-50 dark:shadow-none">
                        <div
                            className="relative flex justify-center w-full h-16 mt-1 bg-cover rounded-xl"
                            style={{ backgroundImage: getRandomGradient() }}
                        >
                            <div className="absolute -bottom-8 flex h-[75px] w-[75px] items-center justify-center rounded-full border-[3px] border-white bg-white/20 p-1 backdrop-blur">
                                <Avatar src={auth.user.profile_image} className="h-[70px] w-[70px] border-none" />
                                
                                <label className="absolute right-0 z-50 flex items-center justify-center p-1 text-sm text-center rounded-full shadow cursor-pointer -bottom-2 hover:bg-slate-200 hover:border-slate-500 hover:text-slate-900 dark:hover:bg-slate-900 dark:hover:text-slate-50 dark:bg-slate-900/20 text-slate-800 dark:text-slate-50 h-7 w-7 backdrop-blur">
                                    <input ref={photoRef} type="file" value={values.photo} className="absolute inset-0 w-full h-full opacity-0 cursor-pointer" onChange={handleProfileImageChange} />
                                    <p className="flex items-center justify-center pl-1 pr-1 font-semibold text-slate-800 dark:text-white">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6.827 6.175A2.31 2.31 0 0 1 5.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 0 0-1.134-.175 2.31 2.31 0 0 1-1.64-1.055l-.822-1.316a2.192 2.192 0 0 0-1.736-1.039 48.774 48.774 0 0 0-5.232 0 2.192 2.192 0 0 0-1.736 1.039l-.821 1.316Z" />
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 12.75a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM18.75 10.5h.008v.008h-.008V10.5Z" />
                                        </svg>
                                    </p>
                                </label>
                            </div>
                        </div>
                        <div className="flex flex-col items-center justify-center mt-10">
                            <h4 className="text-xl font-bold text-center text-slate-800 dark:text-slate-200">{user.username}, {user.age}</h4>
                            <p className="text-base font-medium leading-6 text-center text-slate-600 dark:text-slate-300"> {user.ethnic_origin} From {user.country}</p>
                        </div>

                        <div className="flex gap-2 mt-4 mb-3">
                            <Link as='a' className='flex items-center justify-center px-4 py-2 text-sm font-semibold text-purple-500 border border-purple-500 rounded-md shadow-sm bg-tranparent hover:bg-purple-600 hover:text-white dark:hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500' href="/public-profile">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                </svg>
                                <span className='ml-1'>View As</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="p-4 mt-2 rounded-md shadow-sm sm:mt-0 sm:w-full md:w-2/3 bg-gray-50 dark:bg-slate-800 sm:p-10">
                {user.verification_image === null && (
                    <div className="p-4 mb-4 border border-red-500 rounded-md shadow-sm bg-red-50/50 dark:bg-slate-800">
                        <h2 className="mb-2 text-xl font-bold text-slate-900 dark:text-slate-50">Profile Verification Required</h2>
                        <div className="flex items-start space-x-2 ">
                            <div className="flex-none p-5 text-lg font-bold text-red-400 border border-red-400 rounded-lg shadow-md dark:text-red-400">
                                <svg xmlns="http://www.w3.org/2000/svg" className="text-red-400 h-7 w-7" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clipRule="evenodd" />
                                </svg>
                            </div>
                            <div className="relative flex-auto min-w-0">
                                <div className="pt-0">
                                    <p className="text-sm font-semibold leading-5 tracking-wide text-gray-600 dark:text-gray-400">
                                        Dear {auth.user.username}, your profile is currently pending verification, which may take up to 48 hours. To complete the process, please <Link href={route('verifyAge')} className="text-sm font-semibold text-red-500 ease-in-out dark:text-red-400 hover:border-b-2 hover:border-red-600 hover:text-red-600">Verify your account, </Link>
                                        {auth.user.email_verified_at === null && ( <> and <Link href={route('verification.notice')} className="text-sm font-semibold text-red-500 ease-in-out dark:text-red-400 hover:border-b-2 hover:border-red-600 hover:text-red-600">Verify your email</Link>. </>)}
                                        You will receive a confirmation email once your profile has been approved.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    )
                }

                <div className="">
                    <div className="py-8">
                        <div className="flex items-center justify-between">
                            <div>
                                <h2 className="text-xl font-semibold text-slate-900 dark:text-slate-50">Photos</h2>
                                <p className="mb-2 text-slate-500">Upload or remove photos</p>
                            </div>
                        </div>
                        {message && (
                            <div className="font-semibold text-red-400 text-md">
                                {message}
                            </div>
                         )}
                        <ValidationErrors errors={errors} />

                        <div className="mt-4">
                            <form className="grid grid-cols-2 gap-12 md:grid-cols-2 lg:grid-cols-4">
                                <div className="relative rounded-lg shadow-sm bg-slate-100 w-[150px] h-[150px]">
                                    <div className="flex justify-center items-center absolute z-10 right-0 left-[8rem] -top-[0.6rem] text-md border bg-slate-100 shadow-md cursor-pointer hover:bg-indigo-500 hover:border-gray-50 border-indigo-100 text-indigo-500 hover:text-white text-center h-7 w-7 rounded-full">
                                        <input
                                            ref={photoRef}
                                            type="file"
                                            value={values.photo}
                                            className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                                            onChange={handleProfileImageChange}
                                        />
                                        <CameraIcon className="w-5 h-5" />
                                    </div>
                                    <div className="w-[150px] h-[150px] rounded-lg">
                                        <img
                                            src={auth.user.profile_image}
                                            layout="fill"
                                            className="w-[150px] h-[150px] p-2 rounded-xl object-cover aspect-square"
                                            alt="Profile"
                                        />
                                    </div>
                                </div>

                                {userImages.map(({image, id}) => (
                                    <div key={id}>
                                        <div className="relative rounded-lg shadow-sm bg-slate-100 w-[150px] h-[150px]">
                                            <div onClick={() => handleDelete(id)} className="flex justify-center items-baseline absolute z-10 right-0 left-[8rem] -top-[0.6rem] text-md border bg-red-400 shadow-md cursor-pointer hover:bg-red-500 hover:border-gray-50 border-red-400 text-slate-50 text-center h-7 w-7 rounded-full">x</div>
                                            <div className="w-[150px] h-[150px] rounded-lg">
                                                <img src={image} layout="fill" className="w-[150px] h-[150px] p-2 rounded-xl object-cover aspect-square" />
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            {showFileInput &&
                                <div className="flex items-center justify-center">
                                    <div className="rounded-lg shadow-md bg-gray-50">
                                        <div className="p-2">
                                            <div className="flex items-center justify-center w-full">
                                                <label className="flex flex-col w-[130px] h-[130px] rounded-lg border-4 border-slate-100 border-dashed hover:bg-gray-100 hover:border-gray-300">
                                                    <div className="flex flex-col items-center justify-center pt-7">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 text-gray-400 group-hover:text-gray-600"
                                                            fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                                                        </svg>
                                                        <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                                                            Choose a photo
                                                        </p>
                                                    </div>
                                                    {/* <input ref={photoRef} type="file" value={values.photo} className="opacity-0" onChange={handleImageChange} /> */}
                                                    <input ref={photoRef} type="file" name="images" id="images" accept="image/*" value={values.images} className="opacity-0" onChange={handleImageChange} disabled={!canUploadMoreImages} />
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }
                            </form>
                        </div>
                    </div>
                    {/* Multiple image end */}

                    <form id="profile" className="dark:bg-slate-800" onSubmit={submit}>
                        <div className="dark:bg-gray-800">
                            <div id="filterSection" className="relative block w-full px-2 py-5 mt-2 md:py-10 lg:px-2 md:px-6 bg-gray-50 dark:bg-slate-800">
                                {/* PERSONAL information start */}
                                <div>
                                    <div className="flex items-start pb-2 space-x-6 border-b border-gray-300 dark:border-gray-700 dark:bg-gray-800">
                                        <p className="flex-none text-lg font-semibold text-gray-800 dark:text-gray-100">Personal information</p>
                                        <div className="relative flex-auto min-w-0">
                                            <div className="p-2 text-gray-600 cursor-pointer dark:text-gray-400">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16}>
                                                    <path className="heroicon-ui" d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9a1 1 0 0 1 1 1v4a1 1 0 0 1-2 0v-4a1 1 0 0 1 1-1zm0-4a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" fill="currentColor" />
                                                </svg>
                                            </div>
                                            <dl className="flex flex-wrap mt-2 text-sm font-medium leading-6">
                                                <div className="absolute top-0 right-0 flex items-center space-x-1">
                                                    <Button className="inline-flex items-center justify-center focus:ring-0 dark:focus:ring-0">Save <span className="hidden ml-1 sm:flex">changes</span></Button>
                                                </div>
                                            </dl>
                                        </div>
                                    </div>

                                    {auth.user.is_verified === 1 ? (
                                        <>
                                            <div className="flex items-center justify-center space-x-2">
                                                <div className="w-1/2 mt-4 space-y-1">
                                                    <Label forInput="username" value="User Name" />
                                                    <Input
                                                        type="text"
                                                        name="username"
                                                        value={data.username}
                                                        className="block w-full mt-1"
                                                        autoComplete="false"
                                                        handleChange={onHandleChange}
                                                        placeholder="jhon09"
                                                    />
                                                </div>

                                                <div className="w-1/2 mt-4 space-y-1">
                                                    <Label forInput="email" value="Email" />
                                                    <Input
                                                        type="email"
                                                        name="email"
                                                        value={data.email}
                                                        className="block w-full"
                                                        autoComplete="email"
                                                        handleChange={onHandleChange}
                                                        placeholder="jhon@hotmail.co.uk"
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex items-center justify-center space-x-2">
                                                <div className="w-1/2 mt-4 space-y-1">
                                                    <Label forInput="date_of_birth" value="Date of Birth" />
                                                    <Input
                                                        type="date"
                                                        name="date_of_birth"
                                                        value={data.date_of_birth}
                                                        className="block w-full"
                                                        handleChange={onHandleChange}
                                                    />
                                                </div>
                                                <div className="w-1/2 mt-4 space-y-1">
                                                    <Label forInput="age" value="Age" />
                                                    <Input
                                                        type="text"
                                                        name="age"
                                                        value={user.age}
                                                        className="block w-full"
                                                        handleChange={onHandleChange}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="flex items-center justify-center space-x-2">
                                                <div className="w-1/2 mt-4 space-y-1">
                                                    <Label forInput="username" value="User Name" />
                                                    <Input
                                                        type="text"
                                                        name="username"
                                                        value={data.username}
                                                        className="block w-full mt-1"
                                                        autoComplete="false"
                                                        handleChange={onHandleChange}
                                                        placeholder="jhon09"
                                                        disabled={true}
                                                    />
                                                </div>

                                                <div className="w-1/2 mt-4 space-y-1">
                                                    <Label forInput="email" value="Email" />
                                                    <Input
                                                        type="email"
                                                        name="email"
                                                        value={data.email}
                                                        className="block w-full"
                                                        autoComplete="email"
                                                        handleChange={onHandleChange}
                                                        disabled={true}
                                                        placeholder="jhon@hotmail.co.uk"
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex items-center justify-center space-x-2">
                                                <div className="w-1/2 mt-4 space-y-1">
                                                    <Label forInput="date_of_birth" value="Date of Birth" />
                                                    <Input
                                                        type="date"
                                                        name="date_of_birth"
                                                        value={auth.user.date_of_birth}
                                                        className="block w-full mt-1"
                                                        handleChange={onHandleChange}
                                                        disabled={true}
                                                    />
                                                </div>
                                                <div className="w-1/2 mt-4 space-y-1">
                                                    <Label forInput="age" value="Age" />
                                                    <Input
                                                        type="text"
                                                        name="age"
                                                        value={auth.user.age}
                                                        className="block w-full mt-1"
                                                        handleChange={onHandleChange}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    <div className="mt-4">
                                        <Label forInput="height" value="Height" />
                                        <InputSelect
                                            value={data.height}
                                            onChange={onHandleChange}
                                            options={heightOptions}
                                            className={`block w-full sm:text-sm text-slate-500 dark:text-slate-50`}
                                            placeholder='Select your height'
                                            name='height'
                                        />
                                    </div>

                                    <div className="mt-4">
                                        <Label forInput="marital_status" value="Marital Status" />
                                        <InputSelect
                                            value={data.marital_status}
                                            onChange={onHandleChange}
                                            options={maritalStatusOptions}
                                            className={`block w-full sm:text-sm text-slate-500 dark:text-slate-50`}
                                            placeholder='Select your Marital Status'
                                            name='marital_status'
                                        />
                                    </div>

                                    <div className="mt-4">
                                        <Label forInput="have_children" value="Have children" />
                                        <InputSelect
                                            value={data.have_children}
                                            onChange={onHandleChange}
                                            options={childrenOptions}
                                            className={`block w-full sm:text-sm text-slate-500 dark:text-slate-50`}
                                            placeholder='Do you have any children?'
                                            name='have_children'
                                        />
                                    </div>

                                    <div className="mt-4">
                                        <Label forInput="siblings" value="Number of siblings" />
                                        <select
                                            className={
                                                `border-gray-300 py-3 px-4 rounded-xl shadow-sm text-slate-500 dark:text-slate-200`
                                            }
                                            onChange={onHandleChange}
                                            name="siblings"
                                            value={data.siblings}
                                        >
                                            <option value="" className="text-slate-500 dark:text-slate-200">Select how many</option>
                                            <option value="0" className="text-slate-500 dark:text-slate-200">I am only child</option>
                                            {
                                                nums.map((num) => <option className="text-slate-500 dark:text-slate-200" value={num} key={num}>{num}</option>)
                                            }
                                        </select>
                                    </div>

                                    <div className="mt-4">
                                        <Label forInput="ethnic-origin" value="Ethnic origin" />
                                        <InputSelect
                                            value={data.ethnic_origin}
                                            onChange={onHandleChange}
                                            options={ethnicOriginOptions}
                                            className={`block w-full sm:text-sm text-slate-500 dark:text-slate-50`}
                                            placeholder='Select your ethnic origin'
                                            name='ethnic_origin'
                                        />
                                    </div>

                                    <div className="mt-4">
                                        <Label forInput="relocate" value="Move abroad" />

                                        <InputSelect
                                            value={data.relocate}
                                            onChange={onHandleChange}
                                            options={relocateOptions}
                                            className={`block w-full sm:text-sm text-slate-500 dark:text-slate-50`}
                                            placeholder='Are you willing to relocate?'
                                            name='relocate'
                                        />
                                    </div>

                                </div>
                                {/* PERSONAL information end */}

                                {/* LOCATION information START */}

                                <div>
                                    <div className="mt-4">
                                        <Label forInput="country" value="Country" />
                                        <select
                                            className={
                                                `border-gray-300 py-3 px-4 rounded-xl shadow-sm text-slate-500 dark:text-slate-200`
                                            }
                                            name="country"
                                            onChange={onHandleChange}
                                            value={data.country}
                                        >
                                            <option value={''}>Select your country</option>
                                            <option value={data.country} selected>{data.country}</option>
                                            {sortedcs.map((country) => {
                                                return (
                                                    <option key={country.code} value={country.name}>
                                                        {country.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>

                                    <div className="mt-4">
                                        <Label forInput="recidency_status" value="Recidency status" />
                                        <InputSelect
                                            value={data.recidency_status}
                                            onChange={onHandleChange}
                                            options={residencystatusOptions}
                                            className={`block w-full sm:text-sm text-slate-500 dark:text-slate-50`}
                                            placeholder='Select your residency status'
                                            name='recidency_status'
                                        />
                                    </div>

                                    <div className="flex mt-4">
                                        <div className="w-1/2 pr-2">
                                            <Label forInput="city" value="City" />
                                            <Input
                                                type="text"
                                                name="city"
                                                value={data.city}
                                                className="block w-full mt-1"
                                                handleChange={onHandleChange}
                                                placeholder="eg: London"
                                            />
                                        </div>

                                        <div className="w-1/2">
                                            <Label forInput="area" value="Area" />
                                            <Input
                                                type="text"
                                                name="area"
                                                value={data.area}
                                                className="block w-full mt-1"
                                                handleChange={onHandleChange}
                                                placeholder="Enter your area"
                                            />
                                        </div>
                                    </div>

                                    <h3 className="px-2 mt-4 text-base font-medium border-b border-gray-200 text-slate-500 dark:text-slate-200">Back home address</h3>

                                    <div className="mt-4">
                                        <Label forInput="backhomecountry" value="Country" />
                                        <select
                                            className={
                                                `border-gray-300 py-3 px-4 rounded-xl shadow-sm text-slate-500 dark:text-slate-200`
                                            }
                                            name="back_home_country"
                                            onChange={onHandleChange}
                                            value={data.back_home_country}
                                        >
                                            <option value={''}>Select country</option>
                                            {backhomecountries.map((backhomecountry) => {
                                                return (
                                                    <option key={backhomecountry.code} value={backhomecountry.name}>
                                                        {backhomecountry.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>

                                    <div className="flex mt-4">
                                        <div className="w-1/2 pr-2">
                                            <Label forInput="back_home_city" value="City" />
                                            <Input
                                                type="text"
                                                name="back_home_city"
                                                value={data.back_home_city}
                                                className="block w-full mt-1"
                                                handleChange={onHandleChange}
                                                placeholder="eg: London"
                                            />
                                        </div>

                                        <div className="w-1/2">
                                            <Label forInput="back_home_area" value="Area" />
                                            <Input
                                                type="text"
                                                name="back_home_area"
                                                value={data.back_home_area}
                                                className="block w-full mt-1"
                                                handleChange={onHandleChange}
                                                placeholder="Enter your area"
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* location information ends */}

                                {/* EDUCATION information START */}
                                <div>
                                    <div className="flex items-start pt-4 pb-2 space-x-6 border-b border-gray-300 dark:border-gray-700 dark:bg-gray-800">
                                        <p className="flex-none text-lg font-semibold text-gray-800 dark:text-gray-100">Career | Education</p>
                                        <div className="relative flex-auto min-w-0">
                                            <div className="p-2 text-gray-600 cursor-pointer dark:text-gray-400">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16}>
                                                    <path className="heroicon-ui" d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9a1 1 0 0 1 1 1v4a1 1 0 0 1-2 0v-4a1 1 0 0 1 1-1zm0-4a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" fill="currentColor" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex mt-4">
                                        <div className="w-2/3 pr-2">
                                            <Label forInput="university" value="University/College" />
                                            <Input
                                                type="text"
                                                name="university"
                                                value={data.university}
                                                className="block w-full mt-1"
                                                handleChange={onHandleChange}
                                                placeholder="Enter university name"
                                            />
                                        </div>

                                        <div className="w-1/2">
                                            <Label forInput="course_name" value="Course Name" />
                                            <Input
                                                type="text"
                                                name="course_name"
                                                value={data.course_name}
                                                className="block w-full mt-1"
                                                handleChange={onHandleChange}
                                                placeholder="Enter course name"
                                            />
                                        </div>
                                    </div>
                                    {user.college === '' && (
                                        <p className="flex justify-end pb-0 mb-0 text-sm font-semibold text-purple-600 cursor-pointer item-right dark:text-slate-50" onClick={toggle}>
                                            {add ? "-" : "Add more education"}
                                        </p>
                                    )}

                                    {user.college !== '' || add ? (
                                        <div className="flex mt-2">
                                            <div className="w-2/3 pr-2">
                                                <Label forInput="college" value="University/College" />
                                                <Input
                                                    type="text"
                                                    name="college"
                                                    value={data.college}
                                                    className="block w-full mt-1"
                                                    handleChange={onHandleChange}
                                                    placeholder="Enter college name"
                                                />
                                            </div>

                                            <div className="w-1/2">
                                                <Label forInput="college_course_name" value="Course name" />
                                                <Input
                                                    type="text"
                                                    name="college_course_name"
                                                    value={data.college_course_name}
                                                    className="block w-full mt-1"
                                                    handleChange={onHandleChange}
                                                    placeholder="Enter course name"
                                                />
                                            </div>
                                        </div>
                                    ) : null}

                                    <div className="mt-4">
                                        <Label forInput="current_profession" value="Current profession" />
                                        <Input
                                            type="text"
                                            name="current_profession"
                                            value={data.current_profession}
                                            className="block w-full mt-1"
                                            handleChange={onHandleChange}
                                        />
                                    </div>

                                    <div className="mt-4">
                                        <Label forInput="company_name" value="Company Name" />
                                        <Input
                                            type="text"
                                            name="company_name"
                                            value={data.company_name}
                                            className="block w-full mt-1"
                                            handleChange={onHandleChange}
                                        />
                                        <p className="text-xs font-medium text-slate-500">This will not show on your profile</p>
                                    </div>
                                </div>
                                {/* EDUCATION information END */}

                                {/* Religious information START */}

                                <div className="mt-4">
                                    <div className="flex items-start pb-2 space-x-6 border-b border-gray-300 dark:border-gray-700 dark:bg-gray-800">
                                        <p className="flex-none text-lg font-semibold text-gray-800 dark:text-gray-100">Religious Practice</p>
                                        <div className="relative flex-auto min-w-0">
                                            <div className="p-2 text-gray-600 cursor-pointer dark:text-gray-400">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16}>
                                                    <path className="heroicon-ui" d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9a1 1 0 0 1 1 1v4a1 1 0 0 1-2 0v-4a1 1 0 0 1 1-1zm0-4a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" fill="currentColor" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-4">
                                            <Label forInput="religious_history" value="Religious history" />
                                            <InputSelect
                                                value={data.religious_history}
                                                onChange={onHandleChange}
                                                options={regiousHistoryOptions}
                                                className={`block w-full sm:text-sm text-slate-500 dark:text-slate-50`}
                                                placeholder='Select your religious history'
                                                name='religious_history'
                                            />
                                    </div>

                                    <div className="mt-4">
                                            <Label forInput="prayer_frequency" value="Do you pray" />
                                            <InputSelect
                                                value={data.prayer_frequency}
                                                onChange={onHandleChange}
                                                options={prayerFrequencyOptions}
                                                className={`block w-full sm:text-sm text-slate-500 dark:text-slate-50`}
                                                placeholder='Select your prayer frequency'
                                                name='prayer_frequency'
                                            />
                                    </div>

                                    <div className="mt-4">
                                        <Label forInput="sect" value="Sect" />
                                        <InputSelect
                                            value={data.sect}
                                            onChange={onHandleChange}
                                            options={sectOptions}
                                            className={`block w-full sm:text-sm text-slate-500 dark:text-slate-50`}
                                            placeholder='What sect are you?'
                                            name='sect'
                                            required={true}
                                        />
                                    </div>

                                    <div className="mt-4">
                                        <Label forInput="read_quran" value="Read Quran" />
                                        <InputSelect
                                            value={data.read_quran}
                                            onChange={onHandleChange}
                                            options={readQuranOptions}
                                            className={`block w-full sm:text-sm text-slate-500 dark:text-slate-50`}
                                            placeholder='Can you read Quran?'
                                            required={true}
                                            name='read_quran'
                                        />
                                    </div>

                                    <div className="mt-4">
                                        <Label forInput="eat_halal" value="Eating Halal" />
                                        <InputSelect
                                            value={data.eat_halal}
                                            onChange={onHandleChange}
                                            options={eatHalalOptions}
                                            className={`block w-full sm:text-sm text-slate-500 dark:text-slate-50`}
                                            placeholder='Do you eat halal?'
                                            name='eat_halal'
                                            required={true}
                                        />
                                    </div>

                                    <div className="mt-4">
                                        <Label forInput="drink_alchohol" value="Drink" />
                                        <InputSelect
                                            value={data.drink_alchohol}
                                            onChange={onHandleChange}
                                            options={drinkAlchoholOptions}
                                            className={`block w-full sm:text-sm text-slate-500 dark:text-slate-50`}
                                            placeholder='Do you drink alchohol ?'
                                            name='drink_alchohol'
                                            required={true}
                                        />
                                    </div>

                                    <div className="mt-4">
                                        <Label forInput="bio" value="About you" />
                                        <textarea maxLength="2000" id="about" name="bio"  onChange={onHandleChange} className="py-3 pl-3 mt-1 text-sm text-gray-700 placeholder-gray-500 bg-transparent border border-gray-300 rounded-lg shadow-sm resize-none dark:border-gray-700 focus:outline-none focus:border-indigo-700 dark:text-gray-50" placeholder="Share something nice about you ..." rows={5} defaultValue={data.bio} />
                                        <p className="w-full pt-1 text-xs text-right text-gray-500 dark:text-gray-400">Character Limit: 2000</p>
                                    </div>

                                </div>
                                {/* Religious information END */}
                                <div className="pt-4">
                                    <hr className="w-full my-8 mt-4 bg-gray-200/50 dark:bg-gray-50/50 md:my-10 md:mb-5" />

                                    <div className="w-full px-0 mt-10 md:w-auto md:mt-4 md:absolute md:right-0 md:bottom-0 md:py-10 lg:px-2 md:px-6">
                                        <button className="w-full px-10 py-4 text-base font-medium leading-4 bg-purple-600 rounded-md text-slate-50 hover:text-white hover:bg-purple-700 focus:ring focus:ring-offset-2 focus:ring-gray-800">
                                            Save Changes
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div className="hidden sm:block sm:w-[250px] h-min">
                <div className="mb-4 rounded-lg">
                    <div className="dark:bg-slate-800 shadow-sm rounded-md relative mx-auto flex h-auto w-full flex-col items-center bg-white bg-cover bg-clip-border p-[16px] dark:text-slate-50 dark:shadow-none">
                        <div
                            className="relative flex justify-center w-full h-16 mt-1 bg-cover rounded-xl"
                            style={{ backgroundImage: getRandomGradient() }}
                        >
                            <div className="absolute -bottom-8 flex h-[75px] w-[75px] items-center justify-center rounded-full border-[3px] border-white bg-white/20 p-1 backdrop-blur">
                                <Avatar src={auth.user.profile_image} className="h-[70px] w-[70px] border-none" />
                                
                                <label className="absolute right-0 z-50 flex items-center justify-center p-1 text-sm text-center rounded-full shadow cursor-pointer -bottom-2 hover:bg-slate-200 hover:border-slate-500 hover:text-slate-900 dark:hover:bg-slate-900 dark:hover:text-slate-50 dark:bg-slate-900/20 text-slate-800 dark:text-slate-50 h-7 w-7 backdrop-blur">
                                    <input ref={photoRef} type="file" value={values.photo} className="absolute inset-0 w-full h-full opacity-0 cursor-pointer" onChange={handleProfileImageChange} />
                                    <p className="flex items-center justify-center pl-1 pr-1 font-semibold text-slate-800 dark:text-white">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6.827 6.175A2.31 2.31 0 0 1 5.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 0 0-1.134-.175 2.31 2.31 0 0 1-1.64-1.055l-.822-1.316a2.192 2.192 0 0 0-1.736-1.039 48.774 48.774 0 0 0-5.232 0 2.192 2.192 0 0 0-1.736 1.039l-.821 1.316Z" />
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 12.75a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM18.75 10.5h.008v.008h-.008V10.5Z" />
                                        </svg>
                                    </p>
                                </label>
                            </div>
                        </div>
                        <div className="flex flex-col items-center justify-center mt-16">
                            <h4 className="text-xl font-bold text-center text-slate-800 dark:text-slate-200">{user.username}, {user.age}</h4>
                            <p className="text-base font-medium leading-6 text-center text-slate-600 dark:text-slate-300"> {user.ethnic_origin} From {user.country}</p>
                        </div>

                        <div className="flex gap-2 mt-6 mb-3">
                            <Link as='a' className='flex items-center justify-center px-4 py-2 text-sm font-semibold text-purple-500 border border-purple-500 rounded-md shadow-sm bg-tranparent hover:bg-purple-600 hover:text-white dark:hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500' href="/public-profile">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                </svg>
                                <span className='ml-1'>View As</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </Authenticated>
  )
}

export default Profile
