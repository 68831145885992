import { Link } from '@inertiajs/inertia-react';
import React from 'react';
import Icon from '../../../public/assets/images/favicon.svg';

function Footer() {
    return <>
        <footer className="relative z-10 pt-10 bg-white dark:bg-slate-900">
            <div className="container">
                <div className="flex flex-wrap px-4 pb-5 -mx-4 bg-white footer-widget dark:bg-slate-900">
                    <div className="w-full px-4 sm:w-2/3 lg:w-4/12">
                        <div className="flex-col items-center justify-between gap-4">
                            <Link as="a" href="/" className="">
                                <img src={Icon} alt="shape" />
                            </Link>
                            <p className="p-4 pl-0 text-base text-slate-800 dark:text-white mb-7">
                                Suitable is a trusted muslim match making platform performing all over the world. We are working to connect you with your partner.
                            </p>
                        </div>
                    </div>
                    <div className="w-full px-4 sm:w-1/2 lg:w-2/12">
                        <div className="flex-col items-center justify-between space-y-4 mb-9">
                            <h4 className="text-lg font-semibold text-dark text-slate-800 dark:text-slate-50 ">Resources</h4>
                            <ul className="link">
                                <li>
                                    <a
                                        href="/"
                                        className="inline-block mb-2 text-base leading-loose text-slate-800 dark:text-slate-50 hover:text-primary"
                                        >
                                        Home
                                    </a>
                                </li>
                                
                                <li>
                                    <a
                                        href="/faq"
                                        className="inline-block mb-2 text-base leading-loose text-slate-800 dark:text-slate-50 hover:text-primary"
                                        >
                                        Read Faq
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="/contact-support"
                                        className="inline-block mb-2 text-base leading-loose text-slate-800 dark:text-slate-50 hover:text-primary"
                                        >
                                        Support
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="/privacy-policy"
                                        className="inline-block mb-2 text-base leading-loose text-slate-800 dark:text-slate-50 hover:text-primary"
                                        >
                                        Privacy Policy
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="w-full px-4 sm:w-1/2 lg:w-3/12">
                        <div className="flex-col items-center justify-between w-full space-y-4 mb-9 sm:mb-0">
                            <h4 className="font-semibold ext-lg text-dark text-slate-800 dark:text-slate-50">Company</h4>
                            <ul className="link">
                                <li>
                                    <a
                                        href="/about-us"
                                        className="inline-block mb-2 text-base leading-loose text-slate-800 dark:text-slate-50 hover:text-primary"
                                        >
                                        About Suitable
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="/contact-support"
                                        className="inline-block mb-6 text-base leading-loose text-slate-800 dark:text-slate-50 hover:text-primary"
                                        >
                                        Contact Us
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="/about-us"
                                        className="inline-block mb-2 text-base leading-loose text-slate-800 dark:text-slate-50 hover:text-primary"
                                        >
                                        Success Stories
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="/terms"
                                        className="inline-block mb-2 text-base leading-loose text-slate-800 dark:text-slate-50 hover:text-primary"
                                        >
                                        Terms & Conditions
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    
                    <div className="w-full px-4 sm:w-1/2 lg:w-3/12">
                        <div className="flex-col items-center justify-center w-full mb-10 space-y-4">
                            <h4 className="text-lg font-semibold text-slate-800 dark:text-slate-50">Contact Us</h4>
                            <div className="flex items-center mb-4">
                                <ul className="link">
                                    <li><a href="#"><i className="lni lni-envelope"></i>hello@suitable.one</a></li>
                                    {/* <li><a href="#"><i className="lni lni-facebook"></i>fb.com/suitable</a></li> */}
                                    <li><i className="lni lni-instagram"></i><a href="#">@suitableone</a></li>
                                    <li className="flex">
                                        <svg className='fill-slate-800 dark:fill-slate-50' fill="#1C2033" width="18" height="18" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#clip0_412_113)"><path d="M33.4133 0.0533333C36.9067 0 40.3733 0.0266667 43.84 0C44.0533 4.08 45.52 8.24 48.5067 11.12C51.4933 14.08 55.7067 15.44 59.8133 15.8933V26.64C55.9733 26.5067 52.1067 25.7067 48.6133 24.0533C47.0933 23.36 45.68 22.48 44.2933 21.5733C44.2667 29.36 44.32 37.1467 44.24 44.9067C44.0267 48.64 42.8 52.3467 40.64 55.4133C37.1467 60.5333 31.0933 63.8667 24.88 63.9733C21.0667 64.1867 17.2533 63.1467 14 61.2267C8.61334 58.0533 4.82668 52.24 4.26668 46C4.21334 44.6667 4.18668 43.3333 4.24001 42.0267C4.72001 36.96 7.22668 32.1067 11.12 28.8C15.5467 24.96 21.7333 23.12 27.52 24.2133C27.5733 28.16 27.4133 32.1067 27.4133 36.0533C24.7733 35.2 21.68 35.44 19.36 37.04C17.68 38.1333 16.4 39.8133 15.7333 41.7067C15.1733 43.0667 15.3333 44.56 15.36 46C16 50.3733 20.2133 54.0533 24.6933 53.6533C27.68 53.6267 30.5333 51.8933 32.08 49.36C32.5867 48.48 33.1467 47.5733 33.1733 46.5333C33.44 41.76 33.3333 37.0133 33.36 32.24C33.3867 21.4933 33.3333 10.7733 33.4133 0.0533333Z"/></g><defs><clipPath id="clip0_412_113"><rect width="64" height="64"/></clipPath></defs></svg>
                                        {/* <svg className='w-5 h-5 text-slate-800 dark:text-slate-50' xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fillRule="evenodd" clip-rule="evenodd" viewBox="0 0 449.45 515.38"><path fillRule="nonzero" d="M382.31 103.3c-27.76-18.1-47.79-47.07-54.04-80.82-1.35-7.29-2.1-14.8-2.1-22.48h-88.6l-.15 355.09c-1.48 39.77-34.21 71.68-74.33 71.68-12.47 0-24.21-3.11-34.55-8.56-23.71-12.47-39.94-37.32-39.94-65.91 0-41.07 33.42-74.49 74.48-74.49 7.67 0 15.02 1.27 21.97 3.44V190.8c-7.2-.99-14.51-1.59-21.97-1.59C73.16 189.21 0 262.36 0 352.3c0 55.17 27.56 104 69.63 133.52 26.48 18.61 58.71 29.56 93.46 29.56 89.93 0 163.08-73.16 163.08-163.08V172.23c34.75 24.94 77.33 39.64 123.28 39.64v-88.61c-24.75 0-47.8-7.35-67.14-19.96z"/></svg> */}
                                        <a href="#" className='flex ml-5'>@suitable.one</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <span className="absolute left-0 bottom-0 z-[-1]">
                    <svg
                        width="217"
                        height="229"
                        viewBox="0 0 217 229"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        >
                        <path
                        d="M-64 140.5C-64 62.904 -1.096 1.90666e-05 76.5 1.22829e-05C154.096 5.49924e-06 217 62.904 217 140.5C217 218.096 154.096 281 76.5 281C-1.09598 281 -64 218.096 -64 140.5Z"
                        fill="url(#paint0_linear_1179_5)"
                        />
                        <defs>
                        <linearGradient
                            id="paint0_linear_1179_5"
                            x1="76.5"
                            y1="281"
                            x2="76.5"
                            y2="1.22829e-05"
                            gradientUnits="userSpaceOnUse"
                            >
                            <stop stopColor="#3056D3" stopOpacity="0.08" />
                            <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                        </linearGradient>
                        </defs>
                    </svg>
                </span>
                <span className="absolute top-10 right-10 z-[-1]">
                    <svg
                        width="75"
                        height="75"
                        viewBox="0 0 75 75"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        >
                        <path
                        d="M37.5 -1.63918e-06C58.2107 -2.54447e-06 75 16.7893 75 37.5C75 58.2107 58.2107 75 37.5 75C16.7893 75 -7.33885e-07 58.2107 -1.63918e-06 37.5C-2.54447e-06 16.7893 16.7893 -7.33885e-07 37.5 -1.63918e-06Z"
                        fill="url(#paint0_linear_1179_4)"
                        />
                        <defs>
                        <linearGradient
                            id="paint0_linear_1179_4"
                            x1="-1.63917e-06"
                            y1="37.5"
                            x2="75"
                            y2="37.5"
                            gradientUnits="userSpaceOnUse"
                            >
                            <stop stopColor="#13C296" stopOpacity="0.31" />
                            <stop offset="1" stopColor="#C4C4C4" stopOpacity="0" />
                        </linearGradient>
                        </defs>
                    </svg>
                </span>
            </div>
            <div className="py-8 border-t dark:border-slate-600 footer-copyright">
                <div className="flex items-center justify-center text-slate-800 dark:text-slate-50">
                    <Link href={route('welcome')} >&copy; Suitable</Link>
                        <div className="w-1 h-1 ml-3 rounded-full bg-slate-800 dark:bg-slate-50"></div>
                    <p className="ml-3 text-gray-700 dark:text-slate-50">{(new Date().getFullYear())}</p>
                </div>
            </div>
        </footer>
    </>;
}

export default Footer;
