import { Link, useForm, usePage } from '@inertiajs/inertia-react';
import React, { useRef, useState } from 'react'

import UserIcon from  '../../../../../public/assets/images/user.svg';
import ProfessionIcon from  '../../../../../public/assets/images/profession.svg';
import GlobeIcon from  '../../../../../public/assets/images/globe.svg';
import MosqueIcon from  '../../../../../public/assets/images/mosque.svg';
import RingIcon from  '../../../../../public/assets/images/ring.svg';
import FamilyIcon from  '../../../../../public/assets/images/family.svg';
import Button from '@/Components/Button';
import Authenticated from '@/Layouts/AdminLayouts/Authenticated';
import AccordionItem from '@/Components/AccordionItem';
import { CalendarIcon, ChatAlt2Icon, CheckCircleIcon, CreditCardIcon, CurrencyPoundIcon, ExclamationCircleIcon, EyeIcon, HeartIcon, PaperAirplaneIcon, PhoneIcon, UserRemoveIcon, UsersIcon, XCircleIcon, XIcon } from '@heroicons/react/outline';
import { EyeOffIcon } from '@heroicons/react/solid';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }



function Profile(props) {
    const {user,
            userImages,
            savedusers,
            passedusers,
            interactions,
            viewsReceived,
            savedByOthers,
            passedByOthers,
            invitationsReceived,
            invitationsAccepted,
            invitationsRejected,
            invitationsSent,
            usersSaved,
            usersPassed,
            usersViewed,
            invitedUsers,
            acceptedUsers,
            rejectedUsers,
            invitationsAcceptedFromOthers,
            invitationsRejectedFromOthers,
    } = usePage().props;


    const tabs = [
        { name: 'Profile', id: 'profile-tab', current: true, content: <ProfileTabContent user={user} /> },
        { name: 'Photos', id: 'photos-tab', current: false, content: <PhotosTabContent user={user} userImages={userImages} /> },
        { 
            name: 'Analytics', 
            id: 'analytics-tab', 
            current: false, 
            content: <AnalyticsTabContent 
                        viewsReceived={viewsReceived}
                        savedByOthers={savedByOthers}
                        passedByOthers={passedByOthers}
                        invitationsReceived={invitationsReceived}
                        invitationsAccepted={invitationsAccepted}
                        invitationsRejected={invitationsRejected}
                        invitationsSent={invitationsSent}
                        usersSaved={usersSaved}
                        usersPassed={usersPassed}
                        usersViewed={usersViewed}
                        invitationsAcceptedFromOthers={invitationsAcceptedFromOthers}
                        invitationsRejectedFromOthers={invitationsRejectedFromOthers} 
                     /> 
        },
        { 
            name: 'Activity', 
            id: 'activity-tab', 
            current: false, 
            content: <HistoryTabContent 
                        savedUsers={savedusers} 
                        passedUsers={passedusers} 
                        invitedUsers={invitedUsers} 
                        acceptedUsers={acceptedUsers} 
                        rejectedUsers={rejectedUsers} 
                     /> 
        },
        { 
            name: 'Subscription', 
            id: 'subscription-tab', 
            current: false, 
            content: <SubscriptionTabContent user={user} /> 
        },
    ];
    
    const [showChatPrompt, setShowChatPrompt] = useState(false);
    const { datas } = interactions;

    const getRandomGradient = () => {
        const baseColors = ['#6327D8', '#F77171'];
        const gradients = [];

        for (let i = 0; i < 20; i++) {
          const opacity = Math.random() * 0.5 + 0.5; // Adjust the opacity range as needed
          const color1 = `${baseColors[0]}${Math.round(opacity * 255).toString(16)}`;
          const color2 = `${baseColors[1]}${Math.round(opacity * 255).toString(16)}`;

          const gradient = `linear-gradient(45deg, ${color1}, ${color2})`;
          gradients.push(gradient);
        }

        const randomIndex = Math.floor(Math.random() * gradients.length);
        return gradients[randomIndex];
      };


    const { data, setData, post, processing, errors } = useForm({
        message: '',
      });

    const handleSubmit = () => {
        post(route('admin.sendMessage', user.id), {
          onSuccess: () => {
            // Handle success
            setData('message', ''); // Clear the message input
          },
          onError: () => {
            // Handle error
          },
        });
    };

    const [activeTab, setActiveTab] = useState(tabs[0].id);

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };

    return (
        <Authenticated
            auth={props.auth}
            errors={props.errors}
            // header={user.username}
            // btnName="Dashboard"
            // href={route('admin.dashboard')}
        >

            <article className="px-4 py-6 bg-gray-50 dark:bg-gray-800/50">
                <div>
                    <div>
                        <div
                            className="object-cover w-full h-32 rounded-lg lg:h-35"
                            style={{ backgroundImage: getRandomGradient() }}
                        />
                    </div>
                  <div className="max-w-5xl px-4 mx-auto sm:px-6 lg:px-8">
                    <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
                      <div className="flex">
                        {/* <img
                          className="w-24 h-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32"
                          src={user.profile_image}
                          alt=""
                        /> */}
                        <span className="relative inline-block">
                            <img
                                className="flex-none object-cover w-24 h-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32"
                                src={user.profile_image}
                                alt=""
                            />
                            <span
                                className={`absolute bottom-7 right-0 -mb-1 block h-2.5 w-2.5 rounded-full ring-2 ring-white ${
                                    user.online_status ? 'bg-green-400' : 'bg-gray-400'
                                }`}
                            />
                        </span>
                      </div>
                      <div className="mt-6 sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
                        <div className="flex-1 min-w-0 mt-6 sm:hidden 2xl:block">
                            <h1 className="text-2xl font-bold text-gray-900 truncate dark:text-white">
                                {user.username}, {user.age}
                                {user.is_verified === 1 &&
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75m-3-7.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285Z" />
                                    </svg>
                                }
                            </h1>
                            <p className="text-base font-medium leading-6 text-slate-600 dark:text-slate-300"> {user.ethnic_origin} From {user.country}</p>

                        </div>
                        <div className="flex flex-col mt-6 space-y-3 justify-stretch sm:flex-row sm:space-x-4 sm:space-y-0">
                          {/* <button
                            type="button"
                            className="inline-flex justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                          >
                            <ChatAlt2Icon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                            Message
                          </button>
                          <button
                            type="button"
                            className="inline-flex justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                          >
                            <PhoneIcon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                            Call
                          </button> */}
                          {user.deactivated !== 1 && user.account_status !== 4 &&
                                    <>
                                        {user.account_status !== 1 ? (
                                            <Link
                                                className="px-2 py-2 text-sm font-semibold text-blue-500 bg-transparent border border-blue-600 rounded-lg shadow-sm hover:bg-blue-500 hover:text-white"
                                                href={route('admin.activate_user', user.id)}
                                                method="post"
                                                as="button"
                                            >
                                                Activate user
                                            </Link>
                                        ) : (
                                            <button disabled={true}
                                                className="flex items-center justify-center gap-1 text-sm font-semibold disabled:cursor-not-allowed text-sky-600 "
                                            >
                                                <CheckCircleIcon className="w-6 h-6" area-hidden="true" />

                                                Active
                                            </button>
                                        )}

                                        {user.account_status !== 3 && (
                                            <Link
                                                className="flex items-center justify-center gap-1 px-2 py-2 text-sm font-semibold text-red-400 bg-transparent border border-red-400 rounded-lg shadow-sm hover:bg-red-400 hover:text-white"
                                                href={route('admin.suspendUser', user.id)}
                                                method="post"
                                                as="button"
                                            >
                                                <ExclamationCircleIcon className="w-6 h-6" area-hidden="true" />
                                                <span>Suspend user</span>
                                            </Link>
                                        ) }

                                    </>
                                }

                                {user.deactivated === 1 &&
                                    <>
                                        <button
                                            className="px-2 py-2 text-sm font-semibold text-red-400 bg-transparent border border-red-400 rounded-md shadow-sm "
                                            disabled
                                        >
                                            Deactivated
                                        </button>
                                    </>
                                }

                                {user.account_status === 3 &&
                                    <>
                                        <button
                                            className="px-2 py-2 text-sm font-semibold text-white bg-red-400 border border-red-400 rounded-md shadow-sm "
                                            disabled
                                        >
                                            User Suspended
                                        </button>
                                    </>
                                }

                                {user.account_status === 4 &&
                                    <>
                                        <button
                                            className="px-2 py-2 text-sm font-semibold text-red-400 bg-transparent border border-red-400 rounded-md shadow-sm "
                                            disabled
                                        >
                                            Account Deleted
                                        </button>
                                    </>
                                }
                        </div>
                      </div>
                    </div>
                    <div className="flex-1 hidden min-w-0 mt-6 sm:block 2xl:hidden">
                        <h1 className="inline-flex items-center gap-2 text-2xl font-bold text-gray-900 truncate dark:text-white">
                            {user.username}, {user.age} 
                            {user.is_verified === 1 &&
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-sky-400">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75m-3-7.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285Z" />
                                </svg>
                            }
                        </h1>
                        <p className="text-base font-medium leading-6 text-slate-600 dark:text-slate-300"> {user.ethnic_origin} From {user.country}</p>
                    </div>
                  </div>
                </div>

                {/* Tabs */}
                <div className="mt-6 sm:mt-2 2xl:mt-5">
                  <div className="border-b border-gray-200 dark:border-gray-700">
                    <div className="max-w-5xl px-4 mx-auto sm:px-6 lg:px-8">
                      <nav className="flex -mb-px space-x-8" aria-label="Tabs">
                        {tabs.map((tab) => (
                          <a
                            key={tab.id}
                            href="#"
                            className={classNames(
                                activeTab === tab.id
                                ? 'border-red-400 text-gray-900 dark:text-white'
                                : 'border-transparent text-gray-500 dark:text-gray-400 hover:border-gray-300 hover:text-gray-700',
                                'whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium'
                            )}
                            onClick={() => handleTabClick(tab.id)}
                            aria-current={activeTab === tab.id ? 'page' : undefined}
                            >
                            {tab.name}
                          </a>
                        ))}
                      </nav>
                    </div>
                  </div>
                </div>

                <div className="max-w-5xl px-4 mx-auto mt-6 sm:px-6 lg:px-8">
                {tabs.map((tab) => (
                    <div key={tab.id} className={activeTab === tab.id ? 'block' : 'hidden'}>
                        {tab.content}
                    </div>
                ))}
                </div>

                {/* Team member list */}
                {/* <div className="max-w-5xl px-4 pb-12 mx-auto mt-8 sm:px-6 lg:px-8">
                  <h2 className="text-sm font-medium text-gray-500">Team members</h2>
                  <div className="grid grid-cols-1 gap-4 mt-1 sm:grid-cols-2">
                    {team.map((person) => (
                      <div
                        key={person.handle}
                        className="relative flex items-center px-6 py-5 space-x-3 bg-white border border-gray-300 rounded-lg shadow-sm focus-within:ring-2 focus-within:ring-pink-500 focus-within:ring-offset-2 hover:border-gray-400"
                      >
                        <div className="flex-shrink-0">
                          <img className="w-10 h-10 rounded-full" src={person.imageUrl} alt="" />
                        </div>
                        <div className="flex-1 min-w-0">
                          <a href="#" className="focus:outline-none">
                            <span className="absolute inset-0" aria-hidden="true" />
                            <p className="text-sm font-medium text-gray-900">{person.name}</p>
                            <p className="text-sm text-gray-500 truncate">{person.role}</p>
                          </a>
                        </div>
                      </div>
                    ))}
                  </div>
                </div> */}
            </article>

            <button
                className="fixed inline-flex items-center justify-center h-12 p-0 m-0 text-sm font-medium leading-5 normal-case border border-gray-200 rounded-full cursor-pointer bottom-4 right-4 disabled:pointer-events-none disabled:opacity-50 w-14 bg-slate-900 hover:bg-gray-700 bg-none hover:text-gray-900"
                type="button"
                aria-haspopup="dialog"
                aria-expanded={showChatPrompt}
                onClick={() => setShowChatPrompt(!showChatPrompt)}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="40"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="block text-white align-middle border-gray-200"
                >
                    <path d="m3 21 1.9-5.7a8.5 8.5 0 1 1 3.8 3.8z" className="border-gray-200"></path>
                </svg>
            </button>

            {showChatPrompt && (
                <div className="fixed  overflow-y-scroll bottom-[calc(4rem+1.5rem)] right-0 mr-4 bg-white p-6 rounded-lg border border-[#e5e7eb] w-[440px] h-[525px] shadow-lg">
                    <div className="flex flex-col space-y-1.5 pb-6">
                        <h2 className="text-lg font-semibold tracking-tight">Instant contact with {user.username}</h2>
                        <p className="text-sm text-[#6b7280] leading-3">Powered by Wam Bam 😉</p>
                    </div>
                    <div className="pr-4 h-[380px] overscroll-contain" style={{ minWidth: '100%', display: 'table' }}>
                        {interactions?.map((interaction) => (
                            <div key={interaction.id}>
                                <div className="flex flex-1 gap-3 my-4 text-sm text-gray-600">
                                    <span className="relative flex w-8 h-8 overflow-hidden rounded-full shrink-0">
                                        <div className="p-1 bg-gray-100 border rounded-full">
                                            {interaction.initiated_by === 'admin' ? (
                                                <svg stroke="none" fill="black" strokeWidth="1.5" viewBox="0 0 24 24" aria-hidden="true" height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                        d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z">
                                                    </path>
                                                </svg>
                                            ): <img
                                                    className={`flex-none h-full w-full rounded-full object-cover`}
                                                    src={user.profile_image}
                                                    alt={`${user.username}'s avatar`}
                                                />
                                            }
                                        </div>
                                    </span>
                                    <p className="leading-relaxed">
                                        <span className="block font-bold text-gray-700">
                                            {interaction.initiated_by === 'admin' ? interaction.admin.firstname : user.username}
                                        </span>
                                        <pre className='text-balance'>{interaction.message}</pre>
                                    </p>
                                </div>
                                {interaction.respond &&
                                    <div className="flex flex-1 gap-3 my-4 text-sm text-gray-600">
                                        <span className="">
                                            <div className="w-8 h-8 rounded-full">
                                                {/* <img scr={user.profile_image} className="w-8 h-8 rounded-full" /> */}
                                                <img
                                                    className={`flex-none h-8 w-8 rounded-full object-cover`}
                                                    src={user.profile_image}
                                                />
                                            </div>
                                        </span>
                                        <p className="leading-relaxed">
                                            <span className="block font-bold text-gray-700">
                                                {user.username} responded:
                                            </span>
                                            <pre className='text-balance'>{interaction.respond}</pre>
                                        </p>
                                    </div>
                                }
                            </div>
                        ))}

                        {interactions?.length === 0 && (
                            <div className="flex flex-1 gap-3 my-4 text-sm text-gray-600">
                                <span className="relative flex w-8 h-8 overflow-hidden rounded-full shrink-0">
                                    <div className="p-1 bg-gray-100 border rounded-full">
                                        <svg stroke="none" fill="black" strokeWidth="1.5" viewBox="0 0 24 24" aria-hidden="true" height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z">
                                            </path>
                                        </svg>
                                    </div>
                                </span>
                                <p className="leading-relaxed">
                                    <span className="block font-bold text-gray-700">{props.auth.user.firstname}</span>
                                    Say Hi to {user.username}
                                </p>
                            </div>
                        )}
                    </div>
                    <div className="flex items-center pt-0">
                        <form onSubmit={handleSubmit} className="flex items-center justify-center w-full space-x-2">
                            <textarea
                                name="message"
                                id="message"
                                rows={1}
                                value={data.message}
                                onChange={(e) => setData('message', e.target.value)}
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                            {errors.message && <div className="text-red-500">{errors.message}</div>}

                            <button
                                type="submit"
                                className="inline-flex items-center justify-center rounded-md text-sm font-medium text-[#f9fafb] disabled:pointer-events-none disabled:opacity-50 bg-black hover:bg-[#111827E6] h-10 px-4 py-2"
                                disabled={processing}
                            >
                                Send
                            </button>
                        </form>
                    </div>
                </div>
            )}
    </Authenticated>
    )
}

export default Profile


const UserAnalyticsCard = ({ title, value, svg: SvgComponent, bgColor, textColor }) => {
    return (
        <div className="relative px-4 pt-5 pb-12 overflow-hidden bg-white rounded-lg shadow dark:bg-gray-800/80 sm:px-6 sm:pt-6">
            <dt>
                <div className={`absolute rounded-md ${bgColor} p-3 text-white dark:text-slate-100`}>
                    <SvgComponent className="w-6 h-6" />
                </div>
                <p className="ml-16 text-sm font-medium text-gray-800 truncate dark:text-gray-200">{title}</p>
            </dt>
            <dd className="flex items-baseline ml-16">
                <p className="text-2xl font-semibold text-gray-900 dark:text-gray-100">{value}</p>
                <div className="absolute inset-x-0 bottom-0 px-4 py-4 bg-gray-50 dark:bg-slate-900/50 sm:px-6">
                    {/* <div className="text-sm">
                        <Link href={ route('admin.users') } className={`font-medium text-${textColor}-600 dark:text-${textColor}-300 hover:text-${textColor}-500`}>
                            View all<span className="sr-only"> Item names, stats</span>
                        </Link>
                    </div> */}
                </div>
            </dd>
        </div>
    );
};


// Separate components for tab content
function ProfileTabContent(props) {
    const { user } = props;

    return (
      <div>
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div className="rounded-lg">
                <div className="inline-flex items-center justify-center ">
                    <div className="inline-flex items-center justify-center p-2 mb-2 rounded-full shadow bg-slate-50 dark:bg-slate-800 dark:border dark:border-blue-300">
                        <img src={UserIcon} className="w-8 h-8" />
                    </div>
                    <p className="pl-2 pr-2 text-lg font-semibold text-slate-700 dark:text-slate-300">Profile Basic</p>
                </div>

                <div className="mt-1">
                    <dl className="">
                        <div className="grid grid-cols-3 gap-4 ">
                            <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Email</dt>
                            <dd className="col-span-2 text-sm leading-6 text-gray-700 dark:text-slate-200">{user.email}</dd>
                        </div>
                        {user.phone != null && (
                            <div className="grid grid-cols-3 gap-4 ">
                                <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Phone</dt>
                                <dd className="col-span-2 text-sm leading-6 text-gray-700 dark:text-slate-200">{user.phone}</dd>
                            </div>
                        )}
                        <div className="grid grid-cols-3 gap-4 ">
                            <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Gender</dt>
                            <dd className="col-span-2 text-sm leading-6 text-gray-700 dark:text-slate-200">{user.gender}</dd>
                        </div>
                        <div className="grid grid-cols-3 gap-4 ">
                            <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Date of Birth</dt>
                            <dd className="col-span-2 text-sm leading-6 text-gray-700 dark:text-slate-200">{user.date_of_birth}</dd>
                        </div>
                        <div className="grid grid-cols-3 gap-4 ">
                            <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Age</dt>
                            <dd className="col-span-2 text-sm leading-6 text-gray-700 dark:text-slate-200">{user.age} </dd>
                        </div>

                        <div className="grid grid-cols-3 gap-4 ">
                            <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Height</dt>
                            <dd className="col-span-2 text-sm leading-6 text-gray-700 dark:text-slate-200">{user.height} </dd>
                        </div>
                        <div className="grid grid-cols-3 gap-4 ">
                            <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Ethnicity</dt>
                            <dd className="col-span-2 text-sm leading-6 text-gray-700 dark:text-slate-200">{user.ethnic_origin} </dd>
                        </div>
                    </dl>
                </div>
            </div>

            <div className="rounded-lg">
                <div className="inline-flex items-center justify-center ">
                    <div className="inline-flex items-center justify-center p-2 mb-2 rounded-full shadow bg-slate-50 dark:bg-slate-800 dark:border dark:border-sky-400">
                        <img src={ProfessionIcon} className="w-8 h-8" />
                    </div>
                    <p className="pl-2 pr-2 text-lg font-semibold text-slate-700 dark:text-slate-300">Education/Career</p>
                </div>
                <div className="mt-1">
                    <dl className="">
                        <div className="grid grid-cols-3 gap-4 mt-1">
                            <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">University / College</dt>
                            <dd className="col-span-2 text-sm leading-6 text-gray-700 dark:text-slate-200">{user.university} </dd>
                        </div>
                        <div className="grid grid-cols-3 gap-4 ">
                            <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Course</dt>
                            <dd className="col-span-2 text-sm leading-6 text-gray-700 dark:text-slate-200">{user.course_name} </dd>
                        </div>
                        {user.college != null && (
                            <div className="mt-1">
                                <div className="grid grid-cols-3 gap-4 ">
                                    <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">University / College</dt>
                                    <dd className="col-span-2 text-sm leading-6 text-gray-700 dark:text-slate-200">{user.college} </dd>
                                </div>
                                <div className="grid grid-cols-3 gap-4 ">
                                    <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Course</dt>
                                    <dd className="col-span-2 text-sm leading-6 text-gray-700 dark:text-slate-200">{user.college_course_name} </dd>
                                </div>
                            </div>
                        )}

                        <div className="grid grid-cols-3 gap-4 ">
                            <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Profession</dt>
                            <dd className="col-span-2 text-sm leading-6 text-gray-700 dark:text-slate-200">{user.current_profession} </dd>
                        </div>
                    </dl>
                </div>
            </div>

            <div className="rounded-lg">
                <div className="inline-flex items-center justify-center ">
                    <div className="inline-flex items-center justify-center p-2 mb-2 rounded-full shadow bg-slate-50 dark:bg-slate-800 dark:border dark:border-red-400">
                        <img src={GlobeIcon} className="w-8 h-8" />
                    </div>
                    <p className="pl-2 pr-2 text-lg font-semibold text-slate-700 dark:text-slate-300">Location</p>
                </div>
                <div className="mt-1">
                    <dl className="">
                        <div className="grid grid-cols-3 gap-4 ">
                            <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Address</dt>
                            <dd className="col-span-2 text-sm leading-6 text-gray-700 dark:text-slate-200">{user.city}, {user.country} </dd>
                        </div>
                        <div className="grid grid-cols-3 gap-4 ">
                            <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Residency status</dt>
                            <dd className="col-span-2 text-sm leading-6 text-gray-700 dark:text-slate-200">{user.recidency_status} </dd>
                        </div>
                        <div className="grid grid-cols-3 gap-4 ">
                            <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Relocate</dt>
                            <dd className="col-span-2 text-sm leading-6 text-gray-700 dark:text-slate-200">{user.relocate == 'Yes' ? 'Willing to relocate' : 'No intention to relocate'}</dd>
                        </div>
                        <p className="text-sm font-semibold leading-6 text-gray-900 dark:text-slate-200">Back home</p>
                        <div className="grid grid-cols-3 gap-4 ">
                            <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Address</dt>
                            <dd className="col-span-2 text-sm leading-6 text-gray-700 dark:text-slate-200">{user.back_home_area !== '' && user.back_home_area + ','} {user.back_home_city}, {user.back_home_country} </dd>
                        </div>
                    </dl>
                </div>
            </div>

            <div className="rounded-lg">
                <div className="inline-flex items-center justify-center ">
                    <div className="inline-flex items-center justify-center p-2 mb-2 rounded-full shadow bg-slate-50 dark:bg-slate-800 dark:border dark:border-red-400">
                        <img src={MosqueIcon} className="object-center w-8 h-8" />
                    </div>
                    <p className="pl-2 pr-2 text-lg font-semibold text-slate-700 dark:text-slate-300">Religious Observance</p>
                </div>

                <div className="mt-1">
                    <dl className="">
                        <div className="grid grid-cols-3 gap-4">
                            <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Religious History</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-slate-200">Muslim - {user.religious_history}</dd>
                        </div>
                        <div className="grid grid-cols-3 gap-4">
                            <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Sect</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-slate-200">{user.sect}</dd>
                        </div>
                        <div className="grid grid-cols-3 gap-4">
                            <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Pray</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-slate-200">{user.prayer_frequency}</dd>
                        </div>
                        <div className="grid grid-cols-3 gap-4">
                            <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Read Quran</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-slate-200 sm:col-span-2 sm:mt-0">{user.read_quran}</dd>
                        </div>
                        <div className="grid grid-cols-3 gap-4">
                            <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Eat Halal</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-slate-200 sm:col-span-2 sm:mt-0">{user.eat_halal}</dd>
                        </div>

                        <div className="grid grid-cols-3 gap-4">
                            <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Drink Alchohol</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-slate-200 sm:col-span-2 sm:mt-0">{user.drink_alchohol}</dd>
                        </div>
                    </dl>
                </div>
            </div>

            <div className="rounded-lg">
                <div className="inline-flex items-center justify-center ">
                    <div className="inline-flex items-center justify-center p-2 mb-2 rounded-full shadow bg-slate-50 dark:bg-slate-800 dark:border dark:border-green-300">
                        <img src={RingIcon} className="w-8 h-8" />
                    </div>
                    <p className="pl-2 pr-2 text-lg font-semibold text-slate-700 dark:text-slate-300">Personal</p>
                </div>

                <div className="mt-1">
                    <dl className="">
                        <div className="grid grid-cols-3 gap-4 ">
                            <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Marital Status</dt>
                            <dd className="col-span-2 text-sm leading-6 text-gray-700 dark:text-slate-200">{user.marital_status} </dd>
                        </div>
                        <div className="grid grid-cols-3 gap-4 ">
                            <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Have children</dt>
                            <dd className="col-span-2 text-sm leading-6 text-gray-700 dark:text-slate-200">{user.have_children} </dd>
                        </div>
                    </dl>
                </div>
            </div>

            <div className="rounded-lg ">
                <div className="inline-flex items-center justify-center ">
                    <div className="inline-flex items-center justify-center p-2 mb-2 rounded-full shadow bg-slate-50 dark:bg-slate-800 dark:border dark:border-purple-400">
                        <img src={FamilyIcon} className="w-8 h-8" />
                    </div>
                    <p className="pl-2 pr-2 text-lg font-semibold text-slate-700 dark:text-slate-300">Family</p>
                </div>
                <div className="mt-1">
                    <dl className="">
                        <div className="grid grid-cols-3 gap-4 ">
                            <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Siblings</dt>
                            <dd className="col-span-2 text-sm leading-6 text-gray-700 dark:text-slate-200">{user.siblings == 0 ? 'No Siblings' : user.siblings + ' siblings'}</dd>
                        </div>
                    </dl>
                </div>
            </div>

            <div className="rounded-lg ">
                <div className="inline-flex items-center justify-center ">
                    <div className="inline-flex items-center justify-center p-2 mb-2 rounded-full shadow bg-slate-50 dark:bg-slate-800 dark:border dark:border-orange-400">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-8 h-8 text-orange-400">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 0 0 6 3.75v16.5a2.25 2.25 0 0 0 2.25 2.25h7.5A2.25 2.25 0 0 0 18 20.25V3.75a2.25 2.25 0 0 0-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
                        </svg>
                    </div>
                    <p className="pl-2 pr-2 text-lg font-semibold text-slate-700 dark:text-slate-300">Login details</p>
                </div>
                <div className="mt-1">
                    <dl className="">
                        <div className="grid grid-cols-3 gap-4 ">
                            <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Last login</dt>
                            <dd className="col-span-2 text-sm leading-6 text-gray-700 dark:text-slate-200">{user.login_time}</dd>
                        </div>
                        <div className="grid grid-cols-3 gap-4 ">
                            <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">IP</dt>
                            <dd className="col-span-2 text-sm leading-6 text-gray-700 dark:text-slate-200">{user.ip}</dd>
                        </div>
                        <div className="grid grid-cols-3 gap-4 ">
                            <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Logged-in device</dt>
                            <dd className="col-span-2 text-sm leading-6 text-gray-700 dark:text-slate-200">{user.current_logged_in_device}</dd>
                        </div>
                    </dl>
                </div>
            </div>

            <div className="rounded-lg ">
                <div className="inline-flex items-center justify-center ">
                    <div className="inline-flex items-center justify-center p-2 mb-2 rounded-full shadow bg-slate-50 dark:bg-slate-800 dark:border dark:border-gray-50">
                        <CurrencyPoundIcon className="w-8 h-8 dark:text-gray-50" />
                    </div>
                    <p className="pl-2 pr-2 text-lg font-semibold text-slate-700 dark:text-slate-200">Subscription Details</p>
                </div>
                <div className="mt-1">
                    <dl className="">
                        <div className="grid grid-cols-3 gap-4 ">
                            <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Subscribed to</dt>
                            <dd className="col-span-2 text-sm leading-6 text-gray-700 dark:text-slate-200">{user.subscription.name}</dd>
                        </div>
                        {user.trial_ends_at !== 0 && 
                            <div className="grid grid-cols-3 gap-4 ">
                                <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Trial ends in</dt>
                                <dd className="col-span-2 text-sm leading-6 text-gray-700 dark:text-slate-200">{user.trial_ends_at} days</dd>
                            </div>
                        }
                        <div className="grid grid-cols-3 gap-4 ">
                            <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Status</dt>
                            <dd className={`col-span-2 text-sm leading-6 text-gray-700 dark:text-slate-200 ${user.subscription.status === 'canceled' ? 'bg-red-600' : ''}`}>{`${user.subscription.status === 'trialing' ? 'On Trial ' + '(Ends in ' + user.trial_ends_at + ' Days)' : user.subscription.status }`}</dd>
                        </div>
                        <div className="grid grid-cols-3 gap-4 ">
                            <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Payment method</dt>
                            <dd className="col-span-2 text-sm leading-6 text-gray-700 dark:text-slate-200"></dd>
                        </div>

                        <div className="grid grid-cols-3 gap-4 ">
                            <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Card type</dt>
                            <dd className="col-span-2 text-sm leading-6 text-gray-700 dark:text-slate-200">{`${user.pm_type}`}</dd>
                        </div>
                        <div className="grid grid-cols-3 gap-4 ">
                            <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Card ending</dt>
                            <dd className="col-span-2 text-sm leading-6 text-gray-700 dark:text-slate-200">{` ${user.pm_last_four}`}</dd>
                        </div>
                        
                        
                        {user.subscription.ends_at !== null && 
                            <div className="grid grid-cols-3 gap-4 ">
                                <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Ends at</dt>
                                <dd className="col-span-2 text-sm leading-6 text-gray-700 dark:text-slate-200">{user.subscription.ends_at }</dd>
                            </div>
                        }
                    </dl>
                </div>
            </div>
            {/* <h1 className="text-lg font-semibold border-b border-slate-200 dark:border-slate-700 text-slate-700 dark:text-slate-200">About {user.username}</h1> */}
            
            <div className="sm:col-span-2">
                <dt className="text-lg font-semibold border-b border-slate-200 dark:border-slate-700 text-slate-700 dark:text-slate-200">About {user.username}</dt>
                <dd
                    className="mt-1 space-y-5 text-sm text-gray-900 max-w-prose"
                >
                    <p className="mt-2 leading-6 tracking-wide text-justify whitespace-pre-line align-baseline text-slate-700 dark:text-slate-300">
                        {user.bio !== '' ? user.bio : 'No bio added!'}
                    </p>
                </dd>
            </div>
            </dl>
      </div>
    );
  }
  
function PhotosTabContent(props) {
    const { user, userImages } = props;
    return (
      <div>
        <div className="mt-4">
            {userImages.length !== 0 ?
                <div className="grid grid-cols-2 gap-4 sm:grid-cols-4 sm:gap-4">
                    {userImages.map(({image, id}) => (
                        <div key={id}>
                            <a href="#"
                                className="relative flex items-end h-48 overflow-hidden bg-gray-100 rounded-lg shadow-lg group md:h-80">
                                <img src={image} loading="lazy" alt="" className={`${user.blur_profile_image === 1 ? 'blur-lg' : ''} absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110`} />
                                <div
                                    className="absolute inset-0 opacity-50 pointer-events-none bg-gradient-to-t from-gray-800 via-transparent to-transparent">
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
                :
                <p className="pb-5 text-sm text-slate-700 dark:text-slate-200 ">{user.username} hasn't yet uploaded any photos.</p>
            }
        </div>

        <div className="mt-4">
            {user.is_verified === 0 && user.verification_image === null && (
                <div className="">
                    <div className="flex items-center justify-between mb-2 border-b border-slate-200 dark:border-slate-700">
                        <h2 className="text-lg font-semibold text-slate-900 dark:text-purple-50">
                            Verification image
                        </h2>
                        <Link className="inline-flex items-center px-3 py-2 text-sm font-semibold text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" href={route('admin.verifyuser', user.id)} method="post" as="button">
                            Ask for verification
                        </Link>
                    </div>
                    <p className='mt-2 text-sm text-slate-700 dark:text-slate-200'>{user.username} hasn't uploaded any verification images yet.</p>
                </div>)
            }
            {user.verification_image !== null && (
                <div>
                    <div className="">
                        <div className="flex items-center justify-between mb-2 border-b border-slate-200 dark:border-slate-700">
                            <h2 className="mt-6 text-lg font-semibold text-slate-900 dark:text-purple-50">
                                Verification image
                            </h2>
                            {user.is_verified === 1 && (
                                <div className="flex items-center justify-center gap-3">
                                    <div className='flex items-center gap-1 text-sm text-purple-600 border-none dark:text-white justify-evenly'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z" />
                                        </svg>
                                        <span>Verified</span>
                                    </div>
                                    <Link className="inline-flex items-center px-3 py-2 text-sm font-semibold text-white bg-orange-600 rounded-md shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600" href={route('admin.unverifyuser', user.id)} method="post" as="button">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                        </svg>
                                        <span className='ml-1'>Doesn't look right!</span>
                                    </Link>
                                </div>
                            )}
                            
                            {user.is_verified === 0 && (
                                <div className='flex items-center justify-center gap-2'>
                                    <Link className="inline-flex items-center px-3 py-2 text-sm font-semibold text-orange-600 hover:text-orange-500" href={route('admin.unverifyuser', user.id)} method="post" as="button">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                        </svg>
                                        <span className='ml-1'>Doesn't look right!</span>
                                    </Link>

                                    <Link className="inline-flex items-center px-3 py-2 text-sm font-semibold text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" href={route('admin.verifyuser', user.id)} method="post" as="button">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                                        </svg>
                                        <span className='ml-1'>Verify now</span>
                                    </Link>
                                </div>
                            )}
                        </div>
                    </div>

                    {user.verification_image !== null && (
                        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-4">
                            <div className="max-w-sm p-6 bg-white border h-48 border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 group relative flex items-end overflow-hidden md:h-[13rem]">
                                <img src={user.verification_image} loading="lazy" alt={user.username} className={`absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110`} />
                                <div className="absolute inset-0 opacity-50 pointer-events-none bg-gradient-to-t from-gray-800 via-transparent to-transparent"></div>
                                <span className="relative inline-block mb-3 ml-4 text-sm text-white md:ml-5 md:text-lg">Photo ID</span>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
      </div>
    );
  }
  
  function AnalyticsTabContent(props) {
    const { 
        viewsReceived,
        savedByOthers,
        passedByOthers,
        invitationsReceived,
        invitationsAccepted,
        invitationsRejected,
        invitationsSent,
        usersSaved,
        usersPassed,
        usersViewed,
        invitationsAcceptedFromOthers,
        invitationsRejectedFromOthers 
    } = props;

    return (
      <div>
        <dl className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
            <UserAnalyticsCard title="User views" value={viewsReceived} svg={EyeIcon} bgColor={`bg-yellow-500`} textColor={`yellow`} />
            <UserAnalyticsCard title="Saved By" value={savedByOthers} svg={HeartIcon} bgColor={`bg-rose-500`} textColor={`rose`} />
            <UserAnalyticsCard title="Passed By" value={passedByOthers} svg={XIcon} bgColor={`bg-slate-500`} textColor={`slate`} />
            <UserAnalyticsCard title="Invitations Received" value={invitationsReceived} svg={PaperAirplaneIcon} bgColor={`bg-pink-500`} textColor={`pink`} />
            <UserAnalyticsCard title="Invitations Accepted" value={invitationsAccepted} svg={UsersIcon} bgColor={`bg-red-400`} textColor={`red`} />
            <UserAnalyticsCard title="Invitations Rejected" value={invitationsRejected} svg={UserRemoveIcon} bgColor={`bg-lime-500`} textColor={`lime`} />
            <UserAnalyticsCard title="Invitations Sent" value={invitationsSent} svg={PaperAirplaneIcon} bgColor={`bg-teal-500`} textColor={`teal`} />
            <UserAnalyticsCard title="Users Saved" value={usersSaved} svg={HeartIcon} bgColor={`bg-cyan-500`} textColor={`cyan`} />
            <UserAnalyticsCard title="Users Passed" value={usersPassed} svg={XIcon} bgColor={`bg-sky-500`} textColor={`yellow`} />
            <UserAnalyticsCard title="Users Viewed" value={usersViewed} svg={EyeOffIcon} bgColor={`bg-sky-500`} textColor={`sky`} />
            <UserAnalyticsCard title="Invitations Accepted From Others" value={invitationsAcceptedFromOthers} svg={PaperAirplaneIcon} bgColor={`bg-indigo-500`} textColor={`indigo`} />
            <UserAnalyticsCard title="Invitations Rejected From Others" value={invitationsRejectedFromOthers} svg={XCircleIcon} bgColor={`bg-violet-500`} textColor={`violet`} />
        </dl>
      </div>
    );
  }
  
 
const HistoryTabContent = ({ savedUsers, passedUsers, invitedUsers, acceptedUsers, rejectedUsers }) => {    
    const renderUserList = (users, title) => (
        <section aria-labelledby={`${title.toLowerCase().replace(' ', '-')}-title`}>
            <div className="overflow-hidden bg-white rounded-lg shadow dark:bg-gray-800">
                <div className="px-4 py-5 bg-white border-b border-gray-200 dark:bg-gray-900/50 dark:border-gray-700 sm:px-6">
                    <div className="flex flex-wrap items-center justify-between -mt-2 -ml-4 sm:flex-nowrap">
                        <div className="mt-2 ml-4">
                            <h3 className="text-base font-semibold leading-6 text-gray-900 dark:text-white" id={`${title.toLowerCase().replace(' ', '-')}-title`}>{title}</h3>
                        </div>
                        <div className="flex-shrink-0 mt-2 ml-4">
                            <button
                                type="button"
                                className="relative inline-flex items-center px-3 py-2 text-sm font-semibold text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                View all
                            </button>
                        </div>
                    </div>
                </div>
                <div className="p-6">
                    <div className="flow-root mt-6">
                        <ul role="list" className="-my-5 divide-y divide-gray-200 dark:divide-gray-700">
                            {users.map((user) => (
                                <li key={user.username} className="py-4">
                                    <div className="flex items-center space-x-4">
                                        <div className="flex-shrink-0">
                                            <img className="w-8 h-8 rounded-full" src={user.profile_image} alt="" />
                                        </div>
                                        <div className="flex-1 min-w-0">
                                            <p className="text-sm font-semibold text-gray-900 truncate dark:text-white">{user.username}</p>
                                            <p className="text-sm text-gray-500 truncate dark:text-gray-400">{user.email}</p>
                                        </div>
                                        <div>
                                            <Link href={route('admin.userProfile', user.username)} as="a" className="inline-flex items-center rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                                                View
                                            </Link>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    {/* <div className="mt-6">
                        <a
                            href="#"
                            className="flex items-center justify-center w-full px-3 py-2 text-sm font-semibold text-gray-900 bg-white rounded-md shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        >
                            View all
                        </a>
                    </div> */}
                </div>
            </div>
        </section>
    );

    return (
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
            {renderUserList(savedUsers, 'Saved Users')}
            {renderUserList(passedUsers, 'Passed Users')}
            {renderUserList(invitedUsers, 'Invited Users')}
            {renderUserList(acceptedUsers, 'Accepted Users')}
            {renderUserList(rejectedUsers, 'Rejected Users')}
        </div>
    );
};

function SubscriptionTabContent({ user }) {
    return (
        <div className="relative h-56 m-auto text-white transition-transform transform bg-red-100 shadow-2xl w-96 rounded-xl hover:scale-110">
                <img className="relative object-cover w-full h-full rounded-xl" src="https://i.imgur.com/kGkSg1v.png" />
                <div className="absolute w-full px-8 top-8">
                    <div className="flex justify-between">
                        <div className="">
                            <p className="text-sm font-normal text-slate-900">
                                Subscribed to
                            </p>
                            <h1 className="font-medium tracking-normal text-white">
                                {user.subscription.name} Plan
                            </h1>
                        </div>
                        <div className={`flex items-center justify-center rounded-sm px-2 py-1 text-sm font-medium tracking-wide ring-1 ring-inset ${user.subscription.status === 'canceled' ? 'bg-red-50 text-red-700 ring-red-600/20' : 'bg-green-50 text-green-700 ring-green-600/20'}`}>
                            {`${user.subscription.status }`}
                        </div>
                    </div>
                    <div className="pt-1">
                        <p className="text-sm font-normal text-slate-900">
                            Card
                        </p>
                        <h1 className="font-medium tracking-wide">
                            {user.pm_type} ending {user.pm_last_four}
                        </h1>
                    </div>
                    <div className="pt-6 pr-6">
                        <div className="flex justify-between">
                            {user.trial_ends_at !== 0 && 
                                <div className="">
                                    <h1 className="text-xs font-normal">
                                        Trial ends in
                                    </h1>
                                    <p className="text-sm font-medium tracking-wider">
                                        {user.subscription.trial_ends_at_date}
                                    </p>
                                </div>
                            }

                            {user.subscription.ends_at !== 'N/A' && 
                                <div className="">
                                    <h1 className="text-xs font-normal">
                                        Ends at
                                    </h1>
                                    <p className="text-sm font-medium tracking-wider">
                                        {user.subscription.ends_at }
                                    </p>
                                </div>
                            }
    
                            <div className="">
                                <h1 className="text-xs font-medium">
                                    Started on
                                </h1>
                                <p className="text-sm font-bold tracking-more-wider">
                                    {user.subscription.start_date }
                                </p>
                            </div>
                        </div>
                    </div>
    
                </div>
        </div>
    );
}