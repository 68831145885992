import Authenticated from '@/Layouts/AdminLayouts/Authenticated'
import { InertiaLink, usePage } from "@inertiajs/inertia-react";
import { Inertia } from "@inertiajs/inertia";

import React from 'react'
import Pagination from '@/Components/Admin/Pagination';

function Index({auth, errors}) {
  const { plans, analytics } = usePage().props;
  const { data } = plans;

  const stats = [
        {
            id: 1,
            plan: 'Monthly',
            subscribed: analytics.monthly.subscribed,
            trial: analytics.monthly.trial,
            cancelled: analytics.monthly.cancelled,
        },
        {
            id: 2,
            plan: 'Three months',
            subscribed: analytics.three_months.subscribed,
            trial: analytics.three_months.trial,
            cancelled: analytics.three_months.cancelled,
        },
        {
            id: 3,
            plan: 'Yearly',
            subscribed: analytics.yearly.subscribed,
            trial: analytics.yearly.trial,
            cancelled: analytics.yearly.cancelled,
        },
    ];

  return (
    <Authenticated
          auth={auth}
          errors={errors}
          header={`Subscription Plans`}
          btnName = {`Add Plan`}
          href = {route('admin.plan.create')}
      >

        <div className="py-4 mt-6 sm:py-4">
            <div className="px-6 mx-auto max-w-7xl lg:px-8">
                <div className="max-w-2xl mx-auto lg:max-w-none">
                    <dl className="grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-3">
                        {stats.map((stat) => (
                            <div key={stat.id} className="flex flex-col p-8 shadow bg-slate-50 dark:bg-white/5">
                                <dt className="text-sm font-semibold leading-6 text-gray-800 dark:text-gray-300">{stat.plan} Plan</dt>
                                <dd className="order-first text-5xl font-semibold tracking-tight text-gray-900 dark:text-white">{stat.subscribed}</dd>
                                <div className="flex items-center justify-center gap-4 mt-1 text-sm text-gray-700 dark:text-gray-400">
                                    <p className="text-green-400">On Trial: {stat.trial}</p>
                                    <p className="text-red-400">Cancelled: {stat.cancelled}</p>
                                </div>
                            </div>
                        ))}
                    </dl>
                </div>
            </div>
        </div>

        <div className="p-4 mt-4">
            {/* <div className="bg-gray-900">
                <div className="mx-auto max-w-7xl">
                    <div className="grid grid-cols-1 gap-px bg-white/5 sm:grid-cols-2 lg:grid-cols-4">
                    {analytics.map((stat) => (
                        <div key={stat.name} className="px-4 py-6 bg-gray-900 sm:px-6 lg:px-8">
                        <p className="text-sm font-medium leading-6 text-gray-400">{stat.name}</p>
                        <p className="flex items-baseline mt-2 gap-x-2">
                            <span className="text-4xl font-semibold tracking-tight text-white">{stat.subscribed}</span>
                            {stat.trial ? <span className="text-sm text-gray-400">{stat.trial}</span> : null}
                        </p>
                        </div>
                    ))}
                    </div>
                </div>
            </div> */}
            <div className="mt-4 overflow-x-auto rounded shadow">
                <table className="w-full whitespace-nowrap">
                <thead className='border-b border-slate-200 dark:border-slate-600 '>
                    <tr className="font-bold text-left">
                        <th className="px-6 pt-5 pb-4 text-slate-700 dark:text-slate-100">#</th>
                        <th className="px-6 pt-5 pb-4 text-slate-700 dark:text-slate-100">Stripe info</th>
                        <th className="px-6 pt-5 pb-4 text-slate-700 dark:text-slate-100">Plan</th>
                        <th className="px-6 pt-5 pb-4 text-slate-700 dark:text-slate-100">Price</th>
                        <th className="px-6 pt-5 pb-4 text-slate-700 dark:text-slate-100">Highlight</th>
                        <th className="px-6 pt-5 pb-4 text-slate-700 dark:text-slate-100"></th>
                    </tr>
                </thead>
                <tbody className="">
                    {data.map(({ id, plan_name, price, highlight, stripe_name, abbreviation, stripe_product_id, stripe_price_id, created_at }) => (
                    <tr
                        key={id}
                        className="border-b hover:bg-gray-100/5 focus-within:bg-gray-100/10 border-slate-200 dark:border-slate-600"
                    >
                        <td className="">
                            <InertiaLink
                                href={route('admin.plan.edit', id)}
                                className="flex items-center px-6 py-4 focus:text-indigo-700 focus:outline-none text-slate-400"
                            >
                                {id}
                            </InertiaLink>
                        </td>
                        <td className="">
                            <InertiaLink
                                href={route('admin.plan.edit', id)}
                                className="flex items-center px-6 py-4 focus:text-indigo-700 focus:outline-none text-slate-400"
                            >
                                <div className="flex-row gap-1 ">
                                <p className="text-xs font-semibold text-slate-700 dark:text-slate-100">{stripe_name}</p>
                                <p className="text-xs font-medium text-slate-400">{abbreviation}</p>
                                <p className="text-xs font-medium text-slate-400">{stripe_product_id}</p>
                                <p className="text-xs font-medium text-slate-400">{stripe_price_id}</p>
                                </div>
                             </InertiaLink>
                        </td>
                        <td className="">
                            <InertiaLink
                                href={route('admin.plan.edit', id)}
                                className="flex items-center px-6 py-4 focus:text-indigo-700 focus:outline-none text-slate-700 dark:text-slate-100"
                            >
                                {plan_name}
                                
                            </InertiaLink>
                        </td>
                        <td className="">
                            <InertiaLink
                                tabIndex="1"
                                className="flex items-center px-6 py-4 focus:text-indigo focus:outline-none text-slate-400"
                                href={route('admin.plan.edit', id)}
                            >
                               £ {price}
                            </InertiaLink>
                        </td>
                        <td className="">
                            <div
                                className="flex items-center px-6 py-4 focus:text-indigo focus:outline-none text-slate-400"
                            >
                                <div className={` ${highlight === 1 ? 'ring-1 ring-inset ring-white/15 bg-slate-700/100 text-slate-300' : '' } rounded-md   px-2 py-1 text-sm font-medium text-gray-400 `}>
                                    {highlight === 1 ? 'Highlighted' : 'N/A'}
                                </div>
                            </div>
                        </td>
                        
                        <td className="w-px ">
                            <InertiaLink
                                tabIndex="-1"
                                href={route('admin.plan.edit', id)}
                                className="flex items-center px-4 focus:outline-none text-slate-700 dark:text-slate-100"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-slate-700 dark:text-slate-100" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
                                </svg>
                            </InertiaLink>
                        </td>
                    </tr>
                    ))}
                    {data.length === 0 && (
                    <tr>
                        <td className="px-6 py-4 text-slate-300" colSpan="4">
                            No subscription plans added.
                        </td>
                    </tr>
                    )}
                </tbody>
                </table>
            </div>
            {/* <Pagination links={links} /> */}
            </div>
      </Authenticated>
  )
}

export default Index