import EmptyStateMessage from '@/Components/Admin/EmptyStateMessage';
import SearchFilter from '@/Components/User/SearchFilter';
import UserSearch from '@/Components/UserSearch';
import Authenticated from '@/Layouts/AdminLayouts/Authenticated'
import { ArrowRightIcon } from '@heroicons/react/outline';
import { Inertia } from '@inertiajs/inertia';
import { Link, usePage } from '@inertiajs/inertia-react';
import React, { useState } from 'react'


function SuspendedUsers(props) {
    const [show, setShow] = useState(null)

    const { users, suspended_users, page, loading } = usePage().props;
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [currentPage, setCurrentPage] = useState(Number(page));

    const loadMoreUsers = () => {
        if (isLoadingMore || loading) return;

        setIsLoadingMore(true);

        Inertia.get(`/admin/users/suspended-users?page=${currentPage + 1}`, {
            onSuccess: (res) => {
                setCurrentPage((prevPage) => prevPage + 1);
                setUsers((prevUsers) => [...prevUsers, ...res.data]);
                setIsLoadingMore(false);
            },
            onError: () => {
                setIsLoadingMore(false);
            },
        });
    };
  return (
    <>
        <Authenticated
            auth={props.auth}
            errors={props.errors}
            // header={`Suspended users`}
            // btnName="Back"
            // href={route('admin.users')}
        >
            {/* <SearchFilter /> */}
            <div className="px-4 py-4 rounded-lg sm:px-6 lg:px-8 bg-slate-50 dark:bg-slate-800/85">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h1 className="text-base font-semibold leading-6 text-slate-800 dark:text-gray-300">Suspended Users</h1>
                        <p className="mt-2 text-sm text-slate-600 dark:text-gray-400">
                            List of all Suspended users.
                        </p>
                    </div>
                    <div className="items-center justify-center mt-4 sm:ml-16 sm:mt-0 sm:flex sm:gap-4">
                        <Link
                            as="a"
                            href={route('admin.activeUsers')}
                            className="block px-3 py-2 text-sm font-semibold text-center text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Active Users
                        </Link>
                        <Link
                            as="a"
                            href={route('admin.dashboard')}
                            className="hidden text-sm font-semibold text-center sm:flex sm:gap-2 text-gray-500/95 hover:text-gray-400"
                        >
                            <span>Dashboard</span>
                            <ArrowRightIcon className="w-4 h-4"/>
                        </Link>
                    </div>
                </div>
                <UserSearch />

                <div className="flow-root mt-8">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <table className="min-w-full divide-y divide-slate-200 dark:divide-gray-300/10">
                                <thead>
                                    <tr>
                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold dark:text-slate-100 text-slate-700 sm:pl-0">
                                            #
                                        </th>
                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold dark:text-slate-100 text-slate-700 sm:pl-0">
                                            Basic info
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold dark:text-slate-100 text-slate-700">
                                            Contact
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold dark:text-slate-100 text-slate-700">
                                            Status
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold dark:text-slate-100 text-slate-700">
                                            Subscription
                                        </th>
                                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                                            <span className="sr-only">Edit</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-300/10 ">
                                    {suspended_users.map(({ id,
                                                username,
                                                email,
                                                age,
                                                gender,
                                                date_of_birth,
                                                ethnic_origin,
                                                country,
                                                height,
                                                phone,
                                                account_status,
                                                profile_image,
                                                trial_ends_at,
                                                email_verified_at,
                                                verification_image,
                                                subscription
                                            }) => (
                                        <tr key={id}>
                                            <td className="py-5 pl-4 pr-3 text-sm whitespace-nowrap sm:pl-0 dark:text-gray-400 text-slate-700">{id}</td>
                                            <td className="py-5 pl-4 pr-3 text-sm whitespace-nowrap sm:pl-0 ">
                                                <Link href={route('admin.userProfile', username)} as="a">
                                                    <div className="flex items-center">
                                                        <div className="flex-shrink-0 h-11 w-11">
                                                            <img className="rounded-full h-11 w-11" src={profile_image} alt="" />
                                                        </div>
                                                        <div className="ml-4">
                                                            <div className="font-semibold text-slate-700 dark:text-gray-300 dark:hover:text-white">{username}</div>
                                                            <div className="mt-1 text-slate-700 dark:text-gray-400 ">{gender}, {date_of_birth}</div>
                                                            <div className="mt-1 text-slate-700 dark:text-gray-400 ">{age} Y/O, {height}</div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </td>
                                            <td className="px-3 py-2 text-sm text-gray-500 whitespace-nowrap">
                                                <div className="font-semibold text-slate-500 dark:text-gray-400">{email}</div>
                                                <div className="mt-1 text-slate-500 dark:text-gray-400">{phone}</div>
                                                <div className="mt-1 text-slate-500 dark:text-gray-400">{ethnic_origin} from {country}</div>
                                            </td>
                                            <td className="px-3 py-5 text-sm text-gray-500 whitespace-nowrap">
                                                <div className="flex items-center justify-end gap-x-2 sm:justify-start">
                                                    <div className="text-gray-400 sm:hidden">
                                                        {account_status === 0 ? 'Not Active' : 'Active'}
                                                    </div>
                                                    <div className={account_status === 1 ? 'text-green-400 bg-green-400/10 flex-none rounded-full p-1' : 'flex-none rounded-full p-1 text-rose-400 bg-rose-400/10'}>
                                                        <div className="h-1.5 w-1.5 rounded-full bg-current" />
                                                    </div>
                                                    <div className="hidden text-slate-500 dark:text-white sm:block">
                                                        {account_status === 0 ? 'Not Active' : 'Active'}
                                                    </div>
                                                </div>
                                                <div className="flex items-center justify-end gap-x-2 sm:justify-start">
                                                    <div className="text-gray-400 sm:hidden">
                                                        {verification_image !== null ? 'Age verified' : 'Age not verified yet'}
                                                    </div>
                                                    <div className={verification_image !== null ? 'text-green-400 bg-green-400/10 flex-none rounded-full p-1' : 'flex-none rounded-full p-1 text-rose-400 bg-rose-400/10'}>
                                                        <div className="h-1.5 w-1.5 rounded-full bg-current" />
                                                    </div>
                                                    <div className="hidden text-slate-500 dark:text-white sm:block">
                                                        {verification_image !== null ? 'Age verified' : 'Age not verified yet' }
                                                    </div>
                                                </div>
                                                <div className="flex items-center justify-end gap-x-2 sm:justify-start">
                                                    <div className="text-gray-400 sm:hidden">
                                                        {email_verified_at !== null ? 'Email verified' : 'Email not verified yet'}
                                                    </div>
                                                    <div className={email_verified_at !== null ? 'text-green-400 bg-green-400/10 flex-none rounded-full p-1' : 'flex-none rounded-full p-1 text-rose-400 bg-rose-400/10'}>
                                                        <div className="h-1.5 w-1.5 rounded-full bg-current" />
                                                    </div>
                                                    <div className="hidden text-slate-500 dark:text-white sm:block">
                                                        {email_verified_at !== null ? 'Email verified' : 'Email not verified yet' }
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="px-3 py-5 text-sm text-gray-500 whitespace-nowrap">
                                                <div>{subscription ? `Subscribed to ${subscription.name}` : 'No Subscription'}</div>
                                                <div>Trial ends in - {trial_ends_at} Days</div>
                                            </td>
                                            <td className="relative py-5 pl-3 pr-4 text-sm font-medium text-right whitespace-nowrap sm:pr-3">
                                                {show == id ? <button onClick={() => setShow(null)} className="focus:outline-none pl-7">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none">
                                                        <path d="M4.16667 10.8334C4.62691 10.8334 5 10.4603 5 10.0001C5 9.53984 4.62691 9.16675 4.16667 9.16675C3.70643 9.16675 3.33334 9.53984 3.33334 10.0001C3.33334 10.4603 3.70643 10.8334 4.16667 10.8334Z" stroke="#A1A1AA" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M10 10.8334C10.4602 10.8334 10.8333 10.4603 10.8333 10.0001C10.8333 9.53984 10.4602 9.16675 10 9.16675C9.53976 9.16675 9.16666 9.53984 9.16666 10.0001C9.16666 10.4603 9.53976 10.8334 10 10.8334Z" stroke="#A1A1AA" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M15.8333 10.8334C16.2936 10.8334 16.6667 10.4603 16.6667 10.0001C16.6667 9.53984 16.2936 9.16675 15.8333 9.16675C15.3731 9.16675 15 9.53984 15 10.0001C15 10.4603 15.3731 10.8334 15.8333 10.8334Z" stroke="#A1A1AA" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </button> : <button onClick={() => setShow(id)} className="focus:outline-none pl-7">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none">
                                                        <path d="M4.16667 10.8334C4.62691 10.8334 5 10.4603 5 10.0001C5 9.53984 4.62691 9.16675 4.16667 9.16675C3.70643 9.16675 3.33334 9.53984 3.33334 10.0001C3.33334 10.4603 3.70643 10.8334 4.16667 10.8334Z" stroke="#A1A1AA" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M10 10.8334C10.4602 10.8334 10.8333 10.4603 10.8333 10.0001C10.8333 9.53984 10.4602 9.16675 10 9.16675C9.53976 9.16675 9.16666 9.53984 9.16666 10.0001C9.16666 10.4603 9.53976 10.8334 10 10.8334Z" stroke="#A1A1AA" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M15.8333 10.8334C16.2936 10.8334 16.6667 10.4603 16.6667 10.0001C16.6667 9.53984 16.2936 9.16675 15.8333 9.16675C15.3731 9.16675 15 9.53984 15 10.0001C15 10.4603 15.3731 10.8334 15.8333 10.8334Z" stroke="#A1A1AA" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </button>}
                                                {show == id && <div className="absolute right-0 z-10 w-24 mr-6 rounded shadow-lg dropdown-content">
                                                    {/* <div className="w-full px-4 py-2 text-xs cursor-pointer dark:bg-slate-800 dark:text-white text-slate-700 hover:bg-indigo-700 dark:hover:bg-indigo-700 dark:hover:text-white hover:text-white">
                                                        <Link href={route('admin.suspendUser', id)} method="post" as="button">
                                                            Suspend
                                                        </Link>
                                                    </div> */}
                                                    <div className="w-full px-4 py-2 text-xs cursor-pointer dark:bg-slate-800 dark:text-white text-slate-700 hover:bg-indigo-700 dark:hover:bg-indigo-700 dark:hover:text-white hover:text-white">
                                                        <Link href={route('admin.activate_user', id)} method="post" as="button">
                                                            Activate
                                                        </Link>
                                                    </div>
                                                    <div className="w-full px-4 py-2 text-xs cursor-pointer dark:bg-slate-800 dark:text-white text-slate-700 hover:bg-indigo-700 dark:hover:bg-indigo-700 dark:hover:text-white hover:text-white">
                                                        <Link href={route('admin.userProfile', username)} as="a">
                                                            Show
                                                        </Link>
                                                    </div>
                                                </div>}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {suspended_users.length >= 10 &&  (
                                <div className="flex items-center justify-center mb-2">
                                    <button onClick={loadMoreUsers} disabled={isLoadingMore || loading} className="items-center h-10 px-6 py-2 font-bold text-indigo-600 transition duration-300 ease-in-out bg-transparent bg-indigo-100 border border-indigo-400 rounded-md dark:text-indigo-400 dark:bg-slate-800 hover:bg-indigo-600 hover:text-white dark:hover:bg-indigo-400 dark:hover:text-slate-50 dark:border-indigo-400 focus:ring-2 dark:ring-slate-400 sm:inline-flex focus:outline-none">
                                        {isLoadingMore ?
                                        (
                                            <div className="text-center">
                                                <div role="status">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="inline w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-indigo-600">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                                                    </svg>

                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                        )
                                        : 'Load More'}
                                    </button>
                                </div>
                            )}
                            {suspended_users.length === 0 && (
                                <EmptyStateMessage
                                    title="No users!"
                                    description="No suspended user found!"
                                    buttonText="Dashboard"
                                    buttonUrl={route('admin.dashboard')}
                                    icon={
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-full h-full text-gray-700 dark:text-white">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.182 16.318A4.486 4.486 0 0 0 12.016 15a4.486 4.486 0 0 0-3.198 1.318M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75Zm-.375 0h.008v.015h-.008V9.75Zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75Zm-.375 0h.008v.015h-.008V9.75Z" />
                                        </svg>
                                    }
                                />
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>

        </Authenticated>
    </>
  )
}

export default SuspendedUsers
