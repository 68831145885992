import Button from '@/Components/Button';
import Input from '@/Components/Input';
import InputSelect from '@/Components/InputSelect';
import Label from '@/Components/Label';
import RadioButton from '@/Components/RadioButton';
import ResponsiveSidenav from '@/Components/ResponsiveSidenav';
import UserSideNav from '@/Components/UserSideNav';
import Authenticated from '@/Layouts/Authenticated'
import { Link, useForm, usePage } from '@inertiajs/inertia-react';

import React, { useState } from 'react';
import ValidationErrors from '@/Components/ValidationErrors';

function Preferences({auth}) {
    const {user, hasPreferences} = usePage().props;
    const { data, setData, post, processing, errors, reset } = useForm({
        ethnic_origin: user.ethnic_origin ||'',
        country: user.country ||'',
        recidency_status: user.recidency_status || '',
        relocate: user.relocate || '',
        min_height: user.min_height || '',
        max_height: user.max_height || '',
        min_age: user.min_age || '',
        max_age: user.max_age || '',
        marital_status: user.marital_status || '',
        have_children: user.have_children || '',
        // radius: user.radius || '', // x
    });

    const countries = [{
        "name": "Andorra",
        "code": "Andorra"
      }, {
        "name": "Albania",
        "code": "Albania"
      }, {
        "name": "Austria",
        "code": "Austria"
      }, {
        "name": "Åland Islands",
        "code": "Åland Islands"
      }, {
        "name": "Bosnia and Herzegovina",
        "code": "Bosnia and Herzegovina"
      }, {
        "name": "Belgium",
        "code": "Belgium"
      }, {
        "name": "Bulgaria",
        "code": "Bulgaria"
      }, {
        "name": "Belarus",
        "code": "Belarus"
      }, {
        "name": "Switzerland",
        "code": "Switzerland"
      }, {
        "name": "Cyprus",
        "code": "Cyprus"
      }, {
        "name": "Czech Republic",
        "code": "Czech Republic"
      }, {
        "name": "Germany",
        "code": "Germany"
      }, {
        "name": "Denmark",
        "code": "Denmark"
      }, {
        "name": "Estonia",
        "code": "Estonia"
      }, {
        "name": "Spain",
        "code": "Spain"
      }, {
        "name": "Finland",
        "code": "Finland"
      }, {
        "name": "Faroe Islands",
        "code": "Faroe Islands"
      }, {
        "name": "France",
        "code": "France"
      }, {
        "name": "United Kingdom",
        "code": "United Kingdom"
      }, {
        "name": "Guernsey",
        "code": "Guernsey"
      }, {
        "name": "Greece",
        "code": "Greece"
      }, {
        "name": "Croatia",
        "code": "Croatia"
      }, {
        "name": "Hungary",
        "code": "Hungary"
      }, {
        "name": "Ireland",
        "code": "Ireland"
      }, {
        "name": "Isle of Man",
        "code": "Isle of Man"
      }, {
        "name": "Iceland",
        "code": "Iceland"
      }, {
        "name": "Italy",
        "code": "Italy"
      }, {
        "name": "Jersey",
        "code": "Jersey"
      }, {
        "name": "Liechtenstein",
        "code": "Liechtenstein"
      }, {
        "name": "Lithuania",
        "code": "Lithuania"
      }, {
        "name": "Luxembourg",
        "code": "Luxembourg"
      }, {
        "name": "Latvia",
        "code": "Latvia"
      }, {
        "name": "Monaco",
        "code": "Monaco"
      }, {
        "name": "Moldova",
        "code": "Moldova"
      }, {
        "name": "Macedonia, The Former Yugoslav Republic of",
        "code": "Macedonia, The Former Yugoslav Republic of"
      }, {
        "name": "Malta",
        "code": "Malta"
      }, {
        "name": "Netherlands",
        "code": "Netherlands"
      }, {
        "name": "Norway",
        "code": "Norway"
      }, {
        "name": "Poland",
        "code": "Poland"
      }, {
        "name": "Portugal",
        "code": "Portugal"
      }, {
        "name": "Romania",
        "code": "Romania"
      }, {
        "name": "Russian Federation",
        "code": "Russian Federation"
      }, {
        "name": "Sweden",
        "code": "Sweden"
      }, {
        "name": "Slovenia",
        "code": "Slovenia"
      }, {
        "name": "Svalbard and Jan Mayen",
        "code": "Svalbard and Jan Mayen"
      }, {
        "name": "Slovakia",
        "code": "Slovakia"
      }, {
        "name": "San Marino",
        "code": "San Marino"
      }, {
        "name": "Ukraine",
        "code": "Ukraine"
      }, {
        "name": "Holy See (Vatican City State)",
        "code": "Holy See (Vatican City State)"
      }];
      const sortedcs = countries.sort((a,b) => a.name.localeCompare(b.name));
    

    const ethnicOriginOptions = [
        { value: "Mixed Heritage", label: "Mixed Heritage"},
        { value: "Nigerian", label: "Nigerian"},
        { value: "Pakistani", label: "Pakistani"},
        { value: "Persian", label: "Persian"},
        { value: "Somali", label: "Somali"},
        { value: "Sudanese", label: "Sudanese"},
        { value: "Turkish", label: "Turkish"},
        { value: "Other", label: "Other"},
        { value: "Caribbean", label: "Caribbean"},
        { value: "Egyptian", label: "Egyptian"},
        { value: "Indian", label: "Indian"},
        { value: "Asian", label: "Asian"},
        { value: "Indonesian", label: "Indonesian"},
        { value: "Iraqi", label: "Iraqi"},
        { value: "Kurdish", label: "Kurdish"},
        { value: "Latino", label: "Latino"},
        { value: "Malay", label: "Malay"},
        { value: "African American", label: "African American"},
        { value: "North American", label: "North American"},
        { value: "European", label: "European"},
        { value: "Far East Asian", label: "Far East Asian"},
        { value: "Afghan", label: "Afghan"},
        { value: "Bangladeshi", label: "Bangladeshi"},
        { value: "Berber", label: "Berber"},
        { value: "Arab", label: "Arab"},
        { value: "Central African", label: "Central African"},
        { value: "East African", label: "East African"},
        { value: "North African", label: "North African"},
        { value: "South African", label: "South African"},
        { value: "West African", label: "West African"},
    ];


    // Sort the array alphabetically by label
    ethnicOriginOptions.sort((a, b) => {
      const labelA = a.label.toUpperCase();
      const labelB = b.label.toUpperCase();
      if (labelA < labelB) {
          return -1;
      }
      if (labelA > labelB) {
          return 1;
      }
      return 0;
    });


    const residencystatusOptions = [
        { value: "Student visa", label: "Student visa"},
        { value: "Work permit", label: "Work permit"},
        { value: "Citizen", label: "Citizen"},
    ];


  const heightOptions = [
      { value: '122cm - 4\'', label: '122cm - 4\'', cm: 122 },
      { value: '124cm - 4\' 1"', label: '124cm - 4\' 1"', cm: 124 },
      { value: '127cm - 4\' 2"', label: '127cm - 4\' 2"', cm: 127 },
      { value: '130cm - 4\' 3"', label: '130cm - 4\' 3"', cm: 130 },
      { value: '132cm - 4\' 4"', label: '132cm - 4\' 4"', cm: 132 },
      { value: '135cm - 4\' 5"', label: '135cm - 4\' 5"', cm: 135 },
      { value: '137cm - 4\' 6"', label: '137cm - 4\' 6"', cm: 137 },
      { value: '140cm - 4\' 7"', label: '140cm - 4\' 7"', cm: 140 },
      { value: '142cm - 4\' 8"', label: '142cm - 4\' 8"', cm: 142 },
      { value: '145cm - 4\' 9"', label: '145cm - 4\' 9"', cm: 145 },
      { value: '147cm - 4\' 10"', label: '147cm - 4\' 10"', cm: 147 },
      { value: '150cm - 4\' 11"', label: '150cm - 4\' 11"', cm: 150 },
      
      { value: '152cm - 5\'', label: '152cm - 5\'', cm: 152 },
      { value: '155cm - 5\' 1"', label: '155cm - 5\' 1"', cm: 155 },
      { value: '157cm - 5\' 2"', label: '157cm - 5\' 2"', cm: 157 },
      { value: '160cm - 5\' 3"', label: '160cm - 5\' 3"', cm: 160 },
      { value: '163cm - 5\' 4"', label: '163cm - 5\' 4"', cm: 163 },
      { value: '165cm - 5\' 5"', label: '165cm - 5\' 5"', cm: 165 },
      { value: '168cm - 5\' 6"', label: '168cm - 5\' 6"', cm: 168 },
      { value: '170cm - 5\' 7"', label: '170cm - 5\' 7"', cm: 170 },
      { value: '173cm - 5\' 8"', label: '173cm - 5\' 8"', cm: 173 },
      { value: '175cm - 5\' 9"', label: '175cm - 5\' 9"', cm: 175 },
      { value: '178cm - 5\' 10"', label: '178cm - 5\' 10"', cm: 178 },
      { value: '180cm - 5\' 11"', label: '180cm - 5\' 11"', cm: 180 },

      { value: '183cm - 6\'', label: '183cm - 6\'', cm: 183 },
      { value: '185cm - 6\' 1"', label: '185cm - 6\' 1"', cm: 185 },
      { value: '188cm - 6\' 2"', label: '188cm - 6\' 2"', cm: 188 },
      { value: '191cm - 6\' 3"', label: '191cm - 6\' 3"', cm: 191 },
      { value: '193cm - 6\' 4"', label: '193cm - 6\' 4"', cm: 193 },
      { value: '196cm - 6\' 5"', label: '196cm - 6\' 5"', cm: 196 },
      { value: '198cm - 6\' 6"', label: '198cm - 6\' 6"', cm: 198 },
      { value: '201cm - 6\' 7"', label: '201cm - 6\' 7"', cm: 201 },
      { value: '203cm - 6\' 8"', label: '203cm - 6\' 8"', cm: 203 },
      { value: '206cm - 6\' 9"', label: '206cm - 6\' 9"', cm: 206 },
      { value: '208cm - 6\' 10"', label: '208cm - 6\' 10"', cm: 208 },
      { value: '211cm - 6\' 11"', label: '211cm - 6\' 11"', cm: 211 },

      { value: '213cm - 7\'', label: '213cm - 7\'', cm: 213 },
      { value: '216cm - 7\' 1"', label: '216cm - 7\' 1"', cm: 216 },
      { value: '218cm - 7\' 2"', label: '218cm - 7\' 2"', cm: 218 },
      { value: '221cm - 7\' 3"', label: '221cm - 7\' 3"', cm: 221 },
      { value: '224cm - 7\' 4"', label: '224cm - 7\' 4"', cm: 224 },
      { value: '226cm - 7\' 5"', label: '226cm - 7\' 5"', cm: 226 },
      { value: '229cm - 7\' 6"', label: '229cm - 7\' 6"', cm: 229 },
      { value: '231cm - 7\' 7"', label: '231cm - 7\' 7"', cm: 231 },
      { value: '234cm - 7\' 8"', label: '234cm - 7\' 8"', cm: 234 },
      { value: '236cm - 7\' 9"', label: '236cm - 7\' 9"', cm: 236 },
  ];
  

    const maritalStatusOptions = [
        { value: "Single", label: "Single" },
        { value: "Divorced", label: "Divorced" },
        { value: "Widowed", label: "Widowed" },
    ];

    // const radiusOptions = [
    //     { value: "Any", label: "Any"},
    //     { value: "2mi", label: "2mi"},
    //     { value: "5mi", label: "5mi"},
    //     { value: "7mi", label: "7mi"},
    //     { value: "10mi", label: "10mi"},
    //     { value: "15mi", label: "15mi"},
    //     { value: "20mi", label: "20mi"},
    //     { value: "25mi", label: "25mi"},
    //     { value: "30mi", label: "30mi"},
    //     { value: "35mi", label: "35mi"},
    //     { value: "40mi", label: "40mi"},
    //     { value: "50mi", label: "50mi"},
    //     { value: "60mi", label: "60mi"},
    //     { value: "70mi", label: "70mi"},
    //     { value: "80mi", label: "80mi"},
    //     { value: "90mi", label: "90mi"},
    //     { value: "100mi", label: "100mi"},
    //     { value: "120mi", label: "120mi"},
    //     { value: "130mi", label: "130mi"},
    //     { value: "160mi", label: "160mi"},
    //     { value: "190mi", label: "190mi"},
    //     { value: "200mi", label: "200mi"},
    //     { value: "250mi", label: "250mi"},
    //     { value: "300mi", label: "300mi"},
    //     { value: "400mi", label: "400mi"},
    //     { value: "500mi", label: "500mi"},
    // ];

    const childrenOptions = [
        { value: "Yes", label: "Yes" },
        { value: "No", label: "No" },
    ];

    const onHandleChange = (e) => {
        setData(e.target.name, e.target.type === 'hidden' ? e.target.value : e.target.value);
        // setData({ ...data, [e.target.name]: e.target.value });
    };

    const submit = (e) => {
        e.preventDefault();
        post('preferences/set');
    };


    return (
        <Authenticated 
                auth={auth}
                errors={errors}
                header={<h2 className="text-xl font-semibold leading-tight text-gray-800 dark:text-slate-50 ">Preferences <br/>
                <span className="mb-1 text-sm font-semibold leading-6 text-red-400 dark:text-red-400">Matches - Preferences</span>
                </h2>}
                btnName="Back"
                svg={<svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 mr-0 sm:mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="3"><path strokeLinecap="round" strokeLinejoin="round" d="M10 19l-7-7m0 0l7-7m-7 7h18" /></svg>}
                href={route('home')}
        >

        <ResponsiveSidenav />

        <div className="px-2 mx-auto max-w-7xl sm:px-6 md:px-8">
            <div className="flex-row sm:flex sm:space-x-2">
                <div className="hidden sm:block sm:w-[250px] h-min ">
                <UserSideNav/>
                </div>
                
                <div className="p-3 mt-2 rounded-md shadow-sm sm:mt-0 sm:w-2/3 bg-gray-50 dark:bg-slate-800 sm:p-10">
                    <div className="pt-5 xl:max-w-none">
                        
                        <div className="">
                            <form className="px-2 sm:px-12" onSubmit={submit}>
                                <header id="header" className="relative z-20">
                                    <div className='flex items-center justify-between'>
                                        <div className="">
                                            <h1 className="inline-block text-2xl font-semibold tracking-tight sm:text-xl text-slate-900 dark:text-slate-200">Preferences</h1>
                                            <p className="mb-1 text-sm font-normal text-slate-500 dark:text-slate-300">We will only recommend profiles matched with your preferences.</p>
                                        </div>
                                        <div>
                                            <div className="flex items-center justify-end space-x-1">
                                                {hasPreferences && (
                                                    <Link
                                                        href="/preferences/clear"
                                                        className="mr-3 text-sm font-semibold text-red-400 hover:border-b-2 hover:border-red-400"
                                                    >
                                                        Clear Filter
                                                    </Link>
                                                )}
                                                <button type="submit" className='flex items-center justify-center px-4 py-2 text-sm font-semibold text-purple-500 transition duration-150 ease-in-out border border-purple-500 rounded-md shadow-sm dark:text-purple-500 bg-tranparent hover:bg-purple-600 hover:text-white dark:hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                                    </svg>
                                                <span className='ml-1'>Apply</span> 
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </header>
                                <div id="filterSection" className={"relative md:py-10 lg:px-20 md:px-6 py-5 px-2 bg-gray-50 dark:bg-slate-800 mt-2 w-full block"}>
                                    <div> 
                                        <div className="flex items-start pb-2 space-x-6 border-b border-gray-300 dark:border-gray-700 dark:bg-gray-800">
                                            <p className="flex-none text-lg font-semibold text-gray-800 dark:text-gray-100">Set preferences</p>
                                            
                                        </div>
                                        <ValidationErrors errors={errors} />

                                        {/* <div className="mt-4 space-y-1">
                                            <Label className="dark:text-white" forInput="radius" value="Limit location by distance" />
                                            <InputSelect 
                                                value={data.radius} 
                                                onChange={onHandleChange} 
                                                options={radiusOptions} 
                                                className={`block w-full sm:text-sm dark:text-slate-50`} 
                                                placeholder='Select radius'
                                                name={`radius`}
                                            />
                                        </div> */}

                                        <div className="mt-4 space-y-1">
                                            <Label className="dark:text-white" forInput="ethnic-origin" value="Ethnic Origin" />
                                            <InputSelect 
                                                value={data.ethnic_origin} 
                                                onChange={onHandleChange} 
                                                options={ethnicOriginOptions} 
                                                className={`block w-full sm:text-sm dark:text-slate-50`} 
                                                placeholder='Select ethnic origin'
                                                name={`ethnic_origin`}
                                            />
                                        </div>
                                        
                                        <div className="mt-4 space-y-1">
                                            <Label forInput="country" value="Country" />
                                            <select
                                                className={
                                                    `border-gray-300 py-3 px-4 rounded-xl shadow-sm text-sm text-slate-500 dark:text-slate-200`
                                                }
                                                name="country"
                                                onChange={onHandleChange}
                                                value={data.country}
                                                
                                            >
                                                <option className="text-slate-500 dark:text-slate-200" value={''}>Select a country</option>
                                                {sortedcs.map((country) => {
                                                    return (
                                                        <option className="text-slate-500 dark:text-slate-200" key={country.code} value={country.name} multiple>
                                                            {country.name}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>

                                        <div className="mt-4 space-y-1">
                                            <Label className="dark:text-white" forInput="recidency_status" value="Recidency status" />
                                            <InputSelect 
                                                value={data.recidency_status} 
                                                onChange={onHandleChange} 
                                                options={residencystatusOptions} 
                                                className={`block w-full sm:text-sm dark:text-slate-50`} 
                                                placeholder='Select residency status'
                                                name={`recidency_status`}
                                            />
                                        </div>
                                    </div>
                                        <div>
                                            <div className="flex items-start pb-2 mt-4 space-x-6 border-b border-gray-300 dark:border-gray-700 dark:bg-gray-800">
                                                <p className="flex-none text-lg font-semibold text-gray-800 dark:text-gray-100">Profile Basics</p>
                                                <div className="relative flex-auto min-w-0">
                                                    <div className="p-2 text-gray-600 cursor-pointer dark:text-gray-400">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16}>
                                                            <path className="heroicon-ui" d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9a1 1 0 0 1 1 1v4a1 1 0 0 1-2 0v-4a1 1 0 0 1 1-1zm0-4a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" fill="currentColor" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                              <div className="mt-4">
                                                  <div className="flex items-center space-x-2 justify-evenly">
                                                      <div className="w-full space-y-1"> 
                                                          <Label forInput="min_height" value="Min Height" />
                                                          <InputSelect 
                                                              value={data.min_height} 
                                                              onChange={onHandleChange} 
                                                              options={heightOptions} 
                                                              className={`block w-full sm:text-sm dark:text-slate-50`} 
                                                              placeholder='Select min height'
                                                              name={`min_height`}
                                                          />
                                                      </div>
                                                      <div className="w-full space-y-1"> 
                                                          <Label forInput="max_height" value="Max Height" />
                                                          <InputSelect 
                                                              value={data.max_height} 
                                                              onChange={onHandleChange} 
                                                              options={heightOptions} 
                                                              className={`block w-full sm:text-sm dark:text-slate-50`} 
                                                              placeholder='Select max height'
                                                              name={`max_height`}
                                                          />
                                                      </div>
                                                  </div>
                                              </div>
                                            
                                                <div className="mt-4">
                                                    <div className="flex items-center space-x-2 justify-evenly">
                                                        <div className="w-full space-y-1"> 
                                                            <Label forInput="min_age" value="Age from" />
                                                            <Input
                                                                value={data.min_age}
                                                                handleChange={onHandleChange}
                                                                type="number"
                                                                className={`mt-1 block w-full sm:text-sm dark:text-slate-50`} 
                                                                placeholder='Any Age'
                                                                name={`min_age`}
                                                                min={18}
                                                                max={50}
                                                                title='Min age 18'
                                                                // pattern="\d{4,4}"
                                                            />
                                                        </div>
                                                        <div className="w-full space-y-1"> 
                                                            <Label forInput="max_age" value="Age to" />
                                                            <Input 
                                                                type="number"
                                                                value={data.max_age}
                                                                handleChange={onHandleChange}
                                                                className={`mt-1 block w-full sm:text-sm dark:text-slate-50`} 
                                                                placeholder='Any Age'
                                                                name={`max_age`}
                                                                min="18"
                                                                max="50"
                                                                title='Max age 50'

                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="mt-4 space-y-1">
                                                        <Label forInput="marital_status" value="Marital Status" />
                                                        <InputSelect 
                                                            value={data.marital_status} 
                                                            onChange={onHandleChange} 
                                                            options={maritalStatusOptions} 
                                                            className={`block w-full sm:text-sm dark:text-slate-50`} 
                                                            placeholder='Select your preferred marital status'
                                                            name={`marital_status`}
                                                        />
                                                    </div>
                                                
                                                    <div className="mt-4 space-y-1">
                                                        <Label forInput="have_children" value="Show profile members with children " />
                                                        <InputSelect 
                                                            value={data.have_children} 
                                                            onChange={onHandleChange} 
                                                            options={childrenOptions} 
                                                            className={`block w-full sm:text-sm dark:text-slate-50`} 
                                                            placeholder='Select your preferred option'
                                                            name={`have_children`}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        
                                            
                                            <hr className="w-full my-8 bg-gray-200/50 dark:bg-gray-50/50 md:my-10 md:mb-5" />

                                            <div className="w-full px-0 mt-10 md:w-auto md:mt-4 md:absolute md:right-0 md:bottom-0 md:py-10 lg:px-20 md:px-6">
                                                
                                                <button className="w-full px-10 py-4 text-base font-medium leading-4 bg-purple-600 rounded-md text-slate-50 hover:text-white hover:bg-purple-700 focus:ring focus:ring-offset-2 focus:ring-gray-800">
                                                    Apply Filter
                                                </button>
                                            </div>
                                        </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>  
    </Authenticated>
  )
}

export default Preferences
{/* <div>
    <div className="flex items-start pb-2 space-x-6 border-b border-gray-300 dark:border-gray-700 dark:bg-gray-800">
        <p className="flex-none text-lg font-semibold text-gray-800 dark:text-gray-100">Advance Filters</p>
        <div className="relative flex-auto min-w-0">
            <div className="p-2 text-gray-600 cursor-pointer dark:text-gray-400">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16}>
                    <path className="heroicon-ui" d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9a1 1 0 0 1 1 1v4a1 1 0 0 1-2 0v-4a1 1 0 0 1 1-1zm0-4a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" fill="currentColor" />
                </svg>
            </div>
        </div>
    </div>
    
    <div className="mt-4">
        <Label forInput="highest_education" value="Highest Education" />
        <InputSelect 
            value={data.highest_education} 
            onChange={onHandleChange} 
            options={educationOptions} 
            className={`block w-full sm:text-sm dark:text-slate-50`} 
            placeholder='Preffered educational qualification'
            name={`highest_education`}
        />
    </div>

    <div className="mt-4 dark:text-slate-50">
        <Label className="dark:text-white" forInput="get_married" value="Preferred marriage time ?" />
        <InputSelect 
            value={data.get_married} 
            onChange={onHandleChange} 
            options={getMarriedOptions} 
            className={`block w-full sm:text-sm dark:text-slate-50`} 
            placeholder='When are you planning to get married ?'
            name={`get_married`}
        />
    </div>

    <div className="mt-4">
        <Label className="dark:text-white" forInput="continue_working" value="Do you intend to continue working when married?" />
        <InputSelect 
            value={data.continue_working} 
            onChange={onHandleChange} 
            options={continueWorkingOptions} 
            className={`block w-full sm:text-sm dark:text-slate-50`} 
            placeholder='Select an option'
            name={`continue_working`}
        />
    </div>
    
    <div className="mt-4">
        <Label className="dark:text-white" forInput="intend_to_move_out" value="Do you intend to move out?" />
        <InputSelect 
            value={data.intend_to_move_out} 
            onChange={onHandleChange} 
            options={moveOutOptions} 
            className={`block w-full sm:text-sm dark:text-slate-50`} 
            placeholder='Select an option'
            name={`intend_to_move_out`}
        />
    </div>

    <div className="mt-4">
        <Label className="dark:text-white" forInput="issues_living_with_inlaws" value="Do you have issues living with in laws?" />
        <InputSelect 
            value={data.issues_living_with_inlaws} 
            onChange={onHandleChange} 
            options={moveOutOptions} 
            className={`block w-full sm:text-sm dark:text-slate-50`} 
            placeholder='Select an option'
            name={`issues_living_with_inlaws`}
        />
    </div>

    <div className="mt-4">
        <Label className="dark:text-white" forInput="issues_living_with_inlaws" value="About your ideal partner" />
        <textarea id="bio" name="bio" onChange={onHandleChange}  value={data.bio}  className="py-3 pl-3 mt-1 text-sm text-gray-500 placeholder-gray-500 bg-transparent border border-gray-300 rounded-lg shadow-sm resize-none dark:border-gray-700 focus:outline-none focus:border-indigo-700 dark:text-gray-400" placeholder="How would you describe your ideal partner..." rows={5} />
        <textarea className="input" name="bio" rows="10" cols="10">{data.bio}</textarea> 

        <p className="w-full pt-1 text-xs text-right text-gray-500 dark:text-gray-400">Character Limit: 1000</p>
    </div>
</div> */}
{/* FOR multiple options */}
    {/* <Select
        closeMenuOnSelect={false}
        defaultValue={[ethnicOriginOptions[4], ethnicOriginOptions[5]]}
        isMulti
        // value={data.ethnic_origin} 
        className={`block w-full sm:text-sm dark:text-slate-50`} 
            name={`ethnic_origin`}
            options={ethnicOriginOptions}
    /> */}
{/* FOR multiple options */}

{/* <Select
    closeMenuOnSelect={false}
    defaultValue={[countriesOption[4], countriesOption[5]]}
    isMulti
    // value={data.ethnic_origin} 
    className={`block w-full sm:text-sm dark:text-slate-50`} 
        name={`ethnic_origin`}
        options={countriesOption}
    /> */}

{/* <div className="mt-4">
    <Label forInput="relocate" value="Move abroad" />

    <InputSelect 
        value={data.relocate} 
        onChange={onHandleChange} 
        options={relocateOptions} 
        className={`block w-full sm:text-sm dark:text-slate-50`} 
        placeholder='Are you willing to relocate?'
        name={`relocate`}
    />
</div> */}
        {/* <div className="mt-4">
            <Label forInput="have_children" value="Do you have children" />
            <InputSelect 
                value={data.have_children} 
                onChange={onHandleChange} 
                options={haveChildrenOptions} 
                className={`block w-full sm:text-sm dark:text-slate-50`} 
                placeholder='Select how many'
                name={`have_children`}
            />
        </div> 

        <div className="mt-4">
            <div className="flex items-baseline pb-2 mb-2 space-x-2">
                <div className="w-1/3">
                    <Label forInput="like_to_have_children" value="Like to have children" />
                </div>
                <div className="flex justify-end w-2/3 space-x-1">
                    <RadioButton name="like_to_have_children" value={`Yes`} btnName="Yes" handleChange={onHandleChange}  />
                    <RadioButton name="like_to_have_children" value={`No`} btnName="No"  handleChange={onHandleChange} />
                    <RadioButton name="like_to_have_children" value={`Yes`} btnName="I don't mind" handleChange={onHandleChange} />
                </div>
            </div>
        </div> */}