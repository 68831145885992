import { XIcon } from '@heroicons/react/outline';
import { Link, usePage } from '@inertiajs/inertia-react'
import React from 'react'

function FailedPayment({auth, user}) {
    return (
        <div class="flex items-center justify-center h-screen">
            <div className="relative px-4 pb-4 overflow-hidden text-left transition-all transform bg-white border-2 border-red-400 rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
                <div>
                  <div className="flex items-center justify-center w-12 h-12 mx-auto mt-5 bg-red-100 rounded-full sm:mt-0">
                    <XIcon className="w-6 h-6 text-red-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                  <h1 className="text-2xl font-semibold leading-6 text-red-500">
                        Payment unsuccessful!
                    </h1>
                    <h1 className="mt-3 text-lg font-semibold leading-6 text-gray-700">
                        Problem verifying your {user.pm_type} **** {user.pm_last_four}
                    </h1>
                    <div className="mt-2">
                      <p className="text-base text-gray-800">Our attempt to collect payment for your current subscription was unsuccessful. Please update your payment method.</p>
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-between gap-2 mt-5 sm:mt-6">
                  <a
                    href="/billing-portal"
                    className="inline-flex justify-center w-full px-3 py-2 text-sm font-semibold text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Update payment method
                  </a>
                  <Link
                    href='/logout'
                    as="button"
                    method='post'
                    className="inline-flex justify-center w-full px-3 py-2 text-sm font-semibold text-white rounded-md shadow-sm bg-slate-600 hover:bg-slate-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600"
                  >
                    Logout
                  </Link>
                </div>
              </div>
        </div>
    )
}

export default FailedPayment