import Authenticated from '@/Layouts/Authenticated';
import { Link, usePage } from '@inertiajs/inertia-react';
import React from 'react'

function PublicProfile(props) {
    const {user, userImages} = usePage().props;

    const getRandomGradient = () => {
        const baseColors = ['#6327D8', '#F77171'];
        const gradients = [];
      
        for (let i = 0; i < 20; i++) {
          const opacity = Math.random() * 0.5 + 0.5; // Adjust the opacity range as needed
          const color1 = `${baseColors[0]}${Math.round(opacity * 255).toString(16)}`;
          const color2 = `${baseColors[1]}${Math.round(opacity * 255).toString(16)}`;
      
          const gradient = `linear-gradient(45deg, ${color1}, ${color2})`;
          gradients.push(gradient);
        }
      
        const randomIndex = Math.floor(Math.random() * gradients.length);
        return gradients[randomIndex];
      };

    return (
    <Authenticated 
        auth={props.auth}
        errors={props.errors}
        header={
            <div className='flex justify-center items-center py-2 px-4 border border-purple-500 rounded-md shadow-sm text-sm font-semibold text-purple-500 dark:text-white bg-tranparent' href="/public-profile">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                </svg>
                <span className='ml-1 font-medium'>Viewing As</span>
            </div>
        }
        // btnName="Back"
        svg={<svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2"><path strokeLinecap="round" strokeLinejoin="round" d="M10 19l-7-7m0 0l7-7m-7 7h18" /></svg>}
        href={route('auth.user.profile')}
    >

    <div className="max-w-5xl mx-auto px-2 sm:px-6 md:px-8">
        <div className="mb-4 pt-4 rounded-lg">
            <div className="dark:bg-slate-800 shadow-sm rounded-md relative mx-auto flex h-auto w-full flex-col items-center bg-white bg-cover bg-clip-border p-[16px] dark:text-slate-50 dark:shadow-none">
                {/* Dynamic Banner image */}
                <div
                    className="relative mt-1 flex h-32 w-full justify-center rounded-xl bg-cover"
                    style={{ backgroundImage: getRandomGradient() }}
                >
                    <div className="absolute -bottom-12 flex h-[100px] w-[100px] items-center justify-center rounded-full border-[4px] border-white bg-red-400">
                        <img
                            className={`flex-none h-full w-full rounded-full object-cover`}
                            src={user.profile_image}
                            alt={`${user.username}'s avatar`}
                        />
                    </div>
                </div>

                <div className="mt-16 flex flex-col items-center">
                    <h4 className="text-slate-800 dark:text-slate-200 text-xl font-bold">{user.username}, {user.age} (y/o)</h4>
                    <p className="text-slate-600 leading-6 dark:text-slate-300 text-base font-medium"> {user.ethnic_origin} From {user.country}</p>
                </div>

                <div className="mt-6 mb-3 flex gap-2">
                    {/* Invite button */}
                    <div className="flex flex-col items-center justify-center">
                        <button disabled className="disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none text-slate-800 dark:text-slate-500 dark:bg-slate-800 hover:bg-slate-800 hover:text-slate-50 dark:hover:bg-slate-50 border border-purple-700 bg-purple-300/50 dark:border-purple-500 focus:ring-2 dark:ring-slate-400 font-medium py-2 h-10 px-6 rounded-md inline-flex items-center focus:outline-none transition duration-150 ease-in-out">
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
                            </svg>
                            <span className="text-xs sm:text-sm font-medium">Invite</span>
                        </button>
                    </div>
                    {/* Save button */}
                    <div className="flex flex-col items-center justify-center">
                        <button disabled preserveScroll method="post" href={route('save.user', user.id)} className="flex-none flex items-center justify-center w-9 h-9 rounded-md text-slate-300 border border-slate-200 dark:border-slate-500 hover:bg-slate-800 hover:text-slate-50 dark:hover:bg-slate-50 dark:text-slate-600" type="button" aria-label="Like">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                            </svg>
                        </button> 
                    </div>
                    {/* Pass button */}                 
                    <div className="flex flex-col items-center justify-center">
                        <button disabled preserveScroll method="post" href={route('pass-user', user.id)} className="flex-none flex items-center justify-center w-9 h-9 rounded-md text-slate-300 border border-slate-200 dark:border-slate-500 hover:bg-slate-800 hover:text-slate-50 dark:hover:bg-slate-50 dark:text-slate-600" type="button" aria-label="Like">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                            </svg>
                        </button> 
                    </div>
                </div>
            </div>
            
        </div>
        <div className="flex-row sm:flex sm:space-x-2">
            <div className="mt-2 sm:mt-0 sm:w-full bg-gray-50 dark:bg-slate-800 p-6 sm:p-10 rounded-md shadow-sm">
                <div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mt-8"> 
                        
                        <div className="rounded-lg">
                            <div className="inline-flex items-center justify-center ">
                                <div className="inline-flex items-center justify-center p-2 mb-2 bg-slate-50 dark:bg-slate-800 dark:border dark:border-blue-300 shadow rounded-full"> 
                                    <img src="../assets/images/user.svg" className="h-8 w-8" />
                                </div>
                                <p className="text-lg font-semibold text-slate-700 dark:text-slate-300 pl-2 pr-2">Profile Basic</p>
                            </div>

                            <div className="mt-1">
                                <dl className="">
                                    <div className=" grid grid-cols-3 gap-4">
                                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Age</dt>
                                        <dd className="text-sm leading-6 text-gray-700 dark:text-slate-200 col-span-2">{user.age} </dd>
                                    </div>
                                    <div className=" grid grid-cols-3 gap-4">
                                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Height</dt>
                                        <dd className="text-sm leading-6 text-gray-700 dark:text-slate-200 col-span-2">{user.height} </dd>
                                    </div>
                                    <div className=" grid grid-cols-3 gap-4">
                                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Ethnicity</dt>
                                        <dd className="text-sm leading-6 text-gray-700 dark:text-slate-200 col-span-2">{user.ethnic_origin} </dd>
                                    </div>
                                    {/* <div className=" grid grid-cols-3 gap-4">
                                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Last active</dt>
                                        <dd className="text-sm leading-6 text-gray-700 dark:text-slate-200 col-span-2">{user.login_time} </dd>
                                    </div> */}
                                </dl>
                            </div>
                        </div>

                        <div className="rounded-lg">
                            <div className="inline-flex items-center justify-center ">
                                <div className="inline-flex items-center justify-center p-2 mb-2 bg-slate-50 dark:bg-slate-800 dark:border dark:border-sky-400 shadow rounded-full"> 
                                    <img src="../assets/images/profession.svg" className="h-8 w-8" />
                                </div>
                                <p className="text-lg font-semibold text-slate-700 dark:text-slate-300 pl-2 pr-2">Education/Career</p>
                            </div>
                            <div className="mt-1">
                                <dl className="">
                                    <div className="mt-1 grid grid-cols-3 gap-4">
                                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">University / College</dt>
                                        <dd className="text-sm leading-6 text-gray-700 dark:text-slate-200 col-span-2">{user.university} </dd>
                                    </div>
                                    <div className=" grid grid-cols-3 gap-4">
                                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Course</dt>
                                        <dd className="text-sm leading-6 text-gray-700 dark:text-slate-200 col-span-2">{user.course_name} </dd>
                                    </div>
                                    {user.college != null && (
                                        <div className="mt-1">
                                            <div className=" grid grid-cols-3 gap-4">
                                                <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">University / College</dt>
                                                <dd className="text-sm leading-6 text-gray-700 dark:text-slate-200 col-span-2">{user.college} </dd>
                                            </div>
                                            <div className=" grid grid-cols-3 gap-4">
                                                <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Course</dt>
                                                <dd className="text-sm leading-6 text-gray-700 dark:text-slate-200 col-span-2">{user.college_course_name} </dd>
                                            </div>
                                        </div>
                                     )}

                                    <div className=" grid grid-cols-3 gap-4">
                                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Profession</dt>
                                        <dd className="text-sm leading-6 text-gray-700 dark:text-slate-200 col-span-2">{user.current_profession} </dd>
                                    </div>
                                </dl>
                            </div>
                        </div>

                        <div className="rounded-lg">
                            <div className="inline-flex items-center justify-center ">
                                <div className="inline-flex items-center justify-center p-2 mb-2 bg-slate-50 dark:bg-slate-800 dark:border dark:border-red-400 shadow rounded-full"> 
                                    <img src="../assets/images/globe.svg" className="h-8 w-8" />
                                </div>
                                <p className="text-lg font-semibold text-slate-700 dark:text-slate-300 pl-2 pr-2">Location</p>
                            </div>
                            <div className="mt-1">
                                <dl className="">
                                    <div className=" grid grid-cols-3 gap-4">
                                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Address</dt>
                                        <dd className="text-sm leading-6 text-gray-700 dark:text-slate-200 col-span-2">{user.city}, {user.country} </dd>
                                    </div>
                                    <div className=" grid grid-cols-3 gap-4">
                                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Residency status</dt>
                                        <dd className="text-sm leading-6 text-gray-700 dark:text-slate-200 col-span-2">{user.recidency_status} </dd>
                                    </div>
                                    <div className=" grid grid-cols-3 gap-4">
                                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Relocate</dt>
                                        <dd className="text-sm leading-6 text-gray-700 dark:text-slate-200 col-span-2">{user.relocate == 'Yes' ? 'Willing to relocate' : 'No intention to relocate'}</dd>
                                    </div>
                                    <p className="text-sm font-semibold leading-6 text-gray-900 dark:text-slate-200">Back home</p>
                                    <div className=" grid grid-cols-3 gap-4">
                                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Address</dt>
                                        <dd className="text-sm leading-6 text-gray-700 dark:text-slate-200 col-span-2">{user.back_home_area}, {user.back_home_city}, {user.back_home_country} </dd>
                                    </div>
                                </dl>
                            </div>
                        </div>

                        
                        

                        <div className="rounded-lg">
                            <div className="inline-flex items-center justify-center ">
                                <div className="inline-flex items-center justify-center p-2 mb-2 bg-slate-50 dark:bg-slate-800 dark:border dark:border-red-400 shadow rounded-full"> 
                                    <img src="../assets/images/mosque.svg" className="h-8 w-8 object-center" />
                                </div>
                                <p className="text-lg font-semibold text-slate-700 dark:text-slate-300 pl-2 pr-2">Religious Observance</p>
                            </div>
                            
                            <div className="mt-1">
                                <dl className="">
                                    <div className="grid grid-cols-3 gap-4">
                                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Religious History</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-slate-200">Muslim - {user.religious_history}</dd>
                                    </div>
                                    <div className="grid grid-cols-3 gap-4">
                                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Sect</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-slate-200">{user.sect}</dd>
                                    </div>
                                    <div className="grid grid-cols-3 gap-4">
                                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Pray</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-slate-200">{user.prayer_frequency}</dd>
                                    </div>
                                    <div className="grid grid-cols-3 gap-4">
                                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Read Quran</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-slate-200 sm:col-span-2 sm:mt-0">{user.read_quran}</dd>
                                    </div>
                                    <div className="grid grid-cols-3 gap-4">
                                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Eat Halal</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-slate-200 sm:col-span-2 sm:mt-0">{user.eat_halal}</dd>
                                    </div>
                                    
                                    <div className="grid grid-cols-3 gap-4">
                                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Drink Alchohol</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 dark:text-slate-200 sm:col-span-2 sm:mt-0">{user.drink_alchohol}</dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                        
                        <div className="rounded-lg">
                            <div className="inline-flex items-center justify-center ">
                                <div className="inline-flex items-center justify-center p-2 mb-2 bg-slate-50 dark:bg-slate-800 dark:border dark:border-green-300 shadow rounded-full"> 
                                    <img src="../assets/images/ring.svg" className="h-8 w-8" />
                                </div>
                                <p className="text-lg font-semibold text-slate-700 dark:text-slate-300 pl-2 pr-2">Personal</p>
                            </div>

                            <div className="mt-1">
                                <dl className="">
                                    <div className=" grid grid-cols-3 gap-4">
                                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Marital Status</dt>
                                        <dd className="text-sm leading-6 text-gray-700 dark:text-slate-200 col-span-2">{user.marital_status} </dd>
                                    </div>
                                    <div className=" grid grid-cols-3 gap-4">
                                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Have children</dt>
                                        <dd className="text-sm leading-6 text-gray-700 dark:text-slate-200 col-span-2">{user.have_children} </dd>
                                    </div>
                                </dl>
                            </div>
                        </div>

                        <div className="rounded-lg ">
                            <div className="inline-flex items-center justify-center ">
                                <div className="inline-flex items-center justify-center p-2 mb-2 bg-slate-50 dark:bg-slate-800 dark:border dark:border-purple-400 shadow rounded-full"> 
                                    <img src="../assets/images/family.svg" className="h-8 w-8" />
                                </div>
                                <p className="text-lg font-semibold text-slate-700 dark:text-slate-300 pl-2 pr-2">Family</p>
                            </div>
                            <div className="mt-1">
                                <dl className="">
                                    <div className=" grid grid-cols-3 gap-4">
                                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Siblings</dt>
                                        <dd className="text-sm leading-6 text-gray-700 dark:text-slate-200 col-span-2">{user.siblings == 0 ? 'No Siblings' : user.siblings + ' siblings'}</dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </div>

                    <div className="col-span-2 mt-2">
                        <hr className="bg-gray-200 border-0 h-[0.1rem] dark:bg-gray-700" />
                    </div>

                    <div className="col-span-2 mt-4">
                        <h1 className="text-lg text-slate-700 dark:text-slate-200 font-semibold ">About {user.username}</h1>
                        <p className="whitespace-pre-line align-baseline text-justify leading-6 tracking-wide text-slate-700 dark:text-slate-300">
                            {user.bio}
                        </p>

                        <div className="mt-4"> 
                            <h1 className="text-lg text-slate-700 dark:text-slate-200 font-semibold mb-4">Photos</h1>
                            {userImages.length !== 0 ? 
                                <div class="grid grid-cols-2 gap-4 sm:grid-cols-4 sm:gap-4">
                                    {userImages.map(({image, id}) => (
                                        <div key={id}>
                                            <a href="#"
                                                className="group relative flex h-48 items-end overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-80">
                                                <img src={image} loading="lazy" alt="Photo by Minh Pham" className={`${user.blur_profile_image === 1 ? 'blur-lg' : ''} absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110`} />

                                                <div
                                                    class="pointer-events-none absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50">
                                                </div>
                                            </a>
                                        </div>
                                    ))}
                                </div>
                                : 
                                <p className="text-sm font-semibold text-slate-700 dark:text-slate-200">{user.username} hasn't yet uploaded any photos.</p>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    </Authenticated>
  )
}

export default PublicProfile