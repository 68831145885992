import React from 'react';

const Avatar = ({ src, size = '40px', className = '' }) => {
  return (
    <div
      className={`shrink-0 inline-flex items-center justify-center overflow-hidden rounded-full w-${size} h-${size} text-base ${className}`}
    >
      {src ? (
        <img
          className={`shrink-0 w-full h-full object-cover object-center rounded-full`}
          src={src}
          alt="Profile image"
          loading="lazy"

        />
      ) : (
        <svg
          className={`shrink-0 w-10 h-10 text-gray-300 bg-gray-100 dark:bg-gray-600`}
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
        </svg>
      )}
    </div>
  );
};

export default Avatar;