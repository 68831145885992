import Authenticated from '@/Layouts/Authenticated';
import React, { useRef, useState } from 'react'
import { Head, Link, useForm, usePage } from '@inertiajs/inertia-react';
import Button from '@/Components/Button';
import ValidationErrors from '@/Components/ValidationErrors';
import { Inertia } from '@inertiajs/inertia';

function Selfie({auth, errors, is_verified}) {
    const {verification_image, selfie} = usePage().props;

    const photoRef = useRef();

    const [selectedFile, setSelectedFile] = useState();
    const [link, setLink] = useState(false);
    const [values, setValues] = useState({ photo: ''});
    
    const onHandleChange = (e) => {
        const formData = new FormData();

        setSelectedFile(URL.createObjectURL(e.target.files[0]));
        formData.append('photo', photoRef.current.files[0]);

        Inertia.post('/settings/upload-verification-image/selfie', formData, {
            forceFormData: true,
        });

        setLink(true);
    };

    const submit = (e) => {
        e.preventDefault();
        // setSelectedFile(URL.createObjectURL(e.target.files[0]));
        // const formData = new FormData();

        // formData.append('photo', photoRef.current.files[0]);
        // Inertia.post('/settings/upload-verification-image');
    };

    return (
        <Authenticated 
            auth={auth}
            errors={errors}
            header={<h2 className="inline-flex items-center justify-center gap-2 font-semibold text-xl text-gray-800 dark:text-slate-50 leading-tight ">Verify age<span className="text-sm font-medium"> / Upload selfie</span> <span className="relative text-indigo-600 dark:text-indigo-400">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75m-3-7.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285Z" />
                            </svg>
                        </span>
                    </h2>}
            btnName="Back"
            svg={<svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2"><path strokeLinecap="round" strokeLinejoin="round" d="M10 19l-7-7m0 0l7-7m-7 7h18" /></svg>}
            href={route('verifyAge')}
        >
            <Head title="Upload a selfie" />
            <div className="sm:max-w-3xl max-w-none mx-auto px-4 sm:px-6 md:px-8">
                <div className="">
                    <div className=" mb-2 items-baseline">
                        <h2 className="mt-6 text-lg font-semibold text-slate-900 dark:text-purple-50">
                            Upload a Selfie
                        </h2>
                        <p className="mt-1 text-lg font-normal text-gray-500 dark:text-slate-200">
                            We understand privacy is extremely important. We follow industry best practices for data security and encryption and will be used solely for age verification purposes.
                        </p>
                    </div>
                </div>

                <ValidationErrors errors={errors} />

                {is_verified === 0 &&  (
                    <div className="flex justify-center mt-8">
                        <form>
                            <div className="p-2">
                                <div className="">
                                    {selfie === null && !selectedFile ? (
                                        <div class="max-w-sm p-6 bg-white border border-gray-200 hover:bg-gray-100 hover:border-gray-300 dark:hover:bg-gray-900/90 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 cursor-pointer">
                                            <label className="flex flex-col ">
                                                <div className="flex flex-col items-center justify-center pt-10 cursor-pointer">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="w-8 h-8 text-gray-400 group-hover:text-gray-600"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor"
                                                    >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="2"
                                                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                                    />
                                                    </svg>
                                                    <p className="pt-1 mb-2 text-sm tracking-wider text-gray-400 hover:text-gray-600">
                                                        Choose a photo
                                                    </p>
                                                </div>
                                                <input
                                                    ref={photoRef}
                                                    name='photo'
                                                    type="file"
                                                    value={values.photo}
                                                    className="opacity-0"
                                                    onChange={onHandleChange}
                                                />
                                            </label>
                                        </div>
                                    ) : (
                                        <div class="grid ">
                                            <div class="max-w-sm p-6 bg-white border h-48 border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 group relative flex items-end overflow-hidden md:h-[13rem]">
                                                <img
                                                    src={selectedFile || selfie}
                                                    layout="fill"
                                                    className={`absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110`}
                                                />
                                            </div>
                                            <label className="flex flex-col items-center justify-center mt-2">
                                                <p className="text-md text-center items-center justify-center flex font-medium tracking-wide cursor-pointer text-gray-600 hover:text-gray-800">
                                                    Click here to change
                                                </p>
                                                <input
                                                    ref={photoRef}
                                                    name='photo'
                                                    type="file"
                                                    value={values.photo}
                                                    className="opacity-0"
                                                    onChange={onHandleChange}
                                                />
                                            </label>
                                        </div>
                                    )}
                                </div>
                                <div className={`${link && 'hidden'} mt-10`}>
                                    <p className="text-slate-800 dark:text-slate-50">Instruction</p>
                                    <ul className="list-inside list-disc text-sm text-slate-600 dark:text-slate-200">
                                        <li>Upload clear photos</li>
                                        <li>It is better to avoid group photos</li>
                                        <li>Please upload your photos only.</li>
                                        <li>Avoid adding photos of dolls, celebrities, pets scenery etc.</li>
                                    </ul>
                                </div>
                            </div>
                            {link && (
                                <Button 
                                    // href={route('uploaded')}
                                    type="submit"
                                    className="w-full mb-4 items-center justify-center "
                                >
                                    Upload
                                </Button>
                            )}
                        </form>
                    </div>
                )}
            </div>
        </Authenticated>
  )
}

export default Selfie