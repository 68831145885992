import Authenticated from '@/Layouts/Authenticated';
import { LockClosedIcon, ShieldCheckIcon } from '@heroicons/react/outline';
// import { ShieldCheckIcon } from '@heroicons/react/solid';
import { Inertia } from '@inertiajs/inertia';
import { Link, usePage } from '@inertiajs/inertia-react';
import React from 'react'

function UserProfile(props) {
    const {user, userImages} = usePage().props;
    const { blur_profile_image, isAcceptedByOrAcceptedOther, username } = user;

    const getRandomGradient = () => {
        const baseColors = ['#6327D8', '#F77171'];
        const gradients = [];

        for (let i = 0; i < 20; i++) {
          const opacity = Math.random() * 0.5 + 0.5; // Adjust the opacity range as needed
          const color1 = `${baseColors[0]}${Math.round(opacity * 255).toString(16)}`;
          const color2 = `${baseColors[1]}${Math.round(opacity * 255).toString(16)}`;

          const gradient = `linear-gradient(45deg, ${color1}, ${color2})`;
          gradients.push(gradient);
        }

        const randomIndex = Math.floor(Math.random() * gradients.length);
        return gradients[randomIndex];
      };

      const cancelInviteRequest = async (id) => {
        console.log(id);
        try {
           await Inertia.post(route('cancel-friend-request', id));
        } catch (error) {
            console.log(error)
        }
      };


    return (
    <Authenticated
        auth={props.auth}
        errors={props.errors}
    >

    <div className="max-w-5xl px-2 mx-auto sm:px-6 md:px-8">
        <div className="pt-4 mb-4 rounded-lg">
            <div className="dark:bg-slate-800 shadow-sm rounded-md relative mx-auto flex h-auto w-full flex-col items-center bg-white bg-cover bg-clip-border p-[16px] dark:text-slate-50 dark:shadow-none">
                {/* Dynamic Banner image */}
                <div
                    className="relative flex justify-center w-full h-32 mt-1 bg-cover rounded-xl"
                    style={{ backgroundImage: getRandomGradient() }}
                >
                    <div className="absolute -bottom-12 flex h-[100px] w-[100px] items-center justify-center rounded-full border-[4px] border-white bg-red-400">
                        <img
                            className={`flex-none h-full w-full rounded-full object-cover`}
                            src={user.profile_image}
                            alt={`${user.username}'s avatar`}
                        />
                    </div>
                </div>

                <div className="flex flex-col items-center mt-16">
                    <h4 className="flex items-center gap-1 text-xl font-bold text-slate-800 dark:text-slate-200">
                        {user.username}, {user.age}
                        {user.is_verified === 1 &&
                            (<div className="relative text-indigo-600 dark:text-indigo-400">
                                <ShieldCheckIcon className='w-5 h-5' />
                            </div>)
                        }
                    </h4>
                    <p className="text-base font-medium leading-6 text-slate-600 dark:text-slate-300"> {user.ethnic_origin} From {user.country}</p>
                </div>

                <div className="flex gap-2 mt-6 mb-3">
                    <div className="flex flex-col items-center justify-center">
                        {props.auth.user.account_status === 0 || props.auth.user.account_status === 2 ? (
                            <button
                                disabled
                                className="inline-flex items-center h-10 px-6 py-2 font-bold bg-transparent border rounded-md disabled:bg-slate-100/20 disabled:text-slate-600 disabled:shadow-none text-slate-800 dark:text-slate-300 dark:bg-slate-700 border-slate-300 bg-slate-200 dark:border-slate-500 focus:ring-2 dark:ring-slate-400 "
                            >
                                {/* <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
                                </svg> */}
                                <LockClosedIcon className="w-5 h-5 mr-2" />

                                <span className="text-xs font-medium sm:text-sm">Invite</span>
                            </button>
                        ) : (
                            <>
                                {!user.isInvited && !user.amiInvited &&(
                                    <Link preserveScroll href={route('invite.user', user.id)} method="post" as="button"  className="inline-flex items-center h-10 px-6 py-2 font-bold transition duration-300 ease-in-out border rounded-md bg-indigo-50 text-slate-800 dark:text-slate-300 dark:bg-slate-800 hover:bg-slate-800 hover:text-slate-50 dark:hover:bg-slate-50 dark:hover:text-slate-500 border-slate-200 dark:border-slate-50 focus:ring-2 dark:ring-slate-400 focus:outline-none">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 mr-2 rotate-90" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
                                        </svg>
                                        <span className="text-xs sm:text-sm">Invite</span>
                                    </Link>
                                )}
                                {user.isInvited && !user.amiInvited && !user.hasAcceptedInvitation && !user.amIBeingRejected && (
                                    <button
                                        onClick={() => cancelInviteRequest(user.id)}
                                        className="inline-flex items-center h-10 px-6 py-2 font-bold text-indigo-500 transition duration-300 ease-in-out bg-transparent border rounded-md dark:text-slate-50 dark:bg-slate-800 hover:bg-slate-100 hover:text-indigo-600 dark:hover:bg-slate-50 dark:hover:text-slate-500 border-slate-200 bg-slate-200 dark:border-slate-50 focus:ring-2 dark:ring-slate-400 focus:outline-none"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
                                        </svg>
                                        <span className="text-xs font-medium sm:text-sm">Cancel</span>
                                    </button>
                                )}

                                {user.amiInvited && !user.hasBeenAccepted && !user.isRejectedInvitation && (
                                    <>
                                        <div className="flex items-center justify-center space-x-1">
                                            <Link preserveScroll href={route('accept.user', user.id)} method="post" as="button" className="inline-flex items-center h-10 px-6 py-2 mr-2 font-bold text-purple-800 transition duration-300 ease-in-out bg-transparent border rounded-md dark:text-slate-300 dark:bg-slate-800 hover:bg-slate-800 hover:text-slate-50 dark:hover:bg-slate-50 dark:hover:text-slate-500 border-slate-200 bg-slate-200 dark:border-slate-50 focus:ring-2 dark:ring-slate-400 focus:outline-none">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 mr-2">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z" />
                                                </svg>
                                                <span className="text-xs sm:text-sm">Accept</span>
                                            </Link>
                                            <Link preserveScroll href={route('reject.user', user.id)} method="post" as="button" className="inline-flex items-center h-10 px-6 py-2 ml-2 font-bold text-red-400 transition duration-300 ease-in-out rounded-md bg-red-50 dark:bg-red-100 dark:text-red-500 hover:bg-red-200 hover:text-red-500 dark:hover:bg-slate-50 dark:hover:text-red-500 dark:border-slate-50 focus:ring-2 dark:ring-slate-400 focus:outline-none">
                                                <span className="text-xs sm:text-sm">Reject</span>
                                            </Link>
                                        </div>
                                    </>
                                )}

                                {user.amIBeingAccepted && (
                                    // User can chat
                                    <Link
                                        href={route('chatuser', user.username)}
                                        className="inline-flex items-center justify-center h-10 px-6 py-2 font-bold text-indigo-500 transition duration-300 ease-in-out bg-transparent border rounded-md dark:text-slate-50 dark:bg-slate-800 hover:bg-blue-50 hover:text-indigo-600 dark:hover:bg-blue-50 dark:hover:text-blue-500 border-slate-200 bg-slate-200 dark:border-slate-50 focus:ring-2 dark:ring-slate-400 focus:outline-none"
                                    >
                                        <svg className='w-4 h-4 mr-2' width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19.4313 13.0007C19.4313 13.5312 19.2206 14.0399 18.8455 14.4149C18.4704 14.79 17.9617 15.0007 17.4313 15.0007H5.4313L1.4313 19.0007V3.00073C1.4313 2.4703 1.64202 1.96159 2.01709 1.58652C2.39216 1.21145 2.90087 1.00073 3.4313 1.00073H17.4313C17.9617 1.00073 18.4704 1.21145 18.8455 1.58652C19.2206 1.96159 19.4313 2.4703 19.4313 3.00073V13.0007Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                        <span className="text-xs font-medium sm:text-sm">Chat</span>
                                    </Link>
                                )}
                                {user.hasAcceptedMyInvitation && (
                                    // User can chat
                                    <Link
                                        href={route('chatuser', user.username)}
                                        className="inline-flex items-center justify-center h-10 px-6 py-2 font-bold text-indigo-500 transition duration-300 ease-in-out bg-transparent border rounded-md dark:text-slate-50 dark:bg-slate-800 hover:bg-blue-50 hover:text-indigo-600 dark:hover:bg-blue-50 dark:hover:text-blue-500 border-slate-200 bg-slate-200 dark:border-slate-50 focus:ring-2 dark:ring-slate-400 focus:outline-none"
                                    >
                                        <svg className='w-4 h-4 mr-2' width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19.4313 13.0007C19.4313 13.5312 19.2206 14.0399 18.8455 14.4149C18.4704 14.79 17.9617 15.0007 17.4313 15.0007H5.4313L1.4313 19.0007V3.00073C1.4313 2.4703 1.64202 1.96159 2.01709 1.58652C2.39216 1.21145 2.90087 1.00073 3.4313 1.00073H17.4313C17.9617 1.00073 18.4704 1.21145 18.8455 1.58652C19.2206 1.96159 19.4313 2.4703 19.4313 3.00073V13.0007Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                        <span className="text-xs font-medium sm:text-sm">Chat</span>
                                    </Link>
                                )}
                                {user.isRejectedInvitation && user.amIBeingRejected &&(
                                    // User has rejected the invitation
                                    <button
                                        disabled
                                        className="inline-flex items-center justify-center h-10 px-6 py-2 font-bold text-red-500 transition duration-300 ease-in-out bg-transparent border rounded-md dark:text-slate-50 dark:bg-slate-800 hover:bg-blue-50 hover:text-red-600 dark:hover:bg-red-50 dark:hover:text-red-500 border-slate-200 bg-slate-200 dark:border-slate-50 focus:ring-2 dark:ring-slate-400 focus:outline-none"
                                    >
                                        <span className="text-xs font-medium sm:text-sm">Rejected</span>
                                    </button>
                                )}
                            </>
                        )}
                    </div>
                    <div className="flex flex-col items-center justify-center">
                        {/* Save button */}
                        {!user.isSaved ? (
                            <Link preserveScroll method="post" href={route('save.user', user.id)} className="flex items-center justify-center flex-none border rounded-md w-9 h-9 text-slate-300 border-slate-200 dark:border-slate-500 hover:bg-slate-800 hover:text-slate-50 dark:hover:bg-slate-50 dark:text-slate-600" as="button" aria-label="Like">
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                                </svg>
                            </Link> )
                        : (
                            <Link preserveScroll method="post" href={route('unsave.user', user.id)} className="flex items-center justify-center flex-none border rounded-md w-9 h-9 text-slate-300 border-slate-200 dark:border-slate-500 hover:bg-slate-800 hover:text-slate-50 dark:hover:bg-slate-50 dark:text-slate-600" as="button" aria-label="Like">
                                <svg className="text-red-400" width="20" height="20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z" />
                                </svg>
                            </Link>
                        )}

                    </div>
                    <div className="flex items-center justify-center gap-2">
                        {/* Pass button */}
                        <Link preserveScroll method="post" href={route('pass-user', user.id)} className="flex items-center justify-center flex-none border rounded-md w-9 h-9 text-slate-300 border-slate-200 dark:border-slate-500 hover:bg-slate-800 hover:text-slate-50 dark:hover:bg-slate-50 dark:text-slate-600" as="button" aria-label="Like">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                            </svg>
                        </Link>
                        
                        {/* Share button */}

                        {/* <Link href="#" className="flex items-center justify-center flex-none border rounded-md w-9 h-9 text-slate-300 border-slate-200 dark:border-slate-500 hover:bg-slate-800 hover:text-slate-50 dark:hover:bg-slate-50 dark:text-slate-600" aria-label="Share profile">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                            </svg>
                        </Link> */}
                    </div>
                </div>
            </div>
        </div>
        <div className="flex-row sm:flex sm:space-x-2">
            <div className="p-6 mt-2 rounded-md shadow-sm sm:mt-0 sm:w-full bg-gray-50 dark:bg-slate-800 sm:p-10">
                <div>
                    <div className="grid grid-cols-1 gap-6 mt-8 sm:grid-cols-2">

                        <div className="rounded-lg">
                            <div className="inline-flex items-center justify-center ">
                                <div className="inline-flex items-center justify-center p-2 mb-2 rounded-full shadow bg-slate-50 dark:bg-slate-800 dark:border dark:border-blue-300">
                                    <img src="../assets/images/user.svg" className="w-8 h-8" />
                                </div>
                                <p className="pl-2 pr-2 text-lg font-semibold text-slate-700 dark:text-slate-300">Profile Basic</p>
                            </div>

                            <div class="mt-1">
                                <dl class="">
                                    <div class=" grid grid-cols-3 gap-4">
                                        <dt class="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Age</dt>
                                        <dd class="text-sm leading-6 text-gray-700 dark:text-slate-200 col-span-2">{user.age} </dd>
                                    </div>
                                    <div class=" grid grid-cols-3 gap-4">
                                        <dt class="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Height</dt>
                                        <dd class="text-sm leading-6 text-gray-700 dark:text-slate-200 col-span-2">{user.height} </dd>
                                    </div>
                                    <div class=" grid grid-cols-3 gap-4">
                                        <dt class="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Ethnicity</dt>
                                        <dd class="text-sm leading-6 text-gray-700 dark:text-slate-200 col-span-2">{user.ethnic_origin} </dd>
                                    </div>
                                    {/* <div class=" grid grid-cols-3 gap-4">
                                        <dt class="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Last active</dt>
                                        <dd class="text-sm leading-6 text-gray-700 dark:text-slate-200 col-span-2">{user.login_time} </dd>
                                    </div> */}
                                </dl>
                            </div>
                        </div>

                        <div className="rounded-lg">
                            <div className="inline-flex items-center justify-center ">
                                <div className="inline-flex items-center justify-center p-2 mb-2 rounded-full shadow bg-slate-50 dark:bg-slate-800 dark:border dark:border-sky-400">
                                    <img src="../assets/images/profession.svg" className="w-8 h-8" />
                                </div>
                                <p className="pl-2 pr-2 text-lg font-semibold text-slate-700 dark:text-slate-300">Education/Career</p>
                            </div>
                            <div class="mt-1">
                                <dl class="">
                                    <div class="mt-1 grid grid-cols-3 gap-4">
                                        <dt class="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">University / College</dt>
                                        <dd class="text-sm leading-6 text-gray-700 dark:text-slate-200 col-span-2">{user.university} </dd>
                                    </div>
                                    <div class=" grid grid-cols-3 gap-4">
                                        <dt class="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Course</dt>
                                        <dd class="text-sm leading-6 text-gray-700 dark:text-slate-200 col-span-2">{user.course_name} </dd>
                                    </div>
                                    {user.college != null && (
                                        <div className="mt-1">
                                            <div class=" grid grid-cols-3 gap-4">
                                                <dt class="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">University / College</dt>
                                                <dd class="text-sm leading-6 text-gray-700 dark:text-slate-200 col-span-2">{user.college} </dd>
                                            </div>
                                            <div class=" grid grid-cols-3 gap-4">
                                                <dt class="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Course</dt>
                                                <dd class="text-sm leading-6 text-gray-700 dark:text-slate-200 col-span-2">{user.college_course_name} </dd>
                                            </div>
                                        </div>
                                     )}

                                    <div class=" grid grid-cols-3 gap-4">
                                        <dt class="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Profession</dt>
                                        <dd class="text-sm leading-6 text-gray-700 dark:text-slate-200 col-span-2">{user.current_profession} </dd>
                                    </div>
                                </dl>
                            </div>
                        </div>

                        <div className="rounded-lg">
                            <div className="inline-flex items-center justify-center ">
                                <div className="inline-flex items-center justify-center p-2 mb-2 rounded-full shadow bg-slate-50 dark:bg-slate-800 dark:border dark:border-red-400">
                                    <img src="../assets/images/globe.svg" className="w-8 h-8" />
                                </div>
                                <p className="pl-2 pr-2 text-lg font-semibold text-slate-700 dark:text-slate-300">Location</p>
                            </div>
                            <div class="mt-1">
                                <dl class="">
                                    <div class=" grid grid-cols-3 gap-4">
                                        <dt class="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Address</dt>
                                        <dd class="text-sm leading-6 text-gray-700 dark:text-slate-200 col-span-2">{user.city}, {user.country} </dd>
                                    </div>
                                    <div class=" grid grid-cols-3 gap-4">
                                        <dt class="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Residency status</dt>
                                        <dd class="text-sm leading-6 text-gray-700 dark:text-slate-200 col-span-2">{user.recidency_status} </dd>
                                    </div>
                                    <div class=" grid grid-cols-3 gap-4">
                                        <dt class="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Relocate</dt>
                                        <dd class="text-sm leading-6 text-gray-700 dark:text-slate-200 col-span-2">{user.relocate}</dd>
                                    </div>
                                    <p className="text-sm font-semibold leading-6 text-gray-900 dark:text-slate-200">Back home</p>
                                    <div class=" grid grid-cols-3 gap-4">
                                        <dt class="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Address</dt>
                                        <dd class="text-sm leading-6 text-gray-700 dark:text-slate-200 col-span-2">{user.back_home_area !== '' && user.back_home_area + ','} {user.back_home_city}, {user.back_home_country} </dd>
                                    </div>
                                </dl>
                            </div>
                        </div>

                        <div className="rounded-lg">
                            <div className="inline-flex items-center justify-center ">
                                <div className="inline-flex items-center justify-center p-2 mb-2 rounded-full shadow bg-slate-50 dark:bg-slate-800 dark:border dark:border-red-400">
                                    <img src="../assets/images/mosque.svg" className="object-center w-8 h-8" />
                                </div>
                                <p className="pl-2 pr-2 text-lg font-semibold text-slate-700 dark:text-slate-300">Religious Observance</p>
                            </div>

                            <div class="mt-1">
                                <dl class="">
                                    <div class="grid grid-cols-3 gap-4">
                                        <dt class="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Religious History</dt>
                                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-slate-200">Muslim - {user.religious_history}</dd>
                                    </div>
                                    <div class="grid grid-cols-3 gap-4">
                                        <dt class="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Sect</dt>
                                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-slate-200">{user.sect}</dd>
                                    </div>
                                    <div class="grid grid-cols-3 gap-4">
                                        <dt class="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Pray</dt>
                                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-slate-200">{user.prayer_frequency}</dd>
                                    </div>
                                    <div class="grid grid-cols-3 gap-4">
                                        <dt class="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Read Quran</dt>
                                        <dd class="mt-1 text-sm leading-6 text-gray-700 dark:text-slate-200 sm:col-span-2 sm:mt-0">{user.read_quran}</dd>
                                    </div>
                                    <div class="grid grid-cols-3 gap-4">
                                        <dt class="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Eat Halal</dt>
                                        <dd class="mt-1 text-sm leading-6 text-gray-700 dark:text-slate-200 sm:col-span-2 sm:mt-0">{user.eat_halal}</dd>
                                    </div>

                                    <div class="grid grid-cols-3 gap-4">
                                        <dt class="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Drink Alchohol</dt>
                                        <dd class="mt-1 text-sm leading-6 text-gray-700 dark:text-slate-200 sm:col-span-2 sm:mt-0">{user.drink_alchohol}</dd>
                                    </div>
                                </dl>
                            </div>
                        </div>

                        <div className="rounded-lg">
                            <div className="inline-flex items-center justify-center ">
                                <div className="inline-flex items-center justify-center p-2 mb-2 rounded-full shadow bg-slate-50 dark:bg-slate-800 dark:border dark:border-green-300">
                                    <img src="../assets/images/ring.svg" className="w-8 h-8" />
                                </div>
                                <p className="pl-2 pr-2 text-lg font-semibold text-slate-700 dark:text-slate-300">Personal</p>
                            </div>

                            <div class="mt-1">
                                <dl class="">
                                    <div class=" grid grid-cols-3 gap-4">
                                        <dt class="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Marital Status</dt>
                                        <dd class="text-sm leading-6 text-gray-700 dark:text-slate-200 col-span-2">{user.marital_status} </dd>
                                    </div>
                                    <div class=" grid grid-cols-3 gap-4">
                                        <dt class="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Have children</dt>
                                        <dd class="text-sm leading-6 text-gray-700 dark:text-slate-200 col-span-2">{user.have_children} </dd>
                                    </div>
                                </dl>
                            </div>
                        </div>

                        <div className="rounded-lg ">
                            <div className="inline-flex items-center justify-center ">
                                <div className="inline-flex items-center justify-center p-2 mb-2 rounded-full shadow bg-slate-50 dark:bg-slate-800 dark:border dark:border-purple-400">
                                    <img src="../assets/images/family.svg" className="w-8 h-8" />
                                </div>
                                <p className="pl-2 pr-2 text-lg font-semibold text-slate-700 dark:text-slate-300">Family</p>
                            </div>
                            <div class="mt-1">
                                <dl class="">
                                    <div class=" grid grid-cols-3 gap-4">
                                        <dt class="text-sm font-medium leading-6 text-gray-900 dark:text-slate-200">Siblings</dt>
                                        <dd class="text-sm leading-6 text-gray-700 dark:text-slate-200 col-span-2">{user.siblings == 0 ? 'No Siblings' : user.siblings + ' siblings'}</dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </div>

                    <div className="col-span-2 mt-2">
                        <hr className="bg-gray-200 border-0 h-[0.1rem] dark:bg-gray-700" />
                    </div>

                    <div className="col-span-2 mt-4">
                        {user.bio !== '' &&
                            <div>
                                <h1 className="text-lg font-semibold text-slate-700 dark:text-slate-200 ">About {user.username}</h1>
                                <p className="leading-6 tracking-wide text-justify whitespace-pre-line align-baseline text-slate-700 dark:text-slate-300">
                                    {user.bio}
                                </p>
                            </div>
                        }

                        <div className="mt-4">
                            <h1 className="mb-4 text-lg font-semibold text-slate-700 dark:text-slate-200">Photos</h1>
                            {userImages.length !== 0 ?
                                <div class="grid grid-cols-2 gap-4 sm:grid-cols-4 sm:gap-4">
                                    {userImages.map(({image, id}) => (
                                        <div key={id}>
                                            <a href="#"
                                                className="relative flex items-end h-48 overflow-hidden bg-gray-100 rounded-lg shadow-lg group md:h-80">
                                                <img 
                                                    src={image} 
                                                    loading="lazy" 
                                                    alt="" 
                                                    className={`absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110 ${!isAcceptedByOrAcceptedOther && blur_profile_image === 1 ? 'blur-lg' : ''}`}

                                                    // className={`${user.blur_profile_image === 1 ? 'blur-lg' : ''} absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110`} 
                                                />

                                                <div className="absolute inset-0 opacity-50 pointer-events-none bg-gradient-to-t from-gray-800 via-transparent to-transparent"></div>
                                                {!isAcceptedByOrAcceptedOther && blur_profile_image === 1 &&
                                                    <div className="relative inline-block mb-3 ml-4 text-sm text-white md:ml-5 md:text-lg">
                                                        <LockClosedIcon className="w-6 h-6"/>
                                                        <p className='text-white'>Accept or send an invitation to view images.</p>
                                                    </div>
                                                }
                                            </a>
                                        </div>
                                    ))}
                                </div>
                                :
                                <p className="text-sm font-normal text-slate-700 dark:text-slate-200">{user.username} hasn't yet uploaded any photos.</p>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </Authenticated>
  )
}

export default UserProfile
