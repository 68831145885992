import React from 'react'
import UserSideNavMenuItem from './UserSideNavMenuItem'

function MatchedSideNav() {
  return (
    <>
        {/* <div className="px-4 mx-auto max-w-8xl sm:px-6 md:px-8"> */}
            <nav id="nav" className="fixed z-50 hidden p-2 leading-6 rounded-md shadow-sm sm:block bg-gray-50 dark:bg-slate-800 sm:p-6 ">
                <ul>
                    <UserSideNavMenuItem 
                        href={route('interests')}
                        svg={<svg width="20" height="20" fill="currentColor" aria-hidden="true">
                                <path fillRule="evenodd" clipRule="evenodd" d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z" />
                            </svg>}
                        active={route().current('interests')}
                    >
                        Saved Profiles
                    </UserSideNavMenuItem>

                    <UserSideNavMenuItem 
                        href={route('profilesILike')}
                        svg={<svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
                            </svg>}
                        active={route().current('profilesILike')}
                    >
                        Invited Profiles
                    </UserSideNavMenuItem>

                    <UserSideNavMenuItem 
                        href={route('whoLikedMe')}
                        svg={
                            <svg width="25" height="25" viewBox="0 0 44 30" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
                                <path d="M40.8659 13.2448C40.7003 12.4972 40.3894 11.7915 39.9511 11.1682C39.5128 10.5449 38.9557 10.0162 38.3115 9.61215C37.6673 9.20814 36.9487 8.93681 36.1967 8.81366C35.4447 8.6905 34.6741 8.71793 33.929 8.89438L32.3827 9.26013L32.0387 7.70891C31.7038 6.19912 30.7849 4.89258 29.484 4.07671C28.183 3.26085 26.6067 3.00249 25.1018 3.35846C23.5968 3.71444 22.2865 4.65561 21.4591 5.9749C20.6317 7.2942 20.355 8.88357 20.6899 10.3934L21.0339 11.9446L23.559 23.3299L34.9078 20.6455L36.454 20.2797C37.1993 20.1036 37.9046 19.7819 38.5297 19.3331C39.1548 18.8842 39.6874 18.3169 40.0971 17.6636C40.5068 17.0103 40.7856 16.2838 40.9175 15.5256C41.0494 14.7674 41.0319 13.9924 40.8659 13.2448V13.2448Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M21.3509 3.12185C20.745 2.65347 20.0545 2.31033 19.3188 2.11203C18.5831 1.91373 17.8165 1.86415 17.063 1.96614C16.3095 2.06812 15.5837 2.31967 14.9272 2.7064C14.2706 3.09314 13.6962 3.60748 13.2366 4.22005L12.2828 5.49084L11.0255 4.51931C9.80182 3.57372 8.25946 3.1582 6.73773 3.36416C5.21601 3.57011 3.83959 4.38066 2.91125 5.61751C1.98292 6.85435 1.57873 8.41617 1.78759 9.95938C1.99645 11.5026 2.80126 12.9008 4.02496 13.8464L5.28225 14.8179L14.5102 21.9485L21.5108 12.6215L22.4646 11.3507C22.9244 10.7384 23.2604 10.0397 23.4533 9.29471C23.6462 8.54971 23.6922 7.77293 23.5888 7.00876C23.4854 6.24459 23.2345 5.508 22.8505 4.84107C22.4665 4.17414 21.957 3.58995 21.3509 3.12185V3.12185Z"  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            }
                        active={route().current('whoLikedMe')}
                    >
                        Invitations Received
                    </UserSideNavMenuItem>

                    <UserSideNavMenuItem 
                        href={route('profilesIPassed')}
                        svg={<svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>}
                        active={route().current('profilesIPassed')}
                    >
                        Hidden Profiles
                    </UserSideNavMenuItem>
                </ul>
            </nav>

            <div className="relative z-10 block max-w-3xl mx-auto sm:hidden">
                <div className="flex mb-6 -mx-4 overflow-auto sm:-mx-6">
                    <div className="flex-none min-w-full px-4 sm:px-6">
                        <ul className="flex space-x-6 border-b border-slate-200 whitespace-nowrap dark:border-slate-200/5">
                            <li>
                                <h2>
                                    <UserSideNavMenuItem 
                                        href={route('interests')}
                                        svg={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-user">
                                                <path className="" d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle>
                                            </svg>}
                                        className="flex text-sm leading-6 font-semibold pt-3 pb-2.5 border-b-2 -mb-px text-slate-700 dark:text-slate-400 border-transparent hover:border-slate-300 dark:hover:border-slate-700"
                                        active={route().current('interests')}
                                    >
                                        Saved 
                                    </UserSideNavMenuItem>
                                </h2>
                            </li>
                            <li>
                                <UserSideNavMenuItem 
                                    href={route('profilesILike')}
                                    svg={<svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
                                        </svg>}
                                    active={route().current('profilesILike')}
                                >
                                    Invited 
                                </UserSideNavMenuItem>
                                {/* <h2><Link href={route('profilesILike')} className="flex text-sm leading-6 font-semibold pt-3 pb-2.5 border-b-2 -mb-px text-slate-700 dark:text-slate-400 border-transparent hover:border-slate-300 dark:hover:border-slate-700">Preferences</Link></h2> */}
                            </li>
                            <li>
                                <UserSideNavMenuItem 
                                    href={route('whoLikedMe')}
                                    svg={<svg width="25" height="25" viewBox="0 0 44 30" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
                                    <path d="M40.8659 13.2448C40.7003 12.4972 40.3894 11.7915 39.9511 11.1682C39.5128 10.5449 38.9557 10.0162 38.3115 9.61215C37.6673 9.20814 36.9487 8.93681 36.1967 8.81366C35.4447 8.6905 34.6741 8.71793 33.929 8.89438L32.3827 9.26013L32.0387 7.70891C31.7038 6.19912 30.7849 4.89258 29.484 4.07671C28.183 3.26085 26.6067 3.00249 25.1018 3.35846C23.5968 3.71444 22.2865 4.65561 21.4591 5.9749C20.6317 7.2942 20.355 8.88357 20.6899 10.3934L21.0339 11.9446L23.559 23.3299L34.9078 20.6455L36.454 20.2797C37.1993 20.1036 37.9046 19.7819 38.5297 19.3331C39.1548 18.8842 39.6874 18.3169 40.0971 17.6636C40.5068 17.0103 40.7856 16.2838 40.9175 15.5256C41.0494 14.7674 41.0319 13.9924 40.8659 13.2448V13.2448Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M21.3509 3.12185C20.745 2.65347 20.0545 2.31033 19.3188 2.11203C18.5831 1.91373 17.8165 1.86415 17.063 1.96614C16.3095 2.06812 15.5837 2.31967 14.9272 2.7064C14.2706 3.09314 13.6962 3.60748 13.2366 4.22005L12.2828 5.49084L11.0255 4.51931C9.80182 3.57372 8.25946 3.1582 6.73773 3.36416C5.21601 3.57011 3.83959 4.38066 2.91125 5.61751C1.98292 6.85435 1.57873 8.41617 1.78759 9.95938C1.99645 11.5026 2.80126 12.9008 4.02496 13.8464L5.28225 14.8179L14.5102 21.9485L21.5108 12.6215L22.4646 11.3507C22.9244 10.7384 23.2604 10.0397 23.4533 9.29471C23.6462 8.54971 23.6922 7.77293 23.5888 7.00876C23.4854 6.24459 23.2345 5.508 22.8505 4.84107C22.4665 4.17414 21.957 3.58995 21.3509 3.12185V3.12185Z"  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>}
                                    active={route().current('whoLikedMe')}
                                >
                                    Received
                                </UserSideNavMenuItem>
                                {/* <h2><Link href={route('whoLikedMe')} className="flex text-sm leading-6 font-semibold pt-3 pb-2.5 border-b-2 -mb-px text-slate-700 border-transparent hover:border-slate-300 dark:text-slate-400 dark:hover:border-slate-700">Who Liked Me</Link></h2> */}
                            </li>
                            <li>
                                <UserSideNavMenuItem 
                                    href={route('profilesIPassed')}
                                    svg={<svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                        </svg>}
                                    active={route().current('profilesIPassed')}
                                >
                                    Hidden
                                </UserSideNavMenuItem>
                            </li>
                            
                        </ul>
                    </div>
                </div>
            </div>
    </>
  )
}

export default MatchedSideNav