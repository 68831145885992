import React from 'react'

function Blog() {
  return (
    <div>
        <div className="py-24 flex-col items-center justify-center  px-4">
                <h1 role="heading" className="text-center xl:text-5xl md:text-4xl text-2xl font-bold text-gray-800">
                    Read Our Latest
                </h1>
                <p role="contentinfo" className="text-base leading-normal text-center text-gray-600 mt-4">
                    Whether article spirits new her covered hastily sitting her. Money witty books nor son
                </p>
                <div className="2xl:container 2xl:mx-auto flex flex-wrap items-start justify-center pt-6 gap-6">
                    <div className="flex lg:flex-col sm:flex-row flex-col items-start lg:gap-0 gap-6 lg:w-96 w-auto">
                        <div>
                            <img src="https://i.ibb.co/hKV7YhC/blog-1.png" alt="woman smiling" />
                            <h2 className="text-xl font-semibold leading-5 mt-8 text-gray-800">Busting myths about hair</h2>
                            <div className="mt-6 flex items-center cursor-pointer">
                                <p className="pr-3 text-base font-medium leading-4 underline text-gray-800">5 min read</p>
                                <svg width={16} height={10} viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 5H15" stroke="#1F2937" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M11 9L15 5" stroke="#1F2937" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M11 1L15 5" stroke="#1F2937" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                            <p className="text-base mt-4 italic font-italic leading-4 text-gray-600">23, Feburary 2021</p>
                        </div>
                        <div className="lg:mt-10">
                            <img src="https://i.ibb.co/WkCydhJ/blog-4.png" alt="coffe pouring" />
                            <h2 className="text-xl font-semibold leading-5 mt-8 text-gray-800">Busting myths about hair</h2>
                            <div className="mt-6 flex items-center cursor-pointer">
                                <p className="pr-3 text-base font-medium leading-4 underline text-gray-800">5 min read</p>
                                <svg width={16} height={10} viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 5H15" stroke="#1F2937" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M11 9L15 5" stroke="#1F2937" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M11 1L15 5" stroke="#1F2937" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                            <p className="text-base mt-4 italic font-italic leading-4 text-gray-600">23, Feburary 2021</p>
                        </div>
                    </div>
                    <div className="flex lg:flex-col sm:flex-row flex-col items-start lg:gap-0 gap-6 lg:w-96 w-auto">
                        <div>
                            <img src="https://i.ibb.co/SmNSVs8/blog-2.png" alt="plant held by a man" />
                            <h2 className="text-xl font-semibold leading-5 mt-8 text-gray-800">Busting myths about hair</h2>
                            <div className="mt-6 flex items-center cursor-pointer">
                                <p className="pr-3 text-base font-medium leading-4 underline text-gray-800">5 min read</p>
                                <svg width={16} height={10} viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 5H15" stroke="#1F2937" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M11 9L15 5" stroke="#1F2937" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M11 1L15 5" stroke="#1F2937" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                            <p className="text-base mt-4 italic font-italic leading-4 text-gray-600">23, Feburary 2021</p>
                        </div>
                        <div className="lg:mt-10">
                            <img src="https://i.ibb.co/3pMc5WS/blog-5.png" alt="lady with plant" />
                            <h2 className="text-xl font-semibold leading-5 mt-8 text-gray-800">Busting myths about hair</h2>
                            <div className="mt-6 flex items-center cursor-pointer">
                                <p className="pr-3 text-base font-medium leading-4 underline text-gray-800">5 min read</p>
                                <svg width={16} height={10} viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 5H15" stroke="#1F2937" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M11 9L15 5" stroke="#1F2937" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M11 1L15 5" stroke="#1F2937" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                            <p className="text-base mt-4 italic font-italic leading-4 text-gray-600">23, Feburary 2021</p>
                        </div>
                    </div>
                    <div className="flex xl:flex-col sm:flex-row flex-col items-start xl:gap-0 gap-6 xl:w-96 w-auto">
                        <div>
                            <img src="https://i.ibb.co/TLJdmSt/new-img-2.png" alt="Delighful breakfast" />
                            <h2 className="text-xl font-semibold leading-5 mt-8 text-gray-800">Busting myths about hair</h2>
                            <div className="mt-6 flex items-center cursor-pointer">
                                <p className="pr-3 text-base font-medium leading-4 underline text-gray-800">5 min read</p>
                                <svg width={16} height={10} viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 5H15" stroke="#1F2937" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M11 9L15 5" stroke="#1F2937" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M11 1L15 5" stroke="#1F2937" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                            <p className="text-base mt-4 italic font-italic leading-4 text-gray-600">23, Feburary 2021</p>
                        </div>
                        <div className="xl:mt-10">
                            <img src="https://i.ibb.co/c8dPSYB/blog-6.png" alt="hats with image frame in middle" />
                            <h2 className="text-xl font-semibold leading-5 mt-8 text-gray-800">Busting myths about hair</h2>
                            <div className="mt-6 flex items-center cursor-pointer">
                                <p className="pr-3 text-base font-medium leading-4 underline text-gray-800">5 min read</p>
                                <svg width={16} height={10} viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 5H15" stroke="#1F2937" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M11 9L15 5" stroke="#1F2937" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M11 1L15 5" stroke="#1F2937" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                            <p className="text-base mt-4 italic font-italic leading-4 text-gray-600">23, Feburary 2021</p>
                        </div>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default Blog


// function IndexPage() {
//     return (
//         <>
//             <div id="blog" className="bg-gray-100 px-4 xl:px-0 py-12">
//                 <div className="mx-auto container">
//                     <h1 className="text-center text-3xl lg:text-5xl tracking-wider text-gray-900">Latest from our Blog</h1>
//                     <div className="mt-12 lg:mt-24">
//                         <div className="grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-8">
//                             <div>
//                                 <img className="w-full" src="https://cdn.tuk.dev/assets/components/111220/Blg-6/blog(1).png" alt="computer" />
//                                 <div className="py-4 px-8 w-full flex justify-between bg-indigo-700">
//                                     <p className="text-sm text-white font-semibold tracking-wide">Bruce Wayne</p>
//                                     <p className="text-sm text-white font-semibold tracking-wide">13TH Oct, 2020</p>
//                                 </div>
//                                 <div className="bg-white px-10 py-6 rounded-bl-3xl rounded-br-3xl">
//                                     <h1 className="text-4xl text-gray-900 font-semibold tracking-wider">Transactions</h1>
//                                     <p className="text-gray-700 text-base lg:text-lg  lg:leading-8 tracking-wide mt-6 w-11/12">Find the latest events updates or create events, concerts, conferences, workshops, exhibitions, and cultural events in all cities of the US. The aim of Eventistan is to promote healthy and entertaining event.Find the latest events updates or create events, concerts, conferences, workshops, exhibitions, and cultural events in all cities of the US. The aim of Eventistan is to promote healthy and entertaining event.</p>
//                                     <div className="w-full mt-4 justify-end flex items-center cursor-pointer">
//                                         <p className="text-base tracking-wide text-indigo-500">Read more</p>
//                                         <svg className="ml-3 lg:ml-6" xmlns="http://www.w3.org/2000/svg" width={20} height={18} viewBox="0 0 20 18" fill="none">
//                                             <path d="M11.7998 1L18.9998 8.53662L11.7998 16.0732" stroke="#4338ca" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
//                                             <path d="M1 8.53662H19" stroke="#4338ca" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
//                                         </svg>
//                                     </div>
//                                     <div className="h-5 w-2" />
//                                 </div>
//                             </div>
//                             <div>
//                                 <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-8">
//                                     <div>
//                                         <img className="w-full" src="https://cdn.tuk.dev/assets/components/111220/Blg-6/blog(2).png" alt="games" />
//                                         <div className="py-2 px-4 w-full flex justify-between bg-indigo-700">
//                                             <p className="text-sm text-white font-semibold tracking-wide">Bruce Wayne</p>
//                                             <p className="text-sm text-white font-semibold tracking-wide">13TH Oct, 2020</p>
//                                         </div>
//                                         <div className="bg-white px-3  lg:px-6 py-4 rounded-bl-3xl rounded-br-3xl">
//                                             <h1 className="text-lg text-gray-900 font-semibold tracking-wider">Transactions</h1>
//                                             <p className="text-gray-700 text-sm  lg:text-base  lg:leading-8 pr-4 tracking-wide mt-2">Find the latest events updates or create events, concerts, conferences, workshops...</p>
//                                         </div>
//                                     </div>
//                                     <div>
//                                         <img className="w-full" src="https://cdn.tuk.dev/assets/components/111220/Blg-6/blog(3).png" alt="notes" />
//                                         <div className="py-2 px-4 w-full flex justify-between bg-indigo-700">
//                                             <p className="text-sm text-white font-semibold tracking-wide">Bruce Wayne</p>
//                                             <p className="text-sm text-white font-semibold tracking-wide">13TH Oct, 2020</p>
//                                         </div>
//                                         <div className="bg-white px-3  lg:px-6 py-4 rounded-bl-3xl rounded-br-3xl">
//                                             <h1 className="text-lg text-gray-900 font-semibold tracking-wider">Transactions</h1>
//                                             <p className="text-gray-700 text-sm  lg:text-base  lg:leading-8 pr-4 tracking-wide mt-2">Find the latest events updates or create events, concerts, conferences, workshops...</p>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className="mt-10 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-8">
//                                     <div>
//                                         <img className="w-full" src="https://cdn.tuk.dev/assets/components/111220/Blg-6/blog(4).png" alt="laptop" />
//                                         <div className="py-2 px-4 w-full flex justify-between bg-indigo-700">
//                                             <p className="text-sm text-white font-semibold tracking-wide">Bruce Wayne</p>
//                                             <p className="text-sm text-white font-semibold tracking-wide">13TH Oct, 2020</p>
//                                         </div>
//                                         <div className="bg-white px-3  lg:px-6 py-4 rounded-bl-3xl rounded-br-3xl">
//                                             <h1 className="text-lg text-gray-900 font-semibold tracking-wider">Transactions</h1>
//                                             <p className="text-gray-700 text-sm  lg:text-base  lg:leading-8 pr-4 tracking-wide mt-2">Find the latest events updates or create events, concerts, conferences, workshops...</p>
//                                         </div>
//                                     </div>
//                                     <div>
//                                         <img className="w-full" src="https://cdn.tuk.dev/assets/components/111220/Blg-6/blog(5).png" alt="worker" />
//                                         <div className="py-2 px-4 w-full flex justify-between bg-indigo-700">
//                                             <p className="text-sm text-white font-semibold tracking-wide">Bruce Wayne</p>
//                                             <p className="text-sm text-white font-semibold tracking-wide">13TH Oct, 2020</p>
//                                         </div>
//                                         <div className="bg-white px-3  lg:px-6 py-4 rounded-bl-3xl rounded-br-3xl">
//                                             <h1 className="text-lg text-gray-900 font-semibold tracking-wider">Transactions</h1>
//                                             <p className="text-gray-700 text-sm  lg:text-base  lg:leading-8 pr-4 tracking-wide mt-2">Find the latest events updates or create events, concerts, conferences, workshops...</p>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// }

// export default IndexPage;
