import Authenticated from '@/Layouts/AdminLayouts/Authenticated'
import React, { useState } from 'react'

import Img from '../../../../public/assets/images/man.svg';
import { Inertia } from '@inertiajs/inertia';

function AdminProfile({auth}) {

    const [previewImage, setPreviewImage] = useState(auth.user.profile_image || '');
    const [uploadedImage, setUploadedImage] = useState(null);
  
    const handleImageChange = (e) => {
      const file = e.target.files[0];
      if (file) {
        setPreviewImage(URL.createObjectURL(file));
        setUploadedImage(file);
      }
    };
  
    const handleImageUpload = () => {
      const formData = new FormData();
      formData.append('profile_image', uploadedImage);
  
      Inertia.post('/admin/update-profile-image', formData, {
        onSuccess: () => {
          // Handle success response
        },
        onError: (errors) => {
          // Handle error response
        },
      });
    };
  return (
    <Authenticated
        auth={auth}
    >
        <div className="divide-y divide-white/5">
            <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
                <div>
                    <h2 className="text-base font-semibold leading-7 text-white">Personal Information</h2>
                    <p className="mt-1 text-sm leading-6 text-gray-400">
                    Update your information from here.
                    </p>
                </div>

                {/* <div className="col-span-full flex items-center gap-x-8">
                    <img
                        src={previewImage !== '' ? previewImage : auth.user?.profile_image || Img}
                        alt=""
                        className="h-24 w-24 flex-none rounded-lg bg-gray-800 object-cover"
                    />
                    <div>
                        <label htmlFor="profile-image-upload">
                        <button
                            type="button"
                            className="rounded-md bg-white/10 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-white/20"
                        >
                            Change avatar
                        </button>
                        </label>
                        <input
                            id="profile-image-upload"
                            type="file"
                            accept="image/*"
                            onChange={handleImageChange}
                            className="hidden"
                        />
                        <p className="mt-2 text-xs leading-5 text-gray-400">JPG, GIF or PNG. 1MB max.</p>
                        <button
                            type="button"
                            onClick={handleImageUpload}
                            disabled={!uploadedImage}
                            className="mt-4 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                            Upload Image
                        </button>
                    </div>
                </div> */}
            </div>

            <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
                <div>
                    <h2 className="text-base font-semibold leading-7 text-white">Change password</h2>
                    <p className="mt-1 text-sm leading-6 text-gray-400">
                        Update your password associated with your account.
                    </p>
                </div>

                <form className="md:col-span-2">
                    <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                    <div className="col-span-full">
                        <label htmlFor="current-password" className="block text-sm font-medium leading-6 text-white">
                        Current password
                        </label>
                        <div className="mt-2">
                        <input
                            id="current-password"
                            name="current_password"
                            type="password"
                            autoComplete="current-password"
                            className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                        />
                        </div>
                    </div>

                    <div className="col-span-full">
                        <label htmlFor="new-password" className="block text-sm font-medium leading-6 text-white">
                        New password
                        </label>
                        <div className="mt-2">
                        <input
                            id="new-password"
                            name="new_password"
                            type="password"
                            autoComplete="new-password"
                            className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                        />
                        </div>
                    </div>

                    <div className="col-span-full">
                        <label htmlFor="confirm-password" className="block text-sm font-medium leading-6 text-white">
                        Confirm password
                        </label>
                        <div className="mt-2">
                        <input
                            id="confirm-password"
                            name="confirm_password"
                            type="password"
                            autoComplete="new-password"
                            className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                        />
                        </div>
                    </div>
                    </div>

                    <div className="mt-8 flex">
                    <button
                        type="submit"
                        className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                    >
                        Save
                    </button>
                    </div>
                </form>
            </div>

            
            <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
                <div>
                    <h2 className="text-base font-semibold leading-7 text-white">Delete account</h2>
                    <p className="mt-1 text-sm leading-6 text-gray-400">
                    No longer want to use our service? You can delete your account here. This action is not reversible.
                    All information related to this account will be deleted permanently.
                    </p>
                </div>

                <form className="flex items-start md:col-span-2">
                    <button
                    type="submit"
                    className="rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-400"
                    >
                    Yes, delete my account
                    </button>
                </form>
            </div>
        </div>
    </Authenticated>
  )
}

export default AdminProfile