import React from 'react';
import Button from '@/Components/Button';
import Guest from '@/Layouts/Guest';
import Input from '@/Components/Input';
import ValidationErrors from '@/Components/ValidationErrors';
import { Head, useForm, Link } from '@inertiajs/inertia-react';

import LogoPurple from '../../../../public/assets/images/logo-purple.svg';
import LogoLight from '../../../../public/assets/images/logo-light.svg';

export default function ForgotPassword({ status }) {
    const { data, setData, post, processing, errors } = useForm({
        email: '',
    });

    const onHandleChange = (event) => {
        setData(event.target.name, event.target.value);
    };

    const submit = (e) => {
        e.preventDefault();

        post(route('password.email'));
    };

    return (
        <Guest 
            bgimage="bg-password-background"
        >
            <Head title="Forgot Password" />
            <div className="text-center mb-4">
                <Link href="/" className="flex">
                    <img className="w-full h-7 block dark:hidden" src={LogoPurple} alt="shape" />
                    <img className="w-full h-7 hidden dark:block" src={LogoLight} alt="shape" />
                </Link>
            </div>
            <div className="mb-4 text-sm text-gray-500 leading-normal">
                Forgot your password? No problem. Just let us know your email address and we will email you a password
                reset link that will allow you to choose a new one.
            </div>

            {status && <div className="mb-4 font-medium text-sm text-green-600">{status}</div>}

            <ValidationErrors errors={errors} />

            <form onSubmit={submit}>
                <Input
                    type="text"
                    name="email"
                    value={data.email}
                    className="mt-1 block w-full"
                    isFocused={true}
                    handleChange={onHandleChange}
                />

                <div className="flex items-center justify-end mt-4">
                    <Button className="ml-4" processing={processing}>
                        Email Password Reset Link
                    </Button>
                </div>
            </form>
        </Guest>
    );
}
