import React, { useState } from "react";
import { Head, Link, useForm, usePage } from '@inertiajs/inertia-react';
import Authenticated from "@/Layouts/AdminLayouts/Authenticated";

import UserCheckIcon from '../../../../public/assets/images/usercheck.svg'
import { ShieldCheckIcon, ShieldExclamationIcon, UserCircleIcon } from "@heroicons/react/outline";

export default function Dashboard(props) {
    const {users, waiting_to_be_verified, active_users, today_joined, need_approval_count, verified_count, online_user_count, user_needs_approval, contact_message_count, contact_messages} = usePage().props;
    const [show, setShow] = useState(null)
    
    return (
        <Authenticated
                auth={props.auth}
                errors={props.errors}
            >
            <div>
                <dl className="grid grid-cols-1 gap-5 mt-5 sm:grid-cols-2 lg:grid-cols-3">
                    <div className="relative px-4 pt-5 pb-12 overflow-hidden bg-white rounded-lg shadow dark:bg-gray-800/40 sm:px-6 sm:pt-6">
                        <dt>
                            <div className="absolute p-3 text-white bg-yellow-500 rounded-md dark:text-slate-100">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M18 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM3 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 9.374 21c-2.331 0-4.512-.645-6.374-1.766Z" />
                                </svg>
                            </div>
                            <p className="ml-16 text-sm font-medium text-gray-800 truncate dark:text-gray-200">Newly joined</p>
                        </dt>
                        <dd className="flex items-baseline pb-6 ml-16 sm:pb-7">
                            <p className="text-2xl font-semibold text-gray-900 dark:text-gray-100">{today_joined}</p>
                            <div className="absolute inset-x-0 bottom-0 px-4 py-4 bg-gray-50 dark:bg-slate-800 sm:px-6">
                                <div className="text-sm">
                                    <Link href={ route('admin.users') } className="font-medium text-yellow-600 dark:text-yellow-300 hover:text-yellow-500">
                                        View all<span className="sr-only"> Item names, stats</span>
                                    </Link>
                                </div>
                            </div>
                        </dd>
                    </div>

                    <div className="relative px-4 pt-5 pb-12 overflow-hidden bg-white rounded-lg shadow dark:bg-gray-800/40 sm:px-6 sm:pt-6">
                        <dt>
                            <div className="absolute p-3 text-white rounded-md bg-sky-500 dark:text-slate-100">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z" />
                                </svg>
                            </div>
                            <p className="ml-16 text-sm font-medium text-gray-800 truncate dark:text-gray-200">Active Users</p>
                        </dt>
                        <dd className="flex items-baseline pb-6 ml-16 sm:pb-7">
                            <p className="text-2xl font-semibold text-gray-900 dark:text-gray-100">{active_users}</p>
                            <div className="absolute inset-x-0 bottom-0 px-4 py-4 bg-gray-50 dark:bg-slate-800 sm:px-6">
                                <div className="text-sm">
                                <Link href={route('admin.activeUsers')} className="font-medium text-sky-600 dark:text-sky-300 hover:text-sky-500">
                                    View all<span className="sr-only">Active users</span>
                                </Link>
                                </div>
                            </div>
                        </dd>
                    </div>

                    <div className="relative px-4 pt-5 pb-12 overflow-hidden bg-white rounded-lg shadow dark:bg-gray-800/40 sm:px-6 sm:pt-6">
                        <dt>
                        <div className="absolute p-3 text-white bg-indigo-500 rounded-md dark:text-slate-100">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z" />
                            </svg>
                        </div>
                        <p className="ml-16 text-sm font-medium text-gray-800 truncate dark:text-gray-200">Total Users</p>
                        </dt>
                        <dd className="flex items-baseline pb-6 ml-16 sm:pb-7">
                        <p className="text-2xl font-semibold text-gray-900 dark:text-gray-100">{users}</p>
                        
                        <div className="absolute inset-x-0 bottom-0 px-4 py-4 bg-gray-50 dark:bg-slate-800 sm:px-6">
                            <div className="text-sm">
                            <Link href={ route('admin.users')} className="font-medium text-indigo-600 dark:text-indigo-300 hover:text-indigo-500">
                                View all<span className="sr-only">users</span>
                            </Link>
                            </div>
                        </div>
                        </dd>
                    </div>

                    <div className="relative px-4 pt-5 pb-12 overflow-hidden bg-white rounded-lg shadow dark:bg-gray-800/40 sm:px-6 sm:pt-6">
                        <dt>
                        <div className="absolute p-3 text-white bg-red-400 rounded-md dark:text-slate-100">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12.76c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.076-4.076a1.526 1.526 0 0 1 1.037-.443 48.282 48.282 0 0 0 5.68-.494c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
                            </svg>
                        </div>
                        <p className="ml-16 text-sm font-medium text-gray-800 truncate dark:text-gray-200">New Messages</p>
                        </dt>
                        <dd className="flex items-baseline pb-6 ml-16 sm:pb-7">
                        <p className="text-2xl font-semibold text-gray-900 dark:text-gray-100">{contact_message_count}</p>
                        <div className="absolute inset-x-0 bottom-0 px-4 py-4 bg-gray-50 dark:bg-slate-800 sm:px-6">
                            <div className="text-sm">
                            <Link href={route('admin.contacts')} className="font-medium text-red-400 dark:text-red-300 hover:text-red-600">
                                View all<span className="sr-only">Contact Messages</span>
                            </Link>
                            </div>
                        </div>
                        </dd>
                    </div>

                    <div className="relative px-4 pt-5 pb-12 overflow-hidden bg-white rounded-lg shadow dark:bg-gray-800/40 sm:px-6 sm:pt-6">
                        <dt>
                            <div className="absolute p-3 text-white bg-blue-400 rounded-md dark:text-slate-100">
                                <ShieldCheckIcon className="w-6 h-6" />
                            </div>
                            <p className="ml-16 text-sm font-medium text-gray-800 truncate dark:text-gray-200">Verified users</p>
                        </dt>
                        <dd className="flex items-baseline pb-6 ml-16 sm:pb-7">
                            <p className="text-2xl font-semibold text-gray-900 dark:text-gray-100">{verified_count}</p>
                            <div className="absolute inset-x-0 bottom-0 px-4 py-4 bg-gray-50 dark:bg-slate-800 sm:px-6">
                                <div className="text-sm">
                                    <Link href={route('admin.verifiedUsers')} className="font-medium text-blue-400 dark:text-blue-300 hover:text-blue-600">
                                        View all<span className="sr-only">Verified users</span>
                                    </Link>
                                </div>
                            </div>
                        </dd>
                    </div>

                    <div className="relative px-4 pt-5 pb-12 overflow-hidden bg-white rounded-lg shadow dark:bg-gray-800/40 sm:px-6 sm:pt-6">
                        <dt>
                            <div className="absolute p-3 text-white bg-teal-400 rounded-md dark:text-slate-50">
                                <UserCircleIcon className="w-6 h-6" />
                            </div>
                            <p className="ml-16 text-sm font-medium text-gray-800 truncate dark:text-gray-200">Online users</p>
                        </dt>
                        <dd className="flex items-baseline pb-6 ml-16 sm:pb-7">
                            <p className="text-2xl font-semibold text-gray-900 dark:text-gray-100">{online_user_count}</p>
                            <div className="absolute inset-x-0 bottom-0 px-4 py-4 bg-gray-50 dark:bg-slate-800 sm:px-6">
                                <div className="text-sm">
                                    <Link href={route('admin.onlineUsers')} className="font-medium text-teal-400 dark:text-teal-300 hover:text-teal-600">
                                        View all <span className="sr-only">Unverified users</span>
                                    </Link>
                                </div>
                            </div>
                        </dd>
                    </div>
                </dl>
            </div>

            <div className="flow-root px-4 py-10 mt-4 mt-8 rounded-md dark:bg-slate-800 bg-slate-50">
              <h2 className="text-base font-semibold leading-7 text-slate-700 dark:text-slate-100">Users need approval <span className="text-red-400">{user_needs_approval.length !== 0 && '- ' + user_needs_approval.length}</span></h2>
                <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table className="min-w-full divide-y divide-slate-200 dark:divide-gray-300/10">
                            <thead>
                                <tr>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold dark:text-slate-100 text-slate-700 sm:pl-0">
                                        #
                                    </th>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold dark:text-slate-100 text-slate-700 sm:pl-0">
                                        Basic info
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold dark:text-slate-100 text-slate-700">
                                        Contact
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold dark:text-slate-100 text-slate-700">
                                        Status
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold dark:text-slate-100 text-slate-700">
                                        Subscription
                                    </th>
                                    <th scope="col" className="text-sm font-semibold text-left dark:text-slate-100 text-slate-700">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-300/10 ">
                                {user_needs_approval.map(({ id,
                                            username,
                                            email,
                                            age,
                                            gender,
                                            date_of_birth,
                                            ethnic_origin,
                                            country,
                                            height,
                                            phone,
                                            account_status,
                                            profile_image,
                                            trial_ends_at,
                                            email_verified_at,
                                            verification_image,
                                            subscription
                                        }) => (
                                    <tr key={id}>
                                        <td className="py-5 pl-4 pr-3 text-sm whitespace-nowrap sm:pl-0 dark:text-gray-400 text-slate-700">{id}</td>
                                        <td className="py-5 pl-4 pr-3 text-sm whitespace-nowrap sm:pl-0 ">
                                            <Link href={route('admin.userProfile', username)} as="a">
                                                <div className="flex items-center">
                                                    <div className="flex-shrink-0 h-11 w-11">
                                                        <img className="rounded-full h-11 w-11" src={profile_image} alt="" />
                                                    </div>
                                                    <div className="ml-4">
                                                        <div className="font-semibold text-slate-700 dark:text-gray-300 dark:hover:text-white">{username}</div>
                                                        <div className="mt-1 text-slate-700 dark:text-gray-400 dark:hover:text-white">{gender}, {date_of_birth}</div>
                                                        <div className="mt-1 text-slate-700 dark:text-gray-400 dark:hover:text-white">{age} Y/O, {height}</div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </td>
                                        <td className="px-3 py-2 text-sm text-gray-500 whitespace-nowrap">
                                            <div className="font-semibold text-slate-500 dark:text-gray-400">{email}</div>
                                            <div className="mt-1 text-slate-500 dark:text-gray-400">{phone}</div>
                                            <div className="mt-1 text-slate-500 dark:text-gray-400">{ethnic_origin} from {country}</div>
                                        </td>
                                        <td className="px-3 py-5 text-sm text-gray-500 whitespace-nowrap">
                                            <div className="flex items-center justify-end gap-x-2 sm:justify-start">
                                                <div className="text-gray-400 sm:hidden">
                                                    {account_status === 0 ? 'Not Active' : 'Active'}
                                                </div>
                                                <div className={account_status === 1 ? 'text-green-400 bg-green-400/10 flex-none rounded-full p-1' : 'flex-none rounded-full p-1 text-rose-400 bg-rose-400/10'}>
                                                    <div className="h-1.5 w-1.5 rounded-full bg-current" />
                                                </div>
                                                <div className="hidden text-slate-500 dark:text-white sm:block">
                                                    {account_status === 0 ? 'Not Active' : 'Active'}
                                                </div>
                                            </div>
                                            <div className="flex items-center justify-end gap-x-2 sm:justify-start">
                                                <div className="text-gray-400 sm:hidden">
                                                    {verification_image !== null ? 'Age verified' : 'Age not verified yet'}
                                                </div>
                                                <div className={verification_image !== null ? 'text-green-400 bg-green-400/10 flex-none rounded-full p-1' : 'flex-none rounded-full p-1 text-rose-400 bg-rose-400/10'}>
                                                    <div className="h-1.5 w-1.5 rounded-full bg-current" />
                                                </div>
                                                <div className="hidden text-slate-500 dark:text-white sm:block">
                                                    {verification_image !== null ? 'Age verified' : 'Age not verified yet' }
                                                </div>
                                            </div>
                                            <div className="flex items-center justify-end gap-x-2 sm:justify-start">
                                                <div className="text-gray-400 sm:hidden">
                                                    {email_verified_at !== null ? 'Email verified' : 'Email not verified yet'}
                                                </div>
                                                <div className={email_verified_at !== null ? 'text-green-400 bg-green-400/10 flex-none rounded-full p-1' : 'flex-none rounded-full p-1 text-rose-400 bg-rose-400/10'}>
                                                    <div className="h-1.5 w-1.5 rounded-full bg-current" />
                                                </div>
                                                <div className="hidden text-slate-500 dark:text-white sm:block">
                                                    {email_verified_at !== null ? 'Email verified' : 'Email not verified yet' }
                                                </div>
                                            </div>
                                        </td>
                                        <td className="px-3 py-5 text-sm text-gray-500 whitespace-nowrap">
                                            <div>{subscription ? `Subscribed to ${subscription.name}` : 'No Subscription'}</div>
                                            <div>Trial ends in - {trial_ends_at} Days</div>
                                        </td>
                                        <td className="relative py-5 pl-3 pr-4 text-sm font-medium text-right rounded-md whitespace-nowrap sm:pr-3">
                                            {show == id ? <button onClick={() => setShow(null)} className="pl-4 focus:outline-none">
                                                <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none">
                                                    <path d="M4.16667 10.8334C4.62691 10.8334 5 10.4603 5 10.0001C5 9.53984 4.62691 9.16675 4.16667 9.16675C3.70643 9.16675 3.33334 9.53984 3.33334 10.0001C3.33334 10.4603 3.70643 10.8334 4.16667 10.8334Z" stroke="#A1A1AA" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M10 10.8334C10.4602 10.8334 10.8333 10.4603 10.8333 10.0001C10.8333 9.53984 10.4602 9.16675 10 9.16675C9.53976 9.16675 9.16666 9.53984 9.16666 10.0001C9.16666 10.4603 9.53976 10.8334 10 10.8334Z" stroke="#A1A1AA" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M15.8333 10.8334C16.2936 10.8334 16.6667 10.4603 16.6667 10.0001C16.6667 9.53984 16.2936 9.16675 15.8333 9.16675C15.3731 9.16675 15 9.53984 15 10.0001C15 10.4603 15.3731 10.8334 15.8333 10.8334Z" stroke="#A1A1AA" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </button> : <button onClick={() => setShow(id)} className="pl-4 focus:outline-none">
                                                <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none">
                                                    <path d="M4.16667 10.8334C4.62691 10.8334 5 10.4603 5 10.0001C5 9.53984 4.62691 9.16675 4.16667 9.16675C3.70643 9.16675 3.33334 9.53984 3.33334 10.0001C3.33334 10.4603 3.70643 10.8334 4.16667 10.8334Z" stroke="#A1A1AA" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M10 10.8334C10.4602 10.8334 10.8333 10.4603 10.8333 10.0001C10.8333 9.53984 10.4602 9.16675 10 9.16675C9.53976 9.16675 9.16666 9.53984 9.16666 10.0001C9.16666 10.4603 9.53976 10.8334 10 10.8334Z" stroke="#A1A1AA" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M15.8333 10.8334C16.2936 10.8334 16.6667 10.4603 16.6667 10.0001C16.6667 9.53984 16.2936 9.16675 15.8333 9.16675C15.3731 9.16675 15 9.53984 15 10.0001C15 10.4603 15.3731 10.8334 15.8333 10.8334Z" stroke="#A1A1AA" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </button>}
                                            {show == id && <div className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-gray-50 dark:bg-gray-800 py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                                <div className="px-4 py-2 text-xs cursor-pointer dark:bg-slate-800 dark:text-white text-slate-700 hover:bg-indigo-700 dark:hover:bg-indigo-700 dark:hover:text-white hover:text-white">
                                                    <Link href={route('admin.activate_user', id)} method="post" as="button">
                                                        Activate
                                                    </Link>
                                                </div>
                                                <div className="px-4 py-2 text-xs cursor-pointer dark:bg-slate-800 dark:text-white text-slate-700 hover:bg-indigo-700 dark:hover:bg-indigo-700 dark:hover:text-white hover:text-white">
                                                    <Link href={route('admin.inactivateUser', id)} method="post" as="button">
                                                        Decline account
                                                    </Link>
                                                </div>
                                                <div className="px-4 py-2 text-xs cursor-pointer dark:bg-slate-800 dark:text-white text-slate-700 hover:bg-indigo-700 dark:hover:bg-indigo-700 dark:hover:text-white hover:text-white">
                                                    <Link href={route('admin.suspendUser', id)} method="post" as="button">
                                                        Suspend
                                                    </Link>
                                                </div>

                                                <div className="px-4 py-2 text-xs cursor-pointer dark:bg-slate-800 dark:text-white text-slate-700 hover:bg-indigo-700 dark:hover:bg-indigo-700 dark:hover:text-white hover:text-white">
                                                    <Link href={route('admin.userProfile', username)} as="a">
                                                        Show
                                                    </Link>
                                                </div>
                                            </div>}
                                        </td>
                                    </tr>
                                ))}
                                {user_needs_approval.length === 0 && 
                                    <tr> 
                                        <td colSpan={6} className="text-sm font-normal text-slate-700 dark:text-slate-300">
                                            <div className="flex items-center justify-center mt-5 text-center ">
                                                No user needs approval!
                                            </div> 
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            
        {contact_messages.length !== 0 &&                 
          <div className="py-10 mt-4 rounded-md dark:bg-slate-800 bg-slate-50">
              <h2 className="px-4 text-base font-semibold leading-7 text-slate-700 dark:text-slate-100 sm:px-6 lg:px-8">Latest Messages <span className="text-red-400">{contact_messages.length !== 0 && '- '+ contact_messages.length}</span></h2>
              <table className="w-full mt-6 text-left whitespace-nowrap">
                  <colgroup>
                      <col className="w-full sm:w-4/12" />
                      <col className="lg:w-4/12" />
                      <col className="lg:w-2/12" />
                      <col className="lg:w-1/12" />
                      <col className="lg:w-1/12" />
                  </colgroup>
                  <thead className="text-sm leading-6 border-b border-slate-300 dark:border-white/10 text-slate-800 dark:text-slate-100">
                  <tr>
                      <th scope="col" className="py-2 pl-4 pr-8 font-semibold sm:pl-6 lg:pl-8">
                          Name
                      </th>
                      <th scope="col" className="hidden py-2 pl-0 pr-8 font-semibold sm:table-cell">
                          Contact info
                      </th>
                      <th scope="col" className="hidden py-2 pl-0 pr-8 font-semibold md:table-cell lg:pr-20">
                          Query Type
                      </th>
                      <th scope="col" className="py-2 pl-0 pr-4 font-semibold text-right sm:pr-8 sm:text-left">
                          Message
                      </th>
                      <th scope="col" className="hidden py-2 pl-0 pr-4 font-semibold text-right sm:table-cell sm:pr-6 lg:pr-8">
                          Existing user?
                      </th>
                      <th>
                        
                      </th>
                  </tr>
                  </thead>
                  <tbody className="divide-y divide-slate-500 dark:divide-white/5">
                  {contact_messages.length !== 0 && contact_messages.map(({ id, name, email,
                                                                            contact_number,
                                                                            query_type,
                                                                            message,
                                                                            existing_user,}) => 
                    (
                      <tr key={id}>
                        <td className="py-4 pl-4 pr-8 sm:pl-6 lg:pl-8">
                            <div className="flex items-center ">
                              <div className="text-sm font-medium leading-6 truncate text-slate-800 dark:text-slate-100">{name}</div>
                            </div>
                        </td>
                        <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                            <div className="flex-row gap-x-3">
                              <div className="font-mono text-sm leading-6 text-gray-700 dark:text-gray-100">{email}</div>
                              <div className="font-mono text-sm leading-6 text-gray-700 dark:text-gray-100">{contact_number}</div>
                            </div>
                        </td>
                        <td className="py-4 pl-4 pr-8 sm:pl-6 lg:pl-8">
                            <div className="flex items-center ">
                              <div className="text-sm font-medium leading-6 truncate text-slate-800 dark:text-slate-100">{query_type}</div>
                            </div>
                        </td>
                        <td className="py-4 pl-4 pr-8 sm:pl-6 lg:pl-8">
                            <div className="flex items-center ">
                              <div className="text-sm font-medium leading-6 truncate text-wrap line-clamp-2 text-slate-800 dark:text-slate-100">{message}</div>
                            </div>
                        </td>
                        <td className="py-4 pl-0 pr-4 text-sm leading-6 sm:pr-8 lg:pr-20">
                            <div className="flex items-center justify-end gap-x-2 sm:justify-start">
                                <div className="text-gray-400 sm:hidden">
                                    {existing_user === 1 ? 'Yes' : 'No'}
                                </div>
                                <div className={existing_user === 1 ? 'text-green-400 bg-green-400/10 flex-none rounded-full p-1' : 'flex-none rounded-full p-1 text-rose-400 bg-rose-400/10'}>
                                    <div className="h-1.5 w-1.5 rounded-full bg-current" />
                                </div>
                                <div className="hidden text-slate-800 dark:text-slate-100 sm:block">{existing_user === 1 ? 'Yes' : 'No'}</div>
                            </div>
                        </td>
                        
                        <td className="hidden py-4 pl-0 pr-4 text-sm leading-6 text-right text-slate-700 dark:text-gray-400 sm:table-cell sm:pr-6 lg:pr-8">
                            <Link className="flex items-center justify-center gap-2 text-sm font-semibold hover:underline" href={route('admin.contactrespond', id)} as="a">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                                </svg>
                                <span>View</span>
                            </Link>
                        </td>
                      </tr>
                  ))}
                  {contact_messages.length === 0 && 
                      <tr> 
                          <td colSpan={6} className="text-sm font-normal text-slate-700 dark:text-slate-300">
                             <div className="flex items-center justify-center mt-5 text-center ">
                                No messages!
                              </div> 
                          </td>
                      </tr>
                  }
                  </tbody>
              </table>
          </div>
        }

        {waiting_to_be_verified.length !== 0 &&
            <div className="flow-root px-4 py-10 mt-4 mt-8 rounded-md dark:bg-slate-800 bg-slate-50">
                <h2 className="text-base font-semibold leading-7 text-slate-700 dark:text-slate-100">Waiting to be verified <span className="text-red-400">{waiting_to_be_verified.length !== 0 && '- '+ waiting_to_be_verified.length}</span></h2>
                    <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <table className="min-w-full divide-y divide-slate-200 dark:divide-gray-300/10">
                                <thead>
                                    <tr>
                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold dark:text-slate-100 text-slate-700 sm:pl-0">
                                            #
                                        </th>
                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold dark:text-slate-100 text-slate-700 sm:pl-0">
                                            Basic info
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold dark:text-slate-100 text-slate-700">
                                            Contact
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold dark:text-slate-100 text-slate-700">
                                            Status
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold dark:text-slate-100 text-slate-700">
                                            Subscription
                                        </th>
                                        <th scope="col" className="text-sm font-semibold text-left dark:text-slate-100 text-slate-700">
                                            <span>Action</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-300/10 ">
                                    {waiting_to_be_verified.map(({ id,
                                                username,
                                                email,
                                                age,
                                                gender,
                                                date_of_birth,
                                                ethnic_origin,
                                                country,
                                                height,
                                                phone,
                                                account_status,
                                                profile_image,
                                                trial_ends_at,
                                                email_verified_at,
                                                verification_image,
                                                subscription
                                            }) => (
                                        <tr key={id}>
                                            <td className="py-5 pl-4 pr-3 text-sm whitespace-nowrap sm:pl-0 dark:text-gray-400 text-slate-700">{id}</td>
                                            <td className="py-5 pl-4 pr-3 text-sm whitespace-nowrap sm:pl-0 ">
                                                <Link href={route('admin.userProfile', username)} as="a">
                                                    <div className="flex items-center">
                                                        <div className="flex-shrink-0 h-11 w-11">
                                                            <img className="rounded-full h-11 w-11" src={profile_image} alt="" />
                                                        </div>
                                                        <div className="ml-4">
                                                            <div className="font-semibold text-slate-700 dark:text-gray-300 dark:hover:text-white">{username}</div>
                                                            <div className="mt-1 text-slate-700 dark:text-gray-400 dark:hover:text-white">{gender}, {date_of_birth}</div>
                                                            <div className="mt-1 text-slate-700 dark:text-gray-400 dark:hover:text-white">{age} Y/O, {height}</div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </td>
                                            <td className="px-3 py-2 text-sm text-gray-500 whitespace-nowrap">
                                                <div className="font-semibold text-slate-500 dark:text-gray-400">{email}</div>
                                                <div className="mt-1 text-slate-500 dark:text-gray-400">{phone}</div>
                                                <div className="mt-1 text-slate-500 dark:text-gray-400">{ethnic_origin} from {country}</div>
                                            </td>
                                            <td className="px-3 py-5 text-sm text-gray-500 whitespace-nowrap">
                                                <div className="flex items-center justify-end gap-x-2 sm:justify-start">
                                                    <div className="text-gray-400 sm:hidden">
                                                        {account_status === 0 ? 'Not Active' : 'Active'}
                                                    </div>
                                                    <div className={account_status === 1 ? 'text-green-400 bg-green-400/10 flex-none rounded-full p-1' : 'flex-none rounded-full p-1 text-rose-400 bg-rose-400/10'}>
                                                        <div className="h-1.5 w-1.5 rounded-full bg-current" />
                                                    </div>
                                                    <div className="hidden text-slate-500 dark:text-white sm:block">
                                                        {account_status === 0 ? 'Not Active' : 'Active'}
                                                    </div>
                                                </div>
                                                <div className="flex items-center justify-end gap-x-2 sm:justify-start">
                                                    <div className="text-gray-400 sm:hidden">
                                                        {verification_image !== null ? 'Age verified' : 'Age not verified yet'}
                                                    </div>
                                                    <div className={verification_image !== null ? 'text-green-400 bg-green-400/10 flex-none rounded-full p-1' : 'flex-none rounded-full p-1 text-rose-400 bg-rose-400/10'}>
                                                        <div className="h-1.5 w-1.5 rounded-full bg-current" />
                                                    </div>
                                                    <div className="hidden text-slate-500 dark:text-white sm:block">
                                                        {verification_image !== null ? 'Age verified' : 'Age not verified yet' }
                                                    </div>
                                                </div>
                                                <div className="flex items-center justify-end gap-x-2 sm:justify-start">
                                                    <div className="text-gray-400 sm:hidden">
                                                        {email_verified_at !== null ? 'Email verified' : 'Email not verified yet'}
                                                    </div>
                                                    <div className={email_verified_at !== null ? 'text-green-400 bg-green-400/10 flex-none rounded-full p-1' : 'flex-none rounded-full p-1 text-rose-400 bg-rose-400/10'}>
                                                        <div className="h-1.5 w-1.5 rounded-full bg-current" />
                                                    </div>
                                                    <div className="hidden text-slate-500 dark:text-white sm:block">
                                                        {email_verified_at !== null ? 'Email verified' : 'Email not verified yet' }
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="px-3 py-5 text-sm text-gray-500 whitespace-nowrap">
                                                <div>{subscription ? `Subscribed to ${subscription.name}` : 'No Subscription'}</div>
                                                <div>Trial ends in - {trial_ends_at} Days</div>
                                            </td>
                                            <td className="relative py-5 pl-3 pr-4 text-sm font-medium text-right rounded-md whitespace-nowrap sm:pr-3">
                                                {show == id ? <button onClick={() => setShow(null)} className="pl-4 focus:outline-none">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none">
                                                        <path d="M4.16667 10.8334C4.62691 10.8334 5 10.4603 5 10.0001C5 9.53984 4.62691 9.16675 4.16667 9.16675C3.70643 9.16675 3.33334 9.53984 3.33334 10.0001C3.33334 10.4603 3.70643 10.8334 4.16667 10.8334Z" stroke="#A1A1AA" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M10 10.8334C10.4602 10.8334 10.8333 10.4603 10.8333 10.0001C10.8333 9.53984 10.4602 9.16675 10 9.16675C9.53976 9.16675 9.16666 9.53984 9.16666 10.0001C9.16666 10.4603 9.53976 10.8334 10 10.8334Z" stroke="#A1A1AA" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M15.8333 10.8334C16.2936 10.8334 16.6667 10.4603 16.6667 10.0001C16.6667 9.53984 16.2936 9.16675 15.8333 9.16675C15.3731 9.16675 15 9.53984 15 10.0001C15 10.4603 15.3731 10.8334 15.8333 10.8334Z" stroke="#A1A1AA" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </button> : <button onClick={() => setShow(id)} className="pl-4 focus:outline-none">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none">
                                                        <path d="M4.16667 10.8334C4.62691 10.8334 5 10.4603 5 10.0001C5 9.53984 4.62691 9.16675 4.16667 9.16675C3.70643 9.16675 3.33334 9.53984 3.33334 10.0001C3.33334 10.4603 3.70643 10.8334 4.16667 10.8334Z" stroke="#A1A1AA" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M10 10.8334C10.4602 10.8334 10.8333 10.4603 10.8333 10.0001C10.8333 9.53984 10.4602 9.16675 10 9.16675C9.53976 9.16675 9.16666 9.53984 9.16666 10.0001C9.16666 10.4603 9.53976 10.8334 10 10.8334Z" stroke="#A1A1AA" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M15.8333 10.8334C16.2936 10.8334 16.6667 10.4603 16.6667 10.0001C16.6667 9.53984 16.2936 9.16675 15.8333 9.16675C15.3731 9.16675 15 9.53984 15 10.0001C15 10.4603 15.3731 10.8334 15.8333 10.8334Z" stroke="#A1A1AA" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </button>}
                                                {show == id && <div className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-gray-50 dark:bg-gray-800 py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                                    <div className="px-4 py-2 text-xs cursor-pointer dark:bg-slate-800 dark:text-white text-slate-700 hover:bg-indigo-700 dark:hover:bg-indigo-700 dark:hover:text-white hover:text-white">
                                                        <Link href={route('admin.activate_user', id)} method="post" as="button">
                                                            Activate
                                                        </Link>
                                                    </div>
                                                    <div className="px-4 py-2 text-xs cursor-pointer dark:bg-slate-800 dark:text-white text-slate-700 hover:bg-indigo-700 dark:hover:bg-indigo-700 dark:hover:text-white hover:text-white">
                                                        <Link href={route('admin.inactivateUser', id)} method="post" as="button">
                                                            Decline account
                                                        </Link>
                                                    </div>
                                                    <div className="px-4 py-2 text-xs cursor-pointer dark:bg-slate-800 dark:text-white text-slate-700 hover:bg-indigo-700 dark:hover:bg-indigo-700 dark:hover:text-white hover:text-white">
                                                        <Link href={route('admin.suspendUser', id)} method="post" as="button">
                                                            Suspend
                                                        </Link>
                                                    </div>

                                                    <div className="px-4 py-2 text-xs cursor-pointer dark:bg-slate-800 dark:text-white text-slate-700 hover:bg-indigo-700 dark:hover:bg-indigo-700 dark:hover:text-white hover:text-white">
                                                        <Link href={route('admin.userProfile', username)} as="a">
                                                            Show
                                                        </Link>
                                                    </div>
                                                </div>}
                                            </td>
                                        </tr>
                                    ))}
                                    {waiting_to_be_verified.length === 0 && 
                                        <tr> 
                                            <td colSpan={4} className="text-sm font-medium text-center text-slate-700 dark:text-slate-300">
                                                <div className="flex items-center justify-center mt-5 text-center ">
                                                    No user needs verification at this moment.
                                                </div> 
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
        }

        </Authenticated>
    );
}