// resources/js/Components/SortDropdown.jsx
import React, { useState } from 'react';
import { Inertia } from '@inertiajs/inertia';
import { usePage } from '@inertiajs/inertia-react';

const SortDropdown = () => {
    const { sort } = usePage().props;
    const [selectedSort, setSelectedSort] = useState(sort);

    const handleSortChange = (e) => {
        setSelectedSort(e.target.value);
        Inertia.get(route('home'), { sort: e.target.value }, { preserveState: true });
    };

    return (
        <div className="">
            <div className="relative">
                <select
                    id="sort"
                    value={selectedSort}
                    onChange={handleSortChange}
                    className="appearance-none text-slate-700 dark:text-slate-50 my-2.5 px-2.5 border-0 py-2 pl-3 pr-8 ring-1 ring-slate-700 dark:ring-slate-400 focus:ring-2 focus:ring-slate-800 font-medium sm:text-sm sm:leading-6 rounded-md shadow-sm"
                >
                    <option className="pr-2 mr-2" defaultValue="" disabled >Sort</option>
                    <option className="pr-2 mr-2" value="default">Default</option>
                    <option className="pr-2 mr-2" value="age_asc">Age Ascending</option>
                    <option className="pr-2 mr-2" value="age_desc">Age Descending</option>
                    <option className="pr-2 mr-2" value="height_asc">Height Ascending</option>
                    <option className="pr-2 mr-2" value="height_desc">Height Descending</option>
                </select>
            </div>
        </div>
    );
};

export default SortDropdown;