import ValidationErrors from '@/Components/ValidationErrors'
import Authenticated from '@/Layouts/Authenticated'
import { Inertia } from '@inertiajs/inertia';
import React, { useRef, useState } from 'react'
import { Head, Link, useForm, usePage } from '@inertiajs/inertia-react';
import Button from '@/Components/Button';
import Avatar from '@/Components/Avatar';

function Index({auth, is_verified}) {
    const {verification_image} = usePage().props;
    const { data, setData, post, processing, errors } = useForm({
        image: null,
    });

    const [previewImage, setPreviewImage] = useState(null);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setData('image', file);
        setPreviewImage(URL.createObjectURL(file));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        post('/settings/upload-verification-image');
    };

    return (
        <Authenticated 
            auth={auth}
            errors={errors}
            header={<h2 className="inline-flex items-center justify-center gap-2 text-xl font-semibold leading-tight text-gray-800 dark:text-slate-50 ">Verify age <span className="relative text-indigo-600 dark:text-indigo-400">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75m-3-7.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285Z" />
                        </svg>
                    </span></h2>}
            btnName="Back"
            svg={<svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2"><path strokeLinecap="round" strokeLinejoin="round" d="M10 19l-7-7m0 0l7-7m-7 7h18" /></svg>}
            href={route('auth.user.profile')}
        >
            <Head title="Verify age" />
            <div className="px-4 mx-auto sm:max-w-3xl max-w-none sm:px-6 md:px-8">
                <div className="">
                    <div className="items-baseline mb-2 ">
                    {is_verified === 0 ? (
                        <>
                            <h2 className="mt-6 text-lg font-semibold text-slate-900 dark:text-purple-50">
                                Upload a photo ID.
                            </h2>
                            <p className="mt-1 text-lg font-normal text-gray-500 dark:text-slate-200">
                                We understand privacy is extremely important. We follow industry best practices for data security and encryption and will be used solely for age verification purposes.
                            </p>
                        </>
                    ) : (
                        <>
                            <h2 className="mt-6 text-lg font-semibold text-slate-900 dark:text-purple-50">
                                Thank you for verifying your age.
                            </h2>
                            <p className="mt-1 text-lg font-normal text-gray-500 dark:text-slate-200">
                                We will notify with further updates.
                            </p>
                        </>
                    )}
                    </div>
                </div>

                <ValidationErrors errors={errors} />
 
                {is_verified === 0 && (
                    <div className="flex justify-center mt-8">
                        <form onSubmit={handleSubmit}>

                            <div className="p-2">
                                <div className="">
                                    {verification_image === null && !previewImage ? (
                                        <div className="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow cursor-pointer hover:bg-gray-100 hover:border-gray-300 dark:hover:bg-gray-900/90 dark:bg-gray-800 dark:border-gray-700">
                                            <label className="flex flex-col ">
                                                <div className="flex flex-col items-center justify-center pt-10 cursor-pointer">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="w-8 h-8 text-gray-400 group-hover:text-gray-600"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor"
                                                    >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="2"
                                                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                                    />
                                                    </svg>
                                                    <p className="pt-1 mb-2 text-sm tracking-wider text-gray-400 hover:text-gray-600">
                                                        Choose a photo
                                                    </p>
                                                </div>
                                                <input
                                                    type="file"
                                                    id="image"
                                                    name="image"
                                                    accept="image/*"
                                                    onChange={handleImageChange}
                                                    className="opacity-0"
                                                />
                                            </label>
                                        </div>
                                    ) : (previewImage || verification_image) && (
                                        <div className="grid ">
                                            <div className="max-w-sm p-6 bg-white border h-48 border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 group relative flex items-end overflow-hidden md:h-[13rem]">
                                                <img
                                                    src={previewImage || verification_image}
                                                    layout="fill"
                                                    className={`absolute inset-0 h-full w-full object-cover object-center transition duration-200 group-hover:scale-110`}
                                                />
                                            </div>
                                            <label className="flex flex-col items-center justify-center mt-2">
                                                <p className="flex items-center justify-center font-medium tracking-wide text-center text-gray-600 cursor-pointer text-md hover:text-gray-800">
                                                    Click here to change
                                                </p>
                                                <input
                                                    type="file"
                                                    id="image"
                                                    name="image"
                                                    accept="image/*"
                                                    onChange={handleImageChange}
                                                    className="opacity-0"
                                                />
                                            </label>
                                        </div>
                                    )}
                                </div>
                                
                                <div className={` ${previewImage && 'hidden'} mt-4`}>
                                    <p className="text-slate-800 dark:text-slate-50">Accepted Forms of Photo ID</p>
                                    <ul className="text-sm list-disc list-inside text-slate-600 dark:text-slate-200">
                                        <li>Passport</li>
                                        <li>Photo driving licence (full and provisional)</li>
                                        <li>A biometric immigration document</li>
                                    </ul>
                                </div>
                            </div>
                            {previewImage && 
                                <button type="submit" 
                                    disabled={processing}
                                    className='flex items-center justify-center w-full px-10 py-4 mt-2 mb-6 text-base font-medium leading-4 bg-purple-600 rounded-md text-slate-50 hover:text-white hover:bg-purple-700 focus:ring focus:ring-offset-2 focus:ring-gray-800'
                                >
                                    {processing ? 'Uploading...' : 'Upload Image'}
                                </button>
                            }
                        </form>
                    </div>
                )}
                
            </div>
        </Authenticated>
  )
}

export default Index