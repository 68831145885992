import React, { useState } from 'react';
import ApplicationLogo from '@/Components/ApplicationLogo';
import Dropdown from '@/Components/Dropdown';
import NavLink from '@/Components/NavLink';
import ResponsiveNavLink from '@/Components/ResponsiveNavLink';
import { Link, usePage } from '@inertiajs/inertia-react';
import { ThemeContext } from '@/context/ThemeContext';
import Button from '@/Components/Button';
import FlashMessages from '@/Components/FlashMessages';

import Img from '../../../public/assets/images/user-avatar.png';
import LogoPurple from '../../../public/assets/images/logo-purple.svg';
import LogoLight from '../../../public/assets/images/logo-light.svg';
import SortDropdown from '@/Components/SortDropdown';
import Avatar from '@/Components/Avatar';


export default function Authenticated({ auth, header, btnName, href, svg, children }) {
    const [showingNavigationDropdown, setShowingNavigationDropdown] = useState(false);
    const { theme, setTheme } = React.useContext(ThemeContext);
    const { unreadNotificationsCount, unreadMessagesCount } = usePage().props;

    const { component } = usePage();
    const isHomePage = component === 'User/Home';

    return (
        <div className="min-h-screen bg-gray-100 dark:bg-slate-900">
            <nav className="bg-white dark:bg-slate-800 border-b border-gray-100 dark:border-slate-500 sticky top-0 z-40 backdrop-blur flex-none transition-colors duration-500 lg:z-50 lg:border-b lg:border-slate-900/10 dark:border-slate-50/[0.06] bg-white/95 supports-backdrop-blur:bg-white/60 dark:bg-transparent">
                <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <div className="flex justify-between h-16">
                        <div className="flex">
                            <div className="flex items-center shrink-0">
                                <Link href={route('home')} className="flex">
                                    {/* <img className="w-full h-7" src="assets/images/logo-purple.svg" alt="shape" /> */}
                                    <img className="block w-full h-7 dark:hidden" src={LogoPurple} alt="shape" />
                                    <img className="hidden w-full h-7 dark:block" src={LogoLight} alt="shape" />

                                    {/* <span className="self-center text-2xl font-semibold text-purple-800 whitespace-nowrap dark:text-slate-50 ">Suitable</span> */}
                                </Link>
                            </div>

                            <div className="hidden space-x-8 sm:-my-px sm:ml-10 sm:flex">
                                <NavLink href={route('home')} active={route().current('home')}>
                                    Matches
                                </NavLink>
                                <NavLink href={route('mutualMatches')} active={route().current('mutualMatches')}>
                                    Mutual Matches
                                </NavLink>
                                <NavLink href={route('interests')} active={route().current('interests')}>
                                    Interests
                                </NavLink>
                            </div>
                           
                        </div>

                        <div className="hidden sm:flex sm:items-center sm:ml-6">
                            {/* <Link href={route('auth.user.profile')} type="button" className="ml-2 flex items-center justify-center text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5">
                                <div className="px-2 py-1 border border-red-400 rounded-full h-15 w-15">
                                    <h4 className="text-xs font-semibold text-red-400">Profile - 50%</h4>
                                </div>
                            </Link> */}
                            {theme === 'dark' ? (
                                <button id="theme-toggle" type="button" onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')} className={`${theme === 'light' ? 'hidden' : ''}text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5`}>
                                    <svg id="theme-toggle-dark-icon" xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z" /></svg>
                                    {/* Night */}
                                </button>

                                    ) : (
                                    <button id="theme-toggle" type="button" onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')} className={`${theme === 'dark' ? 'hidden' : ''}text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5`}>

                                        <svg id="theme-toggle-light-icon" className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z" fillRule="evenodd" clipRule="evenodd"></path></svg>
                                        {/* Day */}
                                    </button>
                            )}
{/* animate-bounce */}
                            {/* <Link href={route('notifications')} type="button" className={`text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5`}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                                </svg>
                            </Link> */}

                            <Link href={route('notifications')} type="button" className={`${unreadNotificationsCount > 0 && 'bg-gray-100 dark:bg-slate-700/50'} ml-2 relative inline-flex items-center p-2.5 font-medium text-center  text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm`}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                                </svg>
                                <span className="sr-only">Notifications</span>
                                {/* <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -end-2 dark:border-gray-900">20</div> */}
                                {unreadNotificationsCount > 0 && (
                                    <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-400 border-2 border-white rounded-full animate-pulse -top-2 -end-2 dark:border-gray-900">
                                    {unreadNotificationsCount}
                                    </div>
                                )}
                            </Link>

                            <Link href={route('chat')} type="button" className={`${unreadMessagesCount > 0 && 'bg-gray-100 dark:bg-slate-700/50'} ml-2 relative inline-flex items-center p-2.5 font-medium text-center  text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm`}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
                                </svg>
                                <span className="ml-2">Inbox</span>
                                {/* <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -end-2 dark:border-gray-900">20</div> */}
                                {unreadMessagesCount > 0 && (
                                    <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-400 border-2 border-white rounded-full animate-pulse -top-2 -end-2 dark:border-gray-900">
                                    {unreadMessagesCount}
                                    </div>
                                )}
                            </Link>

                           

                            <div className="relative ml-3">
                                <Dropdown>
                                    <Dropdown.Trigger>
                                        <span className="inline-flex rounded-md">
                                            <button
                                                type="button"
                                                className="inline-flex items-center text-sm font-medium leading-4 text-gray-500 transition duration-150 ease-in-out border-2 border-purple-200 rounded-full hover:border-purple-400 dark:text-gray-100 focus:outline-none"
                                            >
                                                {/* {JSON.stringify(auth.user.profile_image)} */}
                                                    {/* <img src={auth.user.profile_image} alt={`Profile photo`}  className="w-10 h-10 rounded-full" /> */}
                                                    <Avatar src={auth.user.profile_image} className="w-10 h-10" />
                                                
                                            </button>
                                        </span>
                                    </Dropdown.Trigger>

                                    <Dropdown.Content >
                                        <Dropdown.Link href={route('auth.user.profile')} method="get" as="button">
                                            <div className="flex space-x-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
                                                </svg>
                                                <span className='font-medium'>Profile</span>
                                            </div>
                                        </Dropdown.Link>
                                        {/* <Dropdown.Link href={route('auth.user.settings')} method="get" as="button">
                                            Settings
                                        </Dropdown.Link> */}
                                        <Dropdown.Link href={route('logout')} method="post" as="button">
                                            
                                            <div className="flex space-x-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15m3 0 3-3m0 0-3-3m3 3H9" />
                                                </svg>
                                                <span className='font-medium'>Log Out</span>
                                            </div>
                                            
                                        </Dropdown.Link>
                                    </Dropdown.Content>
                                </Dropdown>
                            </div>
                        </div>

                        <div className="flex items-center -mr-2 sm:hidden">
                        {theme === 'dark' ? (
                            <button id="theme-toggle" type="button" onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')} className={`${theme === 'light' ? 'hidden' : ''}text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5`}>
                                <svg id="theme-toggle-dark-icon" xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z" /></svg>
                            </button>

                                ) : (
                                <button id="theme-toggle" type="button" onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')} className={`${theme === 'dark' ? 'hidden' : ''}text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5`}>

                                    <svg id="theme-toggle-light-icon" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z" fillRule="evenodd" clipRule="evenodd"></path></svg>

                                </button>
                            )}
                            <Link href={route('notifications')} type="button" className={`${unreadNotificationsCount > 0 && 'bg-gray-100 dark:bg-slate-700/50'} ml-2 relative inline-flex items-center p-2.5 font-medium text-center  text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm`}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                                </svg>
                                <span className="sr-only">Notifications</span>
                                {/* <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -end-2 dark:border-gray-900">20</div> */}
                                {unreadNotificationsCount > 0 && (
                                    <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-400 border-2 border-white rounded-full animate-pulse -top-2 -end-2 dark:border-gray-900">
                                    {unreadNotificationsCount}
                                    </div>
                                )}
                            </Link>

                            <Link href={route('chat')} type="button" className={`${unreadMessagesCount > 0 && 'bg-gray-100 dark:bg-slate-700/50'} ml-2 relative inline-flex items-center p-2.5 font-medium text-center  text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm`}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
                                </svg>
                                {/* <span className="ml-2">Inbox</span> */}
                                {/* <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -end-2 dark:border-gray-900">20</div> */}
                                {unreadMessagesCount > 0 && (
                                    <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-400 border-2 border-white rounded-full animate-pulse -top-2 -end-2 dark:border-gray-900">
                                    {unreadMessagesCount}
                                    </div>
                                )}
                            </Link>
                            <button
                                onClick={() => setShowingNavigationDropdown((previousState) => !previousState)}
                                className="inline-flex items-center justify-center p-2 text-gray-400 transition duration-150 ease-in-out rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500"
                            >
                                <svg className="w-6 h-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                    <path
                                        className={!showingNavigationDropdown ? 'inline-flex' : 'hidden'}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M4 6h16M4 12h16M4 18h16"
                                    />
                                    <path
                                        className={showingNavigationDropdown ? 'inline-flex' : 'hidden'}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>

                <div className={(showingNavigationDropdown ? 'block' : 'hidden') + ' sm:hidden'}>
                    <div className="pt-2 pb-1 border-t border-gray-200 cursor-pointer dark:border-slate-800/10 hover:bg-slate-100 dark:hover:bg-slate-800/50">
                        <div className="flex items-center px-4 space-x-2">
                            <Link
                                href={route('auth.user.profile')}
                                type="button"
                                className="inline-flex items-center p-0.5 text-sm leading-4 font-medium rounded-full  text-gray-500 dark:text-gray-100 focus:outline-none transition ease-in-out duration-150"
                            >
                                <div className="inline-flex mr-4">
                                    <Avatar src={auth.user.profile_image}  className="w-12 h-12 p-1 border border-purple-500 rounded-full group-hover:border-purple-700" />
                                </div>
                                <div>
                                    <div className="text-base font-medium text-gray-800 dark:text-slate-50">{auth.user.username}</div>
                                    <div className="text-sm font-medium text-gray-500 dark:text-slate-50">{auth.user.email}</div>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="pt-2 pb-3 space-y-1">
                        <ResponsiveNavLink href={route('home')} active={route().current('home')}>
                            Matches
                        </ResponsiveNavLink>
                        <ResponsiveNavLink href={route('mutualMatches')} active={route().current('mutualMatches')}>
                            Mutual matches
                        </ResponsiveNavLink>
                        <ResponsiveNavLink href={route('interests')} active={route().current('interests')}>
                            Interest
                        </ResponsiveNavLink>
                        <ResponsiveNavLink method="post" href={route('logout')} as="button">
                            Log Out
                        </ResponsiveNavLink>
                    </div>
                </div>
            </nav>

            {header && (
                <header className="mt-4">
                    <div className="flex justify-between px-4 py-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                        <div>
                            {header}
                            <FlashMessages />
                        </div>
                        <div className='flex items-center justify-center gap-2'>
                            {isHomePage && <SortDropdown /> /* Render the SortDropdown if on the Home page */}
                            <Link
                                as="button"
                                href={href}
                                className="inline-flex items-center px-2 py-2 font-medium transition duration-150 ease-in-out bg-transparent border rounded-md text-slate-700 dark:text-slate-50 hover:bg-slate-800 hover:text-slate-50 dark:hover:bg-slate-900 border-slate-800 dark:border-slate-50 focus:ring-2 dark:ring-slate-400 focus:outline-none"
                            >
                                {svg}
                                <span className="hidden sm:block">{btnName}</span>
                            </Link>
                        </div>
                    </div>
                </header>
            )}
            


            <main>
                <div className="max-w-full px-2 mx-auto sm:px-6 md:px-8">
                    {children}
                </div>
            </main>

        </div>
    );
}
