import Button from '@/Components/Button';
import Dropdown from '@/Components/Dropdown';
import FlashMessages from '@/Components/FlashMessages';
import MatchedSideNav from '@/Components/MatchedSideNav';
import UserSideNav from '@/Components/UserSideNav';
import Authenticated from '@/Layouts/Authenticated'
import { Link, usePage } from '@inertiajs/inertia-react';
import React from 'react'

function BlockedUsers(props) {
    const { blockedusers, savers, user } = usePage().props;

    return (
      <Authenticated
        auth={props.auth}
        errors={props.errors}
        header={<h2 className="text-xl font-semibold leading-tight text-gray-800 dark:text-slate-50 ">Blocked Profiles</h2>}
        btnName="Back"
        svg={<svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2"><path strokeLinecap="round" strokeLinejoin="round" d="M10 19l-7-7m0 0l7-7m-7 7h18" /></svg>}
        href={route('auth.user.settings')}
      >

          <div className="px-4 mx-auto max-w-9xl sm:px-6 md:px-8">
              <div className="flex-row sm:flex sm:space-x-2">
                  <div className="sm:w-[250px] h-min ">
                      <UserSideNav/>
                  </div>

                  <div className="mt-2 rounded-md shadow-sm sm:mt-0 sm:w-2/3 bg-gray-50 dark:bg-slate-700 sm:p-10">
                      <div className="py-2">
                          <div className="max-w-3xl mx-auto sm:px-6 lg:px-8">
                              <div className="overflow-hidden ">
                                  <div className="p-4">
                                      {blockedusers.map(({ id, username, age, country, recidency_status, ethnic_origin, profile_image }) => (
                                            <div className="" key={id}>
                                                <div class="relative flex flex-col-reverse bg-slate-50 rounded-lg p-6 dark:bg-slate-800 dark:highlight-white/5 shadow-md mb-2">

                                                    <div class="flex items-center space-x-4">
                                                        <img src={profile_image} alt={`${username}'s Profile photo`} class="flex-none w-14 h-14 rounded-full object-cover" loading="lazy"/>
                                                        <div class="flex-auto">
                                                            <div class="text-base text-slate-900 font-semibold dark:text-slate-300">
                                                                {username}, {age}
                                                            </div>
                                                            <div className="mt-0.5 text-sm font-medium text-slate-600 dark:text-slate-200">{ethnic_origin} From {country}  - <span className="text-slate-600 dark:text-slate-200">{recidency_status}</span> </div>
                                                        </div>
                                                        <div class="text-slate-700 dark:text-slate-300">
                                                            <div className="mt-2">
                                                                <Link className="inline-flex items-center h-10 px-4 py-2 font-semibold transition duration-150 ease-in-out border rounded-md border-slate-500 text-slate-500 dark:text-slate-500 hover:bg-slate-800 hover:text-slate-50 dark:hover:bg-slate-50 dark:border-slate-50 focus:ring-2 dark:ring-slate-400 focus:outline-none" href={route('unblock-user', id)} method="post" as="button">
                                                                    Unblock
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                      ))}

                                      {blockedusers.length === 0 && (
                                          <p className="text-2xl font-semibold text-slate-800 dark:text-slate-50">No blocked users</p>
                                      )}
                                      {blockedusers.length >= 10 && (
                                          <div className="flex items-center justify-center">
                                              <Button className="items-center h-10 px-6 py-2 font-bold text-purple-600 transition duration-300 ease-in-out bg-transparent bg-purple-100 border border-purple-400 rounded-md dark:text-purple-400 dark:bg-slate-800 hover:bg-purple-600 hover:text-white dark:hover:bg-purple-400 dark:hover:text-slate-50 dark:border-purple-400 focus:ring-2 dark:ring-slate-400 sm:inline-flex focus:outline-none">
                                                  Load more users
                                              </Button>
                                          </div>
                                      )}
                                  </div>
                              </div>
                          </div>
                      </div>

                  </div>
              </div>
          </div>

      </Authenticated>
    )
}

export default BlockedUsers
