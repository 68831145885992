import { CheckIcon } from '@heroicons/react/outline';
import { CheckCircleIcon } from '@heroicons/react/solid'
import { Inertia } from '@inertiajs/inertia';
import { Link, usePage } from '@inertiajs/inertia-react'
import React, { useEffect } from 'react'

function Success() {
    const { planName, trialEndsAt} = usePage().props;
    useEffect(() => {
        const timer = setTimeout(() => {
            Inertia.visit('/home');
        }, 5000); // 10000 milliseconds = 10 seconds

        return () => clearTimeout(timer);
    }, []);


  return (
    <div class="flex items-center justify-center h-screen">
        {/* <div className="p-6 rounded-md bg-slate-100">
            <div className="flex">
                <div className="flex-shrink-0">
                    <CheckCircleIcon className="w-10 h-10 text-indigo-400" aria-hidden="true" />
                </div>
                <div className="ml-2">
                    <h1 className="text-3xl font-medium text-indigo-700">Subscription Successful</h1>
                    <div className="mt-2 text-sm text-indigo-700">
                        <p>Thank you for subscribing to a <span className="font-semibold">{planName}</span> plan.</p>
                        <p>Enjoy your free trial. Your trial will end on <span className="font-semibold">{trialEndsAt}</span></p>
                    </div>
                    <div className="mt-4">
                        <div className="flex items-center justify-center">
                            <Link
                                href='/home'
                                as="a"
                                className="font-medium main-btn gradient-btn"
                            >
                                <div className="flex items-center justify-center space-x-2">
                                    <div className="text-center">
                                        <p className="text-lg font-medium text-white text-gray-700">
                                            Redirecting
                                        </p>
                                    </div>
                                    <div className="w-1 h-1 bg-purple-200 rounded-full animate-bounce"></div>
                                    <div className="h-2.5 w-2.5 rounded-full bg-purple-300 animate-bounce"></div>
                                    <div className="w-3 h-3 bg-purple-400 rounded-full animate-bounce2"></div>
                                    <div className="h-3.5 w-3.5 rounded-full bg-purple-500 animate-bounce"></div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
        <div className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="flex items-center justify-center w-12 h-12 mx-auto bg-green-100 rounded-full">
                    <CheckIcon className="w-6 h-6 text-green-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <h3 className="text-base font-semibold leading-6 text-gray-900">
                        Subscription Successful
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">Thank you for subscribing to a <span className="font-semibold">{planName}</span> plan.</p>
                      <p>Enjoy your free trial. Your trial will end on <span className="font-semibold">{trialEndsAt}</span></p>

                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <Link
                    href='/home'
                    as="a"
                    className="inline-flex justify-center w-full px-3 py-2 text-sm font-semibold text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    <div className="flex items-center justify-center space-x-2">
                        <div className="text-center">
                            <p className="text-lg font-medium text-white text-gray-700">
                                Redirecting
                            </p>
                        </div>
                        <div className="w-1 h-1 bg-purple-200 rounded-full animate-bounce"></div>
                        <div className="h-1.5 w-1.5 rounded-full bg-purple-300 animate-bounce"></div>
                        <div className="w-2 h-2 bg-purple-400 rounded-full animate-bounce2"></div>
                        <div className="h-2.5 w-2.5 rounded-full bg-purple-500 animate-bounce"></div>
                    </div>
                  </Link>
                  
                </div>
              </div>
    </div>
  )
}

export default Success
