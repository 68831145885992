import Authenticated from '@/Layouts/AdminLayouts/Authenticated'
import { Link, useForm } from '@inertiajs/inertia-react';
import React from 'react'

function Create(props) {
    const { data, setData, post, processing, errors } = useForm({
        firstname: '',
        lastname: '',
        email: '',
        password_length: 8, // Set the default password length to 8
      });
    
      const handleSubmit = (e) => {
        e.preventDefault();
        post(route('admin.moderator.store'), {
          onSuccess: () => {
            // Handle success
          },
          onError: () => {
            // Handle error
          },
        });
      };
  return (
    <Authenticated  
        auth={props.auth}
        errors={props.errors}
    >
        <form onSubmit={handleSubmit}>
            {/* Form fields */}
            <div className="border-b  border-slate-200 dark:border-white/10 pb-12">
                <h2 className="text-base font-semibold leading-7 text-slate-800 dark:text-slate-100">Add new admin</h2>
                <p className="mt-1 text-sm leading-6 text-slate-600 dark:text-gray-400">
                Use a valid email, that will be sent to the newly created admin with a temporery password.
                </p>

                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-3">
                    <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-slate-800 dark:text-slate-100">
                    First name
                    </label>
                    <div className="mt-2">
                    <input
                        type="text"
                        name="first-name"
                        id="first-name"
                        value={data.firstname}
                        onChange={(e) => setData('firstname', e.target.value)}
                        autoComplete="given-name"
                        className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-slate-800 dark:text-slate-100 shadow-sm ring-1 ring-inset dark:ring-white/10 ring-slate-200  focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                    />
                    {errors.firstname && <div className="text-red-500">{errors.firstname}</div>}
                    </div>
                </div>

                <div className="sm:col-span-3">
                    <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-slate-800 dark:text-slate-100">
                        Last name
                    </label>
                    <div className="mt-2">
                        <input
                        type="text"
                        name="last-name"
                        id="last-name"
                        value={data.lastname}
                        onChange={(e) => setData('lastname', e.target.value)}
                        autoComplete="family-name"
                        className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-slate-800 dark:text-slate-100 shadow-sm ring-1 ring-inset dark:ring-white/10 ring-slate-200  focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                        />
                        {errors.lastname && <div className="text-red-500">{errors.lastname}</div>}
                    </div>
                    </div>

                    <div className="sm:col-span-4">
                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-slate-800 dark:text-slate-100">
                        Email address
                    </label>
                    <div className="mt-2">
                        <input
                        id="email"
                        name="email"
                        type="email"
                        value={data.email}
                        onChange={(e) => setData('email', e.target.value)}
                        autoComplete="email"
                        className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-slate-800 dark:text-slate-100 shadow-sm ring-1 ring-inset dark:ring-white/10 ring-slate-200  focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                        />
                        {errors.email && <div className="text-red-500">{errors.email}</div>}
                    </div>
                    </div>
                </div>
            </div>
            <div className="mt-6 flex items-center justify-end gap-x-6">
                <Link as="a" href={route('admin.moderator')} className="text-sm font-semibold leading-6 text-slate-800 dark:text-slate-100">
                    Cancel
                </Link>
                <button
                type="submit"
                className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-slate-800 dark:text-slate-100 shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                disabled={processing}
                >
                Save
                </button>
            </div>
        </form>
    </Authenticated>
  )
}

export default Create