import React from 'react';
import { Link, usePage } from '@inertiajs/inertia-react';
import moment from 'moment';
import Authenticated from '@/Layouts/Authenticated';

function Notification(props) {
    const { notifications } = usePage().props;
    const { data } = notifications;

    const renderNotificationMessage = (notification) => {
        const genderPronoun = notification.data.user.gender === 'Male' ? 'his' : 'her';

        switch (notification.type) {
            case 'App\\Notifications\\InvitedUserNotification':
                return `${notification.data.user.username} invited you to view ${genderPronoun} profile.`;
            case 'App\\Notifications\\AcceptedInvitationNotification':
                return `${notification.data.user.username} accepted your invitation.`;
            default:
                return 'You have a new notification.';
        }
    };

	
    return (
        <Authenticated 
            auth={props.auth}
            errors={props.errors}
            header={<h2 className="text-xl font-semibold leading-tight text-gray-800 dark:text-slate-50">Notifications</h2>}
            btnName="Back"
            svg={<svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2"><path strokeLinecap="round" strokeLinejoin="round" d="M10 19l-7-7m0 0l7-7m-7 7h18" /></svg>}
            href={route('home')}
        >
            <div className="px-4 mx-auto sm:max-w-3xl max-w-none sm:px-6 md:px-8">
                <div className="space-y-2">
                    {data.map(notification => (
                        <div key={notification.id} className="w-full p-6 rounded-lg shadow-md bg-slate-50 dark:bg-slate-800/30 dark:highlight-white/5">
                            <div className="flex flex-col-reverse">
                                <Link preserveScroll href={route('user-profile', { username: notification.data.user.username })} as="a" className="flex items-center space-x-4">
                                    <img
                                        src={notification.data.user.profile_image}
                                        alt={`${notification.data.user.username}'s Profile photo`}
                                        className={`flex-none w-12 h-12 rounded-full object-cover`}
                                        loading="lazy"
                                    />
                                    <div className="flex-auto">
                                        <div className="flex items-center space-x-2">
                                            <p className='font-bold text-md text-slate-900 dark:text-slate-50'>{notification.data.user.username}, {`${notification.data.user.age}`}</p>
                                        </div>
                                        <div className="text-sm font-medium text-slate-600 dark:text-slate-200">
                                            {renderNotificationMessage(notification)}
                                        </div>
                                    </div>
                                    <p className='text-xs font-medium text-slate-900 dark:text-slate-400'>{moment(notification.created_at).fromNow()}</p> 
                                </Link>
                            </div>
                            <div className="pl-8">
                                <div className='pt-2 pl-8'>
                                    <div className="flex space-x-4 text-sm font-medium">
                                        <div className="flex flex-auto space-x-0 sm:space-x-4">
                                            <Link href={route('user-profile', { username: notification.data.user.username })} as='a' className="inline-flex items-center font-bold text-purple-500 transition duration-150 ease-in-out bg-transparent hover:text-purple-700 dark:text-purple-400 dark:hover:text-purple-50 hover:border-b hover:border-purple-500 focus:outline-none">
                                                <span className="mr-1 text-xs font-semibold sm:text-sm">View</span>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-4 h-4">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                                                </svg>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div> 
        </Authenticated>
    );
}

export default Notification;
