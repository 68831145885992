import React from 'react'

function Extra({users}) {
  return (
    <div>
        {/* Side nav goes to bottom nav */}
        <div className="fixed bottom-0 w-full h-16 px-2 text-center bg-gray-900 md:w-1/5 md:bg-gray-900 md:pt-8 md:top-0 md:left-0 md:h-screen md:border-r-4 md:border-gray-600">
            <div className="mx-auto md:relative lg:float-right lg:px-6">
                <ul className="flex flex-row text-center list-reset md:flex-col md:text-left">
                    <li className="flex-1 mr-3">
                        <a href="#" className="block py-1 pl-1 text-gray-800 no-underline align-middle border-b-2 border-gray-800 md:py-3 hover:text-pink-500 md:border-gray-900 hover:border-pink-500">
                        <i className="pr-0 fas fa-link md:pr-3"></i><span className="block pb-1 text-xs text-gray-600 md:pb-0 md:text-base md:text-gray-400 md:inline-block">Link</span>
                        </a>
                    </li>
                    <li className="flex-1 mr-3">
                        <a href="#" className="block py-1 pl-1 text-gray-800 no-underline align-middle border-b-2 border-gray-800 md:py-3 hover:text-pink-500 md:border-gray-900 hover:border-pink-500">
                        <i className="pr-0 fas fa-link md:pr-3"></i><span className="block pb-1 text-xs text-gray-600 md:pb-0 md:text-base md:text-gray-400 md:inline-block">Link</span>
                        </a>
                    </li>
                    <li className="flex-1 mr-3">
                        <a href="#" className="block py-1 pl-1 text-white no-underline align-middle border-b-2 border-pink-600 md:py-3 hover:text-white">
                        <i className="pr-0 text-pink-500 fas fa-link md:pr-3"></i><span className="block pb-1 text-xs text-white md:pb-0 md:text-base md:font-bold md:inline-block">Active Link</span>
                        </a>
                    </li>
                    <li className="flex-1 mr-3">
                        <a href="#" className="block py-1 pl-1 text-gray-800 no-underline align-middle border-b-2 border-gray-800 md:py-3 hover:text-pink-500 md:border-gray-900 hover:border-pink-500">
                        <i className="pr-0 fas fa-link md:pr-3"></i><span className="block pb-1 text-xs text-gray-600 md:pb-0 md:text-base md:text-gray-400 md:inline-block">Link</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>

        <figure class="relative flex flex-col-reverse bg-slate-50 rounded-lg p-6 dark:bg-slate-800 dark:highlight-white/5">
            <blockquote class="mt-6 text-slate-700 dark:text-slate-300">
                <p>I feel like an idiot for not using Tailwind CSS until now.</p>
            </blockquote>
            <figcaption class="flex items-center space-x-4">
                <img src="/_next/static/media/ryan-florence.34fb7796afb30db4ae598b06a00cbee3.jpg" alt="" class="flex-none w-14 h-14 rounded-full object-cover" loading="lazy"/>
                <div class="flex-auto">
                    <div class="text-base text-slate-900 font-semibold dark:text-slate-300">
                        <a href="https://twitter.com/ryanflorence/status/1187951799442886656" tabindex="0">
                            <span class="absolute inset-0">
                            </span>
                            Ryan Florence
                        </a>
                    </div>
                    <div class="mt-0.5">Remix &amp; React Training</div>
                </div>
            </figcaption>
        </figure>

        {/* FLEX AUTO CARD BUTTON AT END START */}


        <article className="flex items-start p-6 space-x-6">
            <p className="flex-none text-lg font-bold text-gray-800 dark:text-gray-100">Basic information</p>
            <div className="relative flex-auto min-w-0">
                <div className="p-2 text-gray-600 cursor-pointer dark:text-gray-400">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16}>
                        <path className="heroicon-ui" d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9a1 1 0 0 1 1 1v4a1 1 0 0 1-2 0v-4a1 1 0 0 1 1-1zm0-4a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" fill="currentColor" />
                    </svg>
                </div>
                <dl className="flex flex-wrap mt-2 text-sm font-medium leading-6">
                    <div className="absolute top-0 right-0 flex items-center space-x-1">
                    <Button className="bg-indigo-700">Save changes</Button>
                    </div>
                </dl>
            </div>
        </article>


        {/* FLEX AUTO CARD BUTTON AT END */}


        {/* FLEX AUTO CARD START */}
        <article className="flex items-start p-6 space-x-6">
            <img src={`http://localhost:3000/uploads/user-profile-images/${props.auth.user.profile_image}`} alt="" width="60" height="88" className="flex-none rounded-md bg-slate-100" />
            <div className="relative flex-auto min-w-0">
                <h2 className="pr-20 font-semibold truncate text-slate-900">{props.auth.user.firstname}</h2>
                <dl className="flex flex-wrap mt-2 text-sm font-medium leading-6">
                <div className="absolute top-0 right-0 flex items-center space-x-1">
                    <dt className="text-sky-500">
                    <span className="sr-only">Star rating</span>
                    <svg width="16" height="20" fill="currentColor">
                        <path d="M7.05 3.691c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.372 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.539 1.118l-2.8-2.034a1 1 0 00-1.176 0l-2.8 2.034c-.783.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.363-1.118L.98 9.483c-.784-.57-.381-1.81.587-1.81H5.03a1 1 0 00.95-.69L7.05 3.69z" />
                    </svg>
                    </dt>
                    <dd>Sorwar</dd>
                </div>
                <div>
                    <dt className="sr-only">Rating</dt>
                    <dd className="px-1.5 ring-1 ring-slate-200 rounded">4.5</dd>
                </div>
                
                </dl>
            </div>
        </article>
        {/* FLEX AUTO CARD END */}

        {/* Head mobile */}
        <div className="flex items-center p-4 border-b border-slate-900/10 lg:hidden dark:border-slate-50/[0.06] fixed">
            <button type="button" className="text-slate-500 hover:text-slate-600 dark:text-slate-400 dark:hover:text-slate-300">
                <span className="sr-only">Navigation</span>
                <svg width="24" height="24">
                    <path d="M5 6h14M5 12h14M5 18h14" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"></path>
                </svg>
            </button>
            <ol className="flex min-w-0 ml-4 text-sm leading-6 whitespace-nowrap">
                <li className="flex items-center">
                    Getting Started
                    <svg width="3" height="6" aria-hidden="true" className="mx-3 overflow-visible text-slate-400">
                        <path d="M0 0L3 3L0 6" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"></path>
                    </svg>
                </li>
                <li className="font-semibold truncate text-slate-900 dark:text-slate-200">
                    Installation: Tailwind CLI
                </li>
            </ol>
        </div>


            {/* CHAT */}

            <div class="flex-1 p:2 sm:p-6 justify-between flex flex-col h-screen">
                <div class="flex sm:items-center justify-between py-3 border-b-2 border-gray-200">
                    <div class="relative flex items-center space-x-4">
                        <div class="relative">
                            <span class="absolute text-green-500 right-0 bottom-0">
                            <svg width="20" height="20">
                                <circle cx="8" cy="8" r="8" fill="currentColor"></circle>
                            </svg>
                            </span>
                        <img src="https://images.unsplash.com/photo-1549078642-b2ba4bda0cdb?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=3&amp;w=144&amp;h=144" alt="" class="w-10 sm:w-16 h-10 sm:h-16 rounded-full" />
                        </div>
                        <div class="flex flex-col leading-tight">
                            <div class="text-2xl mt-1 flex items-center">
                            <span class="text-gray-700 mr-3">Anderson Vanhron</span>
                            </div>
                            <span class="text-lg text-gray-600">Junior Developer</span>
                        </div>
                    </div>
                    <div class="flex items-center space-x-2">
                        <button type="button" class="inline-flex items-center justify-center rounded-lg border h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-6 w-6">
                            <path strokeLinecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                            </svg>
                        </button>
                        <button type="button" class="inline-flex items-center justify-center rounded-lg border h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-6 w-6">
                            <path strokeLinecap="round" stroke-linejoin="round" stroke-width="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"></path>
                            </svg>
                        </button>
                        <button type="button" class="inline-flex items-center justify-center rounded-lg border h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-6 w-6">
                            <path strokeLinecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"></path>
                            </svg>
                        </button>
                    </div>
                </div>
                <div id="messages" class="flex flex-col space-y-4 p-3 overflow-y-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch">
                    <div class="chat-message">
                        <div class="flex items-end">
                            <div class="flex flex-col space-y-2 text-xs max-w-xs mx-2 order-2 items-start">
                            <div><span class="px-4 py-2 rounded-lg inline-block rounded-bl-none bg-gray-300 text-gray-600">Can be verified on any platform using docker</span></div>
                            </div>
                            <img src="https://images.unsplash.com/photo-1549078642-b2ba4bda0cdb?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=3&amp;w=144&amp;h=144" alt="My profile" class="w-6 h-6 rounded-full order-1" />
                        </div>
                    </div>
                    <div class="chat-message">
                        <div class="flex items-end justify-end">
                            <div class="flex flex-col space-y-2 text-xs max-w-xs mx-2 order-1 items-end">
                            <div><span class="px-4 py-2 rounded-lg inline-block rounded-br-none bg-blue-600 text-white ">Your error message says permission denied, npm global installs must be given root privileges.</span></div>
                            </div>
                            <img src="https://images.unsplash.com/photo-1590031905470-a1a1feacbb0b?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=3&amp;w=144&amp;h=144" alt="My profile" class="w-6 h-6 rounded-full order-2" />
                        </div>
                    </div>
                    <div class="chat-message">
                        <div class="flex items-end">
                            <div class="flex flex-col space-y-2 text-xs max-w-xs mx-2 order-2 items-start">
                            <div><span class="px-4 py-2 rounded-lg inline-block bg-gray-300 text-gray-600">Command was run with root privileges. I'm sure about that.</span></div>
                            <div><span class="px-4 py-2 rounded-lg inline-block bg-gray-300 text-gray-600">I've update the description so it's more obviously now</span></div>
                            <div><span class="px-4 py-2 rounded-lg inline-block bg-gray-300 text-gray-600">FYI https://askubuntu.com/a/700266/510172</span></div>
                            <div>
                                <span class="px-4 py-2 rounded-lg inline-block rounded-bl-none bg-gray-300 text-gray-600">
                                    Check the line above (it ends with a # so, I'm running it as root )
                                    <pre># npm install -g @vue/devtools</pre>
                                </span>
                            </div>
                            </div>
                            <img src="https://images.unsplash.com/photo-1549078642-b2ba4bda0cdb?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=3&amp;w=144&amp;h=144" alt="My profile" class="w-6 h-6 rounded-full order-1" />
                        </div>
                    </div>
                    <div class="chat-message">
                        <div class="flex items-end justify-end">
                            <div class="flex flex-col space-y-2 text-xs max-w-xs mx-2 order-1 items-end">
                            <div><span class="px-4 py-2 rounded-lg inline-block rounded-br-none bg-blue-600 text-white ">Any updates on this issue? I'm getting the same error when trying to install devtools. Thanks</span></div>
                            </div>
                            <img src="https://images.unsplash.com/photo-1590031905470-a1a1feacbb0b?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=3&amp;w=144&amp;h=144" alt="My profile" class="w-6 h-6 rounded-full order-2" />
                        </div>
                    </div>
                    <div class="chat-message">
                        <div class="flex items-end">
                            <div class="flex flex-col space-y-2 text-xs max-w-xs mx-2 order-2 items-start">
                            <div><span class="px-4 py-2 rounded-lg inline-block rounded-bl-none bg-gray-300 text-gray-600">Thanks for your message David. I thought I'm alone with this issue. Please, 👍 the issue to support it :)</span></div>
                            </div>
                            <img src="https://images.unsplash.com/photo-1549078642-b2ba4bda0cdb?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=3&amp;w=144&amp;h=144" alt="My profile" class="w-6 h-6 rounded-full order-1" />
                        </div>
                    </div>
                    <div class="chat-message">
                        <div class="flex items-end justify-end">
                            <div class="flex flex-col space-y-2 text-xs max-w-xs mx-2 order-1 items-end">
                            <div><span class="px-4 py-2 rounded-lg inline-block bg-blue-600 text-white ">Are you using sudo?</span></div>
                            {/* <div><span class="px-4 py-2 rounded-lg inline-block rounded-br-none bg-blue-600 text-white ">Run this command sudo chown -R `whoami` /Users/{{your_user_profile}}/.npm-global/ then install the package globally without using sudo</span></div> */}
                            </div>
                            <img src="https://images.unsplash.com/photo-1590031905470-a1a1feacbb0b?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=3&amp;w=144&amp;h=144" alt="My profile" class="w-6 h-6 rounded-full order-2" />
                        </div>
                    </div>
                    <div class="chat-message">
                        <div class="flex items-end">
                            <div class="flex flex-col space-y-2 text-xs max-w-xs mx-2 order-2 items-start">
                            <div><span class="px-4 py-2 rounded-lg inline-block bg-gray-300 text-gray-600">It seems like you are from Mac OS world. There is no /Users/ folder on linux 😄</span></div>
                            <div><span class="px-4 py-2 rounded-lg inline-block rounded-bl-none bg-gray-300 text-gray-600">I have no issue with any other packages installed with root permission globally.</span></div>
                            </div>
                            <img src="https://images.unsplash.com/photo-1549078642-b2ba4bda0cdb?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=3&amp;w=144&amp;h=144" alt="My profile" class="w-6 h-6 rounded-full order-1" />
                        </div>
                    </div>
                    <div class="chat-message">
                        <div class="flex items-end justify-end">
                            <div class="flex flex-col space-y-2 text-xs max-w-xs mx-2 order-1 items-end">
                            <div><span class="px-4 py-2 rounded-lg inline-block rounded-br-none bg-blue-600 text-white ">yes, I have a mac. I never had issues with root permission as well, but this helped me to solve the problem</span></div>
                            </div>
                            <img src="https://images.unsplash.com/photo-1590031905470-a1a1feacbb0b?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=3&amp;w=144&amp;h=144" alt="My profile" class="w-6 h-6 rounded-full order-2" />
                        </div>
                    </div>
                    <div class="chat-message">
                        <div class="flex items-end">
                            <div class="flex flex-col space-y-2 text-xs max-w-xs mx-2 order-2 items-start">
                            <div><span class="px-4 py-2 rounded-lg inline-block bg-gray-300 text-gray-600">I get the same error on Arch Linux (also with sudo)</span></div>
                            <div><span class="px-4 py-2 rounded-lg inline-block bg-gray-300 text-gray-600">I also have this issue, Here is what I was doing until now: #1076</span></div>
                            <div><span class="px-4 py-2 rounded-lg inline-block rounded-bl-none bg-gray-300 text-gray-600">even i am facing</span></div>
                            </div>
                            <img src="https://images.unsplash.com/photo-1549078642-b2ba4bda0cdb?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=facearea&amp;facepad=3&amp;w=144&amp;h=144" alt="My profile" class="w-6 h-6 rounded-full order-1" />
                        </div>
                    </div>
                </div>
                <div class="border-t-2 border-gray-200 px-4 pt-4 mb-2 sm:mb-0">
                    <div class="relative flex">
                        <span class="absolute inset-y-0 flex items-center">
                            <button type="button" class="inline-flex items-center justify-center rounded-full h-12 w-12 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-6 w-6 text-gray-600">
                                <path strokeLinecap="round" stroke-linejoin="round" stroke-width="2" d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z"></path>
                            </svg>
                            </button>
                        </span>
                        <textarea name="message" class="w-full focus:outline-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 pl-12 bg-gray-200 rounded-md py-3" rows="1" placeholder="Type a message..." autofocus></textarea>

                        {/* <input type="text" placeholder="Write your message!" class="w-full focus:outline-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 pl-12 bg-gray-200 rounded-md py-3" /> */}
                        <div class="absolute right-0 items-center inset-y-0 hidden sm:flex">
                            <button type="button" class="inline-flex items-center justify-center rounded-full h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-6 w-6 text-gray-600">
                                <path strokeLinecap="round" stroke-linejoin="round" stroke-width="2" d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"></path>
                            </svg>
                            </button>
                            <button type="button" class="inline-flex items-center justify-center rounded-full h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-6 w-6 text-gray-600">
                                <path strokeLinecap="round" stroke-linejoin="round" stroke-width="2" d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"></path>
                                <path strokeLinecap="round" stroke-linejoin="round" stroke-width="2" d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"></path>
                            </svg>
                            </button>
                            <button type="button" class="inline-flex items-center justify-center rounded-full h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-6 w-6 text-gray-600">
                                <path strokeLinecap="round" stroke-linejoin="round" stroke-width="2" d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                            </svg>
                            </button>
                            <button type="button" class="inline-flex items-center justify-center rounded-lg px-4 py-3 transition duration-500 ease-in-out text-white bg-blue-500 hover:bg-blue-400 focus:outline-none">
                            <span class="font-bold">Send</span>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="h-6 w-6 ml-2 transform rotate-90">
                                <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
                            </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {users.map(({ 
                            id, 
                            username, 
                            age, 
                            height, 
                            country, 
                            ethnic_origin, 
                            profile_image, 
                            current_profession, 
                            isSaved, 
                            isInvited, 
                            amiInvited, 
                            isRejectedInvitation,
                            amIBeingRejected,
                            hasBeenAccepted,
                            hasAcceptedMyInvitation,
                            amIBeingAccepted,
                            is_verified,
                            }) => (
                                <div className="p-6 mb-2 rounded-lg shadow-md bg-slate-50 dark:bg-slate-800 dark:highlight-white/5" key={username}>
                                    <div className="flex flex-col-reverse">
                                        <Link preserveScroll href={route('user-profile', username)} as="a" className="flex items-center space-x-4">
                                            <img
                                                src={profile_image}
                                                alt={`${username}'s Profile photo`}
                                                className={`flex-none w-12 h-12 rounded-full object-cover`}
                                                loading="lazy"
                                            />
                                            <div className="flex-auto">
                                                <div className="flex items-center space-x-1">
                                                    <p className='text-lg font-bold text-slate-900 dark:text-slate-50'>{username}, {`${age}`}</p>
                                                    {is_verified === 1 && 
                                                        (<div className="relative text-indigo-600 dark:text-indigo-400">
                                                            <ShieldCheckIcon className='w-5 h-5' />
                                                        </div>)
                                                    }
                                                </div>
                                                <div className="text-sm font-medium text-slate-600 dark:text-slate-200">
                                                    {ethnic_origin} From {country} 
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="pl-8">
                                        <div className="flex-none w-full pl-8 text-slate-700">
                                            <p className="text-sm text-slate-600 dark:text-slate-300">{current_profession} - {height}</p>
                                        </div>
                                        <div className='pt-2 pl-8'>
                                            <div className="flex mt-2 space-x-4 text-sm font-medium">
                                                <div className="flex flex-auto space-x-0 sm:space-x-4">
                                                    <Link href={route('user-profile', username)} as='a' className="items-center hidden h-10 px-6 py-2 font-bold text-purple-600 transition duration-300 ease-in-out bg-transparent bg-purple-100 border border-purple-400 rounded-md dark:text-purple-400 dark:bg-slate-800 hover:bg-purple-600 hover:text-white dark:hover:bg-purple-400 dark:hover:text-slate-50 dark:border-purple-400 focus:ring-2 dark:ring-slate-400 sm:inline-flex focus:outline-none">
                                                        <span className="mr-1 text-xs font-semibold sm:text-sm">View</span>
                                                        
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                                                        </svg>
                                                    </Link>

                                                    {auth.user.account_status === 0 || auth.user.account_status === 2 ? ( 
                                                        <button 
                                                            disabled 
                                                            className="inline-flex items-center h-10 px-6 py-2 font-bold bg-transparent border rounded-md disabled:bg-slate-100/20 disabled:text-slate-600 disabled:shadow-none text-slate-800 dark:text-slate-300 dark:bg-slate-700 border-slate-300 bg-slate-200 dark:border-slate-500 focus:ring-2 dark:ring-slate-400 "
                                                        >
                                                            
                                                            <LockClosedIcon className="w-5 h-5 mr-2" />

                                                            <span className="text-xs font-medium sm:text-sm">Invite</span>
                                                        </button>
                                                    ) : (
                                                        <>
                                                            {!isInvited && !amiInvited &&(
                                                                // User can send an invitation
                                                                <Link preserveScroll href={route('invite.user', id)} method="post" as="button"  className="inline-flex items-center h-10 px-6 py-2 font-bold transition duration-300 ease-in-out border rounded-md bg-indigo-50 text-slate-800 dark:text-slate-300 dark:bg-slate-800 hover:bg-slate-800 hover:text-slate-50 dark:hover:bg-slate-50 dark:hover:text-slate-500 border-slate-200 dark:border-slate-50 focus:ring-2 dark:ring-slate-400 focus:outline-none">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 mr-2 rotate-90" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
                                                                    </svg>
                                                                    <span className="text-xs sm:text-sm">Invite</span>
                                                                </Link>
                                                            )}
                                                            {isInvited && !amiInvited && !hasAcceptedMyInvitation &&  !amIBeingRejected && (
                                                                // User can cancel the invitation
                                                                <button
                                                                    onClick={() => cancelInviteRequest(id)}
                                                                    className="inline-flex items-center h-10 px-6 py-2 font-bold text-indigo-500 transition duration-300 ease-in-out bg-transparent border rounded-md dark:text-slate-50 dark:bg-slate-800 hover:bg-slate-100 hover:text-indigo-600 dark:hover:bg-slate-50 dark:hover:text-slate-500 border-slate-200 bg-slate-200 dark:border-slate-50 focus:ring-2 dark:ring-slate-400 focus:outline-none"
                                                                >
                                                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
                                                                    </svg>
                                                                    <span className="text-xs font-medium sm:text-sm">Cancel</span>
                                                                </button>
                                                            )}
                                                        
                                                            {amiInvited && !hasBeenAccepted && !isRejectedInvitation && (
                                                                <>
                                                                    <div className="flex items-center justify-center space-x-1">
                                                                        <Link preserveScroll href={route('accept.user', id)} method="post" as="button" className="inline-flex items-center h-10 px-6 py-2 mr-2 font-bold text-purple-800 transition duration-300 ease-in-out bg-transparent border rounded-md dark:text-slate-300 dark:bg-slate-800 hover:bg-slate-800 hover:text-slate-50 dark:hover:bg-slate-50 dark:hover:text-slate-500 border-slate-200 bg-slate-200 dark:border-slate-50 focus:ring-2 dark:ring-slate-400 focus:outline-none">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 mr-2">
                                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z" />
                                                                            </svg>
                                                                            <span className="text-xs sm:text-sm">Accept</span>
                                                                        </Link>
                                                                        <Link preserveScroll href={route('reject.user', id)} method="post" as="button" className="inline-flex items-center h-10 px-6 py-2 font-bold text-red-400 transition duration-300 ease-in-out rounded-md bg-red-50 dark:bg-red-100 dark:text-red-500 hover:bg-red-200 hover:text-red-500 dark:hover:bg-slate-50 dark:hover:text-red-500 dark:border-slate-50 focus:ring-2 dark:ring-slate-400 focus:outline-none">
                                                                            <span className="text-xs sm:text-sm">Reject</span>
                                                                        </Link>
                                                                    </div>
                                                                </>
                                                            )}
                                                            
                                                            {amIBeingAccepted && (
                                                                // User can chat
                                                                <Link
                                                                    href={route('chatuser', username)}
                                                                    className="inline-flex items-center justify-center h-10 px-6 py-2 font-bold text-indigo-500 transition duration-300 ease-in-out bg-transparent border rounded-md dark:text-slate-50 dark:bg-slate-800 hover:bg-blue-50 hover:text-indigo-600 dark:hover:bg-blue-50 dark:hover:text-blue-500 border-slate-200 bg-slate-200 dark:border-slate-50 focus:ring-2 dark:ring-slate-400 focus:outline-none"
                                                                >
                                                                    <svg className='w-4 h-4 mr-2' width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M19.4313 13.0007C19.4313 13.5312 19.2206 14.0399 18.8455 14.4149C18.4704 14.79 17.9617 15.0007 17.4313 15.0007H5.4313L1.4313 19.0007V3.00073C1.4313 2.4703 1.64202 1.96159 2.01709 1.58652C2.39216 1.21145 2.90087 1.00073 3.4313 1.00073H17.4313C17.9617 1.00073 18.4704 1.21145 18.8455 1.58652C19.2206 1.96159 19.4313 2.4703 19.4313 3.00073V13.0007Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                                    </svg>
                                                                    <span className="text-xs font-medium sm:text-sm">Chat</span>
                                                                </Link>
                                                            )}
                                                            {hasAcceptedMyInvitation && (
                                                                // User can chat
                                                                <Link
                                                                    href={route('chatuser', username)}
                                                                    className="inline-flex items-center justify-center h-10 px-6 py-2 font-bold text-indigo-500 transition duration-300 ease-in-out bg-transparent border rounded-md dark:text-slate-50 dark:bg-slate-800 hover:bg-blue-50 hover:text-indigo-600 dark:hover:bg-blue-50 dark:hover:text-blue-500 border-slate-200 bg-slate-200 dark:border-slate-50 focus:ring-2 dark:ring-slate-400 focus:outline-none"
                                                                >
                                                                    <svg className='w-4 h-4 mr-2' width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M19.4313 13.0007C19.4313 13.5312 19.2206 14.0399 18.8455 14.4149C18.4704 14.79 17.9617 15.0007 17.4313 15.0007H5.4313L1.4313 19.0007V3.00073C1.4313 2.4703 1.64202 1.96159 2.01709 1.58652C2.39216 1.21145 2.90087 1.00073 3.4313 1.00073H17.4313C17.9617 1.00073 18.4704 1.21145 18.8455 1.58652C19.2206 1.96159 19.4313 2.4703 19.4313 3.00073V13.0007Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                                    </svg>
                                                                    <span className="text-xs font-medium sm:text-sm">Chat</span>
                                                                </Link>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                                
                                                {/* Save button */}

{!isSaved ? ( 
                                                    <Link preserveScroll method="post" href={route('save.user', id)} className="flex items-center justify-center flex-none w-8 h-8 transition duration-300 ease-in-out border rounded-md text-slate-400 border-slate-400 dark:border-slate-400 hover:bg-red-400 hover:text-slate-50 dark:hover:bg-slate-50 dark:text-slate-600" type="button" aria-label="Like">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                                                        </svg>
                                                    </Link> )
                                                : (
                                                    <Link preserveScroll method="post" href={route('unsave.user', id)} className="flex items-center justify-center flex-none w-8 h-8 transition duration-300 ease-in-out border rounded-md text-slate-400 border-slate-400 dark:border-slate-500 hover:bg-red-400 hover:text-slate-50 dark:hover:bg-slate-50 dark:text-slate-600" type="button" aria-label="Like">
                                                        <svg className="text-red-400 hover:text-white dark:hover:text-red-400" width="20" height="20" fill="currentColor" aria-hidden="true">
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z" />
                                                        </svg>
                                                    </Link>
                                                )}

                                                {/* Pass button */}
                                                {!amIBeingAccepted && !hasAcceptedMyInvitation &&
                                                    <Link preserveScroll method="post" href={route('pass-user', id)} className="flex items-center justify-center flex-none w-8 h-8 transition duration-300 ease-in-out border rounded-md text-slate-400 border-slate-400 dark:border-slate-400 hover:bg-red-400 hover:text-white dark:hover:bg-red-400 dark:hover:text-white dark:text-slate-500" type="button" aria-label="Like">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                                        </svg>
                                                    </Link>
                                                }
                                                {amIBeingAccepted && (
                                                    <div>
                                                        <div className='flex flex-col col-span-1 mt-1 text-center'>
                                                            <Dropdown>
                                                                <Dropdown.Trigger>
                                                                    <span className="inline-flex rounded-md dark:text-slate-300">
                                                                        <button className="dark:text-slate-300">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="w-5 h-5 text-gray-700 bi bi-three-dots-vertical dark:text-slate-300" viewBox="0 0 16 16">
                                                                                <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                                                            </svg>
                                                                        </button>
                                                                    </span>
                                                                </Dropdown.Trigger>

                                                                <Dropdown.Content >
                                                                    <Dropdown.Link className="px-2 py-2" href={route('pass-user', id)} method="post" as="button">
                                                                        <div className="inline-flex items-center w-full gap-3 px-2 py-2 text-sm leading-5 text-left text-gray-500 transition-all duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus:bg-gray-100">
                                                                            <span>
                                                                                <XIcon className="w-6 h-6" />
                                                                            </span>
                                                                            <span>Hide from search</span>
                                                                        </div>
                                                                    </Dropdown.Link>
                                                                    <Dropdown.Link className="px-2 py-2" href={route('block-user', id)} method="post" as="button">
                                                                        <div className="inline-flex items-center w-full gap-3 px-4 py-2 text-sm leading-5 text-left text-gray-500 transition-all duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus:bg-gray-100">
                                                                            <span>
                                                                                <UserRemoveIcon className="w-6 h-6" />
                                                                            </span>
                                                                            <span>Block</span>
                                                                        </div>
                                                                    </Dropdown.Link>
                                                                </Dropdown.Content>
                                                            </Dropdown>
                                                        </div>
                                                    </div>
                                                )}
                                                {hasAcceptedMyInvitation && (
                                                    <div>
                                                        <div className='flex flex-col col-span-1 mt-1 text-center'>
                                                            <Dropdown>
                                                                <Dropdown.Trigger>
                                                                    <span className="inline-flex rounded-md dark:text-slate-300">
                                                                        <button className="dark:text-slate-300">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="w-5 h-5 text-gray-700 bi bi-three-dots-vertical dark:text-slate-300" viewBox="0 0 16 16">
                                                                                <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                                                            </svg>
                                                                        </button>
                                                                    </span>
                                                                </Dropdown.Trigger>

                                                                <Dropdown.Content >
                                                                    <Dropdown.Link className="px-2 py-2" href={route('pass-user', id)} method="post" as="button">
                                                                        <div className="inline-flex items-center w-full gap-3 px-2 py-2 text-sm leading-5 text-left text-gray-500 transition-all duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus:bg-gray-100">
                                                                            <span>
                                                                                <XIcon className="w-6 h-6" />
                                                                            </span>
                                                                            <span>Hide from search</span>
                                                                        </div>
                                                                    </Dropdown.Link>
                                                                    <Dropdown.Link className="px-2 py-2" href={route('block-user', id)} method="post" as="button">
                                                                        <div className="inline-flex items-center w-full gap-3 px-4 py-2 text-sm leading-5 text-left text-gray-500 transition-all duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus:bg-gray-100">
                                                                            <span>
                                                                                <UserRemoveIcon className="w-6 h-6" />
                                                                            </span>
                                                                            <span>Block</span>
                                                                        </div>
                                                                    </Dropdown.Link>
                                                                </Dropdown.Content>
                                                            </Dropdown>
                                                        </div> 
                                                    </div>
                                                )}
                                            </div>
                                           
                                            {isRejectedInvitation &&(
                                                <p className="pt-2 text-sm text-yellow-600">
                                                    You have rejected this user!
                                                </p>
                                            )}
                                            
                                        </div>
                                    </div>
                                </div>
                        ))}
    </div>



  )
}

export default Extra



