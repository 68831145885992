import { Link } from '@inertiajs/inertia-react';

const EmptyStateMessage = ({ title, description, buttonText, buttonUrl, icon }) => {
    return (
        <div className="relative block w-full p-12 mx-auto mt-4 text-center border-2 border-gray-300 border-dashed rounded-lg hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            <div className="text-center">
                {/* SVG Icon */}
                {icon && (
                    <div className="w-12 h-12 mx-auto text-gray-700 dark:text-white">
                        {icon}
                    </div>
                )}

                {/* Title */}
                <h3 className="mt-2 text-sm font-semibold text-gray-700 dark:text-white">{title}</h3>
                
                {/* Description */}
                <p className="mt-1 text-sm text-gray-500 dark:text-gray-300">{description}</p>

                {/* Button */}
                <div className="mt-6">
                    <Link
                        href={buttonUrl}
                        as='a'
                        className="inline-flex items-center px-4 py-3 text-sm font-semibold text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        {buttonText}
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default EmptyStateMessage;
