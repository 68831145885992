import Footer from "@/Layouts/Footer";
import React from "react";
import { ThemeContext } from '@/context/ThemeContext';
import { Link } from "@inertiajs/inertia-react";

import LogoPurple from '../../../public/assets/images/logo-purple.svg';
import LogoLight from '../../../public/assets/images/logo-light.svg';


const About = (props) => {
    const { theme, setTheme } = React.useContext(ThemeContext);
    return (
      <>
        <nav className="bg-white px-5 sm:px-20 py-2  dark:bg-slate-800 border-b border-gray-100 dark:border-slate-500 sticky top-0 z-40 backdrop-blur flex-none transition-colors duration-500 lg:z-50 lg:border-b lg:border-slate-900/10 dark:border-slate-50/[0.06] bg-white/95 supports-backdrop-blur:bg-white/60 dark:bg-transparent">
          <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
              <div className="flex flex-wrap items-center justify-between">
                  <Link as="a" href="/" className="flex items-center justify-center">
                      <img className="block w-full h-7 dark:hidden" src={LogoPurple} alt="shape" />
                      <img className="hidden w-full h-7 dark:block" src={LogoLight} alt="shape" />
                  </Link>
                  <div className="flex space-x-3">
                      {theme === 'dark' ? (
                          <button id="theme-toggle" type="button" onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')} className={`${theme === 'light' ? 'hidden' : ''}text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5`}>
                              <svg id="theme-toggle-dark-icon" xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z" /></svg>
                          </button>

                              ) : (
                              <button id="theme-toggle" type="button" onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')} className={`${theme === 'dark' ? 'hidden' : ''}text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5`}>
                                  <svg id="theme-toggle-light-icon" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z" fillRule="evenodd" clipRule="evenodd"></path></svg>
                              </button>
                          )}

                          {props.auth.user ? (
                                  <Link href={route('home')} className="font-semibold main-btn gradient-btn">
                                      {props.auth.user.username}
                                  </Link>
                              ) : (
                                  <Link href={route('login')} className="font-semibold main-btn gradient-btn">
                                      Get started
                                  </Link>
                          )}
                      </div>
              </div>
          </div>
        </nav>
        <section className="bg-white dark:bg-slate-800/50">
        <div className="px-6 py-32 bg-white dark:bg-slate-800/50 lg:px-8">
      <div className="max-w-4xl mx-auto text-base leading-7 text-gray-700">
        <p className="text-base font-semibold leading-7 text-purple-600 dark:text-purple-400 animate-fade-in">About us</p>
        <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-slate-50 sm:text-4xl animate-fade-in">A modern approach to traditional matchmaking</h1>
        <p className="mt-6 text-xl leading-8 text-gray-800 dark:text-slate-200 animate-fade-in">
          Welcome to Suitable, where tradition meets modernity to foster meaningful connections. As a concept, matchmaking dates back to the fifth century, and for some cultures and Muslims, remains as intrinsic to the process of finding a spouse as it was over a millennium ago.
        </p>
        <div className="max-w-4xl mt-10 animate-fade-in">
          <p className="mt-8 text-lg text-gray-700 dark:text-slate-300">
            We understand the importance of finding a life partner who shares your values, beliefs, and cultural background. That’s why we’ve created a space where Muslim singles can meet, connect, and build lasting relationships in a way that respects their faith and traditions while embracing contemporary matchmaking methods. 
          </p>
          
          <p className="mt-8 text-lg text-gray-700 dark:text-slate-300">
            Our team is dedicated to providing a safe and inclusive environment for Muslims from all walks of life to come together and explore the possibility of finding their perfect match. Our platform is designed to cater to your unique needs and preferences. What sets us apart is our commitment to combining traditional matchmaking principles with modern technology. 
          </p>
          {/* <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">From beginner to expert in 3 hours</h2> */}
          <p className="mt-6 text-xl text-indigo-400 dark:text-indigo-300">
            It is a core tenet of our service that we vet our network of members during the application process – we regard this as intrinsic to protecting the safety and wellbeing of our clients.
          </p>
          
        </div>
        
        
      </div>
    </div>
        </section>
        
        
        <Footer />

      </>
    );
};

export default About;



const ContactTextArea = ({ row, placeholder, name, defaultValue }) => {
    return (
      <>
        <div className="mb-6">
          <textarea
            rows={row}
            placeholder={placeholder}
            name={name}
            className="w-full resize-none rounded border border-stroke px-[14px] py-3 text-base text-body-color outline-none focus:border-primary dark:border-dark-3 dark:bg-dark dark:text-dark-6"
            defaultValue={defaultValue}
          />
        </div>
      </>
    );
  };
  
  const ContactInputBox = ({ type, placeholder, name }) => {
    return (
      <>
        <div className="mb-6">
          <input
            type={type}
            placeholder={placeholder}
            name={name}
            className="w-full rounded border border-stroke px-[14px] py-3 text-base text-body-color outline-none focus:border-primary dark:border-dark-3 dark:bg-dark dark:text-dark-6"
          />
        </div>
      </>
    );
  };