import React from 'react';

export default function Button({ type = 'submit', className = '', processing, children }) {
    return (
        <button
            type={type}
            className={
                `px-3 py-2 border border-purple-700 text-purple-700 bg-transparent dark:bg-slate-700/25 hover:bg-purple-700 hover:border-purple-900 hover:text-white dark:border-purple-200 dark:text-purple-200 dark:hover:bg-purple-500 dark:hover:text-white dark:hover:border-purple-500 rounded-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500 ${
                    processing && 'opacity-25'
                } ` + className
            }
            disabled={processing}
        >
            {children}
        </button>
    );
}
