import '@themesberg/flowbite';
import { Link } from '@inertiajs/inertia-react';
import Footer from '@/Layouts/Footer';
import { ThemeContext } from '@/context/ThemeContext';

import LogoPurple from '../../../public/assets/images/logo-purple.svg';
import LogoLight from '../../../public/assets/images/logo-light.svg';
import React from 'react';
const stats = [
    { id: 1, name: 'Members', value: '200+' },
    { id: 2, name: 'Connections', value: '80%' },
    { id: 3, name: 'Privacy', value: '100%' },
    { id: 4, name: 'Subscribers', value: '200+' },
  ]
export default function Welcome(props) {
    const { theme, setTheme } = React.useContext(ThemeContext);

    return ( 
        <>
           
            {/* <Head title="Suitable" /> */}
            {/* <nav className="sticky top-0 left-0 z-40 w-full px-5 py-2 mx-auto duration-300 bg-white shadow-md sm:px-20 dark:bg-gray-800"> */}
                <nav className="px-5 sm:px-20 py-2 border-b border-gray-100 dark:border-slate-500 sticky bg-white dark:bg-gray-900 top-0 z-40 shrink-0 top-0 backdrop-blur flex-none transition-colors duration-500 lg:z-50 lg:border-b lg:border-slate-900/10 dark:border-slate-50/[0.06] supports-backdrop-blur:bg-white/60 dark:bg-transparent">
                    <div className="mx-auto max-w-8xl lg:max-w-7xl sm:px-6 lg:px-8">
                        <div className="flex items-center justify-between">
                            <Link as="a" href="/" className="flex items-center justify-center">
                                <img className="block w-full h-7 dark:hidden" src={LogoPurple} alt="shape" />
                                <img className="hidden w-full h-7 dark:block" src={LogoLight} alt="shape" />
                            </Link>
                            <div className="flex space-x-3">
                                {theme === 'dark' ? (
                                    <button id="theme-toggle" type="button" onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')} className={`${theme === 'light' ? 'hidden' : ''}text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5`}>
                                        <svg id="theme-toggle-dark-icon" xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z" /></svg>
                                    </button>

                                        ) : (
                                        <button id="theme-toggle" type="button" onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')} className={`${theme === 'dark' ? 'hidden' : ''}text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5`}>
                                            <svg id="theme-toggle-light-icon" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z" fillRule="evenodd" clipRule="evenodd"></path></svg>
                                        </button>
                                )}

                                {props.auth.user ? (
                                        <Link href={route('home')} className="font-semibold main-btn gradient-btn">
                                            {props.auth.user.username}
                                        </Link>
                                    ) : (
                                        <Link href={route('login')} className="font-semibold main-btn gradient-btn">
                                            Get started
                                        </Link>
                                )}
                            </div>
                        </div>
                    </div>
                </nav>
                <section className="h-screen px-4 pt-20 pb-10 mx-auto overflow-hidden text-center max-w-7xl sm:px-6 lg:px-8 lg:pt-32">
                    <div className="relative">
                        <div
                            className="absolute rotate-90 -bottom-8 -left-90 -z-10 transform-gpu blur-3xl sm:-bottom-64 sm:-left-40 lg:-bottom-32 lg:left-8 xl:-left-10"
                            aria-hidden="true"
                        >
                            <div
                                className="aspect-[1266/975] w-[79.125rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20 animate-sway"
                                style={{
                                clipPath:
                                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                                }}
                            />
                        </div>

                        <div className="hidden sm:relative sm:inset-y-0 sm:block sm:h-full sm:w-full" aria-hidden="true">
                            <div className="relative h-full mx-auto max-w-7xl">
                                <svg
                                    className="absolute transform right-full translate-x-1/4 translate-y-1/4 lg:translate-x-1/4"
                                    width={204}
                                    height={384}
                                    fill="none"
                                    viewBox="0 0 204 384"
                                >
                                    <defs>
                                    <pattern
                                        id="4522f7d5-8e8c-43ee-89bd-ad34cbfb07fa"
                                        x={0}
                                        y={0}
                                        width={20}
                                        height={20}
                                        patternUnits="userSpaceOnUse"
                                    >
                                        <rect x={0} y={0} width={4} height={4} className="text-gray-200 dark:text-gray-700/50" fill="currentColor" />
                                    </pattern>
                                    </defs>
                                    <rect width={204} height={384} fill="url(#4522f7d5-8e8c-43ee-89bd-ad34cbfb07fa)" />
                                </svg>
                                <svg
                                    className="absolute transform left-full -translate-x-1/4 -translate-y-3/4 md:-translate-y-1/2 lg:-translate-x-1/4"
                                    width={204}
                                    height={384}
                                    fill="none"
                                    viewBox="0 0 204 384"
                                >
                                    <defs>
                                    <pattern
                                        id="5d0dd344-b041-4d26-bec4-8d33ea57ec9b"
                                        x={0}
                                        y={0}
                                        width={20}
                                        height={20}
                                        patternUnits="userSpaceOnUse"
                                    >
                                        <rect x={0} y={0} width={4} height={4} className="text-gray-200 dark:text-gray-700/50" fill="currentColor" />
                                    </pattern>
                                    </defs>
                                    <rect width={204} height={384} fill="url(#5d0dd344-b041-4d26-bec4-8d33ea57ec9b)" />
                                </svg>
                            </div>
                        </div>
                        <div className="">
                            <div className="relative flex flex-col items-center">
                                <h3 className="relative inline-block text-2xl font-bold tracking-normal text-center text-slate-900 md:font-semibold xs:text-xl sm:text-4xl md:text-5xl dark:text-white animate-fade-in">
                                    And we created you in pairs.
                                    <span
                                        className="absolute right-0 text-sm font-semibold tracking-wide text-red-400 uppercase border-b-2 border-red-400 -bottom-6 dark:text-red-400"
                                    >
                                        Al Quran
                                    </span>
                                </h3>
                            </div>
                            <p className="max-w-3xl px-4 mx-auto mt-8 text-lg leading-7 text-justify text-slate-600 dark:text-slate-300 animate-fade-in">
                                    In today’s busy world of working professionals we understand that is difficult to find and meet other like minded Muslim individuals for the purpose of marriage. Where time is of the essence, and keeping your faith and traditions in place, we understand that it is difficult finding the time to find a suitable match. This is why we do the hard work for you.
                                    Our approach is reassuringly traditional and Islamic, being british but with a global reach. 
                                    We are selective about who we accept to join our platform, we are certainly not right for everyone.
                            </p>
                            <div className="flex items-center justify-center mt-6 space-x-1 sm:space-x-2 sm:mt-10">
                                <Link href={route('register')} className="font-semibold main-btn gradient-btn">
                                    Find your pair
                                </Link>
                                <Link href={route('about')} 
                                      className="items-center justify-center px-3 text-sm font-semibold text-gray-900 hover:text-gray-800 sm:flex focus:outline-none dark:text-gray-200 sm:w-auto dark:hover:text-gray-100 group"
                                      >
                                    Read more
                                    <span
                                        className="ml-1 text-lg transition-transform duration-300 ease-in goup-hover:text-xl group-hover:translate-x-1"
                                        aria-hidden="true"
                                    >
                                        →
                                    </span>
                                </Link>                                
                            </div>
                        </div>
                    </div>
                </section>
                
                <div className="" style={{ fontFamily: '"Lato", sans-serif' }}>                    
                    <section className="container max-w-full pt-16 mx-auto bg-gray-50 dark:bg-slate-950/10 dark:text-slate-50">
                        <div>
                            <div role="contentinfo" className="flex flex-col items-center px-4">
                                <h2 tabIndex={0} className="text-sm font-semibold leading-4 text-center text-red-400 uppercase animate-slide-fwd">We made it super easy</h2>
                                <h1 tabIndex={0} className="pt-2 text-3xl font-bold leading-10 text-center text-gray-800 focus:outline-none lg:text-3xl dark:text-slate-50 lg:w-5/12 md:w-9/12 animate-fade-in">Create your profile &amp; start matching today</h1>
                            </div>
                            <div tabIndex={0} aria-label="group of cards" className="flex flex-wrap justify-center gap-10 px-4 mt-20 focus:outline-none">
                                <div tabIndex={0} aria-label="card 1" className="flex pb-20 focus:outline-none sm:w-full md:w-5/12">
                                    <div className="relative w-20 h-20 mr-5">
                                        <div className="absolute top-0 right-0 w-16 h-16 mt-2 mr-1 bg-purple-100 rounded" />
                                        <div className="absolute bottom-0 left-0 flex items-center justify-center w-16 h-16 mt-2 mr-3 text-white bg-purple-600 rounded">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="w-10/12">
                                        <h2 tabIndex={0} className="text-lg font-bold leading-tight text-gray-800 focus:outline-none dark:text-slate-50">Create a new account</h2>
                                        <p tabIndex={0} className="pt-2 text-base leading-normal text-gray-600 focus:outline-none dark:text-slate-400">We provided a very simple start, no need to write a lot. Simply add your basic information. </p>
                                    </div>
                                </div>
                                <div tabIndex={0} aria-label="card 2" className="flex pb-20 focus:outline-none sm:w-full md:w-5/12">
                                    <div className="relative w-20 h-20 mr-5">
                                        <div className="absolute top-0 right-0 w-16 h-16 mt-2 mr-1 bg-purple-100 rounded" />
                                        <div className="absolute bottom-0 left-0 flex items-center justify-center w-16 h-16 mt-2 mr-3 text-white bg-purple-600 rounded">
                                            <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/icon_and_text-SVG2.svg" alt="check" />
                                        </div>
                                    </div>
                                    <div className="w-10/12">
                                        <h2 tabIndex={0} className="text-lg font-semibold leading-tight text-gray-800 focus:outline-none dark:text-slate-50">Set up your profile</h2>
                                        <p tabIndex={0} className="pt-2 text-base leading-normal text-gray-600 focus:outline-none dark:text-slate-400">We only ask the most relevant information to get you started. Skip where you don't feel like to share.</p>
                                    </div>
                                </div>
                                <div tabIndex={0} aria-label="card 3" className="flex pb-20 focus:outline-none sm:w-full md:w-5/12">
                                    <div className="relative w-20 h-20 mr-5">
                                        <div className="absolute top-0 right-0 w-16 h-16 mt-2 mr-1 bg-purple-100 rounded" />
                                        <div className="absolute bottom-0 left-0 flex items-center justify-center w-16 h-16 mt-2 mr-3 text-white bg-purple-600 rounded">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8" viewBox="0 0 20 20" fill="currentColor">
                                                <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z" />
                                                <path fillRule="evenodd" d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z" clipRule="evenodd" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="w-10/12">
                                        <h2 tabIndex={0} className="text-lg font-semibold leading-tight text-gray-800 focus:outline-none dark:text-slate-50">Choose a plan</h2>
                                        <p tabIndex={0} className="pt-2 text-base leading-normal text-gray-600 focus:outline-none dark:text-slate-400">With a subscription plan you can enjoy all our unlimited features. Cancel anytime! </p>
                                    </div>
                                </div>
                                <div tabIndex={0} aria-label="card 4" className="flex pb-20 focus:outline-none sm:w-full md:w-5/12">
                                    <div className="relative w-20 h-20 mr-5">
                                        <div className="absolute top-0 right-0 w-16 h-16 mt-2 mr-1 bg-purple-100 rounded" />
                                        <div className="absolute bottom-0 left-0 flex items-center justify-center w-16 h-16 mt-2 mr-3 text-white bg-purple-600 rounded">
                                            
                                            <svg className="w-8 h-8 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="w-10/12">
                                        <h2 tabIndex={0} className="text-lg font-semibold leading-tight text-gray-800 focus:outline-none dark:text-slate-50">Start matching</h2>
                                        <p tabIndex={0} className="pt-2 text-base leading-normal text-gray-600 focus:outline-none dark:text-slate-400">We only allow vetted members to join our platform.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* <div className="relative max-w-full px-2 py-24 pt-16 mx-auto overflow-hidden bg-white sm:px-32 dark:bg-gray-900/10 isolate sm:py-32">
                        <div className="relative px-6 mx-auto max-w-7xl lg:px-8">
                            <div
                            className="absolute -bottom-8 -left-96 -z-10 transform-gpu blur-3xl sm:-bottom-64 sm:-left-40 lg:-bottom-32 lg:left-8 xl:-left-10"
                            aria-hidden="true"
                            >
                            <div
                                className="aspect-[1266/975] w-[79.125rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
                                style={{
                                clipPath:
                                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                                }}
                            />
                            </div>
                            <div className="max-w-4xl mx-auto lg:mx-0 lg:max-w-xl">
                                <h2 className="text-sm font-semibold leading-4 text-left text-red-400 uppercase">Our track record</h2>
                                <h1 className="mt-2 text-3xl font-bold leading-10 tracking-normal text-gray-900 dark:text-white sm:text-3xl">
                                    Made for professionals by professionals.
                                </h1>
                                <p className="mt-6 text-lg leading-6 text-gray-600 dark:text-gray-300">
                                    We are selective about who we accept to join our platform, we are certainly not right for everyone. Compromise is simply not an option. <br/> For them, or us. 
                                </p>
                            </div>
                            <dl className="grid max-w-2xl grid-cols-1 mx-auto mt-16 text-gray-900 dark:text-white gap-x-8 gap-y-10 sm:mt-20 sm:grid-cols-2 sm:gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-4">
                                {stats.map((stat) => (
                                    <div key={stat.id} className="flex flex-col pl-6 border-l gap-y-3 border-slate-300 dark:border-white/10">
                                    <dt className="text-sm leading-6">{stat.name}</dt>
                                    <dd className="order-first text-3xl font-semibold tracking-tight">{stat.value}</dd>
                                    </div>
                                ))}
                            </dl>
                        </div>
                    </div> */}
                </div>
                {/* <section id="about" className="pt-20 about-area">
                    <div className="container">
                        <div className="row">
                            <div className="w-full lg:w-1/2">
                                <div className="mx-4 mt-12 about-content">
                                    <div className="relative mb-4 section-title">
                                        <div className="line"></div>
                                        <h3 className="title">And we created you <span className="font-bold text-red-400">in pairs</span> <span className="text-lg font-normal sm:text-3xl">-Quran 78:8</span></h3>
                                    </div>
                                    <p className="mb-8 text-lg text-slate-600 dark:text-slate-400">Suitable is a trusted muslim match
                                        making platform performing all over the world. We are working to
                                        connect you with your partner without the exception of race, color, and ethnicity.</p>

                                    <Link href={route('register')} className="main-btn gradient-btn">
                                        Find your pair
                                    </Link>
                                </div>
                            </div>
                            <div className="w-full lg:w-1/2">
                                <div className="mx-4 mt-12 text-center about-image wow fadeInRightBig" data-wow-duration="1s" data-wow-delay="0.5s">
                                    <img src="assets/images/about1.svg" alt="about" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="about-shape-1">
                        <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
                            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <g id="dots" fill="#000000">
                                    <circle id="Oval-377-Copy-9" cx="3" cy="3" r="3"></circle>
                                    <circle id="Oval-377-Copy-14" cx="13" cy="13" r="3"></circle>
                                </g>
                            </g>
                        </svg>
                    </div>
                </section> */}
            <Footer />
            {/* <div
                className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
                aria-hidden="true"
                >
                <div
                    className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                    style={{
                    clipPath:
                        'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                    }}
                />
            </div> */}

        </>
    );
}

                

 {/* In today’s busy world of working professionals we understand that is difficult to find and meet other like minded Muslim individuals for the purpose of marriage. 

Where time is of the essence, and keeping your faith and traditions in place, we understand that it is difficult finding the time to find a suitable match. 

This is why we do the hard work for you.
<br/>
<br/>
Our approach is reassuringly traditional and Islamic, being british but with a global reach. 

We are selective about who we accept to join our platform, we are certainly not right for everyone. 

Compromise is simply not an option. For them, or us.
Made for professionals by professionals. */}