import React, { useEffect, useState, useRef } from 'react';
import Button from '@/Components/Button';
import Guest from '@/Layouts/Guest';
import Input from '@/Components/Input';
import ValidationErrors from '@/Components/ValidationErrors';
import { Head, Link, useForm } from '@inertiajs/inertia-react';
import { Inertia } from '@inertiajs/inertia';

function UploadProfilePic({ errors }) {
  const photoRef = useRef();

  const [selectedFile, setSelectedFile] = useState();
  const [link, setLink] = useState(false);
  const [values, setValues] = useState({ photo: '', blur: false });

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    setValues((prevValues) => ({ ...prevValues, blur: !prevValues.blur }));
  };
  
  const formData = new FormData();

  const onHandleChange = (e) => {

    formData.append('photo', photoRef.current.files[0]);

    // formData.append('photo', values.photo);

    Inertia.post('upload-profile-pic', formData, {
      forceFormData: true,
    });
    setSelectedFile(URL.createObjectURL(e.target.files[0]));

    setLink(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    formData.append('blur_profile_image', values.blur ? 1 : 0); 
    Inertia.post('uploaded', formData, {
        forceFormData: true,
      });
  };

  return (
    <Guest
      bgimage="bg-upload-background"
      title="Help us find the perfect match for you"
      subtitle="Upload your photos here"
      linktext="Need help ?"
      href="/"
      btnName="Contact support"
    >
      <Head title="Suitable | Set up profile" />

      <div className="">
        <div className=" mb-2 items-baseline">
          <h2 className="mt-6 text-lg font-semibold text-slate-900 dark:text-purple-50">
            Add a photo of yourself
          </h2>
          <p className="mt-1 text-sm font-normal text-gray-500 dark:text-slate-200">
            Your profile will be only visible to other members when you add a
            photo.
          </p>
        </div>
      </div>

      <ValidationErrors errors={errors} />

        <div className="flex justify-center mt-8">
          <div className="rounded-lg shadow-xl bg-gray-50">
            <div className="m-4">
              <div className="flex items-center justify-center w-full">
              <form>
                {!selectedFile ? (
                  <label className="flex flex-col w-40 h-40 border-2 border-slate-100 border-dashed hover:bg-gray-100 hover:border-gray-300">
                    <div className="flex flex-col items-center justify-center pt-10">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-8 h-8 text-gray-400 group-hover:text-gray-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                        />
                      </svg>
                      <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                        Choose a photo
                      </p>
                    </div>

                    <input
                      ref={photoRef}
                      type="file"
                      value={values.photo}
                      className="opacity-0"
                      onChange={onHandleChange}
                    />
                  </label>
                ) : (
                  <div className="h-40 w-40 relative">
                    <img
                      src={selectedFile}
                      layout="fill"
                      className={`object-cover aspect-square ${
                        values.blur ? 'blur-sm' : ''
                      }`}
                    />
                    <label className="mt-5 cursor-pointer flex flex-col w-full h-8 p-1 border-b-2 hover:bg-gray-100 hover:border-gray-300">
                      <p className="text-sm tracking-wider cursor-pointer text-gray-400 group-hover:text-gray-600">
                        Click here to change
                      </p>
                      <input
                        ref={photoRef}
                        type="file"
                        value={values.photo}
                        className="opacity-0"
                        onChange={onHandleChange}
                      />
                    </label>
                  </div>
                )}
                </form>
              </div>
            </div>
          </div>
        </div>
        {selectedFile ? (
          <form onSubmit={handleSubmit}>
            <div className="inline-flex items-center mb-3 mt-20">
              <div className="relative inline-block w-8 h-4 -mt-5 rounded-full cursor-pointer">
                <input
                  type="checkbox"
                  id="blurCheckbox"
                  name='blur_profile_image'
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  className="absolute w-8 h-4 transition-colors duration-300 rounded-full appearance-none cursor-pointer peer bg-blue-gray-100 checked:bg-gray-900 peer-checked:border-gray-900 peer-checked:before:bg-gray-900"
                />
                <label
                  htmlFor="blurCheckbox"
                  className="before:content[''] absolute top-2/4 -left-1 h-5 w-5 -translate-y-2/4 cursor-pointer rounded-full border border-blue-gray-100 bg-white shadow-md transition-all duration-300 before:absolute before:top-2/4 before:left-2/4 before:block before:h-10 before:w-10 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity hover:before:opacity-10 peer-checked:translate-x-full peer-checked:border-gray-900 peer-checked:before:bg-gray-900"
                >
                  <div className="inline-block p-5 rounded-full top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4"></div>
                </label>
              </div>
              <label
                htmlFor="blurCheckbox"
                className="mt-px mb-0 ml-3 font-light text-gray-700 cursor-pointer select-none"
              >
                <div>
                  <p className="block font-sans text-base antialiased font-medium leading-relaxed text-blue-gray-900 dark:text-slate-50">
                    {values.blur ? 'Unblur' : 'Blur'} Photo
                  </p>
                  <p className="block font-sans text-sm antialiased font-normal leading-normal text-gray-700 dark:text-slate-300">
                    {values.blur
                      ? 'Unblur all photos to make them visible to others.'
                      : 'Blur all photos to make them visible only to invited users.'}
                  </p>
                </div>
              </label>
            </div>
            {link && (
              <Button 
                // href={route('uploaded')}
                type="submit"
                className="w-full items-center justify-center "
              >
                Upload
              </Button>
            )}
          </form>
        ) : (
          <div className="mt-6">
            <p className="text-slate-700 dark:text-purple-50">Instruction</p>
            <ul className="list-inside list-disc text-sm text-slate-400 dark:text-purple-200">
              <li>Upload clear photos</li>
              <li>It is better to avoid group photos</li>
              <li>Please upload your photos only.</li>
              <li>
                Avoid adding photos of dolls, celebrities, pets scenery etc.
              </li>
            </ul>
          </div>
        )}
      

      
    </Guest>
  );
}

export default UploadProfilePic;